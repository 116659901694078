<script>
import {mapGetters, mapMutations} from "vuex";

export default {
  data () {
    return {
      newOnline: 0,
    }
  },
  methods: {
    ...mapMutations({
      setIsShowChat: 'config/setIsOpenChat',
      setIsShowChatRules: 'config/setIsShowChatRules'
    })
  },
  computed: {
    ...mapGetters({
      settings: 'settings/settings'
    }),
    online () {
      if (!this.newOnline) {
        return this.settings?.online ?? 0;
      }

      return this.newOnline
    }
  },
  name: "ChatHead",
  mounted() {
    this.$echo.channel(`online`).listen(".OnlineUpdatedEvent", async (payload) => {
      this.newOnline = payload.online
    })
  }
}
</script>

<template>
  <div class="chat-head">
    <div class="chat-head_controls">
      <div class="online">
        <div class="circle"></div>
        <span>{{ online }}</span>
      </div>
    </div>
    <button @click="setIsShowChatRules(true)" class="default-hover chat-head_rules">
      {{ $t('chat_rules') }}
    </button>

    <button class="close" @click="setIsShowChat(false)">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><path data-v-0981affc="" d="M11.3333 4.66666L4.66666 11.3333M4.66666 4.66666L11.3333 11.3333" stroke="#918FBB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>
      {{ $t('close') }}
    </button>
  </div>
</template>

<style scoped lang="scss">
  .chat-head {
    padding: 6px 7px;
    padding-right: 11px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .chat-head_controls {
      display: flex;
      align-items: center;
      .online {
        border-radius: 9px;
        border: 1px solid #1D1B41;
        background: #0C0A33;
        display: flex;
        align-items: center;
        .circle {
          width: 9px;
          height: 9px;
          margin-right: 6px;
          border-radius: 100%;
          background: #acff5a;
          box-shadow: 0px 0px 0px 3px #acff5a4d;
        }
        span {
          font-size: 12px;
          font-family: 'Geometria-Bold';
          line-height: 6px;
          color: #918FBB;
          letter-spacing: 0.6px;
        }
        padding: 14px 12px;
      }
    }
    .chat-head_rules {
      border-radius: 9px;
      color: #C489FF;
      padding: 8px 13px;
      font-size: 12px;
      font-family: 'Geometria-Bold';
      letter-spacing: -0.24px;
      background: rgba(181, 107, 255, 0.20);
      margin-left: auto;
    }
    .close {
      margin-left: 15px;
      padding: 10px 14px;
      border-radius: 40px;
      background: #02002A;
      display: flex;
      align-items: center;
      color: var(--700, #7F7DA6);
      font-family: "Geometria-Bold";
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: normal;
      display: none;
      @media (max-width: 992px) {
        display: flex;
      }
      svg {
        margin-bottom: 1px;
        margin-right: 5px;
      }
      letter-spacing: -0.24px;
    }
  }
</style>