<script>

export default {
  name: "DepositBanner",
}
</script>

<template>
  <div class="deposit-banner">
    <div class="deposit-banner__logo">
      <img src="@/assets/images/white-logo.svg" alt="">
    </div>
    <div class="deposit-banner__h1">
      {{ $t('d_up_to') }}
    </div>
    <div class="deposit-banner__h2">
      {{ $t('d_and_get') }}
    </div>
    <div class="deposit-banner__prize blue">
      15% BONUS
    </div>
  </div>
</template>

<style scoped lang="scss">
  .deposit-banner {
    padding: 19px 0;
    height: 572px;
    width: 330px;
    min-width: 330px;
    margin-right: 8px;
    background: url(@/assets/images/deposit/banner.jpg);
    background-size: cover;
    background-position: center center;
    border-radius: 20px;
    overflow: hidden;
    @media (max-width: 768px) {
      width: 100%;
      min-width: 100%;
      margin-bottom: 6px;
      height: 120px;
      padding: 18px;
      background: url(@/assets/images/deposit-modal.jpg);
      background-size: cover;
      background-position:  center center;
    }
    .deposit-banner__logo {
      width: 100%;
      display: flex;
      justify-content: center;
      @media (max-width: 768px) {
        display: none;
      }
      img {
        width: 94px;
      }
    }
    .deposit-banner__h1 {
      max-width: 80%;
      margin: 0 auto;
      display: block;
      color: var(--1000, #FFF);
      text-align: center;
      margin-top: 10px;
      margin-bottom: 4px;
      font-family: Unbounded;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 34px; /* 106.25% */
      letter-spacing: -0.64px;
      @media (max-width: 768px) {
        margin: 0;
        text-align: left;
        line-height: 22px;
        font-size: 18px;
      }
    }
    .deposit-banner__h2 {
      display: flex;
      justify-content: center;
      color: var(--1000, #FFF);
      text-align: center;
      font-family: Unbounded;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.28px;
      @media (max-width: 768px) {
        font-size: 12px;
        text-align: left;
        justify-content: flex-start;

      }
    }
    .deposit-banner__prize {
      padding: 13px 19px;
      font-family: "Unbounded";
      font-size: 16px;
      font-weight: 700;
      letter-spacing: -0.32px;
      width: fit-content;
      margin: 0 auto;
      margin-top: 9px;
      @media (max-width: 768px) {
        font-size: 14px;
        padding: 11px 15px;
        margin: 0;
        margin-top: 10px;
      }

      @media (max-width: 768px) {
        font-size: 12px;
        padding: 11px 19px;
      }
      &.blue {
        border-radius: 12px;
        box-shadow: 0px -1px 2px 2px #349AFF;
        background: linear-gradient(180deg, #0582E9 0%, #06F 100%);
        @media (max-width: 768px) {
          box-shadow: 0px -1px 1px 1px #349AFF
        }
      }
    }
  }
</style>