<script>
export default {
  name: "LoginBanner"
}
</script>

<template>
  <div class="login-banner">
    <div class="logo">
      <img src="@/assets/images/white-logo.svg" alt="">
    </div>
    <h1>{{ $t('login_form.banner_heading') }}</h1>
  </div>
</template>

<style scoped lang="scss">
  .login-banner {
    height: 100%;
    width: 100%;
    background: url(@/assets/images/auth/login-banner.png), linear-gradient(180deg, #D9C400 0%, #FF1C64 53.55%, #CD00B8 100%);
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: center bottom;
    border-radius: 20px;
    box-shadow: 0px 105px 153.7px 0px rgba(1, 0, 25, 0.55);
    padding-top: 19px;
    @media (max-width: 768px) {
      padding: 27px 23px;
    }
    @media (max-width: 768px) {
      background: url(@/assets/images/auth/login-banner-mobile.png), linear-gradient(180deg, #D9C400 0%, #FF1C64 53.55%, #CD00B8 100%), linear-gradient(180deg, #9C21FC 0%, #4200CD 100%);
      background-size: contain;
      background-position: right center;
      background-repeat: no-repeat;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .logo {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: 10px;
      @media (max-width: 768px) {
        display: none;
      }
    }
    h1 {
      width: 100%;
      font-family: "Unbounded";
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      text-align: center;
      line-height: 34px; /* 106.25% */
      letter-spacing: -0.64px;
      width: 250px;
      margin: 0 auto;
      @media (max-width: 768px) {
        font-size: 18px;
        line-height: 22px;
        margin: 0;
        margin-left: 0;
        max-width: 190px;
        text-align: left;
      }
    }
  }
</style>