import ApiClient from "@/services/ApiClient";

export function getList (params)
{
    return ApiClient.get('/game-provider/games', params);
}

export function getCategoriesList ()
{
    return ApiClient.get('/game-provider/games/categories');
}

export function getGame(id)
{
    return ApiClient.get('/game-provider/games/' + id);
}

export function getProviders(data)
{
    return ApiClient.get('/game-provider/providers', data);
}