<script>
import DepositBanner from "@/components/Modals/Deposit/DepositBanner.vue";
import UiClose from "@/components/Ui/UiClose.vue";
import DepositForm from "@/components/Modals/Deposit/Contents/DepositForm.vue";
import {mapGetters, mapMutations} from "vuex";
import ExchangeForm from "@/components/Modals/Deposit/Contents/Exchange/ExchangeForm.vue";
import WithdrawForm from "@/components/Modals/Deposit/Withdraw/WithdrawForm.vue";

export default {
  data () {
    return {
      tab: null,
    }
  },
  methods: {
    ...mapMutations({
      setIsShowDepositModal: 'config/setIsShowDepositModal'
    }),
    close() {
      this.setIsShowDepositModal(false)
    }
  },
  computed: {
    ...mapGetters({
      depositModalTab: 'config/depositModalTab'
    }),
    getTab() {
      if (this.tab) return this.tab;

      if (this.depositModalTab) {
        return this.depositModalTab
      }

      return 'deposit';
    }
  },
  name: "DepositModal",
  components: {WithdrawForm, ExchangeForm, DepositForm, UiClose, DepositBanner}
}
</script>

<template>
  <div class="deposit-modal">
    <DepositBanner />
    <div class="deposit-modal__form">
      <UiClose @click="setIsShowDepositModal(false)" />
      <h2>{{ $t('d_title') }}</h2>

      <div class="deposit-modal__form-tabs">
        <div class="deposit-modal__form-tab" :class="{'active': getTab === 'deposit'}" @click="tab = 'deposit'">{{ $t('d_title') }}</div>
        <div class="deposit-modal__form-tab" :class="{'active': getTab === 'withdraw'}" @click="tab = 'withdraw'">{{ $t('t_withdraw') }}</div>
        <div class="deposit-modal__form-tab" :class="{'active': getTab === 'exchange'}" @click="tab = 'exchange'">{{ $t('buy_sneg') }}</div>
      </div>

      <div class="deposit-modal__content">
        <DepositForm v-if="getTab === 'deposit'" />
        <WithdrawForm v-if="getTab === 'withdraw'" />
        <ExchangeForm v-if="getTab === 'exchange'" />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .deposit-modal {
    display: flex;
    @media (max-width: 768px) {
      display: block;
      width: 100%;
    }
    .deposit-modal__form {
      overflow: hidden;
      width: 400px;
      min-width: 400px;
      border-radius: 20px;
      background: var(--200, #131139);
      box-shadow: 0px 105px 153.7px 0px rgba(1, 0, 25, 0.55);
      padding: 20px;
      position: relative;
      @media (max-width: 768px) {
        width: 100%;
        min-width: 100%;
        padding: 14px;
      }
      h2 {
        font-family: Unbounded;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px; /* 116.667% */
        letter-spacing: -0.48px;
        display: block;
      }
      .deposit-modal__form-tabs {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        border-radius: 12px;
        background: var(--50-Dark, rgba(0, 3, 42, 0.50));
        padding: 4px;
        margin-top: 14px;
        .deposit-modal__form-tab {
          padding: 12px 0;
          text-align: center;
          border-radius: 12px;
          border: 1px solid transparent;
          color: var(--700, #7F7DA6);
          font-size: 14px;
          font-style: normal;
          font-family: "Geometria-Bold";
          font-weight: 700;
          line-height: normal;
          letter-spacing: -0.28px;
          transition: opacity 0.2s;
          cursor: pointer;
          &:hover {
            opacity: 0.8;
          }
          &.active {
            color: #02002A;
            border: 1px solid var(--Stroke-yellow, #FFEF5E);
            background: var(--Primary, #FFE70C);
          }
          @media (max-width: 768px) {
            font-size: 12px;
          }
        }
      }
      .deposit-modal__content {
        margin-top: 6px;
        min-height: 20vh;
      }
    }
  }
</style>