<script>
import {mapMutations} from "vuex";

export default {
  methods: {
    ...mapMutations({
      setIsOpenLoyalty: 'config/setIsOpenLoyalty'
    })
  },
  name: "SoonFeatures"
}
</script>

<template>
  <div class="features">
    <router-link to="/nft-marketplace" class="feature">
      <span class="feature-icon">
        <img src="@/assets/images/sidebar/nft-icon.svg" alt="">
      </span>
      <span class="feature-name">{{ $t('nft') }}</span>
      <span class="feature-go"></span>
    </router-link>

    <router-link to="/lootboxes" class="feature">
      <span class="feature-icon">
        <img src="@/assets/images/sidebar/lootboxes-icon.svg" alt="">
      </span>
      <span class="feature-name">{{ $t('lootboxes') }}</span>
      <span class="feature-go"></span>
    </router-link>

    <a @click="setIsOpenLoyalty(true)" class="feature">
      <span class="feature-icon">
        <img src="@/assets/images/sidebar/loyal-icon.svg" alt="">
      </span>
      <span class="feature-name">{{ $t('loyal_program') }}</span>
      <span class="feature-go"></span>
    </a>

  </div>
</template>

<style scoped lang="scss">
  .features {
    border-radius: 10px;
    padding: 4px 8px;
    position: relative;
    background: linear-gradient(114deg, rgba(87, 205, 255, 0.17) 14.53%, rgba(64, 60, 137, 0.17) 65.42%);
    .feature {
      position: relative;
      transition: 0.2s;
      display: flex;
      padding: 8px 4px;
      padding-right: 8px;
      cursor: pointer;
      align-items: center;
      .feature-icon {
        width: 24px;
        margin-right: 7px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .feature-name {
        color: #B9B8DD;
        font-size: 14px;
        font-weight: 700;
        letter-spacing: -0.28px;
        font-family: "Geometria-Bold";
      }
      .feature-go {
        position: absolute;
        right: 4px;
        width: 24px;
        height: 24px;
        transition: 0.1s;
        background-repeat: no-repeat;
        background-image: url(@/assets/images/sidebar/arrow-right.svg);
        background-size: 12px 12px;
        background-position: center center;
        border-radius: 6px;
        background-color: #2B2A4E;
      }
      &:hover {
        border-radius: 10px;
        background: #72eeff1a;
        .feature-go {
          background-image: url(@/assets/images/sidebar/arrow-right-black.svg);
          background-color: #72EEFF;
        }
      }
    }
    .feature + .feature {
      border-top: 1px solid rgba(255,255,255, 0.05);
      margin-top: 3px;
    }
    .soon {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      border-radius: 12px;
      background: rgba(23, 21, 71, 0.72);
      backdrop-filter: blur(3.700000047683716px);
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: center;
      @media (max-width: 992px) {
        display: none;
      }
      .lists {
        display: flex;
        justify-content: center;
        .list {
          width: 37px;
          height: 37px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 100%;
          background: rgba(215, 212, 253, 0.15);
          backdrop-filter: blur(3.340277910232544px);
          img {
            height: 20px;
          }
        }
        .list + .list {
          margin-left: -5px;
        }
      }
      strong {
        display: block;
        margin-top: 10px;
        text-align: center;
        width: 100%;
        font-size: 14px;
        font-weight: 700;
        letter-spacing: -0.28px;
        font-family: "Unbounded";
      }
    }
  }
</style>