<script>
import SecurityBlock from "@/components/Profile/Security/SecurityBlock.vue";
import AccentBtn from "@/components/Ui/Buttons/AccentBtn.vue";
import PasswordState from "@/components/Profile/Security/PasswordState.vue";
import TwoFaState from "@/components/Profile/Security/TwoFaState.vue";
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  computed: {
    ...mapGetters({
      user: 'user/user'
    })
  },
  methods: {
    removeTwoFactorProcessing ()
    {
      this.setConfirmationModal({
        text: this.$t('account.sec.remove_two_fa_warning'),
        callback: this.removeTwoFactor
      })
    },
    ...mapActions({
      removeTwoFactor: 'user/removeTwoFactor'
    }),
    ...mapMutations({
      setConfirmationModal: 'config/setConfirmationModal',
      setOpenChangePassword: 'config/setOpenChangePassword',
      setOpenTwoFa: 'config/setOpenTwoFa'
    })
  },
  name: "ProfileSecurity",
  components: {TwoFaState, PasswordState, AccentBtn, SecurityBlock}
}
</script>

<template>
  <div class="profile-security">
    <SecurityBlock :name="$t('account.sec.password')" :icon="require('@/assets/images/password-icon.svg')">
      <template v-slot:button>
        <AccentBtn @click="setOpenChangePassword(true)" :title="user.restrictions.isSetPassword ? $t('account.sec.change_password') : $t('account.sec.set_password')" />
      </template>
      <template v-slot:content>
        <PasswordState/>
      </template>
    </SecurityBlock>
    <SecurityBlock :icon="require('@/assets/images/google-icon.svg')" name="2FA">
      <template v-slot:button>
        <AccentBtn v-if="!user.restrictions.twoFaActivate" @click="setOpenTwoFa(true)" :title="$t('account.sec.activate')" />
        <AccentBtn @click="removeTwoFactorProcessing" class="danger" v-else :title="$t('account.sec.deactivate')" />
      </template>
      <template v-slot:content>
        <TwoFaState/>
      </template>
    </SecurityBlock>
  </div>
</template>

<style scoped lang="scss">
.profile-security {
  width: 100%;
  grid-gap: 16px;
  display: flex;
  @media (max-width: 768px) {
    display: block;
  }
  .security-block {
    flex: 1;
    @media (max-width: 768px) {
      flex: unset;
      width: 100%;
    }
    .accent-btn {
      padding-left: 24px;
      padding-right: 24px;
      @media (max-width: 768px) {
        font-size: 12px;
        padding: 12px 15px;
        padding-top: 14px;
      }
    }
    .danger {
      background: rgba(255, 107, 107, 0.20);
      box-shadow: none;
    }
  }
  .security-block + .security-block {
    @media (max-width: 768px) {
      margin-top: 10px;
    }
  }
}
</style>