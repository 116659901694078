<script>
import UiClose from "@/components/Ui/UiClose.vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
import UiInput from "@/components/Ui/UiInput.vue";
import AccentBtn from "@/components/Ui/Buttons/AccentBtn.vue";
import EmailHasBeenSent from "@/components/EmailHasBeenSent.vue";

export default {
  data () {
    return {
      isShowSuccess: false,
      password: null,
      isLoading: false,
      password_confirmation: null,
    }
  },
  methods: {
    ...mapMutations({
      setOpenChangePassword: 'config/setOpenChangePassword'
    }),
    ...mapActions({
      changePassword: 'user/changePasswordProcessing'
    }),
    close() {
      this.setOpenChangePassword(false)
    },
    async changePasswordProcessing ()
    {
      if (!this.isInputed) return;

      this.isLoading = true

      try {
        await this.changePassword(
            {
              data: {
                password: this.password,
                password_confirmation: this.password_confirmation
              }
            }
        );

        this.isShowSuccess = true
      } catch (e) {
        console.error(e)
      } finally {
        this.password = null;
        this.password_confirmation = null;
        this.isLoading = false
      }
    }
  },
  computed: {
    ...mapGetters({
      user: 'user/user'
    }),
    isInputed () {
      return (this.password && this.password_confirmation) && this.password === this.password_confirmation;
    }
  },
  name: "ChangePasswordModal",
  components: {EmailHasBeenSent, AccentBtn, UiInput, UiClose}
}
</script>

<template>
  <div class="change-password">
    <UiClose @click="setOpenChangePassword(false)" />
    <EmailHasBeenSent @close="isShowSuccess = false" v-if="isShowSuccess" :email="user.email" />

    <template v-else>
      <img src="@/assets/images/profile/password-bg.png" class="bg" alt="">
      <div class="change-password_head">
        <div class="change-password_head-circle">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M18.5408 4.16989L13.0408 2.10989C12.4708 1.89989 11.5408 1.89989 10.9708 2.10989L5.47078 4.16989C4.41078 4.56989 3.55078 5.80989 3.55078 6.93989V15.0399C3.55078 15.8499 4.08078 16.9199 4.73078 17.3999L10.2308 21.5099C11.2008 22.2399 12.7908 22.2399 13.7608 21.5099L19.2608 17.3999C19.9108 16.9099 20.4408 15.8499 20.4408 15.0399V6.93989C20.4508 5.80989 19.5908 4.56989 18.5408 4.16989ZM12.7508 12.8699V15.4999C12.7508 15.9099 12.4108 16.2499 12.0008 16.2499C11.5908 16.2499 11.2508 15.9099 11.2508 15.4999V12.8699C10.2408 12.5499 9.50078 11.6099 9.50078 10.4999C9.50078 9.11989 10.6208 7.99989 12.0008 7.99989C13.3808 7.99989 14.5008 9.11989 14.5008 10.4999C14.5008 11.6199 13.7608 12.5499 12.7508 12.8699Z" fill="#C489FF"/>
          </svg>
        </div>
        <div class="change-password_head-text">
          <h3 v-if="!user.restrictions.isSetPassword">{{ $t('recover_pass.set_password') }}</h3>
          <h3 v-else>{{ $t('recover_pass.change') }}</h3>
          <span v-if="!user.restrictions.isSetPassword">{{ $t('recover_pass.is_not_set') }}</span>
          <span v-else>{{ $t('recover_pass.is_set') }}</span>
        </div>
      </div>
      <div class="change-password_form">
        <UiInput v-model="password" :icon="require('@/assets/images/auth/password-icon.svg')" type="password" :placeholder="$t('recover_pass.enter_password')"></UiInput>
        <UiInput v-model="password_confirmation" :icon="require('@/assets/images/auth/password-icon.svg')" type="password" :placeholder="$t('recover_pass.confirm_password')"></UiInput>

        <AccentBtn :is-loading="isLoading" @click="changePasswordProcessing" :class="{'disabled': !isInputed}" :disabled="!isInputed" :title="$t('recover_pass.save')"/>
      </div>
    </template>
  </div>
</template>

<style scoped lang="scss">
  .change-password {
    width: 345px;
    border-radius: 20px;
    background: #131139;
    padding: 20px;
    box-shadow: 0px 105px 153.7px 0px rgba(1, 0, 25, 0.55);
    position: relative;
    @media (max-width: 575px) {
      width: 100%;
    }
    .bg {
      margin: 0 auto;
      display: block;
      margin-top: 20px;
      margin-bottom: -5px;
    }
    .change-password_head {
      display: flex;
      align-items: center;
      .change-password_head-circle {
        width: 48px;
        height: 48px;
        border-radius: 100%;
        margin-right: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(182, 127, 255, 0.10);
      }
      .change-password_head-text {
        h3 {
          font-family: "Unbounded";
          font-size: 18px;
          font-weight: 700;
          display: block;
          width: 100%;
          margin-bottom: 6px;
          letter-spacing: -0.36px;
        }
        span {
          color: #918FBB;
          font-family: "Geometria-Medium";
          font-size: 14px;
          font-weight: 500;
          display: block;
          width: 100%;
          letter-spacing: -0.28px;
        }
      }
    }
    .change-password_form {
      margin-top: 24px;
      .accent-btn {
        margin-top: 14px;
      }
      .ui-input + .ui-input {
        margin-top: 4px;
      }
    }
  }
</style>