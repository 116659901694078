<script>
import UiInput from "@/components/Ui/UiInput.vue";
import AccentBtn from "@/components/Ui/Buttons/AccentBtn.vue";
import UiClose from "@/components/Ui/UiClose.vue";
import { connect, account, signMessage } from '@kolirt/vue-web3-auth'
import {web3CheckUsername} from "@/services/auth";
import extractErrors from "@/helpers/extractErrors";
import {mapActions, mapGetters, mapMutations} from "vuex";
export default {
  data () {
    return {
      isValidationError: false,
      interval: null,
      account,
      signMessage,
      connect,
      username: null,
      isStartProcessing: false,
      isStartSignature: false,
      signature: null,
    }
  },
  watch: {
    username() {
      this.isValidationError = false
    },
    web3RegisterMethod: {
      deep: true,
      handler() {
        if (this.web3RegisterMethod === 'login') {
          this.startSignature()
        }
      }
    }
  },
  methods: {
    close() {
      this.setIsShowWeb3Register(false)
    },
    ...mapActions({
      web3Auth: 'auth/web3Auth'
    }),
    ...mapMutations({
      setIsShowWeb3Register: 'config/setIsShowWeb3Register'
    }),
    async startConnect (isLogin = false, fromButton = true) {

      if (this.account?.connected && fromButton) {
        this.isStartProcessing = true;
        return await this.startSignature();
      }

      if (isLogin) {
        this.isStartProcessing = true;
        if (!this.account?.connected) {
          await this.connect();
          return;
        } else {
          await this.startSignature()
        }
      }

      await web3CheckUsername(this.username).then(() => {
        this.isStartProcessing = true;

        if (!this.account?.connected) {
          this.connect();
        } else {
          this.startSignature()
        }
      }).catch(err => {
        this.isValidationError = true;
        extractErrors(err).forEach(item => {
          this.$root.$emit('error', {
            title: "Error",
            text: item,
          })
        })
      })
    },
    async startSignature ()
    {
      try {
        this.isStartSignature = true;
        const signMessage = `You are about to login with Ethereum address ${account.address} to https://snegbet.com`
        this.signature = await this.signMessage(signMessage)

        this.web3Auth({
          payload: {
            signature: this.signature,
            address: this.account.address,
            username: this.username,
            signatureMessage: signMessage
          },
          showErrors: () => {
            this.$root.$emit('error', {
              title: "Error",
              text: "Пользователь с таким адресом не найден.",
            })
            this.isStartProcessing = false;
          }
        })
      } catch(e) {
        this.isStartSignature = false;
        this.isStartProcessing = false;
      }
    }
  },
  computed: {
    ...mapGetters({
      web3RegisterMethod: 'config/web3RegisterMethod'
    })
  },
  mounted() {
    if (this.web3RegisterMethod === 'login') {
      this.startConnect(true)
    }
    this.interval = setInterval(() => {
      if (this.account.connected && !this.isStartSignature && this.isStartProcessing) {
        this.startSignature()
      }
    }, 1000)
  },
  name: "Web3Auth",
  components: {UiClose, AccentBtn, UiInput}
}
</script>

<template>
  <div class="web3-auth" v-if="web3RegisterMethod === 'register'">

    <UiClose @click="setIsShowWeb3Register(false)" />

    <h2>{{ $t('web3_form.enter_username') }}</h2>

    <UiInput :class="{'validation-error': isValidationError}" ref="username" @keydown.enter="startConnect" :readonly="isStartProcessing" v-model="username" type="text" :placeholder="$t('web3_form.create_username')" :icon="require('@/assets/images/auth/user-icon.svg')" />

    <AccentBtn :is-loading="isStartProcessing" @click="startConnect(false, false)" :title="$t('web3_form.connect')" :disabled="!username || isValidationError" />
  </div>
</template>

<style scoped lang="scss">
  .web3-auth {
    padding: 20px;
    border-radius: 20px;
    position: relative;
    background: #131139;
    padding-top: 30px;
    width: 400px;
    box-shadow: 0px 105px 153.7px 0px rgba(1, 0, 25, 0.55);
    h2 {
      margin-bottom: 15px;
      font-family: Unbounded;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px; /* 116.667% */
      letter-spacing: -0.48px;
    }
    button {
      margin-top: 15px;
    }
  }
</style>