<script>
export default {
  props: {
    pages: {
      type: Number,
      default: 1
    },
    current_page: {
      type: Number,
      default: 1,
    }
  },
  methods: {
    setPage (page) {
      if (page > this.pages || page < 1) {
        return;
      }
      this.$emit('change', page);
    },
  },
  name: "SimplePagination"
}
</script>

<template>
  <div class="simple-pagination" v-if="pages > 1">
    <div class="page" :class="{'disabled': current_page === 1}" @click="setPage(current_page - 1)">
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.25 4.5L6.75 9L11.25 13.5" stroke="#7F7DA6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </div>
    <div class="page" @click="setPage(page)" :class="{'active': page === current_page}" v-for="page in pages" :key="page">{{ page }}</div>
    <div class="page" :class="{'disabled': pages === current_page}" @click="setPage(current_page + 1)">
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.75 13.5L11.25 9L6.75 4.5" stroke="#7F7DA6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </div>
  </div>
</template>

<style scoped lang="scss">
.simple-pagination {
  border-radius: 14px;
  background: #131139;
  padding: 5px;
  grid-gap: 4px;
  display: flex;
  .page {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    @media (max-width: 575px) {
      width: 35px;
      height: 35px;
    }
    border-radius: 12px;
    background: #1D1B41;
    transition: 0.2s;
    font-family: "Geometria-Bold";
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0.6px;
    line-height: 14px;
    &:hover:not(.disabled), &.active:not(.disabled) {
      cursor: pointer;
      color: #000;
      background: #FFE70C;
    }
    &.disabled {
      cursor: default;
    }
  }
}

</style>