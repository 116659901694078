<script>
export default {
  props: {
    link: {
      type: String
    }
  },
  name: "ViewAll"
}
</script>

<template>
  <router-link :to="link" class="view-all">
    {{ $t('view_all') }}
  </router-link>
</template>

<style scoped lang="scss">
.view-all {
  border-radius: 6px;
  background: rgba(255, 226, 79, 0.27);
  padding: 3px 5px;
  color: #FFE70C;
  font-family: "Geometria-Bold";
  font-size: 14px;
  letter-spacing: -0.28px;
}
</style>