import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '../views/Main.vue'
import ProfileView from '../views/ProfileView.vue'
import BlogView from '../views/BlogView.vue'
import BlogPage from '../views/BlogPage.vue'
import NotFoundView from '../views/NotFoundView.vue'
import GamesView from '../views/GamesView.vue'
import GamesProvidersView from '../views/GamesProvidersView.vue'
import LootboxesView from '../views/LootboxesView.vue'
import LootboxPage from "@/views/LootboxPage.vue";
import GamePage from "@/views/GamePage.vue";
import NftMarketplace from "@/views/NftMarketplace.vue";
import PrivacyPolicy from "@/views/PrivacyPolicy.vue";
import TermsAndConditions from "@/views/TermsAndConditions.vue";
import AmlPolicy from "@/views/AmlPolicy.vue";
import ResponsibleGaming from "@/views/ResponsibleGaming.vue";
import NftPage from "@/views/NftPage.vue";
import LootboxesListView from "@/views/LootboxesListView.vue";
import ProviderGamesView from "@/views/ProviderGamesView.vue";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: Main
    },
    {
        path: '/profile',
        name: 'profile',
        component: ProfileView
    },
    {
        path: '/blog',
        name: 'blog',
        component: BlogView
    },
    {
        path: '/blog/:id',
        name: 'blog.item',
        component: BlogPage
    },
    {
        path: '/games',
        name: 'games',
        component: GamesView
    },
    {
        path: '/game/:id',
        name: 'games.detail',
        component: GamePage
    },
    {
        path: '/games/providers',
        name: 'games.providers',
        component: GamesProvidersView
    },
    {
        path: '/lootboxes',
        name: 'lootboxes',
        component: LootboxesView
    },
    {
        path: '/lootbox/:id',
        name: 'lootboxes.page',
        component: LootboxPage
    },
    {
        path: '/nft-marketplace',
        name: 'nft-marketplace',
        component: NftMarketplace
    },
    {
        path: '/privacy-policy',
        name: 'privacy-policy',
        component: PrivacyPolicy
    },
    {
        path: '/terms-and-conditions',
        name: 'terms-and-conditions',
        component: TermsAndConditions
    },
    {
        path: '/aml-policy',
        name: 'aml-policy',
        component: AmlPolicy
    },
    {
        path: '/responsible-gaming',
        name: 'responsible-gaming',
        component: ResponsibleGaming
    },
    {
        path: '/nft/:id',
        name: 'nft-page',
        component: NftPage
    },
    {
        path: '/lootboxes/list',
        name: 'lootboxes-list',
        component: LootboxesListView
    },
    {
        path: '/provider/:id',
        name: 'providers-games',
        component: ProviderGamesView
    },
    {
        path: "/:notFound",
        name: '404',
        component: NotFoundView,
    },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
