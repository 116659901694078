<script>
export default {
  props: {
    rank: {
      type: Number,
      default: 1
    }
  },
  name: "RankIcon"
}
</script>

<template>
  <div class="ui-rank">
    <img :src="require(`@/assets/images/ranks/${rank}.png`)" alt="">
  </div>
</template>

<style scoped lang="scss">
  .ui-rank {
    display: flex;
    align-items: center;
  }
</style>