<script>
import {getLootboxes} from "@/services/lootboxes";
import LootboxShortItem from "@/components/Lootboxes/LootboxShortItem.vue";
import SimplePagination from "@/components/SimplePagination.vue";
import UiLoader from "@/components/UiLoader.vue";
import BackButton from "@/components/BackButton.vue";
import UiInput from "@/components/Ui/UiInput.vue";
import PriceRanger from "@/components/PriceRanger.vue";
import EmptyState from "@/components/EmptyState.vue";

export default {
  components: {EmptyState, PriceRanger, UiInput, BackButton, UiLoader, SimplePagination, LootboxShortItem},
  data () {
    return {
      isLoading: false,
      lootboxes: [],
      page: 1,
      pages: 1,
      min: 0,
      search: null,
      max: 10000
    }
  },
  name: "LootboxesListView",
  watch: {
    search() {
      this.fetchLootboxes(1)
    },
    min() {
      this.fetchLootboxes(1)
    },
    max() {
      this.fetchLootboxes(1)
    }
  },
  methods: {
    setSearch(value) {
      this.search = value;
    },
    update(data) {
      console.log(data)
      this.min = data.from;
      this.max = data.to;
    },
    fetchLootboxes(page = 1) {
      this.isLoading = true;
      getLootboxes({
        per_page: 18,
        min: this.min,
        max: this.max,
        search: this.search,
        page: page
      }).then(response => {
        this.lootboxes = response.data.data;
        this.pages = response.data.meta.last_page;
        this.page = response.data.meta.current_page;
      }).finally(() => {
        setTimeout(() => {
          this.isLoading = false;
        }, 500)
      })
    },
  },
  mounted() {
    this.fetchLootboxes()
  }
}
</script>

<template>
<div class="lootboxes-list">
  <BackButton :callback="() => $router.push('/lootboxes')" />
  <div class="lootboxes-list-heading">
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M6.75 4.5H6C3.5175 4.5 1.5 6.5175 1.5 9V10.5H6.75V4.5Z" fill="#72EEFF"/>
      <path d="M11.415 8.535C11.7 8.175 12.3 8.175 12.585 8.535L14.16 10.5H15.75V4.5H8.25V10.5H9.84L11.415 8.535Z" fill="#72EEFF"/>
      <path d="M18 4.5H17.25V10.5H22.5V9C22.5 6.5175 20.4825 4.5 18 4.5Z" fill="#72EEFF"/>
      <path d="M14.16 12L12.585 13.965C12.4425 14.145 12.225 14.25 12 14.25C11.775 14.25 11.5575 14.145 11.415 13.965L9.84 12H1.5V18C1.5 18.825 2.175 19.5 3 19.5H21C21.825 19.5 22.5 18.825 22.5 18V12H14.16Z" fill="#72EEFF"/>
    </svg>
    {{ $t('all_lootboxes') }}
  </div>

  <div class="lootboxes-list-filters">
    <div class="lootboxes-list-filters-search">
      <UiInput type="search" @input="setSearch($event)" :placeholder="$t('search_lootboxes')" :icon="require('@/assets/images/search-blog-icon.svg')" />
    </div>
    <div class="lootboxes-list-filters-price">
      <PriceRanger @update="update" :from="min" :to="max" />
    </div>
  </div>

  <UiLoader :fill="true" v-if="isLoading" />
  <div v-else class="lootboxes-list-content">
    <template v-if="lootboxes.length">
      <LootboxShortItem :lootbox="lootbox" v-for="(lootbox, i) in lootboxes" :key="i" />
    </template>
    <EmptyState :heading="$t('no_records_l')" :absolute="true" v-else />
  </div>

  <SimplePagination v-if="pages > 1 && !isLoading" @change="fetchLootboxes" :current_page="page" :pages="pages" />
</div>
</template>

<style scoped lang="scss">
.lootboxes-list {
  min-height: 80vh;
  position: relative;
  padding-bottom: 30px;
  .back {
    @media (max-width: 768px) {
      display: none;
    }
  }
  .lootboxes-list-heading {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    margin-top: 22px;
    @media (max-width: 768px) {
      margin-top: 0;
    }
    svg {
      margin-right: 12px;
    }
    font-family: Unbounded;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* 116.667% */
    letter-spacing: -0.48px;
  }
  .lootboxes-list-filters {
    display: flex;
    justify-content: space-between;
    @media (max-width: 768px) {
      display: block;
    }
    .lootboxes-list-filters-search {
      @media (max-width: 768px) {
        margin-bottom: 10px;
      }
    }
    .lootboxes-list-filters-price {
      ::v-deep {
        @media (max-width: 768px) {
          .price-ranger__body-inputs {
            width: 100%;
          }
          .price-ranger .price-ranger__body {
            width: 100%;
          }
        }
      }
    }
  }
  .lootboxes-list-content {
    margin-top: 12px;
    display: grid;
    grid-gap: 16px;
    grid-template-columns: repeat(6, 1fr);
    @media (max-width: 2050px) {
      grid-template-columns: repeat(5, 1fr);
    }
    @media (max-width: 1800px) {
      grid-template-columns: repeat(4, 1fr);
    }
    @media (max-width: 900px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media (max-width: 600px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  .simple-pagination {
    justify-content: center;
    margin-top: 40px;
  }
}
</style>