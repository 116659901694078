import { render, staticRenderFns } from "./GameProviderItem.vue?vue&type=template&id=57f70434&scoped=true"
import script from "./GameProviderItem.vue?vue&type=script&lang=js"
export * from "./GameProviderItem.vue?vue&type=script&lang=js"
import style0 from "./GameProviderItem.vue?vue&type=style&index=0&id=57f70434&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57f70434",
  null
  
)

export default component.exports