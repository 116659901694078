import { render, staticRenderFns } from "./RecoverPasswordModal.vue?vue&type=template&id=35321a4f&scoped=true"
import script from "./RecoverPasswordModal.vue?vue&type=script&lang=js"
export * from "./RecoverPasswordModal.vue?vue&type=script&lang=js"
import style0 from "./RecoverPasswordModal.vue?vue&type=style&index=0&id=35321a4f&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35321a4f",
  null
  
)

export default component.exports