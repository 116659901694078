export default {
    setUser (state, user) {
        state.user = user
    },

    setBalance (state, {balance, sg_balance}) {
        if (!state.user) {
            return;
        }

        state.user.balance.balance = balance;
        state.user.sg_balance = sg_balance;
    },
    setChatPausedTo(state, value) {
        state.user.chat_paused_to = value;
    },

    updateAvatar(state, avatar) {
        state.user.avatar = avatar;
    }
}