<script >

import SmallSocialsList from "@/components/Parts/SmallSocialsList.vue";
import LanguageSwitcher from "@/components/Parts/LanguageSwitcher.vue";
import {mapGetters, mapMutations} from "vuex";
import { Crisp } from "crisp-sdk-web";

export default {
  computed: {
    ...mapGetters({
      categories: 'games/categories'
    })
  },
  methods: {
    ...mapMutations({
      setIsOpenLoyalty: 'config/setIsOpenLoyalty'
    }),
    openSupport()
    {
      Crisp.chat.show();
      Crisp.chat.open();
    }
  },
  mounted() {
    Crisp.chat.onChatClosed(() => {
      Crisp.chat.hide();
    })
  },
  components: {
    SmallSocialsList,
    LanguageSwitcher,
  }
}
</script>

<template>
<footer class="footer">
    <div class="footer__body">
      <div class="footer__body-top">
        <div class="footer__body-top_logo">
          <router-link to="/">
            <img src="@/assets/images/logo.svg" alt="">
          </router-link>
        </div>
        <div class="footer__body-top_nav">
          <div class="footer__body-top_nav-block">
            <div class="footer__body-top_nav-block_head">
              {{ $t('f_games') }}
            </div>
            <div class="footer__body-top_nav-block_lists">
              <router-link :to="`/games?category=${category.id}`" v-for="category in categories" :key="category.id" class="footer__body-top_nav-block_list">
                {{ category.name }}
              </router-link>
            </div>

          </div>
          <div class="footer__body-top_nav-block">
            <div class="footer__body-top_nav-block_head">
              {{ $t('f_platform') }}
            </div>
            <div class="footer__body-top_nav-block_lists">
              <a @click="setIsOpenLoyalty(true)" class="footer__body-top_nav-block_list">
                <img src="../../assets/images/diamond-icon.svg" alt="">{{ $t('f_loyal') }}
              </a>
              <router-link to="/nft-marketplace" class="footer__body-top_nav-block_list">
                <img src="../../assets/images/nft-icon.svg" alt=""> NFT
              </router-link>
            </div>

          </div>
          <div class="footer__body-top_nav-block">
            <div class="footer__body-top_nav-block_head">
              {{ $t('f_documents') }}
            </div>
            <div class="footer__body-top_nav-block_lists">
              <router-link to="/aml-policy" class="footer__body-top_nav-block_list">
                AML Policy
              </router-link>
              <router-link to="/privacy-policy" class="footer__body-top_nav-block_list">
                Privacy Policy
              </router-link>
              <router-link to="/responsible-gaming" class="footer__body-top_nav-block_list">
                {{ $t('f_resposible') }}
              </router-link>
              <router-link to="/terms-and-conditions" class="footer__body-top_nav-block_list">
                Terms & Conditions
              </router-link>
            </div>

          </div>
        </div>
        <div class="footer__body-top_additional">
          <LanguageSwitcher />
          <a href="#" @click.prevent.stop="openSupport" class="default-hover">{{ $t('f_support') }}</a>
        </div>
      </div>

      <div class="footer__body-bottom">
        <div class="footer__body-bottom_copyright">{{ $t('f_copyright') }}</div>
        <SmallSocialsList />
      </div>
    </div>
</footer>
</template>

<style scoped lang="scss">
footer.footer {
  width: 100%;
  .footer__body {
    width: 100%;
    @media (max-width: 992px) {
      padding-bottom: 100px;
    }
    .footer__body-top {
      width: 100%;
      display: flex;
      align-items: flex-start;
      padding-top: 24px;
      border-top: 1px solid #131139;
      position: relative;
      @media (max-width: 992px) {
        display: block;
        padding-left: 10px;
        padding-top: 14px;
      }
      .footer__body-top_logo {
        width: 151px;
        margin-right: 65px;
        min-width: 151px;
        @media (max-width: 1850px) {
          margin-right: 35px;
        }
        @media (max-width: 992px) {
          width: 100%;
          min-width: 100%;
          margin-right: 0;
          margin-bottom: 33px;
          img {
            width: 121px;
          }
        }
      }
      .footer__body-top_nav {
        flex-grow: 1;
        overflow: hidden;
        display: flex;
        align-items: flex-start;
        @media (max-width: 992px) {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-row-gap: 32px;
        }
        .footer__body-top_nav-block {
          .footer__body-top_nav-block_head {
            font-family: 'Geometria-Bold';
            letter-spacing: -0.28px;
            color: $color-gray-white;
            margin-bottom: 12px;
          }
          .footer__body-top_nav-block_lists {
            .footer__body-top_nav-block_list {
              font-weight: 500;
              color: #918FBB;
              letter-spacing: -0.28px;
              display: flex;
              align-items: center;
              font-family: "Geometria-Medium";
              img {
                margin-right: 7px;
              }
              &:hover {
                color: #fff;
              }
              transition: 0.2s;
              cursor: pointer;
            }
            .footer__body-top_nav-block_list + .footer__body-top_nav-block_list {
              margin-top: 12px;
            }
          }
        }
        .footer__body-top_nav-block + .footer__body-top_nav-block {
          margin-left: 68px;
          @media (max-width: 1850px) {
            margin-left: 40px;
          }
          @media (max-width: 992px) {
            margin-left: 0;
          }
        }
      }
      .footer__body-top_additional {
        margin-left: 160px;
        display: flex;
        @media (max-width: 1950px) {
          margin-left: 40px;
        }
        @media (max-width: 992px) {
          position: absolute;
          right: 10px;
          top: 14px;
          z-index: 3;
        }
        ::v-deep {
          .language-switcher {
            .current-language {
              height: 100%;
            }
          }
        }
        a {
          padding: 12px 0;
          width: 104px;
          min-width: 104px;
          text-align: center;
          border-radius: 11px;
          display: block;
          font-family: 'Geometria-Bold';
          letter-spacing: -0.28px;
          border: 1px solid #3E3C5F;
          background: $color-gray-light;
          @media (max-width: 575px) {
            font-size: 12px;
            padding: 10px 0;
            padding-top: 12px;
            width: 78px;
            min-width: 78px;
          }
        }
        .language-switcher {
          margin-right: 12px;
          @media (max-width: 575px) {
            margin-right: 4px;
          }
        }
      }
    }
    .footer__body-second {
      width: 100%;
    }
    .footer__body-bottom {
      padding: 24px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 0;
      @media (max-width: 768px) {
        flex-wrap: wrap;
      }
      .footer__body-bottom_copyright {
        font-weight: 500;
        letter-spacing: -0.28px;
        color: #918FBB;
        font-family: "Geometria-Medium";
        @media (max-width: 768px) {
          margin-top: 15px;
          order: 1;
          width: 100%;
          font-size: 12px;
          text-align: center;
        }

      }
      .small-socials-list {
        @media (max-width: 768px) {
          width: 100%;
          justify-content: space-evenly;
          order: 0;
        }
      }
    }
  }
}
</style>