<script>
export default {
  data () {
    return {
      isShown: false,
    }
  },
  props: {
    withInfo: {
      type: Boolean
    },
    icon: {
      type: String,
    },
    value: {
      type: String
    },
    desc: {
      type: String
    },
    infoContent: {
      type: String,
    }
  },
  name: "StatisticItem"
}
</script>

<template>
  <div class="statistic-item">
    <div class="statistic-item__head">
      <div class="icon">
        <img :src="icon" alt="">
      </div>
      <strong>{{ value }}</strong>
    </div>
    <div class="statistic-item__name">
      {{ desc }}
      <div class="statistic-item__name-icon default-hover" @click="isShown = !isShown" v-if="withInfo">
        <img src="@/assets/images/info-icon.svg" v-tooltip="{'content': infoContent, shown: isShown, html: true}" alt="">
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .statistic-item {
    border-radius: 13px;
    background: rgba(200, 197, 255, 0.05);
    padding: 12px;
    .statistic-item__head {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      .icon {
        height: 22px;
        @media (max-width: 768px) {
          height: 17px;
        }
        img {
          min-height:100%;
          max-height: 100%;
          margin-right: 8px;
          @media (max-width: 768px) {
            width: 17px;
          }
        }
      }
      strong {
        font-family: "Unbounded";
        font-size: 18px;
        line-height: 16px;
        font-weight: 700;
        display: flex;
        align-items: center;
        letter-spacing: -0.36px;
        white-space: nowrap;
        @media (max-width: 768px) {
          font-size: 14px;
        }
      }
    }
    .statistic-item__name {
      display: flex;
      align-items: center;
      font-family: "Geometria-Medium";
      opacity: 0.8;
      white-space: nowrap;
      @media (max-width: 768px) {
        font-size: 11px;
        line-height: 12px;
      }
      .statistic-item__name-icon {
        display: flex;
        align-items: center;
        margin-left: 8px;
      }
    }
  }
</style>