<script>
import BlogItem from "@/components/Blog/BlogItem.vue";
import PaymentMethodsBlock from "@/components/Parts/PaymentMethodsBlock.vue";
import { getPost } from "@/services/blog";

export default {
  data () {
    return {
      blog: null,
    }
  },
  methods: {
    fetchPost() {
      getPost(this.$route.params.id).then(response => {
        this.blog = response.data;
      })
    }
  },
  mounted() {
    this.fetchPost()
  },
  name: "BlogPage",
  components: {PaymentMethodsBlock, BlogItem}
}
</script>

<template>
  <div class="blog-detail page">
    <router-link to="/blog" class="back default-hover">
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.25 4.5L6.75 9L11.25 13.5" stroke="#7F7DA6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      {{ $t('back') }}
    </router-link>

    <div class="preview">
      <BlogItem :from-inside="true" :blog="blog" />
    </div>

    <div class="body" v-html="blog.content"></div>

    <PaymentMethodsBlock/>

  </div>
</template>

<style scoped lang="scss">
  .blog-detail {
    padding: 48px 0;
    width: 800px;
    margin: 0 auto;
    max-width: 100%;
    overflow: hidden;
    @media (max-width: 768px) {
      padding: 20px 0;
      padding-top: 0;
    }
    .preview {
      margin: 18px 0;
    }
    .back {
      padding: 14px 16px;
      width: fit-content;
      border-radius: 14px;
      background: #1D1B41;
      display: flex;
      align-items: center;
      font-family: "Unbounded";
      font-size: 12px;
      line-height: 14px;
      font-weight: 700;
      letter-spacing: -0.24px;
      svg {
        margin-right: 8px;
      }
      @media (max-width: 768px) {
        padding: 9px;
        font-size: 12px;
        padding-right: 14px;
      }
    }
  }

  .body {
    ::v-deep {
      h1 {
        font-family: "Unbounded";
        font-size: 32px;
        font-weight: 700;
        line-height: 34px; /* 106.25% */
        letter-spacing: -0.64px;
        @media (max-width: 768px) {
          font-size: 26px;
          line-height: 30px;
        }
      }
      h2 {
        font-family: "Unbounded";
        font-size: 24px;
        font-weight: 700;
        line-height: 28px; /* 116.667% */
        letter-spacing: -0.48px;
        @media (max-width: 768px) {
          font-size: 18px;
          line-height: 24px;
        }
      }
      img {
        margin: 12px 0;
        max-width: 100%;
      }
      h3 {
        font-family: "Unbounded";
        font-size: 20px;
        font-weight: 700;
        letter-spacing: -0.4px;
        @media (max-width: 768px) {
          font-size: 16px;
          line-height: 20px;
        }
      }
      h4 {
        font-family: "Unbounded";
        font-size: 18px;
        font-weight: 700;
        letter-spacing: -0.36px;
        @media (max-width: 768px) {
          font-size: 16px;
          line-height: 20px;
        }
      }
      h5 {
        font-family: "Unbounded";
        font-size: 16px;
        font-weight: 700;
        letter-spacing: -0.32px;
        @media (max-width: 768px) {
          font-size: 14px;
          line-height: 18px;
        }
      }
      h6 {
        font-family: "Unbounded";
        font-size: 14px;
        font-weight: 700;
        letter-spacing: -0.28px;
      }

      color: #B9B8DD;
      font-size: 15px;
      font-family: "Geometria-Medium";
      font-weight: 500;
      line-height: 25px; /* 166.667% */
      letter-spacing: -0.3px;
      a {
        font-size: 15px;
        font-family: "Geometria-Medium";
        font-weight: 500;
        line-height: 25px; /* 166.667% */
        letter-spacing: -0.3px;
        color: #45D2FF;
      }

      p {
        color: #B9B8DD;
        font-size: 15px;
        font-family: "Geometria-Medium";
        font-weight: 500;
        line-height: 25px; /* 166.667% */
        letter-spacing: -0.3px;
        margin: 12px 0;
        @media (max-width: 768px) {
          font-size: 13px;
          line-height: 22px;
        }
        a {
          font-size: 15px;
          font-family: "Geometria-Medium";
          font-weight: 500;
          line-height: 25px; /* 166.667% */
          letter-spacing: -0.3px;
          color: #45D2FF;
          @media (max-width: 768px) {
            font-size: 13px;
            line-height: 22px;
          }
        }
      }
    }
  }
</style>