<script>
import GoogleSocialAuth from "@/components/Auth/Buttons/Socials/GoogleSocialAuth.vue";
import TelegramSocialAuth from "@/components/Auth/Buttons/Socials/TelegramSocialAuth.vue";

import {mapGetters, mapMutations} from "vuex";
export default {
  data () {
    return {
      isProcessing: false,
      isStartSignature: false,
    }
  },
  computed: {
    ...mapGetters({
      isOpenAuth: 'config/isOpenAuth',
      tab: 'config/authTab',
    })
  },
  methods: {
    ...mapMutations({
      setIsShowWeb3Register: 'config/setIsShowWeb3Register'
    }),
    web3() {
      return this.setIsShowWeb3Register({value: true, method: this.tab});
    }
  },
  name: "SocialAuthList",
  components: {TelegramSocialAuth, GoogleSocialAuth},
}
</script>

<template>
  <div class="social-auth-list">

    <div class="custom-list">
      <div class="custom-item">
        <TelegramSocialAuth />
      </div>
    </div>

    <div class="default-list">
      <div class="social-auth-list_item default-hover">
        <GoogleSocialAuth />
      </div>
      <a @click="web3" class="social-auth-list_item default-hover">
        <img src="@/assets/images/auth/metamask-icon.svg" alt="">
      </a>
    </div>

  </div>
</template>

<style scoped lang="scss">
  .social-auth-list {
    display: flex;
    align-items: center;
    .default-list {
      display: flex;
      justify-content: center;
      width: 100%;
      grid-gap: 6px;
      .social-auth-list_item {
        width: 32px;
        position: relative;
        height: 32px;
        overflow: hidden;
        border-radius: 100%;
        background: #1D1B41;
        display: flex;
        align-items: center;
        justify-content: center;
        .social-auth-list_item {
          position: absolute;
          left: 0;
          top: 0;
        }
        img {
          position: relative;
          z-index: 5;
        }
      }
    }
    .custom-list {
      margin-top: 10px;
      .custom-item {
        width: 100%;
        display: flex;
        justify-content: center;
      }
      .custom-item + .custom-item {
        margin-top: 10px;
      }
      width: 100%;
      margin-bottom: 5px;
    }
  }
</style>