<script>
export default {
  props: {
    name: {
      type: String,
      required: true
    },
    isActive: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: require('@/assets/images/profile/profile-tab.png')
    }
  },
  name: "TopProfileTab"
}
</script>

<template>
  <div class="tab default-hover" @click="$emit('click')" :class="{'active': isActive}">
    <div class="icon">
      <img :src="icon" alt="">
    </div>
    <div class="name">
      {{ name }}
    </div>
  </div>
</template>

<style scoped lang="scss">
  .tab {
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 114px;
    padding: 0 12px;
    border-radius: 20px;
    overflow: hidden;
    transition: 0.2s;
    background: transparent;
    justify-content: center;
    @media (max-width: 992px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 90px;
    }

    .icon {
      display: flex;
      align-items: center;
      width: 100px;
      min-width: 100px;

      @media (max-width: 1900px) {
        width: 70px;
        min-width: 70px;
      }
      @media (max-width: 1760px) and (min-width: 1600px) {
        width: 50px;
        min-width: 50px;
      }
      @media (max-width: 1350px) {
        width: 50px;
        min-width: 50px;
      }
      @media (max-width: 992px) {
        justify-content: center;
        width: 100%;
        min-width: 100%;
        height: 45px;
        margin-right: 0;
      }
      img {
        max-width: 100%;
        max-height: 100%;
      }
      margin-right: 10px;
    }
    .name {
      font-family: "Unbounded";
      font-size: 16px;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.32px;
      @media (max-width: 1760px) and (min-width: 1200px) {
        font-size: 14px;
      }
      @media (max-width: 1350px) {
        font-size: 14px;
      }
      @media (max-width: 992px) {
        text-align: center;
        width: 100%;
        margin-top: 5px;
        font-size: 12px;
      }
    }
    &.active {
      transition: 0.2s;
      background: linear-gradient(90deg, rgba(171, 107, 255, 0.8), rgba(179, 112, 231, 0.1));
      .icon {
        img {
          filter: drop-shadow(0px 21px 21px black);
        }
      }
      @media (max-width: 768px) {
        background: linear-gradient(180deg, rgba(171, 107, 255, 0.8), rgba(179, 112, 231, 0.1));
      }
    }
  }
</style>