import ApiClient from "@/services/ApiClient";

export function getLootboxes (data) {
    return ApiClient.get('/lootboxes/', data);
}

export function getLootbox (id) {
    return ApiClient.get('/lootboxes/' + id);
}

export function openLootbox(id) {
    return ApiClient.post('/lootboxes/' + id);
}

export function getHistory(data)
{
    return ApiClient.get('/lootboxes/history', data);
}

export function getFreeLootboxes()
{
    return ApiClient.get('/lootboxes/free')
}

export function getFreeLootbox()
{
    return ApiClient.post('/lootboxes/free')
}