<script>
export default {
  name: "SaleItemHead"
}
</script>

<template>
  <div class="sale-item-head">
    <div class="nft">{{ $t('account.nfts.table.nft') }}</div>
    <div class="price">{{ $t('account.nfts.table.price') }}</div>
    <div class="status">{{ $t('account.nfts.table.status') }}</div>
    <div class="date">{{ $t('account.nfts.table.date') }}</div>
    <div class="actions">{{ $t('account.nfts.table.manage') }}</div>
  </div>
</template>

<style scoped lang="scss">
  .sale-item-head {
    display: flex;
    align-items: center;
    padding: 16px 0;
    @media (max-width: 768px) {
      flex-wrap: wrap;
    }
    & > div {
      color: #555379;
      width: 20%;
      font-size: 12px;
      font-family: "Geometria-Bold";
      font-weight: 700;
      letter-spacing: 0.6px;
    }
    .nft {
      width: 25%;
      @media (max-width: 768px) {
        width: 40%;
      }
    }
    @media (max-width: 768px) {
      .price {
        width: 26.66%;
        justify-content: center;
        display: flex;
      }

      .status {
          width: 33.33%;
        justify-content: flex-end;
        display: flex;
      }

      .date {
        width: 50%;
      }
    }
    .actions {
      width: 15%;
      display: flex;
      justify-content: flex-end;
      @media (max-width: 768px) {
        width: 50%;
      }
    }
  }
</style>