<script>
export default {
  props: {
    showCount: {
      type: Boolean,
      default: false
    },
    provider: {
      type: Object
    }
  },
  name: "GameProviderItem"
}
</script>

<template>
  <router-link :to="`/provider/${provider.provider_name}`" class="provider">
    <div class="provider-name">
      {{ provider.provider_name }}
    </div>
    <div class="count" v-if="showCount">{{ provider.count }}</div>
  </router-link>
</template>

<style scoped lang="scss">
.provider {
  overflow: hidden;
  border-radius: 15px;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #1D1B41;
  position: relative;
  .count {
    position: absolute;
    color: #918FBB;
    padding: 2px 6px;
    border-radius: 24px;
    background: #3E3C5F;
    right: 5px;
    top: 5px;
    font-size: 14px;
    font-family: "Geometria-Bold";
    font-weight: 700;
    letter-spacing: -0.28px;
  }
  .provider-name {
    font-family: Unbounded;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.28px;
  }
  img {
    max-width: 80%;
    max-height: 90%;
  }
}
</style>