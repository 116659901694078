export default {
    setList (state, games) {
        state.list = games;
    },

    appendList (state, games) {
        state.list = [...state.list, ...games];
    },

    setSection (state, section) {
        state.section = section;
    },

    setSearch (state, search) {
        state.search = search;
    },

    setCategories (state, categories) {
        state.categories = categories;
    },

    setPage (state, page) {
        state.page = page
    },

    setLastPage (state, page) {
        state.last_page = page
    },

    setIsLoading(state, value) {
        state.isLoading = value;
    },

    resetPagination (state) {
        state.last_page = null;
        state.page = 1;
    },

    setProviders(state, providers) {
        state.providers = providers;
    }
}