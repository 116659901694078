<script>
export default {
  props: {
    status: {
      type: String
    }
  },
  name: "BadgeStatus"
}
</script>

<template>
  <div class="badge-status" :class="[status]">
    <template v-if="status === 'success'">{{ $t('statuses.completed') }}</template>
    <template v-if="status === 'approved'">{{ $t('statuses.approved') }}</template>
    <template v-if="status === 'cancelled'">{{ $t('statuses.cancelled') }}</template>
    <template v-if="status === 'created'">{{ $t('statuses.created') }}</template>
    <template v-if="status === 'timeout'">{{ $t('statuses.timeout') }}</template>
    <template v-if="status === 'failed'">{{ $t('statuses.failed') }}</template>
    <template v-if="status === 'declined'">{{ $t('statuses.declined') }}</template>
    <template v-if="status === 'pending'">{{ $t('statuses.awaiting') }}</template>
  </div>
</template>

<style scoped lang="scss">
  .badge-status {
    text-transform: uppercase;
    padding: 4px 8px;
    border-radius: 6px;
    width: fit-content;
    overflow: hidden;
    font-size: 12px;
    font-family: "Geometria-Bold";
    font-weight: 700;
    letter-spacing: 0.6px;
    line-height: 14px;
    &.cancelled, &.timeout, &.failed, &.declined {
      background: rgba(255, 107, 107, 0.20);
      color: #FF6F6F;
    }
    &.completed, &.success, &.approved {
      color: #BBFF92;
      border-radius: 6px;
      background: linear-gradient(180deg, rgba(154, 255, 52, 0.20) 0%, rgba(118, 236, 0, 0.20) 100%);
    }
    &.awaiting, &.pending, &.created {
      color: #FFE70C;
      background: rgba(255, 226, 79, 0.27);
    }
  }
</style>