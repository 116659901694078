<script>
import FeedItem from "@/components/Parts/LiveFeed/FeedItem.vue";
import {getList} from "@/services/live-feed";
export default {
  data () {
    return {
      items: [],
    }
  },
  methods: {
    async getFeed () {
      await getList().then(response => {
        this.items = response.data.data;
      })
    },
    subscribeToNewItems ()
    {
      this.$echo.channel(`live-feed`).listen(".CreatedWinEvent", async (payload) => {
        this.items.unshift(payload)
        this.items.pop();
      })
    }
  },
  mounted () {
    this.getFeed()
    this.subscribeToNewItems();
  },
  name: "LiveFeed",
  components: {FeedItem}
}
</script>

<template>
  <div class="live-feed">
    <h3>{{ $t('live_feed.heading') }}</h3>

    <div class="items">
      <FeedItem v-for="(feed, i) in items" :key="i" :feed="feed"/>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .live-feed {

    h3 {
      color: #7F7DA6;
      font-size: 12px;
      font-weight: 500;
      font-family: "Geometria-Medium";
      letter-spacing: 0.6px;
      margin-bottom: 8px;
      @media (max-width: 768px) {
        font-size: 10px;
        margin-bottom: 7px;
      }
    }

    position: relative;
    &::after {
      content: "";
      display: block;
      position: absolute;
      width: 160px;
      right: 0;
      height: 100%;
      top: 0;
      background: linear-gradient(270deg, #02002A 0%, rgba(2, 0, 42, 0.00) 100%);
    }
    .items {
      max-width: 100%;
      display: flex;
      grid-gap: 8px;
      position: relative;
      overflow-x: hidden;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
</style>