<script>
import AccentBtn from "@/components/Ui/Buttons/AccentBtn.vue";
import { createFiatDeposit } from "@/services/user";
import extractErrors from "@/helpers/extractErrors";

export default {
  components: {AccentBtn},
  data () {
    return {
      isLoading: false,
      balance: 100,
    }
  },
  methods: {
    async deposit() {
      this.isLoading = true;
      try {
        await createFiatDeposit({
          amount: this.balance
        }).then(response => {
          if (response.data.url) {
            this.balance = null;
            window.location.href = response.data.url;
          } else {
            this.$root.$emit('error', {
              title: "Error",
              text: this.$t('d_p_unavailable'),
            })
          }
        }).catch(errors => {
          extractErrors(errors).forEach(item => {
            this.$root.$emit('error', {
              title: "Error",
              text: item,
            })
          })
        })
      } catch(e) {
        console.log(e)
      } finally {
        this.isLoading = false;
      }
    }
  },
  name: "FiatDeposit"
}
</script>

<template>
<div class="fiat-deposit">
  <form action="">
    <div class="select-label">{{ $t('d_select_amount') }}</div>
    <div class="input">
      <div class="input-currency">
        <div class="input-currency__img">
          <img src="@/assets/images/dollar-icon.svg" alt="">
        </div>
        <div class="input-currency__name">
          USD
        </div>
      </div>
      <input v-model="balance" type="number" @input="setBalance">
      <div class="label">
        {{ $t('d_amount') }}
      </div>
    </div>

    <AccentBtn :is-loading="isLoading" @click="deposit" :disabled="!balance" :title="$t('d_continue')" />
  </form>
</div>
</template>

<style scoped lang="scss">
form {
  .input {
    border-radius: 12px;
    background: var(--50-Dark, rgba(0, 3, 42, 0.50));
    padding: 4px;
    display: flex;
    overflow: hidden;
    padding-right: 16px;

    .input-currency {
      display: flex;
      align-items: center;
      width: 100px;
      border-radius: 9px;
      background: var(--10-purple, rgba(182, 127, 255, 0.10));
      padding: 10px;
      min-width: 100px;
      .input-currency__img {
        width: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 24px;
        margin-right: 8px;
        img {
          width: 24px;
          height: 24px;
        }
      }
      .input-currency__name {
        font-family: "Geometria-Bold";
        font-size: 14px;
        padding-top: 1px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.28px;
      }
    }
    input {
      overflow: hidden;
      padding: 0 16px;
      background: transparent;
      outline: none;
      padding-top: 3px;
      border: none;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 18px;
      letter-spacing: -0.32px;
    }
    .label {
      color: var(--700, #7F7DA6);
      font-family: "Geometria-Medium";
      font-size: 14px;
      white-space: nowrap;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      display: flex;
      align-items: center;
      letter-spacing: -0.28px;
    }
  }
  .select-label {
    color: var(--700, #7F7DA6);
    font-family: "Geometria-Medium";
    font-size: 14px;
    white-space: nowrap;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    margin-bottom: 10px;
    align-items: center;
    width: 100%;
    justify-content: center;
    letter-spacing: -0.28px;
  }
  padding-top: 15px;
  .accent-btn {
    position: absolute;
    bottom: 20px;
    width: calc(100% - 40px);
    left: 20px;
  }
}

</style>