import Vue from 'vue'
import Vuex from 'vuex'
import config from './config/index'
import auth from './auth/index'
import games from './games/index'
import user from './user/index'
import play from './play/index'
import settings from './settings/index'
import chat from './chat/index'
import lootbox from './lootbox/index'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    settings,
    auth,
    play,
    games,
    user,
    config,
    chat,
    lootbox,
  },
})
