<script>
import {VueAgile} from "vue-agile";
import SliderItem from "@/components/Lootboxes/SliderItem.vue";
import {mapGetters, mapMutations} from "vuex";

export default {
  data () {
    return {
      isFinished: false,
      isProcessAnimation: false,
      isInitialize: false,
      proxyItems: [],
      settings: {
        rtl: false,
        pauseOnHover: false,
        pauseOnDotsHover: false,
        navButtons: false,
        centerMode: true,
        slidesToShow: 5.2,
        mobileFirst: true,
        infinite: false,
        initialSlide: 3,
        timing: 'ease',
        dots: false,
        responsive: [
          {
            breakpoint: 2000,
            settings: {
              slidesToShow: 5.2,
            }
          },
          {
            breakpoint: 1800,
            settings: {
              slidesToShow: 3.2,
            }
          },
          {
            breakpoint: 1600,
            settings: {
              slidesToShow: 3.3,
            }
          },
          {
            breakpoint: 1300,
            settings: {
              slidesToShow: 3.2,
            }
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 3.25,
            }
          },
          {
            breakpoint: 700,
            settings: {
              slidesToShow: 3.25,
            }
          },
          {
            breakpoint: 500,
            settings: {
              slidesToShow: 3.2,
            }
          },

          {
            breakpoint: 400,
            settings: {
              slidesToShow: 3.3,
            }
          },

          {
            breakpoint: 300,
            settings: {
              slidesToShow: 3.3,
            }
          },
        ]
      }
    }
  },
  computed: {
    ...mapGetters({
      items: 'lootbox/items',
      winItem: 'lootbox/winItem',
      isPlaying: "lootbox/isPlaying",
      mode: 'lootbox/mode',
      user: 'user/user',
      spinsCount: 'lootbox/spinsCount',
      autoSelectItem: 'lootbox/autoSelectItem',
      balanceLimit: 'lootbox/balanceLimit',
      completedSpinsCurrently: 'lootbox/completedSpinsCurrently'
    }),
    speed () {
      return 7350;
    }
  },
  props: {
    price: {
      type: Number,
      required: true,
    }
  },
  methods: {
    ...mapMutations({
      reset: 'lootbox/reset',
      setIsPlaying: 'lootbox/setIsPlaying',
      setCompletedSpinsCurrently: 'lootbox/setCompletedSpinsCurrently',
      setSpinsCount: 'lootbox/setSpinsCount',
      setAutoSelectItem: 'lootbox/setAutoSelectItem',
    }),
    fillProxyItems () {
      const items = this.items;

      if (!items.length) return [];

      let result = [];

      while(result.length < 60) {
        result = [...result, ...items];
      }

      return result;
    },
    listenStart () {
      this.$root.$on('lootbox.start-roulette', this.start);
    },
    start() {
      if (this.isPlaying) return;

      if (!this.winItem?.id) {
        console.log('not id');
        return this.$root.$emit('error', {
          title: "Error",
          text: this.$t('ll_failed_open')
        })
      }

      let index = null;

      for(const item in this.proxyItems) {
        if (item > (this.proxyItems.length - 6)) break;
        if (this.items[item].id === this.winItem.id) {
          index = item;
        }
      }


      if (!index) {
        console.log('not index', index)
        return this.$root.$emit('error', {
          title: "Error",
          text: this.$t('ll_failed_open')
        })
      }

      this.setIsPlaying(true);
      console.log(this.$refs)
      this.$refs.slider.goTo(index);
      if (this.mode === 'auto') {
        this.setCompletedSpinsCurrently(this.completedSpinsCurrently + 1);
      }
    },
    async wait() {
      return new Promise((res) => {
        setTimeout(res, this.speed);
      })
    },
    async finish() {
      if (!this.isPlaying) return;

      // setTimeout(() => {
      //   this.startOffsetAnimation();
      // }, 2000);

      await this.wait();
      this.isFinished = true;

      if (this.mode === 'auto' && this.autoSelectItem) {
        if (this.autoSelectItem.id === this.winItem.id) {
          this.$root.$emit('success', {
            title: "Congratulations!",
            text: this.$t('ll_open_cong') + " " + this.completedSpinsCurrently
          })

          this.setAutoSelectItem(null);
          this.setSpinsCount(0);
          this.setCompletedSpinsCurrently(0)
          setTimeout(() => {
            this.$root.$emit('lootbox.finish')
          }, 2000)
          this.setIsPlaying(false);
          return;
        }

        if (this.spinsCount > 0 && this.spinsCount <= this.completedSpinsCurrently) {
            if (this.autoSelectItem.id !== this.winItem.id) {
              this.$root.$emit('error', {
                title: "Bad luck",
                text: this.$t('ll_unf_open')
              })

              this.setAutoSelectItem(null);
              this.setSpinsCount(0);
              this.setCompletedSpinsCurrently(0)
              setTimeout(() => {
                this.setIsPlaying(false);
                this.$root.$emit('lootbox.finish')
              }, 2000)
              return;
            }
        }

        if (this.balanceLimit > 0 && (this.user.sg_balance - this.price) < this.balanceLimit) {
          this.$root.$emit('error', {
            title: "Stop balance limit",
            text: this.$t('ll_unf_open')
          })

          this.setAutoSelectItem(null);
          this.setSpinsCount(0);
          this.setCompletedSpinsCurrently(0)

          setTimeout(() => {
            this.$root.$emit('lootbox.finish')
          }, 2000)
          this.setIsPlaying(false);
          return;
        }

        this.setIsPlaying(false);
        this.$root.$emit('lootbox.re-run');
        return;
      }

      setTimeout(() => {
        this.$root.$emit('lootbox.finish')
        this.setIsPlaying(false);
      }, 2000)
    },
    // startOffsetAnimation() {
    //   const slider = this.$refs.slider;
    //   const blockWidth = this.$refs['slide-0'][1].clientWidth;
    //   console.log('blockWidth', blockWidth)
    //   const direction = Math.random() < 0.5 ? 'left' : 'right';
    //   const size = Math.floor(Math.random() * (blockWidth / 2.1));
    //   slider.$el.childNodes[0].childNodes[0].style[`padding-${direction}`] = `${size}px`;
    // },
  },
  name: "LootboxRoulette",
  components: {
    SliderItem,
    'agile': VueAgile
  },
  async mounted() {

    this.proxyItems = await this.fillProxyItems();
    await this.listenStart()
    this.isInitialize = true;
  }
}
</script>

<template>
  <div class="lootbox-roulette">
    <div class="add-left">
      <img src="@/assets/images/lootbox/add-left.png" alt="">
    </div>
    <div class="add-right">
      <img src="@/assets/images/lootbox/add-right.png" alt="">
    </div>
    <div class="shadow">
      <img src="@/assets/images/lootbox/roulette-shadow.png" alt="">
    </div>

    <div class="lootbox-rector" :class="{'running': isPlaying, 'finish': isFinished}" v-if="isInitialize">
      <div class="congratulations" v-if="isFinished">
        <img src="@/assets/images/congratulations.svg" alt="">
        {{ $t('ll_cong') }}
      </div>

      <div class="cursor">
        <img src="@/assets/images/lootbox/cursor.svg" alt="">
      </div>
      <agile @after-change="finish" :swipe-distance="1000000000" :speed="speed" ref="slider" :options="settings">
          <div :ref="`slide-${i}`" class="nft-item" v-for="(item,i) in items" :key="i">
            <SliderItem :class="[`slide-${i}`]" :item="item" />
          </div>
      </agile>
    </div>

  </div>
</template>

<style scoped lang="scss">
  .lootbox-roulette {
    padding: 67px 0;
    background: url(@/assets/images/lootbox/roulette-bg.png), linear-gradient(180deg, #252A65 0%, rgba(19, 24, 87, 0.00) 100%);;
    background-size: 100% 100%;
    border-radius: 20px;
    overflow: hidden;
    position: relative;
    ::v-deep {
      .agile__slides {
        transform: translateX(25px);
      }
    }
    @media (max-width: 768px) {
      padding: 40px 0;
    }
    .lootbox-rector {
      width: 100%;
      background-repeat: no-repeat;
      background-size: 24% 100%;
      background-position: center center;
      position: relative;
      z-index: 2;
      padding: 20px 0;
      margin: 0 auto;
      transition: 0.5s;

      .congratulations {
        position: absolute;
        top: -30px;
        display: flex;
        width: 200px;
        left: calc(50% - 100px);
        justify-content: center;
        align-items: center;
        img {
          margin-right: 7px;
        }
        font-family: Unbounded;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.24px;
      }
      &.finish {
        ::v-deep {
          .agile__slide--active {
            .nft-item-content {
              opacity: 1 !important;
            }
          }
        }
      }
      ::v-deep {
        .nft-item-content {
          opacity: 0.4;
          transition: 0.5s;
        }
        &.running {
         .nft-item-content {
           opacity: 1;
         }
        }
      }
      @media (max-width: 768px) {
        width: 150%;
        z-index: 5;
        margin-left: -25%;
      }
      .cursor {
        opacity: 0.5;

        position: absolute;
        top: 0;
        width: 100px;
        height: 50px;
        img {
          width: 100%;
          height: 100%;
        }
        left: calc(50% - 50px);
        z-index: 4;
      }
      .hooper {
        height: 100%;
        .hooper-slide {
          height: 100%;
          border-radius: 20px;
          overflow: hidden;
        }
      }
      .nft-item {
        width: 100%;
        height: 100%;
        padding: 0 5px;
        user-select: none;
        border-radius: 20px;
        overflow: hidden;
      }
    }

    .lootbox-rector {
      .holder-backdrop {
        top: 0;
        .nft-item-content {
          opacity: 0 !important;
        }

        .holder-item {
          opacity: 0 !important;
        }

        .nft-item {
          position: relative;
          overflow: unset !important;
        }
        ::v-deep {
          .agile__slide--active {

            .nft-item-content {
            }
            .holder-item {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              opacity: 1 !important;
              img {
                margin-left: -15%;
                width: 130%;
              }
            }
          }

        }
      }
    }

    .add-left {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 4;
      img {
        filter: grayscale(1);
      }
    }
    .add-right {
      position: absolute;
      right: 0;
      z-index: 4;
      bottom: 0;
      img {
        filter: grayscale(1);
      }
    }
    .shadow {
      position: absolute;
      height: 100%;
      width: 50%;
      left: 25%;
      img {
        height: 100%;
      }
      top: 0;
    }
  }
</style>