<script>
import {mapGetters, mapMutations} from "vuex";

export default {
  computed: {
    ...mapGetters({
      user: 'user/user'
    })
  },
  methods: {
    ...mapMutations({
      setOpenSetAvatar: 'config/setOpenSetAvatar'
    })
  },
  name: "ShortUser"
}
</script>

<template>
  <div class="user">
    <div class="user-avatar">
      <div class="image">
        <img :class="{'without-avatar': !user.avatar}" :src="user.avatar ? user.avatar : require('@/assets/images/default-avatar.png')" alt="">
      </div>
      <div class="edit default-hover" @click="setOpenSetAvatar(true)">
        <img src="@/assets/images/edit-icon.svg" alt="">
      </div>
    </div>
    <div class="user-names">
      <strong>@{{user.username}}</strong>
      <span>{{user.email}}</span>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .user {
    display: flex;
    align-items: center;
    .user-avatar {
      width: 86px;
      height: 86px;
      margin-right: 20px;
      min-width: 86px;
      position: relative;
      @media (max-width: 768px) {
        width: 56px;
        height: 56px;
        margin-right: 17px;
        min-width: 56px;
      }
      .image {
        overflow: hidden;
        width: 100%;
        height: 100%;
        border-radius: 22px;
        @media (max-width: 768px) {
          border-radius: 16px;
        }
        img {
          width: 100%;
          height: 100%;
          &.without-avatar {
            filter: grayscale(1);
          }
        }

      }
      .edit {
        position: absolute;
        width: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 36px;
        border-radius: 100%;
        right: -10px;
        bottom: -10px;
        border: 4px solid var(--200, #131139);
        background: var(--Blue-Additional, #23A3FF);
        @media (max-width: 768px) {
          width: 30px;
          height: 30px;
          img {
            width: 9px;
          }
          bottom: -7px;
          right: -7px;
          border-width: 3px;
        }
      }
    }
    .user-names {
      flex-grow: 1;
      overflow: hidden;
      strong,span {
        display: block;
        width: 100%;
      }
      strong {
        font-family: "Unbounded";
        font-size: 24px;
        font-weight: 700;
        line-height: 28px; /* 116.667% */
        letter-spacing: -0.48px;
        margin-bottom: 8px;
        overflow: hidden;
        text-overflow: ellipsis;
        @media (max-width: 768px) {
          font-size: 16px;
          line-height: 20px;
          margin-bottom: 4px;
        }
      }
      span {
        font-size: 13px;
        font-family: "Geometria-Medium";
        font-weight: 500;
        letter-spacing: -0.24px;
        opacity: 0.8;
        @media (max-width: 768px) {
          font-size: 12px;
        }
      }
    }
  }
</style>