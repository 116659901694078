<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Title"
    }
  },
  name: "LinkedButton"
}
</script>

<template>
  <button :disabled="disabled" @click="!disabled ? $emit('click', $event) : ''" class="linked-button">
    {{ title }}
  </button>
</template>

<style scoped lang="scss">
  .linked-button {
    padding: 16px;
    width: 100%;
    font-size: 14px;
    text-decoration: underline;
    cursor: pointer;
    transition: 0.2s;
    font-family: "Geometria-Bold";
    &:hover {
      opacity: 0.8;
    }
    background: transparent;
  }
</style>