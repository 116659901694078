<script>
import {mapGetters} from "vuex";

export default {
  computed: {
    ...mapGetters({
      user: 'user/user'
    })
  },
  name: "PasswordState"
}
</script>

<template>
  <div class="password-state">
    <div class="is-not-set" v-if="!user.restrictions.isSetPassword">
      <h1>{{ $t('account.sec.password_is_not_set') }}</h1>
      <span>{{ $t('account.sec.password_is_not_set') }}</span>
    </div>
    <div class="is-set" v-else>
      <img src="@/assets/images/password-set.png" alt="">
      <div class="text">
        <h1>{{ $t('account.sec.password_is_set') }}</h1>
        <span>{{ $t('account.sec.password_is_set') }}</span>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .password-state {
    width: 100%;
    .is-set {
      display: flex;
      justify-content: center;
      align-items: center;
      @media (max-width: 768px) {
        justify-content: space-around;
        img {
          width: 100px;
        }
      }
      .text {
        margin-left: 28px;
      }
    }
    h1 {
      font-family: "Unbounded";
      font-size: 18px;
      font-weight: 700;
      letter-spacing: -0.36px;
      width: 100%;
      display: block;
      text-align: center;
      margin-bottom: 8px;
      @media (max-width: 768px) {
        font-size: 16px;
      }
    }
    span {
      opacity: 0.8;
      width: 100%;
      display: block;
      text-align: center;
      color: #918FBB;
      font-size: 14px;
      font-family: "Geometria-Medium";
      font-weight: 500;
      letter-spacing: -0.28px;
      @media (max-width: 768px) {
        font-size: 12px;
      }
    }
  }
</style>