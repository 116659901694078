<script>
export default {
  data () {
    return {
      counter: 0,
    }
  },
  name: "BaseModal",
  methods: {
    close(event) {
      try {
        const body = this.$slots.default[0].elm;
        if (!body.contains(event.target)) {
          if (body['__vue__']) {
            this.counter++;
            if (this.counter === 2) {
              this.counter = 0;
              body['__vue__'].close();
            }
          }
        }
      } catch (e) {
        console.log(e)
      }
    }
  },
  mounted() {
    this.$root.$emit('openModal');
    document.addEventListener('click', this.close);
  },
  beforeDestroy() {
    this.$root.$emit('closeModal');
    document.removeEventListener('click', this.close)
  }
}
</script>

<template>
  <div class="modal">
    <slot ref="body"></slot>
  </div>
</template>

<style scoped lang="scss">
  .modal {
    position: fixed;
    top: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 64px);
    width: 100%;
    left: 0;
    background: radial-gradient(51.98% 51.98% at 50% 48.02%, rgb(71 67 161 / 1%) 0%, rgba(65, 61, 127, 0) 100%), rgba(2, 0, 42, 0.72);
    z-index: 9;
    overflow-y: auto;
    padding-bottom: 20vh;

    @media (max-height: 830px) and (min-width: 992px) {
      padding-top: 100px;
    }
    @media (max-width: 768px) {
      align-items: flex-start;
      padding-top: 15px;
      padding-right: 20px;
      padding-left: 20px;
      padding-bottom: calc(env(safe-area-inset-bottom) + env(safe-area-inset-top) + 30dvh);
    }
  }
</style>