<script>
import UiInput from "@/components/Ui/UiInput.vue";

export default {
  props: {
    email: {
      type: String,
      required: true
    }
  },
  methods: {
    close() {
      this.$emit('close')
    }
  },
  name: "EmailHasBeenSent",
  components: {UiInput}
}
</script>

<template>
<div class="email">

  <button @click="$emit('close')" class="back default-hover">
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <path d="M11.25 4.5L6.75 9L11.25 13.5" stroke="#7F7DA6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

    <span>{{$t('back')}}</span>
  </button>

  <div class="email-img">
    <img src="@/assets/images/modals/email-sent.svg" alt="">
  </div>
  <h2>
    {{ $t('sent.email_sent') }}
  </h2>
  <p>
    {{ $t('sent.text') }}
  </p>

  <div class="email-value">
    <UiInput :readonly="true" :icon="require('@/assets/images/email-purple.svg')" type="text" :value="email" />
  </div>
</div>
</template>

<style scoped lang="scss">
  .email {
    .back {
      display: flex;
      align-items: center;
      padding: 10px 12px;
      border-radius: 11px;
      background: #1D1B41;
      span {
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        font-family: "Geometria-Bold";
        line-height: normal;
        letter-spacing: -0.28px;
        margin-left: 8px;
      }
    }
    .email-img {
      width: 100%;
      display: flex;
      justify-content: center;
    }
    h2 {
      margin-top: -10px;
      font-family: Unbounded;
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 12px;
      line-height: 28px; /* 116.667% */
      letter-spacing: -0.48px;
    }
    p {
      color: #7F7DA6;
      font-family: "Geometria-Medium";
      font-size: 14px;
      font-weight: 500;
      letter-spacing: -0.28px;
    }
    .email-value {
      margin-top: 14px;
      .ui-input {
        border: 1px dashed rgba(171, 107, 255, 0.20);
      }
    }
  }
</style>