<script>
import SettingItem from "@/components/Profile/SettingItem.vue";
import {updateMe} from "@/services/user";
import extractErrors from "@/helpers/extractErrors";
import {mapActions} from "vuex";
export default {
  name: "PersonalSettings",
  methods: {
    ...mapActions({
      me: 'user/me'
    }),
    async update(field, $event) {
      const data = {};
      data[field] = $event.target.checked;
      await updateMe(data).then(() => {
        this.me()
      }).catch(err => {
        extractErrors(err).forEach(item => {
          this.$root.$emit('error', {
            title: "Error",
            text: item,
          })
        })
      })
    }
  },
  components: {SettingItem}
}
</script>

<template>
  <div class="personal-settings">
    <div class="personal-settings__head">
      {{ $t('account.personal_settings.heading') }}
    </div>
    <div class="personal-settings__list">
      <SettingItem id="streamer_mode" @update="update('streamer_mode', $event)" :icon="require('@/assets/images/profile/streamer-icon.svg')" :name="$t('account.personal_settings.streamer_mode')" />
      <SettingItem id="hide_username" @update="update('hide_username', $event)" :icon="require('@/assets/images/profile/hide-eye-icon.svg')" :name="$t('account.personal_settings.hide_username')" />
    </div>
  </div>
</template>

<style scoped lang="scss">
  .personal-settings {
    .personal-settings__head {
      color: #7F7DA6;
      font-family: "Geometria-Bold";
      font-size: 12px;
      font-weight: 700;
      letter-spacing: 0.6px;
    }
    .personal-settings__list {
      margin-top: 20px;
      @media (max-width: 768px) {
        margin-top: 14px;
        .setting {
          width: 100%;
        }
      }
      .setting + .setting {
        margin-top: 20px;
        @media (max-width: 768px) {
          margin-top: 14px;
        }
      }
    }
  }
</style>