<script>
import CoinPlaceholder from "@/components/Ui/CoinPlaceholder.vue";
import formatNumber from "@/helpers/formatNumber";
export default {
  data () {
    return {
      formatNumber
    }
  },
  components: {CoinPlaceholder},
  props: {
    win: {
      type: Object,
      required: true
    }
  },
  name: "LastWin"
}
</script>

<template>
  <div class="last-win">
    <div class="last-win-message">
      <div class="last-win-message__icon">
        <img src="@/assets/images/chat/money-icon.svg" alt="">
      </div>
      <div class="last-win-message__text">
        {{ $t('my_win_in') }}
      </div>
    </div>
    <div class="last-win-body">
      <div class="last-win__game-icon">
        <img :src="win.game.preview_image" alt="">
      </div>
      <div class="last-win__info">
        <div class="game-name">
          {{ win.game.name }}
        </div>
        <div class="currency">
          <CoinPlaceholder :symbol="win.currency.symbol" />
          <div class="amount">
            {{ formatNumber(win.amount).integer }}
            <em>
              .{{ formatNumber(win.amount).decimal }}
            </em>
          </div>
          <div class="name">
            {{ win.currency.code }}
          </div>
        </div>
      </div>
      <router-link :to="`/game/${win.game.id}`" class="last-win__go-to default-hover">
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
          <path d="M4.5 9L7.5 6L4.5 3" stroke="white" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </router-link>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .last-win {
    .last-win-message {
      display: flex;
      align-items: center;
      margin-bottom: 3px;
      width: 100%;
      overflow: hidden;
      .last-win-message__icon {
        width: 18px;
        height: 18px;
        margin-right: 8px;
      }
      .last-win-message__text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #B9B8DD;
        font-family: "Geometria-Medium";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.28px;
      }
    }
    .last-win-body {
      border-radius: 9px;
      background: linear-gradient(90deg, #932BFC 9.82%, #130F51 100%);
      padding: 4px;
      display: flex;
      overflow: hidden;
      .last-win__game-icon {
        width: 40px;
        min-width: 40px;
        height: 54px;
        margin-right: 13px;
        border-radius: 8px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .last-win__info {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        overflow: hidden;
        .game-name {
          font-size: 12px;
          font-style: normal;
          font-family: "Geometria-Medium";
          padding-right: 30px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.24px;
        }
        .currency {
          display: flex;
          align-items: center;
          .amount {
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: -0.28px;
            font-family: "Geometria-Medium";
            margin: 0 6px;
            display: flex;
            em {
              font-style: normal;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              letter-spacing: -0.28px;
              color: #7d7ba6
            }
          }
          .name {
            padding: 2px 4px;
            border-radius: 5px;
            background: rgba(255, 255, 255, 0.15);
            color: #B9B8DD;
            font-family: "Geometria-Bold";
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            padding-top: 4px;
            line-height: normal;
            letter-spacing: -0.24px;
          }
        }
      }

      position: relative;
      .last-win__go-to {
        position: absolute;
        right: 4px;
        top: 4px;
        border-radius: 30px;
        background: linear-gradient(180deg, #9D42F8 -4.55%, #7F00FF 100%);
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
</style>