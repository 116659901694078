<script>
import HistoryFilter from "@/components/Profile/GameHistory/HistoryFilter.vue";
import HistoryItem from "@/components/Profile/GameHistory/HistoryItem.vue";
import HistoryItemHead from "@/components/Profile/GameHistory/HistoryItemHead.vue";
import {getGameHistory} from "@/services/user";
import MainPagination from "@/components/MainPagination.vue";
import EmptyState from "@/components/EmptyState.vue";
export default {
  data () {
    return {
      currentPage: 1,
      totalPages: 1,
      type: null,
      items: [],
      range: null,
    }
  },
  methods: {
    fetchItems() {
      const data = {
        type: this.type,
        list: 20,
        page: this.currentPage
      };

      if (this.range) {
        data.fromDate = Math.ceil(new Date(this.range.start).getTime() / 1000)
        data.toDate = Math.ceil(new Date(this.range.end).getTime() / 1000)
      }

      getGameHistory(data).then(response => {
        this.items = response.data.data;
        this.currentPage = response.data.meta.current_page;
        this.totalPages = response.data.meta.last_page;
      })
    },
    setPage (page) {
      if (!page || this.totalPages < page) return;

      this.currentPage = page;
      this.fetchItems()
    }
  },
  watch: {
    range() {
      this.currentPage = 1;
      this.fetchItems()
    },
    type () {
      this.currentPage = 1;
      this.fetchItems()
    }
  },
  mounted() {
    this.fetchItems()
  },
  name: "ProfileGameHistories",
  components: {EmptyState, MainPagination, HistoryItemHead, HistoryItem, HistoryFilter}
}
</script>

<template>
  <div class="histories">
    <HistoryFilter @range="range = $event" :type="type" @updateType="type = $event" />

    <div class="items">
      <HistoryItemHead />
      <div class="items-content">
        <template v-if="items.length">
          <HistoryItem :item="item" v-for="item in items" :key="item.id" />
        </template>
        <EmptyState :heading="$t('not_played_games')" v-else />
      </div>

      <MainPagination v-if="totalPages > 1" @setPage="setPage($event)" :totalPages="totalPages" :currentPage="currentPage" />
    </div>
  </div>
</template>

<style scoped lang="scss">
  .histories {
    .items {
      .history-item + .history-item {
        margin-top: 4px;
      }
      .items-content {
        margin-bottom: 20px;
      }
    }
  }
</style>