<script>
export default {
  props: {
    item: {
      type: Object
    }
  },
  name: "SliderItem"
}
</script>

<template>
  <div class="nft-item-content">
    <div class="nft-item-content__image" :class="{'coins': item.type === 'coins'}">
      <img v-if="item.type === 'nft'" :src="item.nft.image_url" alt="">
      <img v-if="item.type === 'coins'" src="@/assets/images/lootbox/coins.png" alt="">
      <img v-if="item.type === 'xp'" src="@/assets/images/new/xp.png" alt="">
    </div>
    <div class="nft-item-content__name" v-if="item.type === 'nft'">{{ item.nft.name }}</div>
    <div class="nft-item-content__name" v-else-if="item.type === 'coins'">Coins</div>
    <div class="nft-item-content__name" v-else-if="item.type === 'xp'">EXP</div>
    <div class="nft-item-content__price">
      <img src="@/assets/images/sneg-coin.svg" v-if="item.type === 'nft'" alt="">
      <img src="@/assets/images/profile/energy.svg" v-else-if="item.type === 'xp'" alt="">
      <span v-if="item.type === 'nft'">{{ parseFloat(item.nft.price).toFixed(2) }}</span>
      <span v-if="item.type === 'coins'">{{ item.sg_coins }}</span>
      <span v-if="item.type === 'xp'">{{ item.xp }}</span>
      <div class="coin" v-if="item.type === 'nft'">SNEG</div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.nft-item-content {
  height: 100%;
  border-radius: 20px;
  overflow: hidden;
  width: 100%;
  padding: 14px;
  user-select: none;
  background: rgba(200, 197, 255, 0.1);
  padding-bottom: 7px;
  @media (max-width: 768px) {
    padding: 5px;
  }
  @media (max-width: 768px) {
    background: url(@/assets/images/lootbox/mb.jpg);
    background-size: 115% 115%;
    background-position: center left -7.5%;
  }
  .nft-item-content__image {
    margin-bottom: 18px;
    border-radius: 20px;
    overflow: hidden;
    width: 100%;
    position: relative;
    padding-bottom: 100%;

    img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
    &.coins {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 80%;
        left: 10%;
        height: auto;
      }
    }
    &::after {
      padding-bottom: 100%;
    }
  }
  .nft-item-content__name {
    white-space: nowrap;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    padding: 0 9px;
    font-family: "Unbounded";
    font-size: 12px;
    font-weight: 700;
    line-height: 14px;
    margin-bottom: 6px;
    letter-spacing: -0.24px;
  }
  .nft-item-content__price {
    display: flex;
    align-items: center;
    overflow: hidden;
    max-width: 100%;
    justify-content: center;
    padding-right: 9px;
    padding-left: 9px;
    padding-bottom: 8px;
    @media (max-width: 768px) {
      padding-left: 0;
      padding-right: 0;
    }
    img {
      width: 18px;
      height: 18px;
      margin-right: 6px;
      @media (max-width: 768px) {
        width: 14px;
        height: 14px;
        margin-right: 4px;
      }
    }
    span {
      font-size: 14px;
      font-family: "Geometria-Bold";
      font-weight: 700;
      letter-spacing: -0.28px;
      line-height: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      @media (max-width: 768px) {
        font-size: 12px;
      }
    }
    .coin {
      margin-left: 5px;
      padding: 2px 4px;
      border-radius: 5px;
      background: rgba(255, 255, 255, 0.15);
      color: #B9B8DD;
      font-size: 12px;
      font-weight: 700;
      line-height: 14px;
      padding-top: 3px;
      font-family: "Geometria-Bold";
      letter-spacing: -0.24px;
      @media (max-width: 768px) {
        font-size: 10px;
      }
    }
  }
}
</style>