<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import BackButton from "@/components/BackButton.vue";
import UiLoader from "@/components/UiLoader.vue";
import { getGame } from "@/services/games";
import extractErrors from "@/helpers/extractErrors";
import GameProviders from "@/components/Main/GameProviders.vue";
import CasinoGames from "@/components/Main/CasinoGames.vue";
import BetsList from "@/components/Parts/BetsList.vue";

export default {
  data () {
    return {
      game: null,
      isFullScreen: false,
      isLoading: false,
      mode: 'real',
    }
  },
  components: {BetsList, CasinoGames, GameProviders, UiLoader, BackButton},
  computed: {
    ...mapGetters({
      categories: 'games/categories',
      play: 'play/play',
      user: 'user/user'
    }),
    proxyMode () {
      if (!this.user) {
        return 'demo';
      }

      return this.mode;
    },
  },
  methods: {
    fetchGame() {
      console.log('fetch game')
      getGame(this.$route.params.id).then((response) => {
        this.game = response.data.data;
      }).catch(errors => {
        extractErrors(errors).forEach(item => {
          this.$root.$emit('error', {
            title: "Error",
            text: item,
          })
        })
      })
    },
    ...mapMutations({
      setIsFulScreenGame: 'config/setIsFulScreenGame',
      setIsOpenAuth: 'config/setOpenAuth'
    }),
    ...mapActions({
      getPlaySession: 'play/getPlaySession'
    }),
    async fetch() {
      this.isLoading = true;
      try {
        await this.getPlaySession({
          gameId: this.$route.params.id,
          mode: this.proxyMode
        });
      } catch(e) {
        console.log(e)
      } finally {
        setTimeout(() => {
          this.isLoading = false;
        }, 1000)
      }
    },
    getParameter(name) {
      if (!this.game || !this.game.parameters) return '-';
      if (typeof this.game.parameters[name] === "undefined") {
        return '-';
      }

      return this.game?.parameters[name]
    }
  },
  watch: {
    isFullScreen() {
      if (this.isFullScreen) {
        this.$root.$emit('openModal')
      } else {
        this.$root.$emit('closeModal')
      }
      this.setIsFulScreenGame(this.isFullScreen)
    },
    mode() {
      this.fetch()
    },
    '$route.params': {
      deep: true,
      async handler() {
        this.fetch();
      }
    }
  },
  mounted () {
    this.fetchGame();
    this.fetch()
  },
  name: "GapePage"
}
</script>

<template>
  <div class="game-page page">
    <div class="game__platform" :class="{'fullscreen': isFullScreen}">
      <div class="game-head">
        <BackButton :callback="() => $router.push('/games')" />

        <div class="full-screen default-hover" @click="isFullScreen = !isFullScreen">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M14 10L21 3M21 3H15M21 3V9M10 14L3 21M3 21H9M3 21L3 15" stroke="#918FBB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
        <div class="game__modes">
          <div class="game-mode default-hover" v-if="user" @click="mode = 'real'" :class="{'active': proxyMode === 'real'}">
            {{ $t('real_play') }}
          </div>
          <div class="game-mode default-hover" v-else @click="setIsOpenAuth(true)">
            {{ $t('real_play') }}
          </div>
          <div class="game-mode default-hover" @click="mode = 'demo'" :class="{'active': proxyMode === 'demo'}">
            {{ $t('fun_play') }}
          </div>
        </div>
      </div>
      <div class="game-frame">
        <UiLoader v-if="isLoading" :fill="true" />
        <iframe allowfullscreen v-if="play && !isLoading" :src="play.url" ></iframe>
      </div>
    </div>

    <div class="game-information" v-if="game">
      <div class="game-information__img">
        <img :src="game.preview_image" alt="">
        <div class="game-information__text-categories">
          <div class="game-information__text-category" v-for="(category, i) in game.categories" :key="i">
            <img :src="category.icon" alt="">
            {{ category.name }}
          </div>
        </div>
      </div>
      <div class="game-information__text">
        <div class="game-information__text-categories">
          <div class="game-information__text-category" v-for="(category, i) in game.categories" :key="i">
            <img :src="category.icon" alt="">
            {{ category.name }}
          </div>
        </div>
        <div class="game-information__text-name">
          {{ game.name }}
        </div>
        <div class="game-information__text-stats">
          <div class="game-information__text-stat__item">
            <div class="game-information__text-stat__item-icon">
              <img src="@/assets/images/info-icon.svg" alt="">
            </div>
            <div class="game-information__text-stat__item-label">
              Software
            </div>
            <div class="game-information__text-stat__item-value">
              {{ game.provider ?? '-' }}
            </div>
          </div>
          <div class="game-information__text-stat__item">
            <div class="game-information__text-stat__item-icon">
              <img src="@/assets/images/info-icon.svg" alt="">
            </div>
            <div class="game-information__text-stat__item-label">
              RTP
            </div>
            <div class="game-information__text-stat__item-value">
              {{ getParameter('rtp') }}%
            </div>
          </div>
          <div class="game-information__text-stat__item">
            <div class="game-information__text-stat__item-icon">
              <img src="@/assets/images/info-icon.svg" alt="">
            </div>
            <div class="game-information__text-stat__item-label">
              Volatility
            </div>
            <div class="game-information__text-stat__item-value">
              {{ getParameter('volatility') }}
            </div>
          </div>
          <div class="game-information__text-stat__item">
            <div class="game-information__text-stat__item-icon">
              <img src="@/assets/images/info-icon.svg" alt="">
            </div>
            <div class="game-information__text-stat__item-label">
              Theme
            </div>
            <div class="game-information__text-stat__item-value">
              {{ getParameter('theme') }}
            </div>
          </div>
          <div class="game-information__text-stat__item">
            <div class="game-information__text-stat__item-icon">
              <img src="@/assets/images/info-icon.svg" alt="">
            </div>
            <div class="game-information__text-stat__item-label">
              Lines count
            </div>
            <div class="game-information__text-stat__item-value">
              {{ getParameter('lines_count') }}
            </div>
          </div>
          <div class="game-information__text-stat__item">
            <div class="game-information__text-stat__item-icon">
              <img src="@/assets/images/info-icon.svg" alt="">
            </div>
            <div class="game-information__text-stat__item-label">
              Autoplay
            </div>
            <div class="game-information__text-stat__item-value">
              {{ getParameter('autoplay') }}
            </div>
          </div>
          <div class="game-information__text-stat__item">
            <div class="game-information__text-stat__item-icon">
              <img src="@/assets/images/info-icon.svg" alt="">
            </div>
            <div class="game-information__text-stat__item-label">
              Reels count
            </div>
            <div class="game-information__text-stat__item-value">
              {{ getParameter('reels_count') }}
            </div>
          </div>
        </div>
        <div class="game-information__text-inner">
          Cleocatra is an online slot game that was developed by Pragmatic Play. It contains 5 reels with 40 paylines and gives you the opportunity to enjoy profitable gambling having RTP of 95.5%. This video slot game will be an outstanding hit for everyone that is a fan of the Egyptian thematics.
          <br><br>
          Developer Pragmatic Play offers a diverse portfolio of games with a catchy and distinctive visual performance, immersive sound effects, engaging game mechanisms, and gameplay. You can play these video slots without worrying about reliability and fairness. All games are certified by an independent laboratory and were tested for compliance with industry standards. Many additional options indicate the technological excellence of Pragmatic Play software for personal computers and mobile devices running Android and iOS.
          <br><br>
          At BetFury, you can try out all slots for free or make bets in any of 40+ cryptocurrencies supported on the platform. Enjoy the excellent gameplay and mine BFG tokens to take advantage of daily multicurrency Staking payouts in BTC, USDT, TRX, BNB and ETH. With an improved Rank System, you can become a VIP Club member, get exclusive bonuses and claim Cashback up to 25% for all failed bets.
        </div>
      </div>
    </div>

    <CasinoGames :category="categories[0]" v-if="categories[0]"></CasinoGames>
    <GameProviders/>
    <BetsList />

  </div>
</template>

<style scoped lang="scss">
  .game-page {
    min-height: 60vh;
    .game-frame {
      width: 100%;
      position: relative;
      min-height: 50vh;
      margin-top: 8px;
      @media (max-width: 768px) {
        margin-bottom: 10px;
      }
      iframe {
        border-radius: 20px;
        overflow: hidden;
        height: 50vh;
        border: none;
        width: 100%;
        @media (max-width: 768px) {
          height: calc(100vh - 280px);
        }
      }
    }
    .game-head {
      display: flex;
      justify-content: space-between;
      .full-screen {
        display: flex;
        margin-right: 5px;
        border-radius: 14px;
        background: var(--300, #1D1B41);
        align-items: center;
        margin-left: auto;
        padding: 7px 12px;
        svg {
          width: 24px;
        }
      }
      .game__modes {
        border-radius: 14px;
        background: var(--300, #1D1B41);
        padding: 4px;
        display: flex;

        .game-mode {
          padding: 11px 10px;
          color: var(--800, #918FBB);
          font-family: "Geometria-Bold";
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          border-radius: 10px;
          line-height: normal;
          letter-spacing: -0.28px;
          display: flex;
          align-items: center;
          cursor: pointer;
          &.active {
            color: #02002A;
            background: var(--Primary, #FFE70C);
          }
        }
        .game-mode + .game-mode {
          margin-left: 8px;
        }
      }
    }
    .game__platform {
      &.fullscreen {
        padding: 20px;
        background: #0C0A33;
        z-index: 999999;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        @media (max-width: 992px) {
          padding: 5px;
        }
        .game-frame {
          iframe {
            height: calc(100vh - 100px) !important;
            width: 100% !important;
            @media (max-width: 992px) {
              height: calc(100vh - 230px);
            }
          }
        }
      }
    }
    .game-information {
      display: flex;
      padding-bottom: 24px;
      @media (max-width: 1200px) {
        display: block;
      }
      .game-information__text-categories {
        display: flex;
        margin-bottom: 8px;
        grid-gap: 8px;
        @media (max-width: 768px) {
          display: none;
        }
        .game-information__text-category {
          border-radius: 61px;
          background: var(--300, #1D1B41);
          padding: 8px 12px;
          display: flex;
          cursor: default;
          align-items: center;
          img {
            width: 24px;
            margin-right: 7px;
          }
          color: var(--700, #7F7DA6);
          font-family: "Geometria-Bold";
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          letter-spacing: -0.28px;
        }
      }

      .game-information__img {
        width: 186px;
        min-width: 186px;
        margin-right: 15px;
        .game-information__text-categories {
          display: none;
        }
        img {
          border-radius: 15px;
          width: 100%;
        }
        @media (max-width: 768px) {
          margin: 0 auto;
          display: flex;
          width: 100%;
          img {
            width: 186px;
          }
          .game-information__text-categories {
            display: flex;
            align-items: flex-start;
            flex-wrap: wrap;
            padding-left: 10px;
            margin-bottom: 0;
          }
        }
        @media (max-width: 575px) {
        }
      }
      .game-information__text {
        flex-grow: 1;
        padding-top: 27px;
        @media (max-width: 768px) {
          padding-top: 15px;
        }
        .game-information__text-name {
          font-family: Unbounded;
          font-size: 40px;
          font-style: normal;
          font-weight: 700;
          line-height: 50px; /* 125% */
          letter-spacing: -0.8px;
          @media (max-width: 768px) {
            font-size: 24px;
            line-height: 26px;
          }
        }
        .game-information__text-stats {
          margin-top: 15px;
          display: grid;
          grid-template-columns: repeat(7, 1fr);
          grid-gap: 6px;
          @media (max-width: 992px) {
            grid-template-columns: repeat(3, 1fr);
          }
          .game-information__text-stat__item {
            border-radius: 14px;
            background: var(--300, #1D1B41);
            padding: 16px;
            .game-information__text-stat__item-icon {
              width: 24px;
              img {
                width: 24px;
              }

              margin-bottom: 15px;
            }
            .game-information__text-stat__item-label {
              color: var(--700, #7F7DA6);
              font-family: "Geometria-Bold";
              font-size: 14px;
              margin-bottom: 2px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
              letter-spacing: -0.28px;
            }
            .game-information__text-stat__item-value {
              color: var(--900, #B9B8DD);
              font-size: 14px;
              text-transform: capitalize;
              font-family: "Geometria-Bold";
              font-style: normal;
              font-weight: 700;
              line-height: normal;
              letter-spacing: -0.28px;
            }
            @media (max-width: 992px) {
              &:last-child {
                grid-column: span 3;
              }
            }
          }
        }
        .game-information__text-inner {
          margin-top: 12px;
          font-family: "Geometria-Medium";
          color: var(--800, #918FBB);
          font-size: 15px;
          font-style: normal;
          font-weight: 500;
          line-height: 25px; /* 166.667% */
          letter-spacing: -0.3px;
        }
      }
    }
  }
</style>