<template>
  <div id="app" :class="{'center': !isInitialized}">
    <template v-if="isInitialized">
      <ScrollTop />

      <MainHeader />
      <MobileNav />
      <div class="wrapper" :class="{'d-flex': !isMobile}">
        <LeftSidebar />
        <div class="content" :class="{'full': $route.name === 'lootboxes.page', 'disabled': isOpenSidebar}">
          <router-view/>
          <MainFooter />
        </div>
        <MainChat />
      </div>

      <transition name="fade">
        <BaseModal v-if="isOpenAuth">
          <AuthModal/>
        </BaseModal>
      </transition>

      <template v-if="isAuth">
        <transition name="fade">
          <BaseModal v-if="isOpenGetFreeLootbox">
            <FreeLootboxModal/>
          </BaseModal>
        </transition>

        <transition name="fade">
          <BaseModal v-if="isOpenWelcomeFreeLootbox">
            <FreeWelcomeLootboxModal/>
          </BaseModal>
        </transition>

        <transition name="fade">
          <BaseModal v-if="isOpenChangePassword">
            <ChangePasswordModal/>
          </BaseModal>
        </transition>

        <transition name="fade">
          <BaseModal v-if="isOpenTwoFa">
            <TwoFaModal/>
          </BaseModal>
        </transition>

        <transition name="fade">
          <BaseModal v-if="isOpenSetAvatar">
            <SetAvatarModal />
          </BaseModal>
        </transition>

        <transition name="fade">
          <BaseModal v-if="sellNft.isOpenSellNft">
            <SellNftModal />
          </BaseModal>
        </transition>

        <transition name="fade">
          <ConfirmationModal />
        </transition>

        <transition name="fade">
          <BaseModal v-if="buyNftObject">
            <BuyNftModal />
          </BaseModal>
        </transition>

        <transition name="fade">
          <BaseModal v-if="isShowDepositModal">
            <DepositModal />
          </BaseModal>
        </transition>

        <transition name="fade">
          <BaseModal v-if="isOpenCancelDepositModal">
            <CancelDepositModal />
          </BaseModal>
        </transition>
      </template>

      <template v-if="!isAuth">
        <transition name="fade">
          <BaseModal v-if="isShowWeb3Register">
            <Web3Auth />
          </BaseModal>
        </transition>

        <transition name="fade">
          <BaseModal v-if="isShowRecoverPasswordModal">
            <recoverPasswordModal></recoverPasswordModal>
          </BaseModal>
        </transition>
      </template>

      <transition name="fade">
        <BaseModal v-if="!isOpenAuth && isOpenLoyalty">
          <LoyalProgramModal />
        </BaseModal>
      </transition>
      <transition name="fade">
        <BaseModal v-if="isShowSearch">
          <SearchModal />
        </BaseModal>
      </transition>
      <transition name="fade">
        <BaseModal v-if="isShowChatRules">
          <ChatRulesModal />
        </BaseModal>
      </transition>
      <transition name="fade">
        <BaseModal v-if="isShowSettings">
          <SettingsModal />
        </BaseModal>
      </transition>
    </template>
    <transition name="fade">
      <MainLoader v-if="!isInitialized" />
    </transition>

    <transition name="fade">
      <NotificationsWrapper />
    </transition>
  </div>
</template>
<script>
import MainHeader from "@/components/Parts/MainHeader.vue";
import MainFooter from "@/components/Parts/MainFooter.vue";
import MainChat from "@/components/Parts/Chat/MainChat.vue";
import LeftSidebar from "@/components/Sidebar/LeftSidebar.vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
import BaseModal from "@/components/Parts/BaseModal.vue";
import AuthModal from "@/components/Modals/Auth/AuthModal.vue";
import ChangePasswordModal from "@/components/Modals/Password/ChangePasswordModal.vue";
import TwoFaModal from "@/components/Modals/TwoFa/TwoFaModal.vue";
import SetAvatarModal from "@/components/Modals/Settings/SetAvatarModal.vue";
import SellNftModal from "@/components/Profile/Nft/Modals/SellNftModal.vue";
import NotificationsWrapper from "@/components/Notifications/NotificationsWrapper.vue";
import ConfirmationModal from "@/components/Modals/Confirmation/ConfirmationModal.vue";
import Web3Auth from "@/components/Modals/Auth/Web3Auth.vue";
import MobileNav from "@/components/Parts/MobileNav.vue";
import BuyNftModal from "@/components/Modals/Nft/BuyNftModal.vue";
import DepositModal from "@/components/Modals/Deposit/DepositModal.vue";
import CancelDepositModal from "@/components/Modals/Deposit/CancelDepositModal.vue";
import LoyalProgramModal from "@/components/Modals/Loyal/LoyalProgramModal.vue";
import SearchModal from "@/components/Modals/Search/SearchModal.vue";
import ChatRulesModal from "@/components/Modals/ChatRules/ChatRulesModal.vue";
import SettingsModal from "@/components/Modals/Settings/SettingsModal.vue";
import recoverPasswordModal from "@/components/Modals/RecoverEmail/RecoverPasswordModal.vue";
import ScrollTop from "@/components/ScrollTop.vue";
import MainLoader from "@/components/MainLoader.vue";
import FreeLootboxModal from "@/components/Modals/FreeLootbox/FreeLootboxModal.vue";
import FreeWelcomeLootboxModal from "@/components/Modals/FreeLootbox/FreeWelcomeLootboxModal.vue";
export default {
  data () {
    return {
      isInitialized: false,
    }
  },
  components: {
    FreeWelcomeLootboxModal,
    FreeLootboxModal,
    MainLoader,
    ScrollTop,
    SettingsModal,
    ChatRulesModal,
    SearchModal,
    LoyalProgramModal,
    CancelDepositModal,
    DepositModal,
    BuyNftModal,
    MobileNav,
    Web3Auth,
    ConfirmationModal,
    NotificationsWrapper,
    recoverPasswordModal,
    SellNftModal,
    SetAvatarModal,
    TwoFaModal,
    ChangePasswordModal,
    AuthModal, BaseModal,
    LeftSidebar,
    MainChat,
    MainFooter,
    MainHeader
  },
  computed: {
    isMobile() {
      return window.innerWidth < 992;
    },
    ...mapGetters({
      isShowSettings: 'config/isShowSettings',
      isShowChatRules: 'config/isShowChatRules',
      isShowWeb3Register: 'config/isShowWeb3Register',
      isAuth: 'user/isAuth',
      sellNft: 'config/sellNft',
      isShowRecoverPasswordModal: "config/isShowRecoverPasswordModal",
      isOpenTwoFa: 'config/isOpenTwoFa',
      isOpenChangePassword: 'config/isOpenChangePassword',
      isOpenGetFreeLootbox: 'config/isOpenGetFreeLootbox',
      isOpenAuth: 'config/isOpenAuth',
      isOpenLoyalty: 'config/isOpenLoyalty',
      isOpenSetAvatar: 'config/isOpenSetAvatar',
      isOpenChat: 'config/isOpenChat',
      isOpenSidebar: 'config/isOpenSidebar',
      buyNftObject: 'config/buyNftObject',
      isOpenCancelDepositModal: 'config/isOpenCancelDepositModal',
      isShowDepositModal: 'config/isShowDepositModal',
      isShowSearch: 'config/isShowSearch',
      fullscreenGame: 'config/fullscreenGame',
      isOpenWelcomeFreeLootbox: 'config/isOpenWelcomeFreeLootbox',
    }),
  },
  watch: {
    '$route': function () {
      this.closeAllModals();

      window.scrollTo(0, 0);
    }
  },
  methods: {
    ...mapMutations({
      closeAllModals: 'config/closeAllModals',
      setIsOpenLoyalty: 'config/setIsOpenLoyalty',
      setIsShowSearch: 'config/setIsShowSearch',
      setIsOpenSidebar: 'config/setIsOpenSidebar'
    }),
    ...mapActions({
      getCategories: 'games/getCategories',
      fetchSettings: 'settings/fetchSettings',
      me: 'user/me'
    }),
    disabledScroll(open) {
      const body = document.querySelector('body');

      if (open) {
        // Сохраняем текущее положение прокрутки
        const scrollY = window.scrollY;
        // Добавляем фиксированный стиль к body
        body.style.top = `-${scrollY}px`;
        body.style.position = 'fixed';
        body.style.left = '0';
        body.style.right = '0';
        body.style.overflow = 'hidden';
      } else {
        // Получаем текущее положение прокрутки, которое мы сохранили ранее
        const scrollY = Math.abs(parseInt(body.style.top || '0', 10));
        // Восстанавливаем положение прокрутки
        body.style.position = '';
        body.style.top = '';
        body.style.left = '';
        body.style.right = '';
        body.style.overflow = '';
        // Восстанавливаем прокрутку страницы
        window.scrollTo(0, scrollY);
      }
    },
  },
  mounted () {
    this.getCategories()

    this.$root.$on('openModal', () => {
      this.disabledScroll(true);
    })
    this.$root.$on('closeModal', () => {
      this.disabledScroll(false);
    })
  },
  async created () {
    try {
      await this.fetchSettings();
      if (localStorage.getItem('token')) {
        await this.me()
      }
    } catch (e) {
      console.log(e)
    } finally {
      setTimeout(() => {
        this.isInitialized = true;

        if (this.$route.query.recovered_success) {
          this.$root.$emit('success', {
            title: "Success recover password",
            text: "A new password has been emailed to you"
          })
          this.$router.replace({'query': null});
        } else if (this.$route.query.changed_email) {
          this.$root.$emit('success', {
            title: "Success changed email",
            text: "You have successfully confirmed your new email"
          })
          this.$router.replace({'query': null});
        } else if (this.$route.query.changed_password) {
          this.$root.$emit('success', {
            title: "Success changed password",
            text: "You have successfully changed your password"
          })
          this.$router.replace({'query': null});
        } else if (this.$route.query.verification_email) {
          this.$root.$emit('success', {
            title: "Success verification email",
            text: "You have successfully verification your email"
          })
          this.$router.replace({'query': null});
        }
      }, 700)
    }
  }
}
</script>
<style lang="scss">
@import './style/main.scss';
#app.stop {
  height: calc(100vh - env(safe-area-inset-bottom));
  overflow: hidden;
  overscroll-behavior: none;
}
#app {
  position: relative;
  &.center {
    display: flex;
    align-items: center;
    height: 100vh;
    width: 100%;
    justify-content: center;
  }
}
.wrapper {
  &.d-flex {
    display: flex;
  }
  .content {
    padding: 15px 70px;
    flex-grow: 1;
    overflow-x: hidden;
    max-width: 1910px;
    margin: 0 auto;
    &.full {
      padding-right: 15px;
      padding-left: 15px;
      @media (max-width: 992px) {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
    @media (max-width: 1600px) {
      padding-right: 15px;
      padding-left: 30px;
    }
    @media (max-width: 1200px) {
      padding-left: 15px;
    }
    @media (max-width: 992px) {
      padding-right: 10px;
      padding-left: 10px;
    }

    &.disabled {
      position: relative;
      &::after {
        position: absolute;
        content: "";
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
