<script>
import UiCheckbox from "@/components/Ui/UiCheckbox.vue";
import AccentBtn from "@/components/Ui/Buttons/AccentBtn.vue";
import {mapGetters} from "vuex";
import { exchangeBalance } from "@/services/user";
import extractErrors from "@/helpers/extractErrors";

export default {
  data () {
    return {
      isLoading: false,
      coins: 1,
      agree: false,
      balance: null,
    }
  },
  name: "ExchangeForm",
  computed: {
    ...mapGetters({
      user: 'user/user',
      coin_exchange_rate: 'settings/coin_exchange_rate'
    }),
    hasBalance() {
      return parseFloat(this.user?.balance?.balance) > this.balance && this.agree;
    }
  },
  watch: {
    balance() {
      if (Number(this.balance) > this.user.balance.balance) {
        this.balance = this.user.balance.balance;
      }
    }
  },
  methods: {
    setMax() {
      console.log(this.user)
      this.balance = parseFloat(this.user.balance.balance);
    },
    exchange() {
      this.isLoading = true;
      if (!this.coins) {
        return this.$root.$emit('error', {
          title: "Error",
          text: this.$t('d_enter_amount')
        })
      }

      exchangeBalance({
        amount: this.coins
      }).then(() => {
        this.coins = null;
        this.balance = null;

        return this.$root.$emit('success', {
          title: "Success",
          text: this.$t('d_success_exch')
        })
      }).catch((errors) => {
        extractErrors(errors).forEach(item => {
          this.$root.$emit('error', {
            title: "Error",
            text: item,
          })
        })
      }).finally(() => {
        this.isLoading = false;
      })
    },
    setBalance () {
      this.coins = parseFloat(this.balance / this.coin_exchange_rate).toFixed(2);
    },
    setCoins () {
      this.balance = parseFloat(this.coins * this.coin_exchange_rate).toFixed(2);
    }
  },
  mounted() {
    this.setCoins()
  },
  components: {
    AccentBtn,
    UiCheckbox
  }
}
</script>

<template>
  <div class="exchange-form">
    <h3>{{ $t('buy_sneg') }}</h3>
    <span>{{ $t('d_buy_sneg_text') }}</span>

    <form action="">
      <div class="input">
        <div class="input-currency">
          <div class="input-currency__img">
            <img src="@/assets/images/dollar-icon.svg" alt="">
          </div>
          <div class="input-currency__name">
            USD
          </div>
        </div>
        <input v-model="balance" type="number" @input="setBalance">
        <div class="label">
          <span class="max default-hover" @click="setMax">{{ $t('d_max') }}</span>
          {{ $t('d_you_sent') }}
        </div>
      </div>
      <div class="input">
        <div class="input-currency">
          <div class="input-currency__img">
            <img src="@/assets/images/sneg-coin.svg" alt="">
          </div>
          <div class="input-currency__name">
            SNEG
          </div>
        </div>
        <input @input="setCoins" v-model="coins" type="number">
        <div class="label">
          {{ $t('d_you_get') }}
        </div>
      </div>
      <div class="rate">
        <img src="@/assets/images/sneg-coin.svg" alt=""> 1 SNEG = <img src="@/assets/images/dollar-icon.svg" alt=""> {{ parseFloat(coin_exchange_rate).toFixed(2) }} USD
      </div>

      <div class="bottom">
        <div class="agree">
          <UiCheckbox @change="agree = $event.target.checked">
            <div class="span" v-html="$t('d_agree')"></div>
          </UiCheckbox>
        </div>
        <AccentBtn :is-loading="isLoading" @click="exchange" :disabled="!hasBalance || !balance" title="Confirm" />
      </div>

    </form>
    <div class="shadow"></div>
  </div>
</template>

<style scoped lang="scss">
.exchange-form {
  padding: 14px 0;
  padding-bottom: 0;
  .shadow {
    position: absolute;
    bottom: 0;
    left: calc(50% - 50px);
    width: 1px;
    height: 1px;
    border-radius: 100%;
    background: rgba(171, 107, 255, 0.2);
    box-shadow: 0px 0px 200px 120px rgba(171, 107, 255, 0.5);
  }
  h3 {
    font-family: Unbounded;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.36px;
  }
  span {
    margin-top: 7px;
    color: var(--700, #7F7DA6);
    font-family: Geometria;
    font-family: "Geometria-Medium";
    display: block;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.28px;
  }
  form {
    position: relative;
    z-index: 2;
    display: block;
    margin-top: 14px;
    .input {
      border-radius: 12px;
      background: var(--50-Dark, rgba(0, 3, 42, 0.50));
      padding: 4px;
      display: flex;
      overflow: hidden;
      padding-right: 16px;

      .input-currency {
        display: flex;
        align-items: center;
        width: 100px;
        border-radius: 9px;
        background: var(--10-purple, rgba(182, 127, 255, 0.10));
        padding: 10px;
        min-width: 100px;
        .input-currency__img {
          width: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 24px;
          margin-right: 8px;
          img {
            width: 24px;
            height: 24px;
          }
        }
        .input-currency__name {
          font-family: "Geometria-Bold";
          font-size: 14px;
          padding-top: 1px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          letter-spacing: -0.28px;
        }
      }
      input {
        overflow: hidden;
        padding: 0 16px;
        background: transparent;
        outline: none;
        padding-top: 3px;
        border: none;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 18px;
        letter-spacing: -0.32px;
      }
      .label {
        color: var(--700, #7F7DA6);
        font-family: "Geometria-Medium";
        font-size: 14px;
        white-space: nowrap;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        display: flex;
        align-items: center;
        letter-spacing: -0.28px;


        .max {
          border-radius: 5px;
          background: linear-gradient(180deg, #9D42F8 -4.55%, #7F00FF 100%);
          box-shadow: 0 -1px 1px 1px #BC79FF;
          padding: 2px 4px;
          font-size: 14px;
          margin: 0;
          margin-right: 5px;
          font-weight: normal;
          font-family: "Geometria-Medium";
          cursor: pointer;
          color: #fff;
        }
      }
    }
    .input + .input {
      margin-top: 4px;
    }
    .rate {
      margin-top: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 20px;
        height: 20px;
        margin: 0 8px;
        margin-bottom: 2px;
      }

      line-height: 14px;
      font-family: "Geometria-Bold";
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.28px;
    }
    .bottom {
      margin-top: 90px;
    }
    .agree {
      border-radius: 12px;
      background: var(--50-Dark, rgba(0, 3, 42, 0.50));
      padding: 12px 16px;
      margin-bottom: 8px;
      ::v-deep {
        .label, .span {
          color: var(--700, #7F7DA6);
          font-family: "Geometria-Medium";
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.28px;
        }
        a {
          color: #23A3FF;
          font-family: "Geometria-Medium";
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.28px;
        }
      }

    }
  }
}
</style>