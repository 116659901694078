<script>
import {mapMutations} from "vuex";

export default {
  props: {
    block: {
      type: Object,
      required: true
    }
  },
  methods: {
    ...mapMutations({
      setIsOpenLoyal: 'config/setIsOpenLoyalty'
    })
  },
  name: "BlockCategory"
}
</script>

<template>
  <div class="block-category">
      <div class="block-category__image">
        <img :src="block.image" :class="{'is-small': block.isSmall}" alt="">
      </div>
    <div class="block-category__name">
      <img :src="block.categoryIcon" alt="">
      <span>{{ $t('categories.' + block.name) }}</span>
    </div>
    <div class="block-category__desc">
      <p>
        {{ $t('try_out_text') }}
      </p>
    </div>

    <div class="block-category__actions">
      <button class="try-it " @click="block.isLoyal ? setIsOpenLoyal(true) : $router.push(block.link)" v-if="block.available">{{ $t('try_it_out') }}</button>
      <button class="soon" v-else>{{ $t('coming_soon') }}...</button>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .block-category {
    height: 289px;
    border-radius: 15px;
    background-color: #131139;
    background-image: url(@/assets/images/main/block-bg.png);
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: center top;
    @media (max-width: 768px) {
      height: auto;
      padding-bottom: 14px;
    }
    .block-category__image {
      padding-top: 8px;
      width: 100%;
      display: flex;
      justify-content: center;
      height: 148px;
      align-items: center;
      @media (max-width: 768px) {
        max-height: 75px;
      }
      img {
        filter: drop-shadow(0px 20px 28px black);
        max-height: 120px;

        @media (max-width: 768px) {
          max-height: 60px;
          &.is-small {
            max-height: 45px;
          }
        }
      }
    }
    .block-category__name {
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        font-family: "Unbounded";
        font-size: 16px;
        font-weight: 700;
        letter-spacing: -0.32px;
        display: block;
        @media (max-width: 768px) {
          font-size: 12px;
        }

      }
      img {
        margin-right: 13px;
        width: 18px;
        @media (max-width: 768px) {
          width: 12px;
          margin-right: 8px;
        }
      }

    }
    .block-category__desc {
      margin-top: 14px;
      @media (max-width: 768px) {
        margin-top: 6px;
        max-width: 95%;
        margin-left: 2.5%;
      }
      p {
        color: #918FBB;
        font-family: "Geometria-Bold";
        font-size: 14px;
        text-align: center;
        font-weight: 700;
        display: block;
        margin-bottom: 0;
        letter-spacing: -0.28px;
        @media (max-width: 768px) {
          font-size: 12px;
        }
      }
    }
    .block-category__actions {
      margin-top: 18px;
      display: flex;
      justify-content: center;
      width: 100%;
      @media (max-width: 768px) {
        margin-top: 8px;
      }
      button {
        padding: 12px 24px;
        border-radius: 11px;
        font-size: 14px;
        font-family: "Geometria-Bold";
        letter-spacing: -0.28px;
        transition: 0.2s;

        &.try-it {
          cursor: pointer;
          border-radius: 11px;
          box-shadow: 0px -1px 1px 1px rgba(113, 47, 180, 0.42);
          transition: 0.4s !important;
          background: linear-gradient(180deg, #28105F -4.55%, #1B0C4B 100%);
          &:hover {
            background: var(--Primary, linear-gradient(180deg, #9D42F8 -4.55%, #7F00FF 100%));
            box-shadow: 0px -1px 1px 1px rgba(113, 47, 180, 0.42);
          }
        }
        &.soon {
          background: #2B2A4E;
          cursor: default;
          color: #B9B8DD;
          user-select: none;
        }
      }
    }
  }
</style>