export default {
    list: state => state.list,
    section: state => state.section,
    search: state => state.search,
    categories: state => state.categories,
    per_page: state => state.per_page,
    last_page: state => state.last_page,
    page: state => state.page,
    providers: state => state.providers,
    isLoading: state => state.isLoading,
}