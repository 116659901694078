<script>
import SliderHead from "@/components/Ui/SliderHead.vue";
import ViewAll from "@/components/Parts/ViewAll.vue";
import SlideGameItem from "@/components/Main/SlideGameItem.vue";
import SlideMoreGameItem from "@/components/Main/SlideMoreGameItem.vue";
import {getList} from "@/services/games";
import extractErrors from "@/helpers/extractErrors";
import { Hooper, Slide } from 'hooper';
import 'hooper/dist/hooper.css';
export default {
  props: {
    category: {
      type: Object
    }
  },
  data () {
    return {
      games: [],
      baseSettings: {
        autoPlay: false,
        playSpeed: 2000,
        transition: 300,
        rtl: false,
        hoverPause: false,
        centerMode: false,
        touchDrag: true,
        mouseDrag: true,
        wheelControl: false,
        keysControl: false,
        itemsToSlide: 1,
        infinite: false,
        trimWhiteSpace: true,
        infiniteScroll: false,
      }
    }
  },
  computed: {
    settings () {
      const settings = this.baseSettings;
      settings.itemsToShow = this.itemsToShow;

      return settings;
    },
    itemsToShow()
    {
      const width = window.innerWidth;

      if (width > 2100) {
        return 9;
      } else if (width > 1950) {
        return 8;
      } else if (width > 1800) {
        return 7;
      } else if (width > 1600) {
        return 6;
      } else if (width > 1400) {
        return 7;
      } else if (width > 992) {
        return 5;
      } else if (width > 768) {
        return 5;
      } else if (width > 650) {
        return 4;
      } else if (width > 500) {
        return 3.5;
      }
      else if (width > 200) {
        return 2.5;
      }

      return 9;
    }
  },
  methods: {
    prev () {
      const slider = this.$refs.slider;

      slider.slidePrev();
    },
    next () {
      const slider = this.$refs.slider;
      slider.slideNext();
    },
    async fetchGames () {
      const data = {
        per_page: 20,
        page: 1
      };

      if (this.category) {
        data.category_id = this.category.id;
      }

        await getList(data).then(response => {
          this.games = response.data.data
        }).catch(err => {
          extractErrors(err).forEach(item => {
            this.$root.$emit('error', {
              title: "Error",
              text: item,
            })
          })
        });
    }
  },
  mounted () {
    this.fetchGames();
  },
  name: "CasinoGames",
  components: {
    SlideMoreGameItem,
    SlideGameItem,
    ViewAll,
    SliderHead,
    Hooper,
    Slide
  }
}
</script>

<template>
  <div class="casino-games" v-if="games.length && games.length > 0">
    <SliderHead :showControls="games.length && games.length === 20" @prev="prev" @next="next">
      <template v-slot:image>
        <img v-if="!category" src="@/assets/images/main/gray-casino-icon.svg" alt="">
        <img v-else :src="category.icon" alt="">
      </template>
      <template v-if="!category" v-slot:name>{{ $t('casino') }}</template>
      <template v-else v-slot:name>{{ category.name }}</template>
      <template v-slot:all>
        <ViewAll :link="!category ? '/games' : `/games?category=${category.id}`"/>
      </template>
    </SliderHead>

    <div class="games">
      <hooper ref="slider" :settings="settings">
        <slide v-for="game in games" :key="game.id">
          <SlideGameItem :game="game" />
        </slide>
        <slide v-if="games.length && games.length === 20">
          <SlideMoreGameItem/>
        </slide>
      </hooper>
    </div>
  </div>
</template>

<style scoped lang="scss">
.casino-games {
  width: 100%;

}
  .games {
    margin-top: 12px;
    margin-left: -8px;
    margin-right: -8px;
    margin-bottom: 14px;
    @media (max-width: 768px) {
      &::after {
        content: "";
        width: 93px;
        height: 100%;
        position: absolute;
        right: -10px;
        display: block;
        background: linear-gradient(270deg, #02002A 0%, rgba(2, 0, 42, 0.00) 100%);
        top: 0;
      }
      margin-right: -4px;
      margin-left: -4px;
      position: relative;
    }
    ::v-deep {
      .hooper {
        height: unset;
      }
      .hooper-list {
        padding-top: 2px;
        height: unset;
        @media (max-width: 768px) {
          //.hooper-track {
          //  height: 174px;
          //}
        }
        .hooper-slide {
          padding-right: 8px;
          padding-left: 8px;
          @media (max-width: 768px) {
            padding-right: 4px;
            padding-left: 4px;
          }
        }
      }
    }
  }
</style>