<script>
import {mapActions} from "vuex";

export default {
  name: "UnauthorizedInput",
  methods: {
    ...mapActions({
      openLogin: 'config/openLogin',
    })
  }
}
</script>

<template>
<div class="unauthorized-input">
  <span>{{ $t('auth_for_comment') }}</span>
  <button class="default-hover" @click="openLogin">{{ $t('go_authorize') }}</button>
</div>
</template>

<style scoped lang="scss">
.unauthorized-input {
  border-radius: 10px;
  background: var(--300, #1D1B41);
  width: 100%;
  padding: 6px;
  display: flex;
  align-items: center;
  padding-left: 16px;
  justify-content: space-between;
  span {
    margin-right: 22px;
    white-space: nowrap;
    color: var(--800, #918FBB);
    font-family: "Geometria-Bold";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.28px;
  }
  button {
    padding: 10px 14px;
    border-radius: 12px;
    box-shadow: 0px -1px 1px 1px #BC79FF;
    background: var(--Primary, linear-gradient(180deg, #9D42F8 -4.55%, #7F00FF 100%));
    font-family: "Geometria-Bold";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.28px;
  }
}
</style>