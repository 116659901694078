<script>
export default {
  props: {
    message: {
      type: Object,
      required: true
    }
  },
  name: "ReplyMessage"
}
</script>

<template>
  <div class="message-body__reply">
    <div class="message-body__reply-user">
      <span>{{ message.user.name }}</span>
    </div>
    <div class="message-body__reply-text">
      <span>{{ message.message }}</span>
    </div>
  </div>
</template>

<style scoped lang="scss">
.message-body__reply {
  border-radius: 5px;
  background: #1D1B41;
  margin: 4px 0;
  padding: 4px 8px;
  .message-body__reply-user {
    span {
      font-family: "Geometria-Medium", sans-serif;
      font-size: 11px;
      font-weight: 500;
      letter-spacing: -0.22px;
    }
  }
  .message-body__reply-text {
    span {
      color: #918FBB;
      font-size: 12px;
      font-weight: 500;
      font-family: "Geometria-Medium", sans-serif;
      letter-spacing: -0.24px;
    }
  }

  position: relative;
  &::after {
    content: "";
    display: block;
    position: absolute;
    height: 18px;
    width: 2px;
    background: #AB6BFF;
    box-shadow: 0px 0px 8px 2px #AB6BFF;
    left: -1px;
    top: calc(50% - 9px);
  }
}
</style>