<script>
// import SignUp from "@/components/Auth/Buttons/SignUp.vue";
// import SocialAuthList from "@/components/Auth/Buttons/SocialAuthList.vue";
import BonusPrizes from "@/components/Parts/BonusPrizes.vue";
import {mapGetters} from "vuex";

export default {
  name: "WelcomeBanner",
  computed: {
    ...mapGetters({
      settings: 'settings/settings',
      isAuth: 'user/isAuth'
    })
  },
  components: {BonusPrizes}
}
</script>

<template>
  <div class="welcome-banner" :style="{'background-image': `url('${settings?.main_banner}')`}">
    <div class="information">
      <h6>{{ $t('banner.heading_1') }}</h6>
      <h1 v-html="$t('banner.heading_big')"></h1>

      <BonusPrizes/>
    </div>
<!--    <div class="go-register" v-if="!isAuth">-->
<!--      <SignUp :text="$t('banner.go_to_signup')" />-->
<!--      <div class="go-register_separator"></div>-->
<!--      <div class="go-register_socials">-->
<!--        <span>{{ $t('banner.quick_auth') }}</span>-->
<!--        <SocialAuthList/>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</template>

<style scoped lang="scss">
  .welcome-banner {
    padding: 98px 0;
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: center right;
    @media (max-width: 768px) {
      padding: 22px 0;
      padding-bottom: 224px;
      background-position: bottom right;
      background-size: auto 282px;
      margin-right: -10px;
      margin-left: -10px;
      padding-right: 10px;
      padding-left: 20px;
    }
    @media (max-width: 575px) {
      background-size: auto 242px;
      background-position: bottom right -60px;
      padding-bottom: 210px;
    }
    .information {
      h6 {
        color: #7F7DA6;
        font-size: 14px;
        font-family: "Geometria-Medium";
        font-weight: 500;
        display: block;
        margin-bottom: 12px;
        letter-spacing: -0.28px;
        @media (max-width: 768px) {
          margin-bottom: 8px;

        }
      }
      h1 {
        font-family: "Unbounded";
        font-size: 46px;
        font-weight: 700;
        line-height: 50px; /* 108.696% */
        letter-spacing: -0.92px;
        margin-bottom: 12px;
        display: block;
        @media (max-width: 768px) {
          font-size: 24px;
          line-height: 26px;
          margin-bottom: 8px;
          br {
            display: none;
          }
        }
      }
    }
    .go-register {
      margin-top: 52px;
      display: flex;
      .go-register_separator {
        width: 1px;
        background: #1D1B41;
        margin: 0 15px;
      }
      .go-register_socials {
        span {
          margin-bottom: 3px;
          display: block;
          color: #7F7DA6;
          font-family: "Geometria-Bold";
          font-size: 12px;
          letter-spacing: 0.6px;
        }
      }
    }
  }
</style>