<script>
export default {
  name: "SlideMoreGameItem"
}
</script>

<template>
  <div class="game more">
    <div class="more">
      <div class="icon">
        <img src="@/assets/images/main/more-games-icon.svg" alt="">
      </div>
      <div class="title">999+</div>
      <div class="sub-title">{{ $t('more_games') }}</div>
      <div @click="$router.push('/games')" class="go-to default-hover">
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
          <path d="M10.2784 3.21307C9.98335 2.92236 9.50849 2.92587 9.21777 3.22093C8.92705 3.51598 8.93057 3.99084 9.22563 4.28156L13.2534 8.25011H3C2.58579 8.25011 2.25 8.58589 2.25 9.00011C2.25 9.41432 2.58579 9.75011 3 9.75011H13.2484L9.22563 13.7138C8.93057 14.0045 8.92705 14.4793 9.21777 14.7744C9.50849 15.0695 9.98335 15.073 10.2784 14.7823L15.4715 9.66547C15.8442 9.29826 15.8442 8.69707 15.4715 8.32986L10.2784 3.21307Z" fill="white"/>
        </svg>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.game {
  height: 250px;
  border-radius: 15px;
  overflow: hidden;
  background: linear-gradient(0deg, #763FB0 0%, rgba(118, 63, 176, 0.00) 76.51%), #2B2A4E;
  & > img {
    width: 100%;
    user-select: none;
    height: 100%;
  }

  &.more {
    box-shadow: 0 -1px 2px 1px #BC79FF;
  }

  &.more {
    @media (max-width: 992px) {
      height: auto;
      position: relative;
      padding-bottom: 140%;
    }
  }

  .more {
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media (max-width: 992px) {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
    height: 100%;
    .icon {
      margin-bottom: 13px;
      width: 100%;
      display: flex;
      justify-content: center;
    }
    .title {
      font-family: "Unbounded";
      font-size: 32px;
      text-align: center;
      width: 100%;
      font-style: normal;
      font-weight: 700;
      line-height: 34px; /* 106.25% */
      letter-spacing: -0.64px;
      margin-bottom: 6px;
    }
    .sub-title {
      width: 100%;
      text-align: center;
      color: #918FBB;
      font-family: "Geometria-Bold";
      font-size: 14px;
      letter-spacing: -0.28px;
    }
    .go-to {
      border: 1px solid #BC79FF;
      background: linear-gradient(180deg, #9D42F8 -4.55%, #7F00FF 100%);
      box-shadow: 0px 16px 22.7px 0px rgba(0, 0, 0, 0.25);
      width: 42px;
      height: 42px;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      margin-top: 28px;
    }
  }
}

</style>