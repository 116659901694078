<script>
export default {
  props: ['errors'],
  data () {
      return {
        filteredErrors: []
      }
  },
  methods: {
    extractErrors(errors) {

      if (Array.isArray(errors)) {
        const errs = [];
        for (const item in errors) {
          if (typeof errors[item] === 'string') {
            errs.push(errors[item])
          }
        }

        return errs;
      }

      const errorMessages = [];

      function extractErrorsRecursive(obj) {
        for (const key in obj) {
          if (typeof obj[key] === 'string') {
            errorMessages.push(obj[key]);
          } else if (typeof obj[key] === 'object') {
            extractErrorsRecursive(obj[key]);
          }
        }
      }

      extractErrorsRecursive(errors);

      return errorMessages;
    },
    clearErrors () {
      this.filteredErrors = []
    }
  },
  watch: {
    errors () {
      this.filteredErrors = this.extractErrors(this.errors)
    }
  },
  name: "UiErrors"
}
</script>

<template>
  <div class="errors" v-if="filteredErrors.length">
    <div class="error" v-for="(error, e) in filteredErrors" :key="e">
      {{ error }}
    </div>
    <div class="close default-hover" @click="clearErrors(), $emit('clear')">
      <svg data-v-29a248d9="" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none"><path data-v-29a248d9="" d="M17 7L7 17M7 7L17 17" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .errors {
    background: indianred;
    border-radius: 5px;
    padding: 10px 15px;
    margin: 15px 0;
    position: relative;
    padding-right: 30px;
    .error {
      line-height: 14px;
      font-family: "Geometria-Bold";
      font-weight: 700;
    }
    .close {
      position: absolute;
      width: 20px;
      height: 20px;
      top: calc(50% - 10px);
      right: 15px;
    }
  }
</style>