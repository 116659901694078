<script>
import BaseModal from "@/components/Parts/BaseModal.vue";
import AccentBtn from "@/components/Ui/Buttons/AccentBtn.vue";
import UiClose from "@/components/Ui/UiClose.vue";
import LinkedButton from "@/components/Ui/Buttons/LinkedButton.vue";
import {mapGetters, mapMutations} from "vuex";

export default {
  data () {
    return {
      isLoading: false
    }
  },
  computed: {
    ...mapGetters({
      confirmationModal: 'config/confirmationModal',
    })
  },
  methods: {
    ...mapMutations({
      setConfirmationModal: 'config/setConfirmationModal'
    }),
    close() {
      this.setConfirmationModal(null)
    },
    async confirm () {
      this.isLoading = true;
      try {
        await this.confirmationModal.callback();
      } catch (e) {
        console.error(e);
      } finally {
        this.isLoading = false
        this.setConfirmationModal(null)
      }
    }
  },
  name: "ConfirmationModal",
  components: {LinkedButton, UiClose, AccentBtn, BaseModal}
}
</script>

<template>
  <BaseModal v-if="confirmationModal">
    <div class="confirmation">
      <UiClose @click="setConfirmationModal(null)" />
      <h2>{{ $t('you_are_sure') }}</h2>
      <p>{{ confirmationModal.text }}</p>

      <div class="buttons">
        <LinkedButton :disabled="isLoading" @click="setConfirmationModal(null)" :title="$t('cancel_action')" />
        <AccentBtn @click="confirm" :is-loading="isLoading" :title="$t('confirm_action')" />
      </div>
    </div>
  </BaseModal>

</template>

<style scoped lang="scss">
  .confirmation {
    border-radius: 20px;
    background: #131139;
    position: relative;
    box-shadow: 0px 105px 153.7px 0px rgba(1, 0, 25, 0.55);
    padding: 20px;
    h2 {
      font-size: 24px;
      font-family: "Geometria-Bold";
      margin-bottom: 15px;
    }
    p {
      font-size: 14px;
      font-family: "Geometria-Medium";
      margin-bottom: 20px;
    }
    .buttons {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 12px;
    }
  }
</style>