<script>
export default {
  name: "TransactionItemHead"
}
</script>

<template>
  <div class="item-head">
    <div class="type">
      {{ $t('t_type') }}
    </div>
    <div class="time">
      {{ $t('t_time') }}
    </div>
    <div class="wager">
      {{ $t('t_amount') }}
    </div>
    <div class="status">
      {{ $t('t_status') }}
    </div>
    <div class="transaction">
      TXiD
    </div>
  </div>
</template>

<style scoped lang="scss">
.item-head {
  display: flex;
  align-items: center;
  padding: 8px;
  & > div {
    color: #3E3C5F;
    font-family: Geometria;
    font-family: "Geometria-Bold";
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0.6px;
  }
  .type {
    width: 200px;
    min-width: 200px;
    @media (max-width: 768px) {
      width: 50%;
    }
  }
  .game {

  }
  .time {
    width: 150px;
    min-width: 150px;
    @media (max-width: 768px) {
      display: none;
    }
  }
  .wager {
    @media (max-width: 768px) {
      display: none;
    }
    width: 130px;
    min-width: 130px;
  }
  .status {
    width: 150px;
    min-width: 150px;
    @media (max-width: 768px) {
      width: 50%;
      justify-content: flex-end;
      display: flex;
    }
  }
  .transaction {
    flex-grow: 1;
    justify-content: flex-end;
    display: flex;
    @media (max-width: 768px) {
      display: none;
    }
  }
}
</style>