<script>
export default {
  props: {
    currentPage: {
      type: Number,
      default: 1
    },
    totalPages: {
      type: Number,
      default: 1
    }
  },
  name: "MainPagination"
}
</script>

<template>
  <div class="pagination">
    <div class="prev dir default-hover" @click="$emit('setPage', currentPage - 1)" :class="{'disabled': currentPage === 1}">
      <div class="icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
          <path d="M11.25 4.5L6.75 9L11.25 13.5" stroke="#7F7DA6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
      <span>{{ $t('prev') }}</span>
    </div>

    <div class="pages">
      <div class="page" @click="$emit('setPage', item)" v-for="item in totalPages" :class="{'active': currentPage === item}" :key="item">{{ item }}</div>
    </div>

    <div class="next dir default-hover" @click="$emit('setPage', currentPage + 1)" :class="{'disabled': totalPages === currentPage}">

      <span>{{ $t('next') }}</span>
      <div class="icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
          <path d="M6.75 13.5L11.25 9L6.75 4.5" stroke="#7F7DA6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .pagination {
    display: flex;
    justify-content: space-between;
    .dir {
      border-radius: 9px;
      background: #1D1B41;
      height: 38px;
      padding: 0 8px;
      display: flex;
      transition: 0.2s;
      align-items: center;
      &.disabled {
        cursor: not-allowed !important;
        user-select: none !important;
        &:hover {
          opacity: 1 !important;
          background: #1D1B41;
          span {
            color: #7F7DA6 !important;
          }
        }
      }
      .icon {
        display: flex;
        align-items: center;
      }
      &.prev {
        .icon {
          margin-right: 8px;
        }
      }
      &.next {
        .icon {
          margin-left: 8px;
        }
      }
      span {
        color: #7F7DA6;
        display: flex;
        align-items: center;
        transition: 0.2s;
        font-size: 14px;
        font-weight: 700;
        font-family: "Geometria-Bold";
        letter-spacing: -0.28px;
      }
      &:hover {
        cursor: pointer;
        background: #F7FC00;
        span {
          color: #000;
        }
      }
    }
    .pages {
      display: flex;
      .page {
        border-radius: 9px;
        background: #1D1B41;
        height: 38px;
        width: 40px;
        min-width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #7F7DA6;
        font-size: 14px;
        font-family: "Geometria-Bold";
        font-weight: 700;
        transition: 0.2s;
        letter-spacing: -0.28px;
        &.active, &:hover {
          color: #000;
          cursor: pointer;
          background: #F7FC00;
        }
      }
      .page + .page {
        margin-left: 4px;
      }
    }
  }
</style>