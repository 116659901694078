<script>
import BackButton from "@/components/BackButton.vue";
import TextWrapper from "@/components/TextWrapper.vue";

export default {
  name: "ResponsibleGaming",
  components: {TextWrapper, BackButton}
}
</script>

<template>
  <div class="terms-and-conditions text-page">
    <BackButton :callback="() => $router.push('/')" />
    <TextWrapper>
      <h1>Responsible Gaming</h1>
      <p>
        Objective of the AML Policy: We seek to offer the highest security to all of our users and customers on rollbit.com. For that, a five-step account verification is done in order to ensure the identity of our customers. The reason behind this is to prove that the details of the person registered are correct and the deposit methods used are not stolen or being used by someone else, which is to create the general framework for the fight against money laundering. We also take into account that depending on the nationality and origin, the payment method and withdrawal method, different safety measurements must be taken. rollbit.com also puts reasonable measures in place to control and limit ML risk, including dedicating the appropriate means.
      </p>
      <h6>Definition of money laundering:</h6>
      <ul>
        <li>The conversion or transfer of property, especially money, knowing that such property is derived from criminal activity or from taking part in such activity, for the purpose of concealing or disguising the illegal origin of the property or of helping any person who is involved in the commission of such an activity to evade the legal consequences of that person's or company's action;</li>
        <li>The concealment or disguise of the true nature, source, location, disposition, movement, rights with respect to, or ownership of, property, knowing that such property is derived from criminal activity or from an act of participation in such an activity;</li>
        <li>The acquisition, possession or use of property, knowing, at the time of receipt, that such property was derived from criminal activity or from assisting in such an activity;</li>
      </ul>

      <h6>Enhanced Due Diligence and Ongoing Monitoring</h6>
      <p>
        performs ongoing monitoring on its users in order to detect any behaviors or indicators that might raise suspicions in regard to money laundering and terrorism financing practices. For that purpose, Rollbit.com has implemented a set of red flag indicators that help it determine such behaviors and require further action from Rollbit.com in assessing the customer information.
        Whenever one of those red flags is triggered, the user account will be suspended and Rollbit.com will pursue enhanced due diligence. Enhanced KYC diligence under this policy is deemed to include, but not limit to, the provision of:
      </p>
      <ul>
        <li>
          Full legal name;
        </li>
        <li>
          Country of citizenship;
        </li>
        <li>
          Permanent Address (which, for an individual, must be a residential or business street address, and for an entity, must be a principal place of business, local office or other physical location);
        </li>
        <li>
          Identification number (either a taxpayer identification number, or, if unavailable, a passport number and country of issuance, alien identification card number or number and country of issuance of another government-issued document evidencing nationality or residence and bearing a photograph or similar safeguard);
        </li>
        <li>Identification Document; and</li>
        <li>Source of Funds and Source of Wealth.</li>
      </ul>
    </TextWrapper>
  </div>
</template>

<style scoped lang="scss">

</style>