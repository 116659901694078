<script>
export default {
  data () {
    return {
      isOpen: false,
    }
  },
  props: {
    label: {
      type: String
    },
    value: {
      type: Object,
    },
    options: {
      type: Array,
    },
    nameBy: {
      type: String,
      default: 'name'
    }
  },
  methods: {
    close () {
      this.isOpen = false;
    }
  },
  mounted() {
    document.addEventListener('click', this.close);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.close);
  },
  name: "DepositSelect"
}
</script>

<template>
  <div class="deposit-select">
    <div class="deposit-select__main" @click.prevent.stop="isOpen = !isOpen">
      <div class="deposit-select__main-icon">
        <img :src="value.icon" alt="">
      </div>
      <div class="deposit-select__main-text">
        <div class="deposit-select__main-text__label">
          {{ label }}
        </div>
        <div class="deposit-select__main-text__value">
          {{ value[nameBy] }}
        </div>
      </div>
      <div class="deposit-select__main-img" :class="{'transform': isOpen}">
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
          <path d="M4.5 6.75L9 11.25L13.5 6.75" stroke="#7F7DA6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
    </div>
    <transition name="fade">
      <div class="deposit-select__list" v-if="isOpen">
        <div class="deposit-select__list-item" @click="$emit('update', option)" v-for="option in options" :key="option.id">
          <div class="deposit-select__list-item__icon">
            <img :src="option.icon" alt="">
          </div>
          <div class="deposit-select__list-item__name">
            {{ option[nameBy] }}
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<style scoped lang="scss">
  .deposit-select {
    position: relative;
    z-index: 2;
    .deposit-select__main {
      border-radius: 12px;
      cursor: pointer;
      display: flex;
      align-items: center;
      background: var(--50-Dark, rgba(0, 3, 42, 0.50));
      padding: 8px 13px;
      &:focus {
        border: 1px solid var(--Purple, #AB6BFF);
      }
      transition: 0.2s;
      &:hover {
        opacity: 0.8;
      }
      .deposit-select__main-icon {
        width: 24px;
        height: 24px;
        margin-right: 8px;
        img {
          height: 100%;
          width: 100%;
        }
      }
      .deposit-select__main-text {
        .deposit-select__main-text__label {
          color: var(--800, #918FBB);
          font-family: "Geometria-Medium";
          font-size: 11px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.22px;
          margin-bottom: 2px;
        }
        .deposit-select__main-text__value {
          font-family: "Geometria-Bold";
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          letter-spacing: -0.28px;
          text-transform: uppercase;
          @media (max-width: 768px) {
            font-size: 12px;
          }
        }
      }
      .deposit-select__main-img {
        width: 18px;
        height: 18px;
        transition: 0.2s;
        cursor: pointer;
        position: absolute;
        right: 10px;
        &:hover {
          opacity: 0.8;
        }
        &.transform {
          transform: rotate(180deg);
        }
      }
    }
    .deposit-select__list {
      border-radius: 12px;
      background: var(--50-Dark, rgba(0, 3, 42, 0.50));
      box-shadow: 0px 29px 32px 0px rgba(22, 0, 59, 0.81);
      backdrop-filter: blur(17.549999237060547px);
      padding: 8px 4px;
      position: absolute;
      top: calc(100% + 3px);
      width: 100%;
      left: 0;
      max-height: 200px;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 2px;
        background: transparent;
      }
      &::-webkit-scrollbar-thumb {
        background: #9D42F8;
      }
      .deposit-select__list-item {
        display: flex;
        align-items: center;
        padding: 4px 8px;
        border-radius: 8px;
        transition: 0.1s;
        cursor: pointer;
        .deposit-select__list-item__icon {
          width: 24px;
          height: 24px;
          margin-right: 8px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .deposit-select__list-item__name {
          font-size: 14px;
          overflow: hidden;
          text-overflow: ellipsis;
          padding-top: 1px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          letter-spacing: -0.28px;
          font-family: "Geometria-Bold";
          text-transform: uppercase;
        }
        &:hover {
          background: rgba(200, 197, 255, 0.10);
        }
      }
      .deposit-select__list-item + .deposit-select__list-item {
        margin-top: 8px;
      }
    }
  }
</style>