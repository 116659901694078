export default function extractErrors (errors)
{
    let messages = [];

    // Проверяем, является ли переданный объект массивом
    if (Array.isArray(errors)) {
        // Если это массив, проходим по каждому элементу и рекурсивно вызываем collectErrorMessages
        errors.forEach(error => {
            if (typeof error === 'string') {
                messages.push(error);
            }  else {
                messages = messages.concat(extractErrors(error));
            }
        });
    } else if (typeof errors === 'object' && errors !== null) {
        // Если это объект, проходим по каждому ключу и рекурсивно вызываем collectErrorMessages
        Object.keys(errors).forEach(key => {
            messages = messages.concat(extractErrors(errors[key]));
        });
    } else if (typeof errors === 'string') {
        // Если это строка, добавляем ее в массив сообщений
        messages.push(errors);
    }

    return messages;
}