<script>
export default {
  props: {
    notification: {
      type: Object,
      required: true
    }
  },
  name: "NotificationItem",
  mounted () {
    setTimeout(() => {
      this.$emit('close');
    }, 7500);
  }
}
</script>

<template>
<div class="notification">
  <div class="notification-shadow" :class="[notification.type]"></div>
  <div class="notification-body">
    <div class="notification-body__status" :class="[notification.type]">
      <img src="@/assets/images/notifications/success-icon.svg" v-if="notification.type === 'success'" alt="">
      <img src="@/assets/images/notifications/error-icon.svg" v-if="notification.type === 'error'" alt="">
      <img src="@/assets/images/notifications/info-icon.svg" v-if="notification.type === 'info'" alt="">
    </div>
    <div class="notification-body__info">
      <strong>{{ notification.title }}</strong>
      <span>{{ notification.text }}</span>
    </div>
    <div class="notification-body__close default-hover" @click.prevent.stop="$emit('close')">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M17 7L7 17M7 7L17 17" stroke="#918FBB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </div>
  </div>
</div>
</template>

<style scoped lang="scss">
  .notification {
    padding: 2px;
    background: #918FBB;
    border-radius: 12px;
    position: relative;
    overflow: hidden;
    .notification-body {
      border-radius: 12px;
      position: relative;
      z-index: 2;
      background: #1D1B41;
      display: flex;
      align-items: center;
      padding: 12px;
      .notification-body__status {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 40px;
        margin-right: 12px;
        &.success {
          border-radius: 10px;
          border: 1px solid #D9FF90;
          background: linear-gradient(180deg, #B8FF23 0%, #3B8600 100%);
          box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.25), -4px 5px 23.3px 0px rgba(83, 156, 7, 0.38);
        }
        &.error {
          border-radius: 10px;
          border: 1px solid #FF6F6F;
          background: linear-gradient(180deg, #FF2828 0%, #900 100%);
          box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.25), -4px 5px 23.3px 0px rgba(156, 7, 7, 0.38);
        }
        &.info {
          border-radius: 10px;
          background: linear-gradient(180deg, #E4CD00 0%, #E7A600 100%);
          box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.25), -4px 5px 23.3px 0px rgba(223, 167, 1, 0.28);
        }
      }
      .notification-body__info {
        flex-grow: 1;
        strong {
          font-family: Unbounded;
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          letter-spacing: -0.24px;
          margin-bottom: 3px;
          display: block;
        }
        span {
          display: block;
          width: 100%;
          color: #918FBB;
          font-size: 14px;
          font-family: "Geometria-Bold";
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          letter-spacing: -0.28px;
          padding-right: 40px;
        }
      }
      .notification-body__close {
        min-width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        background: #02002A;
      }
    }
    .notification-shadow {
      position: absolute;
      height: 100%;
      border-radius: 12px;
      left: 0;
      width: 1px;
      top: 0;
      animation: fill 7s forwards;
      &.success {
        background: #D9FF90;
      }
      &.error {
        background: #FF6F6F;
      }
      &.info {
        background: #E4CD00;
      }
    }
    width: 100%;
  }

  @keyframes fill {
    0% {
      width: 1px;
    }
    100% {
      width: 100%;
    }

  }
</style>