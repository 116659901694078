<script>
import RankIcon from "@/components/Ui/RankIcon.vue";
import AccentBtn from "@/components/Ui/Buttons/AccentBtn.vue";
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  props: {
    userId: {
      type: Number,
    },
    price: {
      type: Number,
    },
    id: {
      type: Number,
    },
    nft: {
      type: Object,
      required: true,
    }
  },
  methods: {
    ...mapActions({
      openRegister: 'config/openRegister'
    }),
    ...mapMutations({
      setBuyNftObject: 'config/setBuyNftObject'
    }),
    buyNft () {
      if (!this.isAuth) {
        return this.openRegister()
      }

      if (this.user.sg_balance < this.price) {
        return this.$root.$emit('error', {
          title: "Error",
          text: this.$t('enough_sneg')
        });
      }

      console.log({
        ...this.nft,
        id: this.id,
        price: this.price,
      })

      this.setBuyNftObject({
        ...this.nft,
        id: this.id,
        price: this.price,
      })
    }
  },
  computed: {
    ...mapGetters({
      isAuth: 'user/isAuth',
      user: 'user/user',
    })
  },
  name: "NftItem",
  components: {AccentBtn, RankIcon}
}
</script>

<template>
<div class="nft-item">
  <router-link :to="`/nft/${nft.id}`" class="nft-image">
    <div class="nft-image-content">
      <img :src="nft.image_url" alt="">
      <div class="nft-image-level">
        <RankIcon :rank="nft.level" />
        <div class="nft-image-level-name">
          Level {{ nft.level }}
        </div>
      </div>
    </div>
  </router-link>
  <div class="nft-info">
    <div class="nft-info__name">
      {{ nft.name }}
    </div>
    <div class="nft-info__price">
      <div class="nft-info__price-icon">
        <img src="@/assets/images/sneg-coin.svg" alt="">
      </div>
      <div class="nft-info__price-value">{{ parseFloat(price).toFixed(2) }}</div>
      <div class="nft-info__price-wallet">SNEG</div>
    </div>

    <AccentBtn @click="buyNft" :title="isAuth && userId && userId === user.id ? $t('your_nft') : $t('buy_nft')" :disabled="isAuth && userId && userId === user.id" />

  </div>
</div>
</template>

<style scoped lang="scss">
  .nft-item {
    border-radius: 10px;
    background: #131139;
    overflow: hidden;
    padding: 6px;
    .nft-image {
      position: relative;
      width: 100%;
      display: block;
      padding-top: 100%;
      .nft-image-content {
        position: absolute;
        top: 0;
        left: 0;
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 8px;
        overflow: hidden;
        img {
          width: 105%;
          margin-left: -2.5%;
          top: -2.5%;
          height: 105%;
        }
        .nft-image-level {
          position: absolute;
          top: 4px;
          left: 4px;
          z-index: 2;
          border-radius: 34px;
          padding: 6px;
          background: var(--50-Dark, rgba(0, 3, 42, 0.50));
          backdrop-filter: blur(16.899999618530273px);
          display: flex;
          align-items: center;
          padding-right: 10px;
          .nft-image-level-name {
            margin-left: 6px;
            font-size: 12px;
            font-family: "Geometria-Bold";
            font-style: normal;
            font-weight: 700;
            line-height: 14px;
            letter-spacing: -0.24px;
          }
        }
      }
    }
    .nft-info {
      padding: 14px 7px;
      padding-bottom: 6px;
      max-width: 100%;
      overflow: hidden;
      .nft-info__name {
        font-family: "Geometria-Bold";
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.28px;
        margin-bottom: 7.5px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        @media (max-width: 575px) {
          font-size: 13px;
        }
      }
      .nft-info__price {
        margin-bottom: 12.5px;
        display: flex;
        align-items: center;
        .nft-info__price-icon {
          width: 18px;
          display: flex;
          align-items: center;
          margin-right: 6px;
          img {
            width: 100%;
          }
        }
        .nft-info__price-value {
          font-family: "Geometria-Medium";
          font-size: 14px;
          font-style: normal;
          display: flex;
          align-items: center;
          font-weight: 500;
          line-height: 14px;
          padding-top: 2px;
          letter-spacing: -0.28px;
          @media (max-width: 575px) {
            font-size: 13px;
          }
        }
        .nft-info__price-wallet {
          margin-left: 5px;
          border-radius: 5px;
          background: var(--15, rgba(255, 255, 255, 0.15));
          padding: 2px 4px;
          color: #B9B8DD;
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          line-height: 14px;
          padding-bottom: 1px;
          letter-spacing: -0.24px;
          font-family: "Geometria-Bold";
        }
      }
    }
  }
</style>