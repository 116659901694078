import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { Chains, createWeb3Auth } from '@kolirt/vue-web3-auth'
Vue.use(createWeb3Auth({
  projectId: 'b57ad84c2c2dd28337ddb7fcff2e059e', // generate here https://cloud.walletconnect.com/ and turn on 'Supports Sign v2'
  chains: [
    Chains.bsc,
    Chains.mainnet,
    Chains.polygon
  ]
}))
import FloatingVue from 'floating-vue'
import 'floating-vue/dist/style.css'
Vue.use(FloatingVue)
import VCalendar from 'v-calendar';
Vue.use(VCalendar);
Vue.use(VueAxios, axios)
import VueEcho from 'vue-echo-laravel';
import {echoConfiguration} from "@/plugins/echo";
import './plugins/crisp';
Vue.use(VueEcho, echoConfiguration);
Vue.config.productionTip = false

export const vue = new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
