<script>
import UiInput from "@/components/Ui/UiInput.vue";
import BlogItem from "@/components/Blog/BlogItem.vue";
import SortFilter from "@/components/Parts/SortFilter.vue";
import { getNews } from "@/services/blog";
import SimplePagination from "@/components/SimplePagination.vue";

export default {
  data () {
    return {
      isOpenSearch: false,
      sortDefault: {
        id: 0,
        name: 'Newest',
        dir: 'desc',
        slug: 'id'
      },
      sortOptions: [
        {
          id: 0,
          name: 'Newest',
          dir: 'desc',
          slug: 'id'
        },
        {
          id: 1,
          name: 'Oldest',
          dir: 'asc',
          slug: 'id'
        }
      ],
      pages: 1,
      current_page: 1,
      posts: [],
      search: null,
    }
  },
  watch: {
    search () {
      this.current_page = 1;
      this.fetchNews()
    },
    sortDefault () {
      this.fetchNews()
    }
  },
  methods: {
    setPage (page) {
      this.current_page = page;
      this.fetchNews();
    },
    fetchNews() {
      getNews({
        order_by: this.sortDefault.slug,
        order_dir: this.sortDefault.dir,
        page: this.current_page,
        search: this.search
      }).then(response => {
        this.pages = response.data.meta.last_page;
        this.current_page = response.data.meta.current_page;
        this.posts = response.data.data;
      })
    }
  },
  mounted () {
    this.fetchNews();
  },
  name: "BlogView",
  components: {SimplePagination, SortFilter, BlogItem, UiInput}
}
</script>

<template>
  <div class="blog page">
    <h1>{{ $t('all_news') }}</h1>
    <div class="blog__filters">
      <div class="blog__filters-search" :class="{'active': isOpenSearch}">
        <UiInput v-model="search" type="text" :icon="require('@/assets/images/search-blog-icon.svg')" :placeholder="$t('news_search')" />
      </div>
      <SortFilter @update="sortDefault = $event" :class="{'hide': isOpenSearch}" :default="sortDefault" :options="sortOptions" />
    </div>
    <div class="blog-list">
      <BlogItem v-for="(blog,i) in posts" :key="i" :blog="blog" />
    </div>
    <div class="blog-pagination">
<!--      <div class="load">-->
<!--        <AccentBtn title="Load more" />-->
<!--      </div>-->

      <SimplePagination @update="setPage" :current_page="current_page" :pages="pages" />
    </div>
  </div>
</template>

<style scoped lang="scss">
  .blog {
    padding: 48px 0;
    @media (max-width: 768px) {
      padding: 20px 0;
      padding-top: 0;
    }
    h1 {
      font-family: "Unbounded";
      font-size: 46px;
      font-weight: 700;
      line-height: 50px; /* 108.696% */
      letter-spacing: -0.92px;
      @media (max-width: 768px) {
        font-size: 24px;
        line-height: 28px;
      }
    }
    .blog__filters {
      margin: 13px 0;
      display: flex;
      justify-content: space-between;
      .blog__filters-search {
        width: 490px;
        @media (max-width: 768px) {
          position: absolute;
          width: auto;
          left: 10px;
          z-index: 4;
          &:not(.active) {
            ::v-deep {
              .ui-input {
                padding-right: 0;
              }
              .ui-input__area {
                input {
                  width: 0;
                }
              }
            }
          }
        }
      }

      .blog__filters-sort {
        &.hide {
          visibility: hidden;
        }
        @media (max-width: 768px) {
          margin-left: auto;
        }
      }
    }
    .blog-list {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 16px;
      @media (max-width: 1900px) {
        grid-template-columns: repeat(3, 1fr);
      }
      @media (max-width: 992px) {
        grid-gap: 8px;
        grid-template-columns: repeat(2, 1fr);
      }
      @media (max-width: 620px) {
        grid-template-columns: repeat(1, 1fr);
      }
    }
    .blog-pagination {
      display: flex;
      margin: 16px 0;
      margin-top: 30px;
      justify-content: flex-end;
      align-items: center;
      .load {
        width: 140px;
      }
      @media (max-width: 768px) {
        margin-top: 12px;
      }
    }
  }
</style>