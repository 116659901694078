<script>
export default {
  props: {
    title: {
      type: String,
    }
  },
  name: "YellowBtn"
}
</script>

<template>
  <button @click="$emit('click')" class="yellow-btn default-hover">{{ title }}</button>
</template>

<style scoped lang="scss">
.yellow-btn {
  border-radius: 11px;
  background: linear-gradient(180deg, #F7FC00 -4.55%, #FFEA31 98.49%);
  box-shadow: 0 -1px 1px 1px #F7FC00;
  width: 100%;
  padding: 16px 0;
  font-size: 14px;
  font-family: "Geometria-Bold";
  font-weight: 700;
  color: #0C0A33;
  letter-spacing: -0.28px;
}
</style>