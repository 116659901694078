<script>
export default {
  data () {
    return {
      id: Math.random().toString(36).substring(2,7)
    }
  },
  name: "UiCheckbox"
}
</script>

<template>
  <div class="ui-checkbox">
    <input @input="$emit('change', $event)" type="checkbox" :id="id">
    <label :for="id">
      <span class="square"></span>
      <span class="label">
        <slot></slot>
      </span>
    </label>
  </div>
</template>

<style scoped lang="scss">
  .ui-checkbox {
    cursor: pointer;
    input {
      display: none;
    }
    label {
      cursor: pointer;
      display: flex;
      align-items: center;
      .square {
        min-width: 22px;
        width: 22px;
        height: 22px;
        border-radius: 8px;
        border: 1px solid #3E3C5F;
        background: #1D1B41;
        margin-right: 10px;
        position: relative;
        &::after {
          content: "";
          display: block;
          transition: 0.1s;
          width: 70%;
          height: 70%;
          background: #23A3FF;
          border-radius: 5px;
          left: 15%;
          top: 15%;
          transform: scale(0.1);
          opacity: 0;
          position: absolute;
        }
      }
    }
    input:checked + label {
      .square {
        &::after {
          transform: scale(1);
          opacity: 1;
        }
      }
    }
  }
</style>