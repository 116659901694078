<script>
import {vueTelegramLogin} from "vue-telegram-login";
import {mapActions} from "vuex";

export default {
  methods: {
    ...mapActions({
      socialCallback: 'auth/socialCallback'
    }),
    socialCallbackProcessing (data) {
      this.socialCallback({data, provider: 'telegram'})
    }
  },
  name: "TelegramSocialAuth",
  components: {vueTelegramLogin}
}
</script>

<template>
  <vue-telegram-login
      ref="tgButton"
      size="medium"
      :userpic="false"
      mode="callback"
      telegram-login="snegbet_authorize_bot"
      @callback="socialCallbackProcessing" />
</template>

<style scoped lang="scss">

</style>