<script>
import UiInput from "@/components/Ui/UiInput.vue";
import PriceRanger from "@/components/PriceRanger.vue";
import RankIcon from "@/components/Ui/RankIcon.vue";
import NftItem from "@/components/Nft/NftItem.vue";
import SimplePagination from "@/components/SimplePagination.vue";
import BackButton from "@/components/BackButton.vue";
import { getItems, getCollections } from "@/services/marketplace";
import UiLoader from "@/components/UiLoader.vue";
import EmptyState from "@/components/EmptyState.vue";

export default {
  data () {
    return {
      isLoading: false,
      current_page: 1,
      pages: 1,
      min: 0,
      max: 100000,
      search: null,
      collectionId: null,
      level: null,
      items: [],
      collections: []
    }
  },
  watch: {
    search() {
      this.current_page = 1;
      this.fetchItems()
    },
    level() {
      this.current_page = 1;
      this.fetchItems()
    },
    collectionId() {
      this.current_page = 1;
      this.fetchItems()
    }
  },
  methods: {
    fetchCollections () {
      getCollections().then(response => {
        this.collections = response.data.data;
      })
    },
    update (data) {
      this.current_page = 1;
      this.min = data.from;
      this.max = data.to;

      this.fetchItems();
    },
    async fetchItems () {
      if (this.isLoading) return;

      this.isLoading = true;

      await getItems({
        page: this.page,
        search: this.search,
        price_min: this.min,
        level: this.level,
        price_max: this.max,
        collection_id: this.collectionId
      }).then(response => {
        this.items = response.data.data;
        this.pages = response.data.meta.last_page;
        this.current_page = response.data.current_page;
      }).finally(() => {
        setTimeout(() => {
          this.isLoading = false;
        }, 500)
      })
    }
  },
  mounted () {
    this.fetchItems();
    this.fetchCollections();
    this.$root.$on('drop-nft', (id) => {
      const index = this.items.findIndex(item => item.id === id);
      if (index > -1) {
        this.items.splice(index, 1);
      }
    })
  },
  name: "NftMarketplace",
  components: {EmptyState, UiLoader, BackButton, SimplePagination, NftItem, RankIcon, PriceRanger, UiInput}
}
</script>

<template>
  <div class="nft-marketplace">
    <BackButton :callback="() => $router.push('/')" />
    <div class="marketplace-content">
      <div class="nft-heading">
        <img src="@/assets/images/nft/heading.svg" alt="">
        NFT Marketplace
      </div>
      <div class="nft-filters">
        <div class="nft-filters__search">
          <UiInput v-model="search" type="text" :icon="require('@/assets/images/search-blog-icon.svg')" :placeholder="$t('search_nft')" />
        </div>

        <div class="nft__filters-sort">
          <PriceRanger @update="update" :from="min" :to="max" />
        </div>

        <div class="nft-filters__collections">
          <div class="nft-filters__collection" :class="{'active': collectionId === null}" @click="collectionId = null">{{ $t('sidebar.all') }}</div>
          <div class="nft-filters__collection"
               v-for="collection in collections" :key="collection.id"
               :class="{'active': collectionId === collection.id}"
               @click="collectionId = collection.id"
          >{{ collection.name }}</div>
        </div>
        <div class="nft-filters__mobile-collections">
          <div class="nft-filters__mobile-collection" :class="{'active': collectionId === null}" @click="collectionId = null">{{ $t('sidebar.all') }}</div>
          <div class="nft-filters__mobile-collection"
               v-for="collection in collections" :key="collection.id"
               :class="{'active': collectionId === collection.id}"
               @click="collectionId = collection.id"
          >
            {{ collection.name }}
          </div>
        </div>
      </div>

      <div class="nft-levels">
        <div class="nft-level" :class="{'active': level === null}" @click="level = null">
          <div class="nft-level-name">{{ $t('all_rare') }}</div>
        </div>
        <div class="nft-level" :class="{'active': level === 1}" @click="level = 1">
          <RankIcon :rank="1" />
          <div class="nft-level-name">
            Level 1st
          </div>
        </div>
        <div class="nft-level" :class="{'active': level === 2}" @click="level = 2">
          <RankIcon :rank="2" />
          <div class="nft-level-name">
            Level 2nd
          </div>
        </div>
        <div class="nft-level" :class="{'active': level === 3}" @click="level = 3">
          <RankIcon :rank="3" />
          <div class="nft-level-name">
            Level 3rd
          </div>
        </div>
        <div class="nft-level" :class="{'active': level === 4}" @click="level = 4">
          <RankIcon :rank="4" />
          <div class="nft-level-name">
            Level 4nd
          </div>
        </div>
        <div class="nft-level" :class="{'active': level === 5}" @click="level = 5">
          <RankIcon :rank="5" />
          <div class="nft-level-name">
            Level 5th
          </div>
        </div>
        <div class="nft-level" :class="{'active': level === 6}" @click="level = 6">
          <RankIcon :rank="6" />
          <div class="nft-level-name">
            Level 6th
          </div>
        </div>
      </div>
      <div class="nft-list">
        <transition name="fade">
          <UiLoader v-if="isLoading" :fill="true" />
        </transition>

        <template v-if="!isLoading">
          <template v-if="items.length">
            <NftItem v-for="nft in items" :id="nft.id" :user-id="nft.user.id" :price="nft.price" :key="nft.id" :nft="nft.nft" />
          </template>
          <EmptyState :heading="$t('no_listing')" :absolute="true" v-else />
        </template>
      </div>

      <div class="nft-pagination" v-if="pages > 1">
        <SimplePagination :pages="pages" :current_page="current_page" />
      </div>
    </div>

  </div>
</template>

<style scoped lang="scss">
  .nft-marketplace {
    padding: 10px 13px;
    margin-bottom: 20px;
    border-radius: 20px;
    background: var(--100, #0C0A33);
    @media (max-width: 992px) {
      padding: 10px 0;
      background: transparent;
      .back {
        display: none;
      }
    }
    .marketplace-content {
      padding: 22px 55px;
      @media (max-width: 1400px) {
        padding: 22px 20px;
      }
      @media (max-width: 992px) {
        padding: 0;
      }
      @media (max-width: 1800px) and (min-width: 1600px) {
        padding: 22px 10px;
      }
      .nft-heading {
        display: flex;
        align-items: center;
        img {
          width: 24px;
          margin-right: 12px;
        }
        font-size: 24px;
        font-style: normal;
        font-family: "Geometria-Bold";
        font-weight: 700;
        line-height: 28px; /* 116.667% */
        letter-spacing: -0.48px;
        @media (max-width: 768px) {
          font-size: 18px;
          line-height: 20px;
          padding-left: 15px;
          img {
            width: 22px;
          }
        }
      }
      .nft-filters {
        justify-content: space-between;
        display: flex;
        margin-top: 12px;
        @media (max-width: 768px) {
          display: block;
        }
        .nft-filters__search {
          margin-right: 10px;
          width: 350px;
          @media (max-width: 1400px) {
            width: 200px;
          }
          @media (max-width: 768px) {
            margin-bottom: 10px;
            width: 100%;
          }
        }
        .nft__filters-sort {
          margin-left: auto;
          margin-right: 8px;
          @media (max-width: 768px) {
            margin-right: 0;
          }
        }
        .nft-filters__collections {
          border-radius: 10px;
          background: #1D1B41;
          padding: 4px;
          display: flex;
          @media (max-width: 768px) {
            display: none;
          }
          .nft-filters__collection {
            width: 85px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #918FBB;
            font-family: "Geometria-Bold";
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            letter-spacing: -0.28px;
            cursor: pointer;
            transition: opacity 0.2s;
            border-radius: 9px;
            box-shadow: 0px -1px 1px 1px transparent;
            &:hover {
              opacity: 0.8;
            }
            &.active {
              color: #fff;
              box-shadow: 0px -1px 1px 1px #BC79FF;
              background: linear-gradient(180deg, #9D42F8 -4.55%, #7F00FF 100%);
            }
          }
        }
        .nft-filters__mobile-collections {
          display: none;
          width: 100%;
          margin-top: 7px;
          .nft-filters__mobile-collection {
            color: var(--800, #918FBB);
            font-family: "Geometria-Bold";
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            flex: 1;
            letter-spacing: -0.28px;
            padding: 7px 0;
            border-bottom: 2px solid #2B2A4E;
            text-align: center;
            &.active {
              color: #fff;
              border-color: #AB6BFF
            }
          }

          @media (max-width: 768px) {
            display: flex;
          }
        }
      }
      .nft-levels {
        margin: 8px 0;
        margin-top: 10px;
        display: flex;
        grid-gap: 8px;
        overflow-x: auto;
        padding-top: 2px;
        padding-left: 1px;
        &::-webkit-scrollbar {
          display: none;
        }
        @media (max-width: 1200px) {
          padding-top: 2px;
          padding-bottom: 2px;
          padding-left: 2px;
        }
        .nft-level {
          border-radius: 34px;
          background: #2B2A4E;
          padding: 5px 12px;
          backdrop-filter: blur(16.899999618530273px);
          display: flex;
          align-items: center;
          padding-right: 16px;
          cursor: pointer;
          &:hover {
            opacity: 0.8;
          }

          .ui-rank {
            margin-right: 12px;
          }
          .nft-level-name {
            color: #7F7DA6;
            font-family: "Geometria-Bold";
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: 14px;
            white-space: nowrap;
            transition: 0.2s;
            letter-spacing: -0.24px;
          }
          transition: opacity 0.2s;
          &.active {
            border-radius: 34px;
            background: linear-gradient(180deg, #9D42F8 -4.55%, #7F00FF 100%);
            backdrop-filter: blur(16.899999618530273px);
            box-shadow: 0px -1px 1px 1px #BC79FF;
            .nft-level-name {
              color: #fff;
            }
          }
        }
      }
      .nft-list {
        position: relative;
        min-height: 50vh;
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        align-items: flex-start;
        grid-gap: 16px;
        @media (max-width: 1900px) {
          grid-template-columns: repeat(5, 1fr);
        }
        @media (max-width: 1600px) {
          grid-template-columns: repeat(4, 1fr);
        }
        @media (max-width: 992px) {
          grid-template-columns: repeat(3, 1fr);
        }
        @media (max-width: 700px) {
          grid-gap: 8px;
          grid-template-columns: repeat(2, 1fr);
        }
      }
      .nft-pagination {
        display: flex;
        justify-content: center;
        margin-top: 25px;
        .simple-pagination {
          width: 100%;
          justify-content: center;
          padding: 15px 0;
        }
      }
    }
  }
</style>