<script>
export default {
  props: {
    fromInside: {
      type: Boolean,
      default: false
    },
    blog: {
      type: Object,
      required: true
    }
  },
  name: "BlogItem"
}
</script>

<template>
  <router-link :to="`/blog/${blog.id}`" class="blog-item" :class="{'big': blog.is_big, 'inside': fromInside}" :style="{'background-image': `url('${blog.image}')`}">
    <div class="blog-item__categories">
      <div :style="{'background': tag.color, 'color': tag.text_color ?? 'black'}" class="blog-item__category primary" v-for="(tag, i) in blog.tags" :key="i">
        {{ tag.name }}
      </div>
    </div>
    <div class="blog-item__info">
      <div class="time">
        <div class="icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 16 18" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.70396 2.56674C6.43292 2.36057 7.20284 2.25 7.99984 2.25C8.79683 2.25 9.56676 2.36057 10.2957 2.56674C10.6943 2.67948 11.1088 2.44775 11.2215 2.04917C11.3343 1.6506 11.1025 1.2361 10.704 1.12337C9.84348 0.87999 8.93622 0.75 7.99984 0.75C7.06346 0.75 6.1562 0.87999 5.29572 1.12337C4.89714 1.2361 4.66542 1.6506 4.77815 2.04917C4.89088 2.44775 5.30538 2.67948 5.70396 2.56674ZM15.0832 10.25C15.0832 14.162 11.9119 17.3333 7.99984 17.3333C4.08782 17.3333 0.916504 14.162 0.916504 10.25C0.916504 6.33798 4.08782 3.16667 7.99984 3.16667C11.9119 3.16667 15.0832 6.33798 15.0832 10.25ZM8.62484 6.5C8.62484 6.15482 8.34502 5.875 7.99984 5.875C7.65466 5.875 7.37484 6.15482 7.37484 6.5V9.58389C7.00121 9.80002 6.74984 10.204 6.74984 10.6667C6.74984 11.357 7.30948 11.9167 7.99984 11.9167C8.69019 11.9167 9.24984 11.357 9.24984 10.6667C9.24984 10.204 8.99846 9.80003 8.62484 9.58389V6.5Z" fill="#B9B8DD"/>
          </svg>
        </div>
        <div class="value">
          {{ blog.time_ago }}
        </div>
      </div>
      <div class="title">
        {{ blog.title }}
      </div>
      <div class="except">
        {{ blog.subtitle }}
      </div>
    </div>

    <div class="go-to default-hover" v-if="blog.is_big && !fromInside">
      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
        <path d="M2 12L12 2M12 2H2M12 2V12" stroke="#02002A" stroke-width="2.66667" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </div>
  </router-link>
</template>

<style scoped lang="scss">
  .blog-item {
    height: 330px;
    border-radius: 24px;
    overflow: hidden;
    padding: 14px;
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: space-between;
    background-position: center center;
    @media (max-width: 768px) {
      height: 219px;
    }

    &::before {
      content: "";
      display: block;
      background: linear-gradient(0deg, #2C2B4C 0%, rgba(30, 28, 67, 0.43) 62.91%, rgba(53, 43, 63, 0.00) 100%);
      bottom: 0;
      height: 200px;
      position: absolute;
      left: 0;
      width: 100%;
      @media (max-width: 768px) {
        height: 80px;
      }
    }

    .go-to {
      position: absolute;
      right: 12px;
      bottom: 12px;
      width: 52px;
      height: 52px;
      border-radius: 100%;
      display: flex;
      align-items: center;
      z-index: 4;
      justify-content: center;
      background: #FFE70C;
      @media (max-width: 768px) {
        width: 36px;
        height: 36px;
        border-radius: 15px;
        svg {
          width: 10px;
        }
      }
    }
    .blog-item__categories {
      display: flex;
      grid-gap: 4px;
      .blog-item__category {
        padding: 8px 12px;
        border-radius: 11px;
        overflow: hidden;
        font-size: 12px;
        font-weight: 700;
        letter-spacing: -0.24px;
        font-family: "Geometria-Bold";
        line-height: 14px;
        &.accent {
          background: linear-gradient(180deg, #0582E9 0%, #06F 100%);
        }
        &.primary {
          background: linear-gradient(180deg, #F7FC00 -4.55%, #FFEA31 98.49%);
          color: #0C0A33;
        }
      }
    }
    .blog-item__info {
      padding: 0 10px;
      @media (max-width: 768px) {
        padding: 0;
        max-width: 80%;
      }
      .time {
        display: flex;
        align-items: center;
        .icon {
          margin-right: 11px;
          display: flex;
          align-items: center;
          @media (max-width: 768px) {
            margin-right: 4px;
            width: 12px;
          }
        }
        .value {
          color: #B9B8DD;
          display: flex;
          align-items: center;
          line-height: 14px;
          padding-top: 3px;
          font-family: "Unbounded";
          font-size: 12px;
          font-weight: 700;
          letter-spacing: -0.24px;
          @media (max-width: 768px) {
            font-size: 10px;
            padding-top: 1px;
          }
        }
      }
      .title {
        margin: 10px 0;
        font-family: "Unbounded";
        font-size: 18px;
        font-weight: 700;
        letter-spacing: -0.36px;
        @media (max-width: 768px) {
          font-size: 16px;
        }
      }
      .except {
        margin-top: 7px;
        font-family: "Geometria-Medium";
        font-size: 14px;
        font-weight: 500;
        letter-spacing: -0.28px;
        opacity: 0.7;
        @media (max-width: 768px) {
          font-size: 12px;
        }
      }
    }
    .blog-item__categories, .blog-item__info {
      position: relative;
      z-index: 2;
    }
    background-repeat: no-repeat;
    background-size: cover;
    &::after {
      position: absolute;
      content: "";
      display: block;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height: 100%;
      width: 100%;
      background: rgba(0,0,0,0.2);
      z-index: 1;
    }
    &.inside {
      cursor: default;
    }
    &.big:not(.inside) {
      grid-column: span 2;
      @media (max-width: 1900px) {
        grid-column: span 3;
      }
      @media (max-width: 992px) {
        grid-column: span 1;
      }
    }
  }
</style>