<script>
import AccentBtn from "@/components/Ui/Buttons/AccentBtn.vue";

export default {
  props: {
    lootbox: {
      type: Object,
      required: true
    }
  },
  name: "LootboxShortItem",
  components: {AccentBtn}
}
</script>

<template>
  <div :class="[lootbox.color]" class="lootbox-item">
    <div class="lootbox-item-shadow"></div>

    <div class="lootbox-item__question default-hover" v-if="!lootbox.is_free">
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.6663 7.99992C14.6663 11.6818 11.6816 14.6666 7.99967 14.6666C4.31778 14.6666 1.33301 11.6818 1.33301 7.99992C1.33301 4.31802 4.31778 1.33325 7.99967 1.33325C11.6816 1.33325 14.6663 4.31802 14.6663 7.99992ZM7.99967 4.74992C7.30932 4.74992 6.74967 5.30956 6.74967 5.99992C6.74967 6.41413 6.41389 6.74992 5.99967 6.74992C5.58546 6.74992 5.24967 6.41413 5.24967 5.99992C5.24967 4.48114 6.48089 3.24992 7.99967 3.24992C9.51846 3.24992 10.7497 4.48114 10.7497 5.99992C10.7497 7.04193 10.1698 7.94744 9.31878 8.41333C9.12758 8.51799 8.96916 8.63892 8.8668 8.75828C8.7677 8.87382 8.74967 8.95195 8.74967 8.99992V9.33325C8.74967 9.74747 8.41389 10.0833 7.99967 10.0833C7.58546 10.0833 7.24967 9.74747 7.24967 9.33325V8.99992C7.24967 8.4956 7.46891 8.08409 7.72819 7.78177C7.9842 7.48326 8.30526 7.2581 8.59851 7.09757C8.98816 6.88427 9.24967 6.47209 9.24967 5.99992C9.24967 5.30956 8.69003 4.74992 7.99967 4.74992ZM7.99967 11.9999C8.36786 11.9999 8.66634 11.7014 8.66634 11.3333C8.66634 10.9651 8.36786 10.6666 7.99967 10.6666C7.63148 10.6666 7.33301 10.9651 7.33301 11.3333C7.33301 11.7014 7.63148 11.9999 7.99967 11.9999Z" fill="#23A3FF"/>
      </svg>
      {{ $t('ll_wh_inside') }}
    </div>
    <div class="lootbox-item__question" v-else>
      {{ lootbox.expires_at }} {{ $t('ll_day_left') }}
    </div>

<!--    <div class="lootbox-item__favorite">-->
<!--      <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--        <path d="M6.68822 2.68086C7.22467 1.55072 8.77467 1.55072 9.31113 2.68086L10.2383 4.63404C10.4513 5.08282 10.8631 5.39388 11.3394 5.46584L13.4126 5.77905C14.6121 5.96027 15.0911 7.49295 14.2231 8.37264L12.723 9.89298C12.3783 10.2423 12.221 10.7456 12.3023 11.2389L12.6565 13.3856C12.8614 14.6278 11.6074 15.575 10.5345 14.9885L8.68023 13.975C8.25417 13.7421 7.74518 13.7421 7.31912 13.975L5.46485 14.9885C4.39194 15.575 3.13796 14.6278 3.34287 13.3856L3.697 11.2389C3.77837 10.7456 3.62108 10.2423 3.2764 9.89298L1.77626 8.37264C0.908252 7.49295 1.38723 5.96027 2.58678 5.77905L4.65992 5.46584C5.13627 5.39388 5.54805 5.08282 5.76108 4.63404L6.68822 2.68086Z" fill="#0C0A33"/>-->
<!--      </svg>-->
<!--    </div>-->

    <div class="lootbox-image" v-if="!lootbox.is_free">
      <img :src="lootbox.image ? lootbox.image : require('@/assets/images/lootbox/red-lootbox.png')" alt="">
      <div class="count">+{{ lootbox.prizes.length - 2 }}</div>
    </div>
    <div class="lootbox-free" v-else>
      <img src="@/assets/images/lootbox/free.svg" alt="">
    </div>

    <div class="lootbox-item-information">
      <strong>{{ lootbox.name }}</strong>
      <div class="price">
        <div class="price-icon">
          <img src="@/assets/images/sneg-coin.svg" alt="">
        </div>
        <div class="price-value">
          <template v-if="lootbox.is_free">FREE</template>
          <template v-else>{{ lootbox.price }}</template>
        </div>
        <div class="price-coin" v-if="!lootbox.is_free">
          SNEG
        </div>
      </div>
      <AccentBtn @click="$router.push(`/lootbox/${lootbox.id}`)" :class="{'blue': lootbox.is_free}" :title="lootbox.is_free ? $t('ll_open_free') : $t('ll_open_btn')" />
    </div>

  </div>
</template>

<style scoped lang="scss">
  .lootbox-item {
    overflow: hidden;
    border-radius: 10px;
    height: 315px;
    background: linear-gradient(180deg, rgba(89, 96, 121, 0.59) 0%, rgba(107, 124, 141, 0.00) 100%);
    position: relative;
    @media (max-width: 768px) {
      height: 280px;
    }
    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 80%;
      background: url(@/assets/images/lootbox/lootbox-short-bg.png);
      background-repeat: no-repeat;
      background-size: auto 100%;
      background-position: top center;
    }

    .lootbox-item-shadow {
      position: absolute;
      top: 0;
      background: #FFBE41;
      width: 100%;
      opacity: 0;
      left: 0;
      transition: 0.2s;
    }

    &.yellow {
      border: 1px solid #FFBC3A;
      background: linear-gradient(180deg, rgba(135, 89, 19, 0.59) 0%, rgba(19, 17, 57, 0.00) 100%);
      transition: 0.2s;

      .lootbox-item-shadow {
        box-shadow: 0px 0px 200px 170px rgba(255, 190, 65, 0.8);
      }
      &:hover {
        .lootbox-item-shadow {
          opacity: 1;
        }
      }
      &:hover {
        box-shadow: 0px 7px 32.3px -5px rgba(135, 89, 19, 0.8);
      }

      &::before {
        background: url(@/assets/images/lootbox/yellow-shadow.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: top center;
      }
      .lootbox-image {
        .count {
          background: rgba(135, 89, 19, 1);
        }
      }
    }
    &.red {
      border-radius: 10px;
      border: 1px solid #FF3A3A;
      background: linear-gradient(180deg, rgba(189, 8, 8, 0.54) 0%, rgba(189, 8, 8, 0.00) 100%);
      transition: 0.2s;
      &:hover {
        box-shadow: 0px 7px 32.3px -5px rgba(255, 64, 66, 0.57);
      }

      .lootbox-item-shadow {
        box-shadow: 0px 0px 200px 170px rgba(255, 58, 58, 0.9);
      }
      &:hover {
        .lootbox-item-shadow {
          opacity: 1;
        }
      }

      &::before {
        background: url(@/assets/images/lootbox/red-shadow.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        height: 100%;
        background-position: top center;
      }
      .lootbox-image {
        .count {
          background: #FF2828;
        }
      }
    }
    &.blue {
      border-radius: 10px;
      background: rgba(43, 153, 255, 0.14);
      transition: 0.2s;
      &:hover {
        box-shadow: 0px 7px 32.3px -5px rgba(43, 153, 255, 0.4);
      }

      .lootbox-item-shadow {
        box-shadow: 0px 0px 200px 170px rgba(136, 191, 255, 0.7);
      }
      &:hover {
        .lootbox-item-shadow {
          opacity: 1;
        }
      }


      &::before {
        background: url(@/assets/images/lootbox/blue-shadow.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: top center;
      }
      .lootbox-image {
        .count {
          background: #23A3FF;
        }
      }
    }
    &.yellow {

    }
    &.green {
      border-radius: 10px;
      background: rgba(143, 255, 2, 0.16);
      transition: 0.2s;
      &:hover {
        box-shadow: 0px 7px 32.3px -5px rgba(143, 255, 2, 0.4);
      }

      .lootbox-item-shadow {
        box-shadow: 0px 0px 200px 170px rgba(165, 255, 50, 0.8);
      }
      &:hover {
        .lootbox-item-shadow {
          opacity: 1;
        }
      }

      &::before {
        background: url(@/assets/images/lootbox/green-shadow.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: top center;
      }
      .lootbox-image {
        .count {
          background: #0FBA00;
        }
      }
    }
    &::before {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 70%;
    }
    &.gray {
      border-radius: 10px;
      background: linear-gradient(180deg, rgba(89, 96, 121, 0.59) 0%, rgba(107, 124, 141, 0.00) 100%);
      &::before {
        background: url(@/assets/images/lootbox/gray-shadow.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: top center;
      }
      .lootbox-image {
        .count {
          background: #7D7D7D;
        }
      }

      .lootbox-item-shadow {
        box-shadow: 0px 0px 200px 170px rgba(224, 224, 224, 0.5);
      }
      &:hover {
        .lootbox-item-shadow {
          opacity: 1;
        }
      }

    }
    .lootbox-item__question {
      position: absolute;
      top: 7px;
      left: 6px;
      border-radius: 34px;
      background: rgba(200, 197, 255, 0.10);
      backdrop-filter: blur(16.899999618530273px);
      padding: 6px 6px;
      display: flex;
      align-items: center;
      font-size: 12px;
      font-family: "Geometria-Bold";
      line-height: 14px;
      padding-right: 10px;
      font-weight: 700;
      letter-spacing: -0.24px;
      svg {
        margin-bottom: 2px;
        margin-right: 6px;
      }
    }
    .lootbox-item__favorite {
      position: absolute;
      right: 7px;
      top: 6px;
      width: 34px;
      height: 34px;
      border-radius: 100%;
      background: #FFE70C;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .accent-btn.blue {
      border-radius: 11px;
      background: linear-gradient(180deg, #0582E9 0%, #06F 100%);
      box-shadow: 0px 4px 7.2px 0px rgba(98, 0, 197, 0.24);
    }
    .lootbox-free {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 50px;
      position: relative;
      z-index: 3;
      img {
        max-width: 80%;
        max-height: 127px;
      }
    }
    .lootbox-image {
      width: 40%;
      margin: 0 auto;
      margin-top: 50px;
      position: relative;
      z-index: 3;
      img {
        border-radius: 13px;
        overflow: hidden;
        width: 100%;
        height: 100%;
      }
      &::after {
        padding-bottom: 100%;
      }
      &::before {
        content: "";
        display: block;
        position: absolute;
        background: url(@/assets/images/lootbox/Coins.svg);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        width: 100%;
        padding-bottom: 90%;
        right: -30%;
        bottom: -40%;
        z-index: 2;
      }
      .count {
        position: absolute;
        top: 100%;
        border-radius: 53px;
        padding: 4px 8px;
        font-family: Unbounded;
        font-size: 14px;
        z-index: 4;
        right: 40%;
        font-weight: 700;
        letter-spacing: -0.28px;
      }
    }
    .lootbox-item-information {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      padding: 13px;
      strong {
        font-family: "Unbounded";
        font-size: 14px;
        font-weight: 700;
        letter-spacing: -0.28px;
        display: block;
        margin-bottom: 8px;
        width: 100%;
        @media (max-width: 768px) {
          font-size: 12px;
        }
      }
      .price {
        margin-bottom: 14px;
        display: flex;
        align-items: center;
        .price-icon {
          width: 18px;
          height: 18px;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            max-width: 100%;
            max-height: 100%;
            filter: drop-shadow(0px 2px 5px #7F00FF);
          }
          margin-right: 6px;
          @media (max-width: 768px) {
            max-width: 15px;
          }
        }
        .price-value {
          font-family: "Geometria-Medium";
          font-size: 14px;
          display: flex;
          align-items: center;
          line-height: 14px;
          font-weight: 500;
          letter-spacing: -0.28px;
          margin-right: 5px;
          @media (max-width: 768px) {
            font-size: 12px;
          }
        }
        .price-coin {
          border-radius: 5px;
          background: rgba(255, 255, 255, 0.15);
          padding: 2px 4px;
          color: #B9B8DD;
          line-height: 14px;
          font-size: 12px;
          font-family: "Geometria-Bold";
          font-weight: 700;
          letter-spacing: -0.24px;
          @media (max-width: 768px) {
            font-size: 11px;

          }
        }
      }
    }
  }
</style>