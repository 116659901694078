export default {
    lootbox: state => state.lootbox,
    isPlaying: state => state.isPlaying,
    winItem: state => state.winItem,
    mode: state => state.mode,
    autoSelectItem: state => state.autoSelectItem,
    spinsCount: state => state.spinsCount,
    balanceLimit: state => state.balanceLimit,
    completedSpinsCurrently: state => state.completedSpinsCurrently,
    items: state => {
        const items = state.lootbox?.prizes || [];

        if (!items.length) return [];

        let result = [];

        while(result.length < 60) {
            result = [...result, ...items];
        }

        return result;
    }
}