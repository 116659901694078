<script>
import UiInput from "@/components/Ui/UiInput.vue";
import MultiRangeSlider from "multi-range-slider-vue";
import AccentBtn from "@/components/Ui/Buttons/AccentBtn.vue";
export default {
  props: {
    from: {
      type: Number,
    },
    to: {
      type: Number
    }
  },
  components: {UiInput, AccentBtn, MultiRangeSlider},
  data () {
    return {
      barMinValue: 0,
      barMaxValue: 100000,
      value: null,
      isOpen: false,
    }
  },
  methods: {
    update () {
      this.$emit('update', {
        from: this.barMinValue,
        to: this.barMaxValue
      })
      this.isOpen = false;
    },
    UpdateValues(e) {
      this.barMinValue = e.minValue;
      this.barMaxValue = e.maxValue;
    },
    close () {
      this.isOpen = false;
    }
  },
  mounted() {
    window.addEventListener('click', this.close);
  },
  beforeDestroy() {
    window.removeEventListener('click', this.close)
  },
  name: "PriceRanger"
}
</script>

<template>
<div class="price-ranger" @click.prevent.stop>
  {{ value }}
  <div class="price-ranger__preview" @click.prevent.stop="isOpen = !isOpen">
    <div class="price-ranger__preview-icon">
      <img src="@/assets/images/ranger/ranger.svg" alt="">
    </div>
    <div class="price-ranger__preview-name">
      {{ $t('price_ranger.price') }}
    </div>
    <div class="price-ranger__preview-value">
      {{ $t('price_ranger.from') }} {{from}} {{ $t('price_ranger.to') }} {{to}}
    </div>
    <div :class="{'rotate': isOpen}" class="price-ranger__preview-arrow">
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
        <path d="M4.5 6.75L9 11.25L13.5 6.75" stroke="#7F7DA6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </div>
  </div>
  <div class="price-ranger__body" v-if="isOpen">
    <div class="price-ranger__body-inputs">
      <UiInput :icon="require('@/assets/images/sneg-coin.svg')" readonly :value="`${barMinValue}`" type="text" :placeholder="$t('price_ranger.from')" />
      <UiInput :icon="require('@/assets/images/sneg-coin.svg')" readonly :value="`${barMaxValue}`" type="text" :placeholder="$t('price_ranger.to')" />
    </div>
    <div class="price-ranger__body-slider">
      <MultiRangeSlider
          baseClassName="multi-range-slider"
          :min="0"
          :max="100000"
          :step="1"
          :ruler="false"
          :label="false"
          :minValue="barMinValue"
          :maxValue="barMaxValue"
          @input="UpdateValues"
      />

      <AccentBtn :title="$t('price_ranger.apply')" @click="update()" />
    </div>
  </div>
</div>
</template>

<style scoped lang="scss">
.price-ranger {
  border-radius: 12px;
  background: #1D1B41;
  position: relative;
  z-index: 5;
  .price-ranger__preview {
    display: flex;
    align-items: center;
    padding: 15px;
    cursor: pointer;
    transition: 0.2s;
    &:hover {
      opacity: 0.8;
    }
    .price-ranger__preview-icon {
      width: 16px;
      margin-right: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .price-ranger__preview-name {
      color: #918FBB;
      font-family: "Geometria-Medium";
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-right: 6px;
      letter-spacing: -0.28px;
    }
    .price-ranger__preview-value {
      font-family: "Geometria-Medium";
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.28px;
      width: 170px;
      display: flex;
      align-items: center;
      img {
        width: 10px;
        margin: 0 5px;
      }
    }
    .price-ranger__preview-arrow {
      display: flex;
      align-items: center;
      margin-left: auto;
      justify-content: center;
      transition: 0.2s;
      &.rotate {
        transform: rotate(180deg);
      }
    }
  }
  .price-ranger__body {
    position: absolute;
    top: calc(100% + 5px);
    border-radius: 12px;
    padding: 15px;
    background: #02002A;
    .price-ranger__body-inputs {
      display: flex;
      align-items: center;
      grid-gap: 5px;
      ::v-deep {
        input {
          cursor: default;
        }
      }
    }
    .price-ranger__body-slider {
      margin-top: 10px;
      width: 100%;
      .multi-range-slider {
        padding: 0;
        margin-bottom: 20px;
        margin-top: 20px;
        border: none;
        ::v-deep {
          .bar-inner {
            background: #9D42F8;
            border: none !important;
            box-shadow: none !important;
          }
          .bar-left {
            background: #1D1B41;
            box-shadow: none;
          }
          .bar-right {
            background: #1D1B41;
            box-shadow: none;
          }
          .thumb::before {
            background: #9D42F8;
          }
          .caption {
            display: none;
          }
        }
      }
    }
  }
}
</style>