export default {
    list: [],
    section: null,
    search: null,
    categories: [],
    last_page: null,
    page: 1,
    per_page: 50,
    isLoading: false,
    providers: []
}