import ApiClient from "@/services/ApiClient";
export default function signIn (data) {
    return ApiClient.post('/auth/login', data);
}

export function register (data) {
    return ApiClient.post('/auth/registera', data);
}

export function socialAuth (provider, data) {
    return ApiClient.post(`/auth/social/${provider}/callback`, data);
}

export function web3CheckUsername (username) {
    return ApiClient.post('/auth/web3/available-username', {username});
}

export function web3Auth(data) {
    return ApiClient.post('/auth/web3/auth', data);
}

export function recoverPassword(data) {
    return ApiClient.post('/auth/recover-password', data);
}