<script>
export default {
  name: "TypeWithdraw"
}
</script>

<template>
  <div class="withdraw">
    <div class="deposit-icon">
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.0013 0.5H4.0013C2.16035 0.5 0.667969 1.99238 0.667969 3.83333C0.667969 5.23224 1.52971 6.42988 2.7513 6.92437V3.83333C2.7513 3.50181 2.883 3.18387 3.11742 2.94945C3.35184 2.71503 3.66978 2.58333 4.0013 2.58333H14.0013C14.3328 2.58333 14.6508 2.71503 14.8852 2.94945C15.1196 3.18387 15.2513 3.50181 15.2513 3.83333V6.92437C16.4729 6.42988 17.3346 5.23224 17.3346 3.83333C17.3346 1.99239 15.8423 0.5 14.0013 0.5ZM14.0009 13.8335L14.0009 3.83352H4.00092V13.8335C4.00092 14.754 4.74711 15.5002 5.66759 15.5002H12.3343C13.2547 15.5002 14.0009 14.754 14.0009 13.8335ZM8.2513 10.3563L7.86497 9.96999C7.57207 9.67709 7.0972 9.67709 6.80431 9.96999C6.51141 10.2629 6.51141 10.7378 6.80431 11.0306L7.88172 12.1081C8.50005 12.7264 9.50256 12.7264 10.1209 12.1081L11.1983 11.0306C11.4912 10.7378 11.4912 10.2629 11.1983 9.96999C10.9054 9.67709 10.4305 9.67709 10.1376 9.96999L9.7513 10.3563V7.16698C9.7513 6.75277 9.41552 6.41698 9.0013 6.41698C8.58709 6.41698 8.2513 6.75277 8.2513 7.16698V10.3563Z" fill="white"/>
      </svg>
    </div>
    <strong>{{ $t('t_withdraw') }}</strong>
  </div>
</template>

<style scoped lang="scss">
.withdraw {
  display: flex;
  align-items: center;
  .deposit-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 39px;
    height: 39px;
    border-radius: 12px;
    box-shadow: 0px -1px 1px 1px #FF5454;
    border-radius: 12px;
    background: linear-gradient(180deg, #F84242 -4.55%, #D00000 100%);

    margin-right: 12px;
  }
  strong {
    color: #B9B8DD;
    font-size: 14px;
    font-weight: 700;
    font-family: "Geometria-Bold";
    letter-spacing: -0.28px;
  }
}
</style>