<script>
import LootboxesBanner from "@/components/Lootboxes/LootboxesBanner.vue";
import LootboxShortItem from "@/components/Lootboxes/LootboxShortItem.vue";
import NftWins from "@/components/Nft/NftWins.vue";
import { getLootboxes } from "@/services/lootboxes";
import { Hooper, Slide } from 'hooper';
import 'hooper/dist/hooper.css';
import FreeLootboxes from "@/components/Lootboxes/Free/FreeLootboxes.vue";
import {mapGetters} from "vuex";
export default {
  data () {
    return {
      lootboxes: [],
      baseSettings: {
        autoPlay: false,
        playSpeed: 2000,
        transition: 300,
        rtl: false,
        hoverPause: false,
        centerMode: false,
        touchDrag: true,
        mouseDrag: true,
        wheelControl: false,
        keysControl: false,
        itemsToSlide: 1,
        infinite: true,
        trimWhiteSpace: true,
        infiniteScroll: false,
      }
    }
  },
  computed: {
    ...mapGetters({
      isAuth: 'user/isAuth'
    }),
    settings () {
      const settings = this.baseSettings;
      settings.itemsToShow = this.itemsToShow;

      return settings;
    },
    itemsToShow()
    {
      const width = window.innerWidth;

      if (width > 2100) {
        return 6;
      } else if (width > 1950) {
        return 4;
      } else if (width > 1800) {
        return 4;
      } else if (width > 1600) {
        return 4;
      } else if (width > 1400) {
        return 5;
      } else if (width > 992) {
        return 4;
      } else if (width > 768) {
        return 3;
      } else if (width > 650) {
        return 2;
      } else if (width > 500) {
        return 2;
      }
      else if (width > 200) {
        return 2;
      }

      return 9;
    }
  },
  methods: {
    prev () {
      const slider = this.$refs.slider;

      slider.slidePrev();
    },
    next () {
      const slider = this.$refs.slider;
      slider.slideNext();
    },
    fetchLootboxes() {
      getLootboxes({
        per_page: 10,
        page: 1
      }).then(response => {
        this.lootboxes = response.data.data;
      })
    },
  },
  mounted () {
    this.fetchLootboxes()
  },
  name: "LootboxesView",
  components: {FreeLootboxes, NftWins, Hooper, Slide, LootboxShortItem, LootboxesBanner}
}
</script>

<template>
  <div class="lootboxes page">
    <LootboxesBanner />

    <FreeLootboxes v-if="isAuth" />

    <div class="lootboxes-list">
      <div class="lootboxes-head">
        <div class="lootboxes-head__title">
          <div class="lootboxes-head__title-icon">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M6 2C3.79086 2 2 3.79086 2 6V18C2 20.2091 3.79086 22 6 22H18C20.2091 22 22 20.2091 22 18V6C22 3.79086 20.2091 2 18 2H6ZM12.7501 6C12.7501 5.58579 12.4143 5.25 12.0001 5.25C11.5859 5.25 11.2501 5.58579 11.2501 6V7.30888C10.1985 7.47568 9.26179 7.98761 8.55967 8.72496L7.04492 7.85042C6.6862 7.64331 6.22751 7.76622 6.0204 8.12494C5.8133 8.48366 5.9362 8.94235 6.29492 9.14946L7.70701 9.96473C7.41404 10.5816 7.25012 11.2717 7.25012 12C7.25012 12.7283 7.41403 13.4184 7.70699 14.0352L6.29492 14.8505C5.9362 15.0576 5.8133 15.5163 6.0204 15.875C6.22751 16.2337 6.6862 16.3566 7.04492 16.1495L8.55963 15.275C9.26175 16.0124 10.1985 16.5243 11.2501 16.6911V18C11.2501 18.4142 11.5859 18.75 12.0001 18.75C12.4143 18.75 12.7501 18.4142 12.7501 18V16.6911C13.8018 16.5243 14.7385 16.0124 15.4406 15.275L16.9552 16.1495C17.3139 16.3566 17.7726 16.2337 17.9797 15.8749C18.1868 15.5162 18.0639 15.0575 17.7052 14.8504L16.2932 14.0352C16.5862 13.4184 16.7501 12.7283 16.7501 12C16.7501 11.2717 16.5862 10.5816 16.2932 9.96472L17.7052 9.14952C18.0639 8.94241 18.1868 8.48372 17.9797 8.125C17.7726 7.76628 17.3139 7.64337 16.9552 7.85048L15.4406 8.72495C14.7384 7.9876 13.8017 7.47568 12.7501 7.30888V6ZM8.75012 12C8.75012 10.2051 10.2052 8.75 12.0001 8.75C13.795 8.75 15.2501 10.2051 15.2501 12C15.2501 13.7949 13.795 15.25 12.0001 15.25C10.2052 15.25 8.75012 13.7949 8.75012 12Z" fill="#FFE70C"/>
            </svg>
          </div>
          <div class="lootboxes-head__title-name">
            {{ $t('categories.lootboxes') }}
          </div>
        </div>
        <div class="lootboxes-head__pagination">
          <div class="lootboxes-head__pagination-arrows" @click="prev">
            <div class="lootboxes-head__pagination-arrow default-hover">
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.7216 14.7869C8.01665 15.0776 8.49151 15.0741 8.78223 14.7791C9.07295 14.484 9.06943 14.0092 8.77437 13.7184L4.74661 9.74989L15 9.74989C15.4142 9.74989 15.75 9.41411 15.75 8.99989C15.75 8.58568 15.4142 8.24989 15 8.24989L4.75156 8.24989L8.77437 4.28623C9.06943 3.99551 9.07295 3.52065 8.78223 3.2256C8.49151 2.93054 8.01665 2.92703 7.7216 3.21774L2.52846 8.33453C2.15577 8.70174 2.15577 9.30293 2.52846 9.67014L7.7216 14.7869Z" fill="#918FBB"/>
              </svg>
            </div>
            <div class="lootboxes-head__pagination-arrow default-hover" @click="next">
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.2784 3.21307C9.98335 2.92236 9.50849 2.92587 9.21777 3.22093C8.92705 3.51598 8.93057 3.99084 9.22563 4.28156L13.2534 8.25011H3C2.58579 8.25011 2.25 8.58589 2.25 9.00011C2.25 9.41432 2.58579 9.75011 3 9.75011H13.2484L9.22563 13.7138C8.93057 14.0045 8.92705 14.4793 9.21777 14.7744C9.50849 15.0695 9.98335 15.073 10.2784 14.7823L15.4715 9.66547C15.8442 9.29826 15.8442 8.69707 15.4715 8.32986L10.2784 3.21307Z" fill="#918FBB"/>
              </svg>
            </div>
          </div>
          <div @click="$router.push('/lootboxes/list')" class="lootboxes-head__pagination-go-to default-hover">
            {{ $t('view_all') }}
          </div>
        </div>
      </div>

      <div class="lootboxes-items">
        <hooper ref="slider" :settings="settings">
          <slide v-for="(lootbox, i) in lootboxes" :key="i">
            <LootboxShortItem :lootbox="lootbox" />
          </slide>
          <slide v-for="(lootbox, i) in lootboxes" :key="i">
            <LootboxShortItem :lootbox="lootbox" />
          </slide>
        </hooper>
      </div>
    </div>

    <NftWins/>

  </div>
</template>

<style scoped lang="scss">
  .lootboxes-list {
    padding: 30px 0;
    padding-top: 0px;
    .lootboxes-head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .lootboxes-head__title {
        display: flex;
        align-items: center;
        .lootboxes-head__title-icon {
          width: 24px;
          height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 7px;
        }
        .lootboxes-head__title-name {
          color: #7F7DA6;
          font-family: "Geometria-Bold";
          font-size: 14px;
          display: flex;
          align-items: center;
          font-weight: 700;
          letter-spacing: -0.28px;
        }
      }
      .lootboxes-head__pagination {
        display: flex;
        .lootboxes-head__pagination-arrows {
          margin-right: 8px;
          display: flex;
          border-radius: 26px;
          background: #1D1B41;
          padding: 4px;
          grid-gap: 4px;
          .lootboxes-head__pagination-arrow {
            border-radius: 31px;
            background: #2B2A4E;
            display: flex;
            align-items: center;
            padding: 6px 12px;
          }
        }
        .lootboxes-head__pagination-go-to {
          border-radius: 39px;
          background: linear-gradient(180deg, #F7FC00 -4.55%, #FFEA31 98.49%);
          padding: 12px 24px;
          color: #0C0A33;
          font-family: "Geometria-Bold";
          font-size: 14px;
          font-weight: 700;
          letter-spacing: -0.28px;
          @media (max-width: 768px) {
            font-size: 12px;
            padding: 12px 20px;
            padding-top: 13px;
          }
        }
      }
    }
    .lootboxes-items {
      width: 100%;
      ::v-deep {
        .hooper-list {
          padding-top: 15px;
          padding-bottom: 35px;
        }
        .hooper-slide {
          padding: 0 8px;
          @media (max-width: 768px) {
            padding: 0 4px;
          }
        }
      }
    }
    ::v-deep {
      .hooper {
        height: auto;
      }
    }
  }
  .lootboxes {
  }
</style>