<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
    }
  },
  computed: {
    isDisabled () {
      return this.isLoading || this.disabled
    }
  },
  methods: {
    clickEmmit () {
      if (this.isDisabled) return;
      
      this.$emit('click');
    }
  },
  name: "AccentBtn"
}
</script>

<template>
  <button type="button" :class="{'disabled': isDisabled}" :disabled="isDisabled" @click="clickEmmit" class="accent-btn default-hover">
    {{ title }}
    <slot></slot>
    <transition name="fade">
      <span class="is-loading" v-show="isLoading">
        <img src="@/assets/images/loader-3.gif" alt="">
      </span>
    </transition>
  </button>
</template>

<style scoped lang="scss">
  .accent-btn {
    border-radius: 11px;
    background: linear-gradient(180deg, #9D42F8 -4.55%, #7F00FF 100%);
    box-shadow: 0 -1px 1px 1px #BC79FF;
    width: 100%;
    padding: 16px 0;
    font-size: 14px;
    font-family: "Geometria-Bold";
    font-weight: 700;
    letter-spacing: -0.28px;
    position: relative;
    .is-loading {
      width: 40px;
      height: 40px;
      right: 15px;
      position: absolute;
      top: calc(50% - 20px);
      img {
        width: 100%;
        height: 100%;
      }
    }
    
    &.disabled {
      cursor: default !important;
      opacity: 0.6 !important;
    }
  }
</style>