<script>
import LoginState from "@/components/Auth/LoginState.vue";
import {mapGetters} from "vuex";
import UserState from "@/components/Auth/UserState.vue";
import BalanceState from "@/components/Auth/BalanceState.vue";

export default {
  computed: {
    ...mapGetters({
      isAuth: 'user/isAuth'
    })
  },
  components: {
    BalanceState,
    UserState,
    LoginState
  }
}
</script>

<template>
  <div class="authorization-state">
    <LoginState v-if="!isAuth" />
    <template v-else>
      <BalanceState/>
      <UserState/>
    </template>
  </div>
</template>

<style scoped lang="scss">
  .authorization-state {
    display: flex;
    align-items: center;
    .balance-state {
      margin-right: 8px;
    }
  }
</style>