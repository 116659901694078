<script>
import LevelItem from "@/components/Profile/LevelItem.vue";
import CurrentLevel from "@/components/Profile/CurrentLevel.vue";
import {mapGetters} from "vuex";

export default {
  data () {
    return {
      width: null,
    }
  },
  computed: {
    currentLevel () {
      if (!this.user?.level?.level) {
        return this.settings.levels[0];
      }

      if (this.settings.levels) {
        return this.settings.levels.find(item => item.level === this.user.level.level) || 1;
      }

      return null;
    },
    getWidth() {
      return this.width
    },
    firstPart ()
    {
      if (!this.settings?.levels) return [];

      const levels = this.settings.levels;

      if(!this.user?.level) {
        return [];
      }

      return levels.slice(0, this.user.level.level - 1);
    },
    secondPart () {
      if (!this.settings?.levels) return [];

      const levels = this.settings.levels;

      if (!this.user?.level) {
        return this.settings.levels.slice(1);
      }

      return levels.slice(this.user.level.level);
    },
    ...mapGetters({
      user: 'user/user',
      settings: 'settings/settings'
    })
  },
  name: "ProfileLevels",
  components: {CurrentLevel, LevelItem},
  mounted () {
    this.width = window.innerWidth

    window.addEventListener('resize', () => {
      console.log(this.width)
      this.width = window.innerWidth;
    })
  }
}
</script>

<template>
  <div class="levels" v-if="settings && settings.levels">
    <template v-if="getWidth > 992">
      <LevelItem v-for="(level, i) in firstPart" :key="i" :level="level" />

      <div class="current-level">
        <CurrentLevel/>
      </div>

      <LevelItem v-for="(level, i) in secondPart" :key="i" :level="level" />
    </template>
    <template v-else>
      <div class="mobile-levels">
        <div class="current-level-list">
          <div class="current-level-list__line">
            <div class="current-level-list__line-content" :style="{'height': ((currentLevel.level * 40) - 20) + 'px'}"></div>
          </div>
          <div class="current-level-list__items" v-if="settings && settings.levels && currentLevel">
            <div class="current-level-list__item" v-for="level in settings.levels" :key="level.level">
              <div class="completed">
                <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                  <path d="M6.66668 2L3.00001 5.66667L1.33334 4" stroke="white" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </div>
              <div :class="{'first': level.level === 1}" class="current-level-list__item-icon">
                <img :src="level.icon" alt="">
              </div>
              <div class="current-level-list__item-name" :class="{'energy': currentLevel.level === level.level, 'disabled': currentLevel.level > level.level}">
                {{ level.name }}
              </div>
              <div class="current-level-list__item-status" :class="{'done': currentLevel.level > level.level, 'energy': currentLevel.level === level.level, 'upcoming': currentLevel.level < level.level}">
                <img src="@/assets/images/profile/check.svg" v-if="currentLevel.level > level.level" alt="">
                <img src="@/assets/images/profile/energy.svg" v-else-if="currentLevel.level === level.level" alt="">
                <img src="@/assets/images/profile/up.svg" v-else alt="">
              </div>
            </div>
          </div>
        </div>
        <div class="current-level-mobile">
          <CurrentLevel />
        </div>
      </div>
    </template>
  </div>
</template>

<style scoped lang="scss">
  .levels {
    display: flex;
    grid-gap: 6px;
    border-radius: 16px;
    background: linear-gradient(268deg, rgba(111, 0, 255, 0.48) 3.5%, rgba(161, 90, 253, 0.04) 43.04%, rgba(166, 99, 253, 0.00) 46.87%), linear-gradient(91deg, rgba(111, 0, 255, 0.48) -3.16%, rgba(166, 99, 253, 0.00) 51.02%), #131139;
    padding: 6px;
    @media (max-width: 992px) {
      background: #131139;
    }
    .current-level {
      padding: 0 60px;
      flex-grow: 1;
      margin-bottom: -6px;
      @media (max-width: 1900px) {
        padding: 0 20px;
      }
    }
    .mobile-levels {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      padding-left: 5px;
      padding-top: 5px;
      padding-bottom: 5px;
      @media (max-width: 992px) {
        align-items: center;
        padding-right: 10px;
      }
      .current-level-list {
        position: relative;
        .current-level-list__line {
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          width: 4px;
          border-radius: 41px;
          overflow: hidden;
          background: rgba(255, 255, 255, 0.15);
          .current-level-list__line-content {
            background: linear-gradient(180deg, #1AA10F -4.55%, #38FF88 61.32%, #7231E8 92.52%, #7F00FF 100%);
          }
        }
        .current-level-list__item {
          display: flex;
          position: relative;
          align-items: center;
          padding-left: 22px;
          height: 40px;
          @media (max-width: 400px) {
            padding-left: 15px;
          }

          .completed {
            position: absolute;
            top: calc(50% - 7px);
            width: 14px;
            height: 14px;
            border-radius: 100%;
            background: #1AA10F;
            display: flex;
            align-items: center;
            justify-content: center;
            left: -5px;
          }
          .next {
            position: absolute;
            top: calc(50% - 7px);
            width: 14px;
            height: 14px;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            left: -5px;
            border: 4px solid #9D42F8;
            background: var(--1000, #FFF);
          }

          &:first-child {
            &::after {
              border-radius: 41px;
            }
          }
          .current-level-list__item-icon {
            width: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 9px;
            img {
              max-width: 100%;
            }
            &.first {
              @media (max-width: 768px) {
                img {
                  width: 22px;
                }
              }
            }
            @media (max-width: 400px) {
              margin-right: 4px;
            }
          }
          .current-level-list__item-name {
            font-family: "Geometria-Bold";
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            color: #fff;
            line-height: normal;
            white-space: nowrap;
            letter-spacing: -0.24px;
            opacity: 1;
            color: #fff;
            &.disabled {
              color: #918FBB;
              opacity: 0.7;
            }

            padding-top: 2px;

            @media (max-width: 400px) {
              font-size: 10px;
            }
          }
          .current-level-list__item-status {
            width: 28px;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 8px;
            border-radius: 8px;
            @media (max-width: 400px) {
              width: 26px;
              height: 20px;
            }
            &.done {
              background: linear-gradient(180deg, rgba(154, 255, 52, 0.20) 0%, rgba(118, 236, 0, 0.20) 100%);
            }
            &.energy {
              background: rgba(255, 226, 79, 0.27);
            }
            &.upcoming {
              background: rgba(171, 107, 255, 0.20);
            }
          }
        }
      }
      .current-level-mobile {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        @media (max-width: 700px) {
          padding-top: 20px;
        }
        .level {
          width: 100%;
        }
      }
    }
  }
</style>