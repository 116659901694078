<script>
import {mapActions} from "vuex";

export default {
  methods: {
    ...mapActions({
      logout: 'auth/logout'
    })
  },
  name: "LogoutBtn"
}
</script>

<template>
  <button @click="logout" class="logout-btn default-hover">
    <img src="@/assets/images/auth/logout.svg" alt="">
    {{ $t('account.log_out') }}
  </button>
</template>

<style scoped lang="scss">
  .logout-btn {
    display: flex;
    border-radius: 11px;
    background: rgba(200, 197, 255, 0.10);
    align-items: center;
    font-size: 14px;
    font-family: "Geometria-Bold";
    letter-spacing: -0.28px;
    padding: 14.5px 18px;
    white-space: nowrap;
    @media (max-width: 575px) {
      font-size: 12px;
      padding: 10px 15px;
      padding-top: 11px;
    }
    img {
      margin-right: 13px;
    }
  }
</style>