<script>
import UiInput from "@/components/Ui/UiInput.vue";
import AccentBtn from "@/components/Ui/Buttons/AccentBtn.vue";
import {mapActions} from "vuex";
import UiErrors from "@/components/Ui/UiErrors.vue";
import { markInputErrors, unmarkInputErrors } from "@/helpers/inputErrors";
export default {
  data () {
      return {
        isLoading: false,
        payload: {
          email: null,
          password: null
        },
        errors: [],
      }
  },
  name: "LoginForm",
  components: {UiErrors, AccentBtn, UiInput},
  methods: {
    ...mapActions({
      signIn: 'auth/signIn'
    }),
    async signInMethod () {
      await this.clearErrors()

      this.isLoading = true;

      try {
        await this.signIn({
          email: this.payload.email,
          password: this.payload.password,
          showErrors: this.showErrors
        })
      } catch (e) {
        console.error(e.message);
      }

      this.isLoading = false
    },
    showErrors (errors) {
      this.errors = errors
      markInputErrors(Object.keys(this.payload), errors, this.$refs);
    },
    clearErrors () {
      this.errors = []
      unmarkInputErrors(Object.keys(this.payload), this.$refs)
    }
  },
  beforeDestroy() {
    this.clearErrors()
  },
  watch: {
    payload: {
      deep: true,
      handler () {
        this.clearErrors()
      }
    }
  },
}
</script>

<template>
  <form @submit.prevent="signInMethod" autocomplete="off" class="login-form">
    <UiInput ref="email" v-model="payload.email" type="email" :required="true" :placeholder="$t('login_form.enter_email')" :icon="require('@/assets/images/auth/email-icon.svg')" />
    <UiInput ref="password" v-model="payload.password" :required="true" minlength="4" maxlength="42" type="password" :placeholder="$t('login_form.enter_password')" :icon="require('@/assets/images/auth/password-icon.svg')" />

    <transition name="fade">
      <UiErrors :errors="errors" />
    </transition>

    <AccentBtn :is-loading="isLoading" type="submit" title="Log in" />

    <p v-html="$t('login_form.agree_text')"></p>
  </form>
</template>

<style scoped lang="scss">
  .login-form {
    .ui-input + .ui-input {
      margin-top: 4px;
    }
    .accent-btn {
      margin-top: 8px;
    }
    p {
      width: 100%;
      display: block;
      margin-bottom: 0;
      margin-top: 14px;
      text-align: center;
      font-family: "Geometria-Medium";
      font-size: 12px;
      font-weight: 500;
      letter-spacing: -0.24px;
      color: #7F7DA6;
      ::v-deep {
        a {
          font-family: "Geometria-Medium";
          font-size: 12px;
          color: #23A3FF;
        }
      }
    }
  }
</style>