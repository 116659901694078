<script>
export default {
  name: "BlogButton"
}
</script>

<template>
  <router-link to="/blog" class="buy-crypto default-hover">
    <img src="@/assets/images/blog-icon-nav.svg" alt="">

    {{ $t('header.blog') }}
  </router-link>
</template>

<style scoped lang="scss">
.buy-crypto {
  display: flex !important;
  img {
    margin-right: 6px;
  }
  align-items: center;
  border-radius: 10px;
  background: #1D1B41;
  padding: 10.5px 12px !important;
  width: 100% !important;
  color: #B9B8DD;
  font-family: "Geometria-Bold";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.28px;

  margin-top: 7px;
}
</style>