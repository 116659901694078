<script>
export default {
  props: {
    showMinutes: {
      type: Boolean,
      default: true,
    },
    seconds: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      timeLeft: this.seconds,
      intervalId: null
    };
  },
  computed: {
    formattedTime() {
      const minutes = Math.floor(this.timeLeft / 60);
      const seconds = this.timeLeft % 60;
      return `${this.showMinutes ? `${minutes}m:` : ''}${seconds < 10 ? '0' : ''}${seconds}s`;
    }
  },
  mounted() {
    this.intervalId = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        clearInterval(this.intervalId);
        this.$emit('end');
      }
    }, 1000);
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },
  name: "TwoFaTimer"
};
</script>

<template>
  <div class="timer">
    {{ formattedTime }}
  </div>
</template>

<style scoped lang="scss">
  .timer {
    color: black;
  }
</style>