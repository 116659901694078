<script>
import formatNumber from "@/helpers/formatNumber";
import {mapGetters} from "vuex";

export default {
  computed: {
    ...mapGetters({
      user: 'user/user'
    }),
    formattedBalance () {
      return formatNumber(parseFloat(this.user.balance.balance))
    },
    formattedSgBalance () {
      return formatNumber(parseFloat(this.user.sg_balance))
    }
  },
  name: "MobileBalanceState"
}
</script>

<template>
<div class="mobile-balance-state">
  <div class="balance-block">
    <div class="balance-block__wallet">
      <img src="@/assets/images/coin.svg" alt="">
      <span>USD</span>
    </div>
    <div class="balance-block__value">
      <strong>{{formattedBalance.integer}}</strong>
      <span>.{{formattedBalance.decimal}}</span>
    </div>
  </div>
  <div class="balance-block">
    <div class="balance-block__wallet">
      <img src="@/assets/images/sneg-coin.svg" alt="">
      <span>SNEG</span>
    </div>
    <div class="balance-block__value">
      <strong>{{formattedSgBalance.integer}}</strong>
      <span>.{{formattedSgBalance.decimal}}</span>
    </div>
  </div>
</div>
</template>

<style scoped lang="scss">
.mobile-balance-state {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 4px;
  margin: 8px 0;
  .balance-block {
    border-radius: 12px;
    background: var(--10-purple, rgba(182, 127, 255, 0.10));
    padding: 10px 12px;
    .balance-block__wallet {
      display: flex;
      align-items: center;
      img {
        margin-right: 4px;
        width: 16px;
      }
      span {
        font-size: 12px;
        font-family: "Geometria-Bold";
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        padding-top: 2px;
        letter-spacing: -0.24px;
      }
    }
    .balance-block__value {
      margin-top: 2px;
      display: flex;
      align-items: center;
      strong {
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.28px;
        font-family: "Geometria-Bold";
      }
      span {
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        color: #918FBB;
        line-height: normal;
        letter-spacing: -0.28px;
        font-family: "Geometria-Bold";
      }
    }
  }
}
</style>