<script>
export default {
  props: {
    symbol: {
      type: String,
      default: '$'
    }
  },
  name: "CoinPlaceholder"
}
</script>

<template>
  <div class="coin-placeholder">
      {{ symbol }}
  </div>
</template>

<style scoped lang="scss">
  .coin-placeholder {
    width: 18px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url(@/assets/images/coin-placeholder.svg);
    background-size: 100% 100%;
    font-weight: bold;
    font-family: Arial, sans-serif;
    font-size: 13px;
    min-width: 20px;
    line-height: 14px;
    filter: drop-shadow(0px 1px 5px #7F00FF);
  }
</style>