<script>
import TopProfile from "@/components/Profile/TopProfile.vue";
import ProfileInformation from "@/components/Profile/ProfileInformation.vue";
import {mapGetters} from "vuex";
import ProfileSecurity from "@/components/Profile/Security/ProfileSecurity.vue";
import ProfileTransactions from "@/components/Profile/Transactions/ProfileTransactions.vue";
import ProfileNft from "@/components/Profile/Nft/ProfileNft.vue";
import ProfileGameHistories from "@/components/Profile/GameHistory/ProfileGameHistories.vue";

export default {
  name: "ProfileView",
  computed: {
    ...mapGetters({
      user: 'user/user',
      isAuth: 'user/isAuth',
      activeTab: 'config/profileTab'
    })
  },
  watch: {
    user() {
      if (!this.user) {
        this.$router.push('/')
      }
    }
  },
  mounted() {
    if (!this.isAuth) {
      this.$router.push('/')
    }
  },
  components: {ProfileGameHistories, ProfileNft, ProfileTransactions, ProfileSecurity, ProfileInformation, TopProfile}
}
</script>

<template>
  <div class="profile page">
    <TopProfile/>

    <div class="profile-content">
      <ProfileInformation v-if="activeTab === 'profile'"/>
      <ProfileSecurity v-if="activeTab === 'security'" />
      <ProfileGameHistories v-if="activeTab === 'game_history'" />
      <ProfileTransactions v-if="activeTab === 'transactions'" />
      <ProfileNft v-if="activeTab === 'nft'" />
    </div>

  </div>
</template>

<style scoped lang="scss">
  .page {
    min-height: 100vh;
    .profile-content {
      margin-top: 16px;
      padding-bottom: 40px;
      @media (max-width: 992px) {
        margin-top: 15px;
      }
    }
  }
</style>