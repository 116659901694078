<script>
import {banUser, deleteMessage, muteUser, unmuteUser} from "@/services/chat";
import extractErrors from "@/helpers/extractErrors";
import {vue} from "@/main";

export default {
  props: {
    isLast: {
      type: Boolean,
      default: false,
    },
    message: {
      type: Object,
      required: true
    }
  },
  methods: {
    open() {
      this.isOpen = !this.isOpen;
      if (this.isOpen) {
        this.$root.$emit('open-admin-actions', this.message.id);
      }
    },
    close() {
      this.isOpen = false;
    },
    banUser() {
      banUser(this.message.user.id).then(() => {
        this.$root.$emit('success', {
          title: "Success",
          text: this.$t('user_has_banned')
        })
        // eslint-disable-next-line vue/no-mutating-props
        this.message.user.is_blocked = true;
        this.isOpen = false;
        this.$root.$emit('chat.deleteUserMessages', this.message.user.id);
      }).catch(err => {
        const errors = extractErrors(err);
        if (!errors.length) {
          vue.$root.$emit('error', {
            title: 'Error',
            text: "Unknown error"
          })
        }
        errors.forEach(item => {
          vue.$root.$emit('error', {
            title: 'Error',
            text: item
          })
        })
      })
    },
    muteUser() {
      muteUser(this.message.user.id).then(() => {
        this.$root.$emit('success', {
          title: "Success",
          text: this.$t('user_has_been_muted')
        })
        // eslint-disable-next-line vue/no-mutating-props
        this.message.user.chat_muted_to = (new Date()).getSeconds();
      }).catch(err => {
        const errors = extractErrors(err);
        if (!errors.length) {
          vue.$root.$emit('error', {
            title: 'Error',
            text: "Unknown error"
          })
        }
        errors.forEach(item => {
          vue.$root.$emit('error', {
            title: 'Error',
            text: item
          })
        })
      })
    },
    unmuteUser() {
      unmuteUser(this.message.user.id).then(() => {
        this.$root.$emit('success', {
          title: "Success",
          text: this.$t('uset_unmuted')
        })
        // eslint-disable-next-line vue/no-mutating-props
        this.message.user.chat_muted_to = 0;
      }).catch(err => {
        const errors = extractErrors(err);
        if (!errors.length) {
          vue.$root.$emit('error', {
            title: 'Error',
            text: "Unknown error"
          })
        }
        errors.forEach(item => {
          vue.$root.$emit('error', {
            title: 'Error',
            text: item
          })
        })
      })
    },
    deleteMessage() {
      deleteMessage(this.message.id).then(() => {
        this.$root.$emit('success', {
          title: "Success",
          text: this.$t('message_deleted')
        })
        this.isOpen = false;
        this.$root.$emit('delete-chat-message', this.message.id);
      }).catch(err => {
        const errors = extractErrors(err);
        if (!errors.length) {
          vue.$root.$emit('error', {
            title: 'Error',
            text: "Unknown error"
          })
        }
        errors.forEach(item => {
          vue.$root.$emit('error', {
            title: 'Error',
            text: item
          })
        })
      })
    },
  },

  data () {
    return {
      isOpen: false,
    }
  },
  beforeDestroy() {
    document.removeEventListener('click', this.close)
  },
  mounted() {
    this.$root.$on('open-admin-actions', (id) => {
      if (this.message.id !== id) {
        this.isOpen = false;
      }
    })
    document.addEventListener('click', this.close);
  },
  name: "ModeratorActions"
}
</script>

<template>
  <div class="moderator-actions" :class="{'last': isLast}">
    <div class="moderator-actions__btn default-hover" @click.prevent.stop="open">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path d="M7.99996 8.66666C8.36815 8.66666 8.66663 8.36818 8.66663 7.99999C8.66663 7.63181 8.36815 7.33333 7.99996 7.33333C7.63177 7.33333 7.33329 7.63181 7.33329 7.99999C7.33329 8.36818 7.63177 8.66666 7.99996 8.66666Z" stroke="#B9B8DD" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M12.6666 8.66666C13.0348 8.66666 13.3333 8.36818 13.3333 7.99999C13.3333 7.63181 13.0348 7.33333 12.6666 7.33333C12.2984 7.33333 12 7.63181 12 7.99999C12 8.36818 12.2984 8.66666 12.6666 8.66666Z" stroke="#B9B8DD" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M3.33329 8.66666C3.70148 8.66666 3.99996 8.36818 3.99996 7.99999C3.99996 7.63181 3.70148 7.33333 3.33329 7.33333C2.9651 7.33333 2.66663 7.63181 2.66663 7.99999C2.66663 8.36818 2.9651 8.66666 3.33329 8.66666Z" stroke="#B9B8DD" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </div>
    <div class="moderator-actions__dropdown" v-if="isOpen">
      <button class="default-hover" v-if="!message.user.chat_banned" @click.prevent.stop="banUser">
        <span class="icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M2.8146 4.23534C2.5 4.68357 2.5 6.01589 2.5 8.68055V9.9928C2.5 14.6912 6.03247 16.9712 8.2488 17.9394C8.85001 18.202 9.15062 18.3333 10 18.3333C10.8494 18.3333 11.15 18.202 11.7512 17.9394C13.9675 16.9712 17.5 14.6912 17.5 9.9928V8.68055C17.5 6.01589 17.5 4.68357 17.1854 4.23534C16.8708 3.78712 15.6181 3.3583 13.1126 2.50066L12.6352 2.33727C11.3292 1.8902 10.6762 1.66667 10 1.66667C9.32384 1.66667 8.67082 1.8902 7.36477 2.33727L6.88743 2.50066C4.38194 3.3583 3.12919 3.78712 2.8146 4.23534ZM8.35861 7.47471C8.11453 7.23063 7.7188 7.23063 7.47472 7.47471C7.23065 7.71879 7.23065 8.11452 7.47472 8.35859L9.11613 9.99999L7.47474 11.6414C7.23066 11.8855 7.23066 12.2812 7.47474 12.5253C7.71882 12.7693 8.11455 12.7693 8.35863 12.5253L10 10.8839L11.6414 12.5252C11.8855 12.7693 12.2812 12.7693 12.5253 12.5252C12.7693 12.2812 12.7693 11.8854 12.5253 11.6414L10.8839 9.99999L12.5253 8.35861C12.7694 8.11453 12.7694 7.7188 12.5253 7.47473C12.2812 7.23065 11.8855 7.23065 11.6414 7.47473L10 9.11611L8.35861 7.47471Z" fill="#FF6F6F"/>
        </svg>
        </span>
        {{ $t('go_block_user') }}
      </button>


      <button @click.prevent.stop="muteUser" v-if="!message.user.chat_muted_to" class="default-hover">
        <span class="icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M11.2241 7.05806C11.4682 6.81398 11.8639 6.81398 12.108 7.05806L14.166 9.11613L16.2241 7.05808C16.4682 6.814 16.8639 6.814 17.108 7.05808C17.352 7.30216 17.352 7.69788 17.108 7.94196L15.0499 10L17.1079 12.058C17.352 12.3021 17.352 12.6978 17.1079 12.9419C16.8639 13.186 16.4681 13.186 16.2241 12.9419L14.166 10.8839L12.108 12.9419C11.8639 13.186 11.4682 13.186 11.2241 12.9419C10.98 12.6979 10.98 12.3021 11.2241 12.0581L13.2821 10L11.2241 7.94194C10.98 7.69786 10.98 7.30214 11.2241 7.05806Z" fill="#555379"/>
          <path d="M3.0675 6.80302C2.5499 7.46013 2.53411 8.22772 2.50253 9.7629C2.50087 9.84353 2.5 9.92268 2.5 10C2.5 10.0773 2.50087 10.1565 2.50253 10.2371C2.53411 11.7723 2.5499 12.5399 3.0675 13.197C3.16197 13.3169 3.30058 13.4594 3.41676 13.5561C4.05328 14.0855 4.89366 14.0855 6.57443 14.0855C7.17492 14.0855 7.47517 14.0855 7.76139 14.1629C7.82086 14.179 7.87971 14.1975 7.93779 14.2185C8.21729 14.3194 8.46799 14.493 8.96937 14.8403C10.9475 16.2105 11.9366 16.8956 12.7667 16.598C12.9259 16.5409 13.0799 16.4586 13.2175 16.357C13.8725 15.8734 13.9751 14.8045 14.071 12.7474L12.9925 13.8258C12.2603 14.5581 11.0731 14.5581 10.3409 13.8258C9.60863 13.0936 9.60863 11.9064 10.3409 11.1742L11.515 10L10.3408 8.82582C9.60861 8.09359 9.60861 6.90641 10.3408 6.17417C11.0731 5.44194 12.2603 5.44194 12.9925 6.17417L14.071 7.25265C13.9751 5.19553 13.8725 4.12657 13.2175 3.64302C13.0799 3.54143 12.9259 3.45907 12.7667 3.402C11.9366 3.10439 10.9475 3.78948 8.96937 5.15968C8.46799 5.50697 8.21729 5.68062 7.93779 5.7815C7.87971 5.80247 7.82086 5.82101 7.76139 5.8371C7.47517 5.91451 7.17493 5.91451 6.57444 5.91451C4.89366 5.91451 4.05328 5.91451 3.41676 6.44395C3.30058 6.54058 3.16197 6.68309 3.0675 6.80302Z" fill="#555379"/>
        </svg>
        </span>
        {{ $t('go_mute_user') }}
      </button>
      <button @click.prevent.stop="unmuteUser" v-else class="default-hover">
        <span class="icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M11.2241 7.05806C11.4682 6.81398 11.8639 6.81398 12.108 7.05806L14.166 9.11613L16.2241 7.05808C16.4682 6.814 16.8639 6.814 17.108 7.05808C17.352 7.30216 17.352 7.69788 17.108 7.94196L15.0499 10L17.1079 12.058C17.352 12.3021 17.352 12.6978 17.1079 12.9419C16.8639 13.186 16.4681 13.186 16.2241 12.9419L14.166 10.8839L12.108 12.9419C11.8639 13.186 11.4682 13.186 11.2241 12.9419C10.98 12.6979 10.98 12.3021 11.2241 12.0581L13.2821 10L11.2241 7.94194C10.98 7.69786 10.98 7.30214 11.2241 7.05806Z" fill="#555379"/>
          <path d="M3.0675 6.80302C2.5499 7.46013 2.53411 8.22772 2.50253 9.7629C2.50087 9.84353 2.5 9.92268 2.5 10C2.5 10.0773 2.50087 10.1565 2.50253 10.2371C2.53411 11.7723 2.5499 12.5399 3.0675 13.197C3.16197 13.3169 3.30058 13.4594 3.41676 13.5561C4.05328 14.0855 4.89366 14.0855 6.57443 14.0855C7.17492 14.0855 7.47517 14.0855 7.76139 14.1629C7.82086 14.179 7.87971 14.1975 7.93779 14.2185C8.21729 14.3194 8.46799 14.493 8.96937 14.8403C10.9475 16.2105 11.9366 16.8956 12.7667 16.598C12.9259 16.5409 13.0799 16.4586 13.2175 16.357C13.8725 15.8734 13.9751 14.8045 14.071 12.7474L12.9925 13.8258C12.2603 14.5581 11.0731 14.5581 10.3409 13.8258C9.60863 13.0936 9.60863 11.9064 10.3409 11.1742L11.515 10L10.3408 8.82582C9.60861 8.09359 9.60861 6.90641 10.3408 6.17417C11.0731 5.44194 12.2603 5.44194 12.9925 6.17417L14.071 7.25265C13.9751 5.19553 13.8725 4.12657 13.2175 3.64302C13.0799 3.54143 12.9259 3.45907 12.7667 3.402C11.9366 3.10439 10.9475 3.78948 8.96937 5.15968C8.46799 5.50697 8.21729 5.68062 7.93779 5.7815C7.87971 5.80247 7.82086 5.82101 7.76139 5.8371C7.47517 5.91451 7.17493 5.91451 6.57444 5.91451C4.89366 5.91451 4.05328 5.91451 3.41676 6.44395C3.30058 6.54058 3.16197 6.68309 3.0675 6.80302Z" fill="#555379"/>
        </svg>
        </span>
        {{ $t('go_unmute_user') }}
      </button>


      <button @click.prevent.stop="deleteMessage" class="default-hover">
        <span class="icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path d="M2.5 5.43651C2.5 5.10777 2.77226 4.84127 3.10811 4.84127H7.09823C7.10364 4.14025 7.17962 3.1792 7.87531 2.5139C8.4228 1.99032 9.1734 1.66667 9.99999 1.66667C10.8266 1.66667 11.5772 1.99032 12.1247 2.5139C12.8204 3.1792 12.8963 4.14025 12.9018 4.84127H16.8919C17.2277 4.84127 17.5 5.10777 17.5 5.43651C17.5 5.76525 17.2277 6.03175 16.8919 6.03175H3.10811C2.77226 6.03175 2.5 5.76525 2.5 5.43651Z" fill="#555379"/>
          <path d="M9.67213 18.3333H10.3279C12.5842 18.3333 13.7123 18.3333 14.4458 17.614C15.1793 16.8946 15.2543 15.7147 15.4044 13.3548L15.6206 9.95434C15.7021 8.67387 15.7428 8.03364 15.3748 7.62793C15.0069 7.22222 14.3855 7.22222 13.1427 7.22222H6.85737C5.61458 7.22222 4.99319 7.22222 4.62522 7.62793C4.25725 8.03364 4.29797 8.67387 4.3794 9.95434L4.59566 13.3548C4.74574 15.7147 4.82078 16.8946 5.55427 17.614C6.28776 18.3333 7.41589 18.3333 9.67213 18.3333Z" fill="#555379"/>
        </svg>
        </span>
        {{ $t('go_delete_message') }}
      </button>
    </div>
  </div>
</template>

<style scoped lang="scss">
    .moderator-actions {
      position: relative;
      .moderator-actions__btn {
        width: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 20px;
        border-radius: 7px;
        background: #2B2A4E;
        margin-left: 8px;
      }
      .moderator-actions__dropdown {
        position: absolute;
        top: calc(100% + 6px);
        width: 164px;
        right: 0;
        border-radius: 16px;
        z-index: 10;
        background: var(--000-background, #02002A);
        box-shadow: 0px 64px 89.7px 0px rgba(0, 0, 0, 0.25);
        padding: 6px;
        button {
          display: flex;
          padding: 12px 14px;
          white-space: nowrap;
          border-radius: 11px;
          background: var(--200, #131139);
          .icon {
            width: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
          }
          font-size: 12px;
          font-family: "Geometria-Bold";
          width: 100%;
          font-weight: 700;
          line-height: normal;
          letter-spacing: -0.24px;
          align-items: center;
        }
        button + button {
          margin-top: 6px;
        }
      }
      &.last {
        .moderator-actions__dropdown {
          top: unset;
          bottom: calc(100% + 6px);
        }
      }
    }
</style>