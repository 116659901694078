import ApiClient from "@/services/ApiClient";

export function getMe ()
{
    return ApiClient.get('/user/me');
}

export function updateMe (data)
{
    return ApiClient.patch('/user/', data);
}

export function changePassword (data)
{
    return ApiClient.patch('/user/change-password', data);
}

export function changeEmail (data)
{
    return ApiClient.patch('/user/change-email', data);
}

export function getGameHistory(data)
{
    return ApiClient.get('/user/games-history', data);
}

export function send2faSmsCode(data)
{
    return ApiClient.post('/user/2fa/send-sms', data);
}

export function send2faConfirmation(data)
{
    return ApiClient.post('/user/2fa/confirmation', data);
}

export function removeTwoFactor()
{
    return ApiClient.delete('/user/2fa/');
}

export function getNfts(data = {page: 1})
{
    return ApiClient.get('/user/nfts', data)
}

export function getNftsSales(data = {page: 1})
{
    return ApiClient.get('/user/nfts/sales', data)
}

export function sellNft (nftId, price)
{
    return ApiClient.patch('/user/nfts/' + nftId + '/sell', {
        price
    });
}

export function sellChangePrice (nftId, price)
{
    return ApiClient.patch('/user/nfts/' + nftId + '/sell/change-price', {
        price
    });
}

export function cancelNftSell (nftId)
{
    return ApiClient.patch('/user/nfts/' + nftId + '/sell/cancel');
}

export function setNftAsAvatar(nftId)
{
    return ApiClient.post('/user/nfts/' + nftId + '/make-avatar')
}

export function getCurrentDeposit()
{
    return ApiClient.get('/user/deposits/crypto/current');
}

export function deleteCurrentDeposit()
{
    return ApiClient.delete('/user/deposits/crypto/current');
}

export function createCryptoDeposit(data)
{
    return ApiClient.post('/user/deposits/crypto', data);
}

export function createFiatDeposit(data) {
    return ApiClient.post('/user/deposits/fiat', data);
}

export function getTransactions(data) {
    return ApiClient.get('/user/transactions', data);
}

export function exchangeBalance(data) {
    return ApiClient.post('/user/exchange', data);
}

export function withdraw(data) {
    return ApiClient.post('/user/withdraw', data);
}

export function seenWelcome() {
    return ApiClient.patch('/user/seen-welcome');
}

export function sendVerificationEmail()
{
    return ApiClient.post('/auth/send-verification-email');
}