<script>
import NftWinItem from "@/components/Nft/NftWinItem.vue";
import NftHeadItem from "@/components/Nft/NftHeadItem.vue";
import { getHistory } from "@/services/lootboxes";
import {mapGetters} from "vuex";
import UiLoader from "@/components/UiLoader.vue";

export default {
  data () {
    return {
      isLoading: false,
      type: 'all',
      histories: []
    }
  },
  computed: {
    ...mapGetters({
      isAuth: 'user/isAuth',
      user: 'user/user'
    })
  },
  watch: {
    type() {
      this.fetchHistory()
    }
  },
  methods: {
    subscribeToNewItems ()
    {
      this.$echo.channel(`lootbox-open`).listen(".OpenLootboxEvent", async (payload) => {
        setTimeout(() => {
          if (this.type === 'all') {
            this.histories.unshift(payload)
            this.histories.pop();
          } else if (this.type === 'my') {
            if (payload.user.id === this.user.id) {
              this.histories.unshift(payload)
              this.histories.pop();
            }
          }
          else if (this.type === 'nft') {
            if (payload.prize.type === 'nft') {
              this.histories.unshift(payload)
              this.histories.pop();
            }
          }
        }, 1000 * 10)
      })
    },
    async fetchHistory()
    {
      this.isLoading = true;
      await getHistory({
        type: this.type,
      }).then(response => {
        this.histories = response.data.data;
      }).finally(() => {
        setTimeout(() => {
          this.isLoading = false;
        }, 500);
      })
    }
  },
  mounted() {
    this.fetchHistory();
    this.subscribeToNewItems();
  },
  name: "NftWins",
  components: {UiLoader, NftHeadItem, NftWinItem}
}
</script>

<template>
  <div class="nft-wins">
    <div class="nft-head">
      <div class="nft-head__title">
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M3.75 1.5H14.25C15.0784 1.5 15.75 2.17157 15.75 3V14.3658C15.75 15.4047 14.7194 16.129 13.7419 15.7771L12.5706 15.3554C12.2046 15.2237 11.8017 15.2392 11.447 15.3989L9.61555 16.223C9.22409 16.3992 8.77591 16.3992 8.38445 16.223L6.55303 15.3989C6.19829 15.2392 5.79541 15.2237 5.4294 15.3554L4.25808 15.7771C3.28059 16.129 2.25 15.4047 2.25 14.3658V3C2.25 2.17157 2.92157 1.5 3.75 1.5ZM6 4.6875C5.68934 4.6875 5.4375 4.93934 5.4375 5.25C5.4375 5.56066 5.68934 5.8125 6 5.8125H9C9.31066 5.8125 9.5625 5.56066 9.5625 5.25C9.5625 4.93934 9.31066 4.6875 9 4.6875H6ZM6 7.6875C5.68934 7.6875 5.4375 7.93934 5.4375 8.25C5.4375 8.56066 5.68934 8.8125 6 8.8125H12C12.3107 8.8125 12.5625 8.56066 12.5625 8.25C12.5625 7.93934 12.3107 7.6875 12 7.6875H6ZM6 10.6875C5.68934 10.6875 5.4375 10.9393 5.4375 11.25C5.4375 11.5607 5.68934 11.8125 6 11.8125H12C12.3107 11.8125 12.5625 11.5607 12.5625 11.25C12.5625 10.9393 12.3107 10.6875 12 10.6875H6Z" fill="#7F7DA6"/>
        </svg>
        <span>{{ $t('n_wins_title') }}</span>
      </div>
      <div class="nft-head__tabs">
        <div class="nft-head__tab default-hover" :class="{'active': type === 'all'}" @click="type = 'all'">{{ $t('n_all_spins') }}</div>
        <div class="nft-head__tab default-hover" v-if="isAuth" :class="{'active': type === 'my'}" @click="type = 'my'">{{ $t('n_my_spins') }}</div>
        <div class="nft-head__tab default-hover" :class="{'active': type === 'nft'}" @click="type = 'nft'">{{ $t('n_wins') }}</div>
      </div>
    </div>
    <NftHeadItem/>
    <div class="nft-items" :class="{'blur': isLoading}">
        <UiLoader v-if="isLoading" :fill="true" />
        <NftWinItem v-for="(history,i) in histories" :key="i" :nft="history" />
    </div>
  </div>
</template>

<style scoped lang="scss">
  .nft-wins {
    display: flex;
    flex-direction: column;
    padding-bottom: 24px;
    position: relative;
    .nft-head {
      margin-bottom: 7px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .nft-head__title {
        display: flex;
        align-items: center;
        span {
          margin-left: 6px;
          display: flex;
          align-items: center;
          color: #7F7DA6;
          font-family: "Geometria-Bold";
          font-size: 14px;
          font-weight: 700;
          letter-spacing: -0.28px;
          line-height: 14px;
        }
      }
      .nft-head__tabs {
        padding: 4px;
        display: flex;
        grid-gap: 4px;
        background: #1D1B41;
        border-radius: 26px;
        .nft-head__tab {
          padding: 6px 12px;
          border-radius: 31px;
          color: #7F7DA6;
          font-family: "Geometria-Medium";
          font-size: 14px;
          font-weight: 500;
          letter-spacing: -0.28px;
          &.active {
            background: #FFE70C;
            color: #0C0A33;
          }
        }
      }
      @media (max-width: 575px) {
        display: block;
        .nft-head__title {
          width: 100%;
          margin-bottom: 10px;
        }
        .nft-head__tabs {
          .nft-head__tab {
            flex: 1;
            text-align: center;
          }
        }
      }
    }
    .nft-items {
      flex-grow: 1;
      overflow-y: auto;
      max-height: 500px;
      transition: 0.2s;
      &.blur {
        filter: blur(5px);
      }
      &::-webkit-scrollbar {
        display: none;
      }
      .nft-win + .nft-win {
        margin-top: 4px;
      }
    }

    &::after {
      height: 120px;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      content: "";
      display: block;
      background: linear-gradient(360deg, #02002A 0%, rgba(2, 0, 42, 0.00) 100%);
    }
  }
</style>