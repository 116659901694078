<script>
import UiClose from "@/components/Ui/UiClose.vue";
import AccentBtn from "@/components/Ui/Buttons/AccentBtn.vue";
import {mapMutations} from "vuex";

export default {
  name: "ChatRulesModal",
  methods: {
    ...mapMutations({
      setIsShowChatRules: 'config/setIsShowChatRules'
    }),
    close() {
      this.setIsShowChatRules(false)
    }
  },
  components: {AccentBtn, UiClose}
}
</script>

<template>
  <div class="chat-rules-modal">
    <h2>Rules</h2>
    <UiClose @click="setIsShowChatRules(false)" />

    <ul>
      <li>Do not harass or insult other users</li>
      <li>Do not beg, ask for loans, trivias or tips</li>
      <li>Do not insinuate Rollbit has bad intent ("scam site" etc)</li>
      <li>Do not spam or post non-Rollbit links</li>
      <li>Do not advertise any form of trading, buying or selling services</li>
      <li>Do not share or advertise your referral code</li>
      <li>Do not ask to become a staff member</li>
      <li>English only</li>
      <li>Respect mods, admins and other users</li>
      <li>Enter "/" to see available chat commands</li>
    </ul>

    <AccentBtn @click="setIsShowChatRules(false)" title="Agree and continue" />
  </div>
</template>

<style scoped lang="scss">
.chat-rules-modal {
  border-radius: 20px;
  background: var(--200, #131139);
  box-shadow: 0px 105px 153.7px 0px rgba(1, 0, 25, 0.55);
  padding: 16px;
  width: 320px;
  max-width: 100%;
  position: relative;
  padding-right: 30px;
  h2 {
    font-family: Unbounded;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: block;
    letter-spacing: -0.32px;
    margin-bottom: 15px;
  }
  ul {
    padding-left: 16px;
    margin-bottom: 12px;
    li {
      color: var(--700, #7F7DA6);
      font-family: "Geometria-Medium";
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.28px;
    }
  }
}
</style>