<script>
import LastWins from "@/components/Parts/Chat/Commands/LastWins.vue";
import {mapGetters} from "vuex";

export default {
  components: {LastWins},
  data () {
    return {
      selectCommand: null,
    }
  },
  computed: {
    ...mapGetters({
      isOpenCommandsList: 'chat/isOpenCommandsList',
    })
  },
  watch: {
    isOpenCommandsList() {
      if (!this.isOpenCommandsList) {
        setTimeout(() => {
          this.selectCommand = null
        }, 500)
      }
    }
  },
  name: "ChatCommands"
}
</script>

<template>
  <div class="chat-commands">
    <div class="commands-list" v-if="!selectCommand">
      <div @click="selectCommand = 'last-wins'" class="command-item">
        <div class="command-item_icon">
          <img src="@/assets/images/chat/money-icon.svg" alt="">
        </div>
        <div class="command-item_text">
          /win
        </div>
        <div class="command-item_desc">
          {{ $t('my_win_in_game') }}
        </div>
      </div>
    </div>

    <div class="select-command">
      <LastWins @close="selectCommand = null" v-if="selectCommand === 'last-wins'" />
    </div>

  </div>
</template>

<style scoped lang="scss">
  .chat-commands {
    border-radius: 10px;
    background: #1D1B41;
    z-index: 4;
    width: 100%;
    padding: 8px;
    .commands-list {
      .command-item {
        display: flex;
        padding: 7px;
        align-items: center;
        .command-item_icon {
          width: 18px;
          height: 18px;
          img {
            width: 100%;
            height: 100%;
          }

          margin-right: 8px;
        }
        .command-item_text {
          font-size: 14px;
          font-family: "Geometria-Bold";
          font-weight: 700;
          line-height: normal;
          letter-spacing: -0.28px;
        }
        .command-item_desc {
          margin-left: 8px;
          color: #B9B8DD;
          font-family: "Geometria-Medium";
          font-size: 14px;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.28px;
        }
        border-radius: 10px;
        transition: 0.2s;
        cursor: pointer;
        &:hover {
          background: #131139;
        }
      }
    }
  }
</style>