<script>
import ComingSoon from "@/components/Sidebar/ComingSoon.vue";
import CategoriesList from "@/components/Sidebar/CategoriesList.vue";
import SoonFeatures from "@/components/Sidebar/SoonFeatures.vue";
import OtherNav from "@/components/Sidebar/OtherNav.vue";
import SideBottom from "@/components/Sidebar/SideBottom.vue";
import {mapGetters, mapMutations} from "vuex";
import BuySnegCoin from "@/components/Ui/BuySnegCoin.vue";

export default {
  data() {
    return {
      counter: 0,
    }
  },
  computed: {
    ...mapGetters({
      isOpenSidebar: 'config/isOpenSidebar'
    })
  },
  watch: {
    isOpenSidebar() {
      const body = document.querySelector('body');
      if (window.innerWidth < 992) {
        if (this.isOpenSidebar) {
          body.style.height = `100dvh`;
          body.style.top = '0';
          body.style.left = '0';
          body.style.right = '0';
          body.style.bottom = '0';
          body.style.overflow = 'hidden';
        } else {
          body.style.top = 'unset';
          body.style.left = 'unset';
          body.style.right = 'unset';
          body.style.bottom = 'unset';
          body.style.overflow = 'unset';
        }
      }
    }
  },
  methods: {
    ...mapMutations({
      setIsOpenSidebar: 'config/setIsOpenSidebar'
    }),
    close(event) {
      const body = this.$refs.body;

      if (!body.contains(event.target) && window.innerWidth < 992) {
        this.counter++;
        if (this.counter === 2) {
          this.counter = 0;
          this.setIsOpenSidebar(false)
        }
      }
    }
  },
  mounted() {
    document.addEventListener('click', this.close)
  },
  beforeDestroy() {
    document.removeEventListener('click', this.close)
  },
  name: "LeftSidebar",
  components: {BuySnegCoin, SideBottom, OtherNav, SoonFeatures, CategoriesList, ComingSoon}
}
</script>

<template>
  <div class="sidebar" ref="body" :class="{'open': isOpenSidebar}">
    <div class="mobile-closer">
      <div class="mobile-closer-title">
        <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M1.16665 0.0833435C0.568338 0.0833435 0.083313 0.568368 0.083313 1.16668V3.33334C0.083313 3.93165 0.568338 4.41668 1.16665 4.41668H3.33331C3.93162 4.41668 4.41665 3.93165 4.41665 3.33334V1.16668C4.41665 0.568368 3.93162 0.0833435 3.33331 0.0833435H1.16665ZM8.74998 4.41668C9.9466 4.41668 10.9166 3.44663 10.9166 2.25001C10.9166 1.05339 9.9466 0.0833435 8.74998 0.0833435C7.55336 0.0833435 6.58331 1.05339 6.58331 2.25001C6.58331 3.44663 7.55336 4.41668 8.74998 4.41668ZM4.41665 8.75001C4.41665 9.94663 3.4466 10.9167 2.24998 10.9167C1.05336 10.9167 0.083313 9.94663 0.083313 8.75001C0.083313 7.55339 1.05336 6.58334 2.24998 6.58334C3.4466 6.58334 4.41665 7.55339 4.41665 8.75001ZM7.66665 6.58334C7.06834 6.58334 6.58331 7.06837 6.58331 7.66668V9.83334C6.58331 10.4317 7.06834 10.9167 7.66665 10.9167H9.83331C10.4316 10.9167 10.9166 10.4317 10.9166 9.83334V7.66668C10.9166 7.06837 10.4316 6.58334 9.83331 6.58334H7.66665Z" fill="#918FBB"/>
        </svg>
        {{ $t('sidebar_menu') }}
      </div>
      <div class="mobile-closer-btn" @click="setIsOpenSidebar(false)">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path d="M11.3333 4.66666L4.66666 11.3333M4.66666 4.66666L11.3333 11.3333" stroke="#918FBB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        {{ $t('sidebar_close') }}
      </div>
    </div>
    <div class="coin">
      <BuySnegCoin />
    </div>
    <ComingSoon/>
    <CategoriesList/>
    <SoonFeatures/>
    <OtherNav/>
    <SideBottom/>
  </div>
</template>

<style scoped lang="scss">
.sidebar {
  display: flex;
  flex-direction: column;
  width: 264px;
  min-width: 264px;
  position: sticky;
  top: 65px;
  left: 0;
  padding: 0 6px;
  padding-top: 15px;
  height: calc(100vh - 65px);
  z-index: 9;
  border-radius: 15px;
  background: #0C0A33;
  .mobile-closer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 4px;
    margin-bottom: 6px;
    display: none;
    @media (max-width: 992px) {
      display: flex;
    }
    .mobile-closer-title {
      color: var(--700, #7F7DA6);
      font-family: "Geometria-Bold";
      font-size: 12px;
      display: flex;
      align-items: center;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.24px;
      svg {
        margin-right: 6px;
        margin-bottom: 2px;
      }
    }
    .mobile-closer-btn {
      padding: 8px 10px;
      border-radius: 40px;
      background: var(--200, #131139);
      display: flex;
      align-items: center;
      svg {
        margin-right: 5px;
      }
      color: var(--700, #7F7DA6);
      font-family: "Geometria-Bold";
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.24px;
    }
  }
  .side-bottom {
    margin-top: 10px;
  }
  .features {
    margin-top: auto;
  }

  .coin {
    padding-left: 4px;
    margin-bottom: 6px;
    overflow: hidden;
    max-width: 100%;
    display: flex;
    @media (max-height: 655px) {
      min-height: 56px;
    }
  }
  @media (min-width: 992px) {
    .coin {
      display: none;
    }
  }

  @media (max-width: 1200px) {
    position: fixed;
    left: 0;
    border-radius: 0;
    transform: translateX(-264px);
    transition: 0.3s;
    &.open {
      transform: translateX(0);
      box-shadow: -4px -56px 103px 44px #000;
    }
  }

  @media (max-width: 992px) {
    right: 0;
    left: unset;
    padding-top: 0px;
    transform: translateX(264px);
    &.open {
      transform: translateX(0);
    }

    .features {
      display: none;
    }
    .other-nav {
      display: none;
    }
    .categories {
      flex-grow: 1;
    }
    overflow: hidden;
    height: calc(100vh - 137px);
    background: #02002A;
  }

  @media (max-width: 992px) {
    .category-switcher {
      display: none;
    }
    .buy-crypto {
      display: none;
    }
  }
}
</style>