<script>
export default {
  props: {
    game: {
      type: Object,
      required: true
    }
  },
  name: "SlideGameItem"
}
</script>

<template>
  <router-link :to="`/game/${game.id}`" class="game">
    <img :src="game ? game.preview_image : require('@/assets/images/main/casino-1.png')" alt="">
    <div class="hover-holder">
      <div class="hover-holder__circle">
        <img src="@/assets/images/play.svg" alt="">
      </div>
      <div class="hover-holder__text">
        <strong>{{ game.name }}</strong>
        <span>
          {{ game.provider }}
        </span>
      </div>
    </div>
  </router-link>
</template>

<style scoped lang="scss">
.game {
  display: block;
  border-radius: 15px;
  overflow: hidden;
  position: relative;
  background: linear-gradient(0deg, #763FB0 0%, rgba(118, 63, 176, 0.00) 76.51%), #2B2A4E;
  padding-bottom: 140%;
  & > img {
    width: 100%;
    user-select: none;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
  }
  .hover-holder {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(180deg, rgba(157, 66, 248, 0.63) -4.55%, rgba(127, 0, 255, 0.63) 100%);
    backdrop-filter: blur(2px);
    opacity: 0;
    .hover-holder__text {
      position: absolute;
      bottom: 16px;
      left: 16px;

      max-width: calc(100% - 20px);
      @media (max-width: 768px) {
        left: 8px;
        bottom: 8px;
        max-width: calc(100% - 8px);
        padding-right: 5px;
      }
      strong,span {
        display: block;
      }
      strong {
        color: #FFF;
        font-family: Unbounded;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 19px; /* 105.556% */
        letter-spacing: -0.36px;
        margin-bottom: 5px;
        @media (max-width: 768px) {
          font-size: 14px;
        }
      }
      span {
        font-family: "Geometria-Bold";
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        opacity: 0.6;
        text-transform: uppercase;
        line-height: normal;
        letter-spacing: -0.24px;

      }
    }
    .hover-holder__circle {
      width: 70px;
      height: 70px;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;

      top: calc(40% - 35px);
      left: calc(50% - 35px);
      @media (max-width: 768px) {
        width: 40px;
        height: 40px;
        top: calc(40% - 20px);
        left: calc(50% - 20px);
      }
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset, 0px 2px 0px 0px #5100A2, 0px 4px 14.3px 0px rgba(0, 0, 0, 0.25), 0px 17px 23.8px 0px rgba(135, 18, 252, 0.34);
      img {
        width: 100%;
        height: 100%;
      }
    }
    transition: 0.2s;
  }
  &:hover {
    .hover-holder {
      opacity: 1;
    }
  }
}
</style>