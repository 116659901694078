<script>
import RankIcon from "@/components/Ui/RankIcon.vue";

export default {
  props: {
    nft: {
      type: Object
    }
  },
  name: "NftWinItem",
  components: {RankIcon}
}
</script>

<template>
  <div class="nft-win">
    <div class="lootbox">
      <div class="lootbox-coin">
        <img src="../../assets/images/sneg-coin-square1.svg" alt="">
      </div>
      <div class="lootbox-image">
        <img :src="nft.lootbox.image" alt="">
      </div>
      <div class="lootbox-name">
        <template v-if="nft.prize.type === 'nft'">
          {{ nft.prize.nft.name }}
        </template>
        <template v-else-if="nft.prize.type === 'coins'">
          <img src="@/assets/images/sneg-coin.svg" alt="">
          {{ nft.prize.sg_coins }}
        </template>
      </div>
    </div>
    <div class="user">
      <div class="user-avatar">
        <img :src="nft.user.avatar" alt="">
      </div>
      <div class="user-name">@{{nft.user.username}}</div>
    </div>
    <div class="time">
      <img src="@/assets/images/clock-icon.svg" alt="">
      <span>{{ nft.created_at }}</span>
    </div>
    <div class="price">
      <img src="@/assets/images/sneg-coin.svg" alt="">
      <span v-if="nft.price > 0">{{ parseFloat(nft.price).toFixed(2) }}</span>
      <span v-else>FREE</span>
    </div>
    <div class="chance">
      <div class="chance-block">
        %
      </div>
      <div class="chance-value">
        {{ parseFloat(nft.chance).toFixed(2) }}
      </div>
    </div>
    <div class="rank">
      <div class="rank-insert">
        <RankIcon v-if="nft.prize.type === 'nft'" :rank="nft.prize.nft.level" />
        <template v-else>-</template>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .nft-win {
    border-radius: 15px;
    background: #0C0A33;
    padding: 7px;
    display: flex;
    align-items: center;
    .lootbox {
      width: 20%;
      display: flex;
      align-items: center;
      .lootbox-coin {
        width: 38px;
        height: 38px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 10px;
          border: 2.082px solid #131139;
        }
      }
      .lootbox-image {
        border-radius: 10px;
        margin-left: -10px;
        border: 4px solid  #131139;
        width: 36px;
        height: 36px;
        img {
          width: 100%;
          height: 100%;
        }
        overflow: hidden;
        box-sizing: content-box;
      }
      .lootbox-name {
        margin-left: 12px;
        color: #B9B8DD;
        font-family: "Geometria-Bold";
        font-size: 14px;
        font-weight: 700;
        letter-spacing: -0.28px;
        display: flex;
        align-items: center;
        img {
          margin-right: 10px;
          width: 15px;
        }
      }

      @media (max-width: 768px) {
        display: none;
      }
    }
    .user {
      width: 20%;
      display: flex;
      align-items: center;
      @media (max-width: 768px) {
        width: 40%;
        overflow: hidden;
        padding-right: 10px;
      }
      .user-avatar {
        min-width: 18px;
        width: 18px;
        height: 18px;
        border-radius: 100%;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .user-name {
        flex-grow: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-left: 5px;
        color: #918FBB;
        font-family: "Geometria-Bold";
        font-size: 14px;
        font-weight: 500;
        letter-spacing: -0.28px;
      }
    }
    .time {
      width: 15%;
      display: flex;
      align-items: center;
      @media (max-width: 768px) {
        display: none;
      }
      span {
        margin-left: 4px;
        color: #918FBB;
        font-family: "Geometria-Bold";
        font-size: 14px;
        font-weight: 500;
        letter-spacing: -0.28px;
      }
    }
    .price {
      width: 25%;
      display: flex;
      align-items: center;
      @media (max-width: 768px) {
        width: 40%;
      }
      img {
        width: 18px;
        height: 18px;
      }
      span {
        margin-left: 6px;
        font-size: 14px;
        display: flex;
        align-items: center;
        font-weight: 500;
        font-family: "Geometria-Bold";
        letter-spacing: -0.28px;
      }
    }
    .chance {
      width: 10%;
      display: flex;
      align-items: center;
      @media (max-width: 768px) {
        width: 25%;
        justify-content: flex-end;
      }
      .chance-block {
        border-radius: 7px;
        background: linear-gradient(180deg, #0582E9 0%, #06F 100%);
        padding: 2px 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: "Geometria-Medium";
        font-size: 12px;
        margin-right: 4px;
        font-weight: 500;
        line-height: 14px;
        letter-spacing: -0.24px;
      }
      .chance-value {
        font-family: "Geometria-Medium";
        font-size: 14px;
        font-weight: 500;
        display: flex;
        align-items: center;
        letter-spacing: -0.28px;
      }
    }
    .rank {
      display: flex;
      padding-right: 15px;
      justify-content: flex-end;
      width: 10%;
      @media (max-width: 768px) {
        display: none;
      }
      .rank-insert {
        width: 25px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
</style>