<script>
import SignUp from "@/components/Auth/Buttons/SignUp.vue";
import {mapActions} from "vuex";

export default {
  methods: {
    ...mapActions({
      openLogin: 'config/openLogin',
    })
  },
  components: {
    SignUp
  }
}
</script>

<template>
  <div class="login-state">
    <button class="default-hover login" @click="openLogin">{{ $t('sign_in') }}</button>
    <SignUp />
  </div>
</template>

<style scoped lang="scss">
  .login-state {
    display: flex;
    .login {
      border-radius: 11px;
      border: 1px solid rgba(113, 47, 180, 0.42);
      background: linear-gradient(180deg, #28105F -4.55%, #1B0C4B 100%);
      margin-right: 7px;
    }
    button {
      white-space: nowrap;
      padding: 12px 24px;
      font-size: 14px;
      font-family: 'Geometria-Bold';
      letter-spacing: -0.28px;
      @media (max-width: 450px) {
        text-overflow: ellipsis;
        overflow: hidden;
      }
      @media (max-width: 768px) {
        font-size: 13px;
        padding: 9px 14px;
        padding-top: 10px;
      }
    }
  }
</style>