<script>
import UiClose from "@/components/Ui/UiClose.vue";
import AccentBtn from "@/components/Ui/Buttons/AccentBtn.vue";
import LinkedButton from "@/components/Ui/Buttons/LinkedButton.vue";
import {mapMutations} from "vuex";
import { deleteCurrentDeposit } from "@/services/user";
import extractErrors from "@/helpers/extractErrors";

export default {
  data () {
    return {
      isLoading: false,
    }
  },
  methods: {
    close() {
      this.setIsOpenCancelDepositModal(false)
    },
    ...mapMutations({
      setIsOpenCancelDepositModal: 'config/setIsOpenCancelDepositModal'
    }),
    confirm () {
      this.isLoading = true;
      try {
        deleteCurrentDeposit().then(() => {
          this.$root.$emit('success', {
            title: "Success",
            text: this.$t('d_cancelled')
          })
          this.$root.$emit('refresh-deposit');
        }).catch(err => {
          extractErrors(err).forEach(item => {
            this.$root.$emit('error', {
              title: "Error",
              text: item,
            })
          })
        })
      } catch(e) {
        console.log(e)
      } finally {
        this.isLoading = false;
        this.setIsOpenCancelDepositModal(null);
      }
    }
  },
  name: "CancelDepositModal",
  components: {LinkedButton, AccentBtn, UiClose}
}
</script>

<template>
  <div class="confirmation">
    <UiClose @click="setIsOpenCancelDepositModal(null)" />
    <h2>{{ $t('you_are_sure') }}</h2>
    <p>{{ $t('d_cancel_sure') }}</p>

    <div class="buttons">
      <LinkedButton :disabled="isLoading" @click="setIsOpenCancelDepositModal(null)" :title="$t('cancel_action')" />
      <AccentBtn @click="confirm" :is-loading="isLoading" :title="$t('confirm_action')" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.confirmation {
  border-radius: 20px;
  background: #131139;
  position: relative;
  box-shadow: 0px 105px 153.7px 0px rgba(1, 0, 25, 0.55);
  padding: 20px;
  h2 {
    font-size: 24px;
    font-family: "Geometria-Bold";
    margin-bottom: 15px;
  }
  p {
    font-size: 14px;
    font-family: "Geometria-Medium";
    margin-bottom: 20px;
  }
  .buttons {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 12px;
  }
}
</style>