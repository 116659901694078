<script>
import BonusPrizes from "@/components/Parts/BonusPrizes.vue";
import {mapGetters} from "vuex";

export default {
  name: "RegisterBanner",
  computed: {
    ...mapGetters({
      currentLanguage: 'settings/currentLanguage'
    })
  },
  components: {BonusPrizes}
}
</script>

<template>
  <div class="register-banner">
    <div class="logo">
      <img src="@/assets/images/white-logo.svg" alt="">
    </div>
    <h1 :class="{'small': currentLanguage === 'ru' || currentLanguage === 'de'}">{{ $t('register_form.banner_heading') }}</h1>
    <div class="prizes">
      <BonusPrizes/>
    </div>
  </div>
</template>

<style scoped lang="scss">
.register-banner {
  height: 100%;
  width: 100%;
  background: url(@/assets/images/auth/register-banner.png), linear-gradient(180deg, #9C21FC 0%, #4200CD 100%);
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: center bottom;
  padding-top: 19px;
  border-radius: 20px;
  box-shadow: 0px 105px 153.7px 0px rgba(1, 0, 25, 0.55);
  @media (max-width: 768px) {
    background: url(@/assets/images/register-banner-mobile.png), linear-gradient(180deg, #9C21FC 0%, #4200CD 100%);
    background-size: contain;
    background-position: right center;
    background-repeat: no-repeat;
    padding: 18px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .logo {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    @media (max-width: 768px) {
      display: none;
    }
  }
  .prizes {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 14px;
    @media (max-width: 768px) {
      margin-top: 8px;
      justify-content: flex-start;
    }
  }
  h1 {
    width: 100%;
    font-family: "Unbounded";
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    text-align: center;
    line-height: 34px; /* 106.25% */
    letter-spacing: -0.64px;
    width: 250px;
    margin: 0 auto;
    &.small {
      font-size: 20px;
      line-height: 25px;
    }
    @media (max-width: 768px) {
      font-size: 18px;
      text-align: left;
      width: 100%;
      line-height: 20px;
      max-width: 80%;
      margin-left: 0;
    }
  }
}
</style>