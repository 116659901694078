<script>
import LootboxShortItem from "@/components/Lootboxes/LootboxShortItem.vue";
import {Hooper, Slide} from "hooper";
import { getFreeLootboxes } from "@/services/lootboxes";
import EmptyState from "@/components/EmptyState.vue";
import UiLoader from "@/components/UiLoader.vue";

export default {
  data () {
    return {
      isLoading: false,
      lootboxes: [],
      baseSettings: {
        autoPlay: false,
        playSpeed: 2000,
        transition: 300,
        rtl: false,
        hoverPause: false,
        centerMode: false,
        touchDrag: true,
        mouseDrag: true,
        wheelControl: false,
        keysControl: false,
        trimWhiteSpace: true,
        itemsToSlide: 1,
        infinite: true,
        infiniteScroll: false,
      }
    }
  },
  name: "FreeLootboxes",
  components: {UiLoader, EmptyState, Slide, Hooper, LootboxShortItem},
  mounted() {
    this.fetchItems()
    this.$root.$on('reload-free-lootboxes', this.fetchItems)
  },
  methods: {
    prev () {
      const slider = this.$refs.slider;

      slider.slidePrev();
    },
    next () {
      const slider = this.$refs.slider;
      slider.slideNext();
    },
    fetchItems() {
      this.isLoading = true;
      getFreeLootboxes().then(response => {
        this.lootboxes = response.data.data;
      }).finally(() => {
        setTimeout(() => {
          this.isLoading = false;
        }, 500)
      })
    }
  },
  computed: {
    settings () {
      const settings = this.baseSettings;
      settings.itemsToShow = this.itemsToShow;

      return settings;
    },
    itemsToShow()
    {
      const width = window.innerWidth;

      if (width > 2100) {
        return 6;
      } else if (width > 1950) {
        return 4;
      } else if (width > 1800) {
        return 4;
      } else if (width > 1600) {
        return 4;
      } else if (width > 1400) {
        return 5;
      } else if (width > 992) {
        return 4;
      } else if (width > 768) {
        return 3;
      } else if (width > 650) {
        return 2;
      } else if (width > 500) {
        return 2;
      }
      else if (width > 200) {
        return 2;
      }

      return 9;
    }
  },
}
</script>

<template>
  <div class="free-lootboxes">
    <div class="lootboxes-head">
      <div class="lootboxes-head__title">
        <div class="lootboxes-head__title-icon">
          <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.75 0.5H5C2.5175 0.5 0.5 2.5175 0.5 5V6.5H5.75V0.5Z" fill="#72EEFF"/>
            <path d="M10.415 4.535C10.7 4.175 11.3 4.175 11.585 4.535L13.16 6.5H14.75V0.5H7.25V6.5H8.84L10.415 4.535Z" fill="#72EEFF"/>
            <path d="M17 0.5H16.25V6.5H21.5V5C21.5 2.5175 19.4825 0.5 17 0.5Z" fill="#72EEFF"/>
            <path d="M13.16 8L11.585 9.965C11.4425 10.145 11.225 10.25 11 10.25C10.775 10.25 10.5575 10.145 10.415 9.965L8.84 8H0.5V14C0.5 14.825 1.175 15.5 2 15.5H20C20.825 15.5 21.5 14.825 21.5 14V8H13.16Z" fill="#72EEFF"/>
          </svg>

        </div>
        <div class="lootboxes-head__title-name">
          My free lootboxes
        </div>
      </div>
      <div class="lootboxes-head__danger">
        <img src="@/assets/images/danger.svg" alt="">
        Free lootboxes expires in one month
      </div>
      <div class="lootboxes-head__pagination" v-if="lootboxes.length">
        <div class="lootboxes-head__pagination-arrows" @click="prev">
          <div class="lootboxes-head__pagination-arrow default-hover">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.7216 14.7869C8.01665 15.0776 8.49151 15.0741 8.78223 14.7791C9.07295 14.484 9.06943 14.0092 8.77437 13.7184L4.74661 9.74989L15 9.74989C15.4142 9.74989 15.75 9.41411 15.75 8.99989C15.75 8.58568 15.4142 8.24989 15 8.24989L4.75156 8.24989L8.77437 4.28623C9.06943 3.99551 9.07295 3.52065 8.78223 3.2256C8.49151 2.93054 8.01665 2.92703 7.7216 3.21774L2.52846 8.33453C2.15577 8.70174 2.15577 9.30293 2.52846 9.67014L7.7216 14.7869Z" fill="#918FBB"/>
            </svg>
          </div>
          <div class="lootboxes-head__pagination-arrow default-hover" @click="next">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.2784 3.21307C9.98335 2.92236 9.50849 2.92587 9.21777 3.22093C8.92705 3.51598 8.93057 3.99084 9.22563 4.28156L13.2534 8.25011H3C2.58579 8.25011 2.25 8.58589 2.25 9.00011C2.25 9.41432 2.58579 9.75011 3 9.75011H13.2484L9.22563 13.7138C8.93057 14.0045 8.92705 14.4793 9.21777 14.7744C9.50849 15.0695 9.98335 15.073 10.2784 14.7823L15.4715 9.66547C15.8442 9.29826 15.8442 8.69707 15.4715 8.32986L10.2784 3.21307Z" fill="#918FBB"/>
            </svg>
          </div>
        </div>
      </div>
    </div>
    <div class="lootboxes-items">
      <UiLoader v-if="isLoading" />
      <template v-else>
        <EmptyState :absolute="true" heading="You don`t have free lootboxes" size="small" v-if="!lootboxes.length" />
        <hooper ref="slider" :settings="settings">
          <slide v-for="(lootbox, i) in lootboxes" :key="i">
            <LootboxShortItem :lootbox="lootbox" />
          </slide>
        </hooper>
      </template>
    </div>
  </div>
</template>

<style scoped lang="scss">
.free-lootboxes {
  margin: 29px 0;
  margin-bottom: 0;
  .lootboxes-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .lootboxes-head__title {
      display: flex;
      align-items: center;
      .lootboxes-head__title-icon {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 7px;
      }
      .lootboxes-head__title-name {
        color: #7F7DA6;
        font-family: "Geometria-Bold";
        font-size: 14px;
        display: flex;
        align-items: center;
        font-weight: 700;
        letter-spacing: -0.28px;
      }
    }
    .lootboxes-head__danger {
      border-radius: 10px;
      background: rgba(255, 103, 103, 0.19);
      padding: 12px 16px;
      display: flex;
      align-items: center;
      margin-left: auto;
      margin-right: 8px;
      img {
        margin-right: 12px;
        width: 20px;
        height: 20px;
      }
      font-family: "Geometria-Medium";
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.24px;
      @media (max-width: 768px) {
        display: none;
      }
    }
    .lootboxes-head__pagination {
      display: flex;
      .lootboxes-head__pagination-arrows {
        margin-right: 8px;
        display: flex;
        border-radius: 26px;
        background: #1D1B41;
        padding: 4px;
        grid-gap: 4px;
        .lootboxes-head__pagination-arrow {
          border-radius: 31px;
          background: #2B2A4E;
          display: flex;
          align-items: center;
          padding: 6px 12px;
        }
      }
      .lootboxes-head__pagination-go-to {
        border-radius: 39px;
        background: linear-gradient(180deg, #F7FC00 -4.55%, #FFEA31 98.49%);
        padding: 12px 24px;
        color: #0C0A33;
        font-family: "Geometria-Bold";
        font-size: 14px;
        font-weight: 700;
        letter-spacing: -0.28px;
        @media (max-width: 768px) {
          font-size: 12px;
          padding: 12px 20px;
          padding-top: 13px;
        }
      }
    }
  }
  .lootboxes-items {
    width: 100%;
    position: relative;
      min-height: 100px;
    ::v-deep {
      .hooper-list {
        padding-top: 15px;
        padding-bottom: 35px;
      }
      .hooper-slide {
        padding: 0 8px;
        @media (max-width: 768px) {
          padding: 0 4px;
        }
      }
    }
  }

  ::v-deep {
    .hooper {
      height: auto;
    }
  }
}

</style>