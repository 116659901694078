<template>
  <div class="home">
    <WelcomeBanner/>
    <LiveFeed />
    <PaymentMethodsBlock/>
    <CasinoGames/>
    <GameProviders/>
    <BetsList />
    <BlockCategories/>
    <OriginalGames/>
<!--    <SportsList/>-->

  </div>
</template>

<script>
// @ is an alias to /src

import LiveFeed from "@/components/Parts/LiveFeed/LiveFeed.vue";
import WelcomeBanner from "@/components/Parts/Welcome/WelcomeBanner.vue";
import BlockCategories from "@/components/Main/BlockCategories.vue";
import OriginalGames from "@/components/Main/OriginalGames.vue";
import CasinoGames from "@/components/Main/CasinoGames.vue";
import GameProviders from "@/components/Main/GameProviders.vue";
// import SportsList from "@/components/Main/SportsList.vue";
import BetsList from "@/components/Parts/BetsList.vue";
import PaymentMethodsBlock from "@/components/Parts/PaymentMethodsBlock.vue";

export default {
  name: 'HomeView',
  components: {
    PaymentMethodsBlock,
    BetsList,
    // SportsList,
    GameProviders,
    CasinoGames,
    OriginalGames,
    BlockCategories,
    WelcomeBanner,
    LiveFeed
  }
}
</script>
<style scoped lang="scss">
.home {
  overflow-x: hidden;
  .original-games {
    margin-top: 20px;
    @media (max-width: 768px) {
      margin-top: 12px;
    }
  }
  .bets {
    margin-bottom: 30px;
  }
}
</style>
