import ApiClient from "@/services/ApiClient";

export function getItems (data) {
    return ApiClient.get('/nft/marketplace/', data);
}

export function getCollections() {
    return ApiClient.get('/nft/marketplace/collections');
}

export function buyNft(id) {
    return ApiClient.post('/nft/marketplace/buy/' + id)
}

export function buyFromShop(id) {
    return ApiClient.post('/nft/buy/' + id);
}

export function fetchNft(id) {
    return ApiClient.get('/nft/' + id);
}

export function getList(data) {
    return ApiClient.get('/nft/list', data);
}