<template>
  <div>
    <transition name="fade">
      <button
          v-show="showButton"
          @click="scrollToTop"
          class="scroll-to-top"
      >
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4.5 9L7.5 6L4.5 3" stroke="#7F7DA6" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </button>
    </transition>
  </div>
</template>

<script>
export default {
  name: "ScrollTop",
  data() {
    return {
      showButton: false,
    };
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.showButton = window.scrollY > window.innerHeight;
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
  },
};
</script>

<style scoped>
.scroll-to-top {
  position: fixed;
  bottom: calc(env(safe-area-inset-bottom) + 90px);
  right: 20px;
  width: 50px;
  height: 50px;
  svg {
    width: 20px;
    height: 20px;
    transform: rotate(-90deg);
  }
  border-radius: 14px;
  background-color: #1b1452;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: opacity 0.3s;
  z-index: 1000;
  display: none;
  @media (max-width: 992px) {
    display: block;
  }
}
</style>