<script>
export default {
  name: "BonusPrizes"
}
</script>

<template>
  <div class="information-prizes">
    <div class="prize blue">
      1000 FS
    </div>
    <div class="separator">
      &
    </div>
    <div class="prize yellow">
      $3500
    </div>
  </div>
</template>

<style scoped lang="scss">
.information-prizes {
  display: flex;
  align-items: center;
  .prize {
    padding: 13px 19px;
    font-family: "Unbounded";
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.32px;
    @media (max-width: 768px) {
      font-size: 12px;
      padding: 11px 19px;
    }
    &.blue {
      border-radius: 12px;
      box-shadow: 0px -1px 2px 2px #349AFF;
      background: linear-gradient(180deg, #0582E9 0%, #06F 100%);
    }
    &.yellow {
      border-radius: 12px;
      border: 2px solid #FFEF5E;
      background: #FFE70C;
      color: #0C0A33;
    }
  }
  .separator {
    font-family: "Unbounded";
    font-size: 20px;
    font-weight: 700;
    letter-spacing: -0.4px;
    margin: 0 13px;
  }

}
</style>