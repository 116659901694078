<script>
export default {
  name: "TypeDeposit"
}
</script>

<template>
  <div class="deposit">
    <div class="deposit-icon">
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.0013 0.5H4.0013C2.16035 0.5 0.667969 1.99238 0.667969 3.83333C0.667969 5.23224 1.52971 6.42988 2.7513 6.92437V3.83333C2.7513 3.50181 2.883 3.18387 3.11742 2.94945C3.35184 2.71503 3.66978 2.58333 4.0013 2.58333H14.0013C14.3328 2.58333 14.6508 2.71503 14.8852 2.94945C15.1196 3.18387 15.2513 3.50181 15.2513 3.83333V6.92437C16.4729 6.42988 17.3346 5.23224 17.3346 3.83333C17.3346 1.99239 15.8423 0.5 14.0013 0.5ZM14.0009 13.8335L14.0009 3.83352H4.00092V13.8335C4.00092 14.754 4.74711 15.5002 5.66759 15.5002H12.3343C13.2547 15.5002 14.0009 14.754 14.0009 13.8335ZM8.2513 8.63253L7.86497 9.01887C7.57207 9.31176 7.0972 9.31176 6.80431 9.01887C6.51141 8.72598 6.51141 8.2511 6.80431 7.95821L7.88172 6.8808C8.50005 6.26247 9.50256 6.26247 10.1209 6.8808L11.1983 7.95821C11.4912 8.2511 11.4912 8.72598 11.1983 9.01887C10.9054 9.31176 10.4305 9.31176 10.1376 9.01887L9.7513 8.63253V11.8219C9.7513 12.2361 9.41552 12.5719 9.0013 12.5719C8.58709 12.5719 8.2513 12.2361 8.2513 11.8219V8.63253Z" fill="white"/>
      </svg>
    </div>
    <strong>{{ $t('tt_deposit') }}</strong>
  </div>
</template>

<style scoped lang="scss">
  .deposit {
    display: flex;
    align-items: center;
    .deposit-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 39px;
      height: 39px;
      border-radius: 12px;
      box-shadow: 0px -1px 1px 1px #349AFF;
      background: linear-gradient(180deg, #0582E9 0%, #06F 100%);
      margin-right: 12px;
    }
    strong {
      color: #B9B8DD;
      font-size: 14px;
      font-weight: 700;
      font-family: "Geometria-Bold";
      letter-spacing: -0.28px;
    }
  }
</style>