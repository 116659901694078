<script>
export default {
  name: "HistoryItemHead"
}
</script>

<template>
  <div class="history-item-head">
    <div class="game">{{ $t('gm.game') }}</div>
    <div class="time">{{ $t('gm.time') }}</div>
    <div class="amount">{{ $t('gm.amount') }}</div>
    <div class="wager">{{ $t('gm.type') }}</div>
    <div class="payout">{{ $t('gm.payout') }}</div>
  </div>
</template>

<style scoped lang="scss">
  .history-item-head {
    display: flex;
    align-items: center;
    padding: 7px;
    padding-left: 0;
    & > div {
      width: 100%;
      display: flex;
      padding: 8px;
      color: #3E3C5F;
      font-family: "Geometria-Bold";
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 700;
      letter-spacing: 0.6px;
    }
    .game {
      width: 30%;
      @media (max-width: 800px) {
        width: 50%;
      }
    }
    .time {
      width: 15%;
      @media (max-width: 800px) {
        display: none;
      }
    }
    .amount {
      width: 15%;
      @media (max-width: 800px) {
        display: none;
      }
    }
    .wager {
      @media (max-width: 800px) {
        display: none;
      }
      width: 15%;
    }
    .payout {
      width: 25%;
      display: flex;
      justify-content: flex-end;
      @media (max-width: 800px) {
        width: 50%;
      }
    }
    padding-right: 18px;
  }
</style>