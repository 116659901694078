<script>
export default {
  props: {
    showControls: {
      type: Boolean,
      default: true,
    }
  },
  name: "SliderHead"
}
</script>

<template>
  <div class="slider-head">
    <div class="information">
      <slot name="image"></slot>
      <span>
        <slot name="name"></slot>
      </span>

      <slot name="all"></slot>

    </div>
    <div class="control" v-if="showControls">
      <div @click="$emit('prev')" class="control-dir left default-hover">
        <img src="@/assets/images/main/arrow-right.svg" alt="">
      </div>
      <div @click="$emit('next')" class="control-dir right default-hover">
        <img src="@/assets/images/main/arrow-right.svg" alt="">
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.slider-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .information {
    display: flex;
    align-items: center;
    span {
      color: #7F7DA6;
      font-size: 14px;
      font-family: "Geometria-Bold";
      display: flex;
      align-items: center;
      letter-spacing: -0.28px;
      margin-right: 7px;
    }
    img {
      width: 24px;
      margin-right: 10px;
    }

  }
  .control {
    border-radius: 26px;
    background: #1D1B41;
    padding: 4px;
    display: flex;
    grid-gap: 4px;
    .control-dir {
      padding: 6px 12px;
      display: flex;
      align-items: center;
      img {
        width: 18px;
        height: 18px;
      }
      border-radius: 31px;
      background: #2B2A4E;
      &.left {
        transform: rotate(180deg);
      }
    }
  }
}
</style>