<script>
import {mapGetters} from "vuex";

export default {
  computed: {
    ...mapGetters({
      categories: 'games/categories'
    })
  },
  name: "CategoriesList"
}
</script>

<template>
  <div class="categories">
    <a class="category disabled" v-tooltip.right="{content: 'Coming soon'}">
      <span class="category-icon">
        <img src="@/assets/images/sidebar/category-1.svg" alt="">
      </span>
      <span class="category-name">{{ $t('sidebar.originals') }}</span>

      <span class="lock">
        <img src="@/assets/images/sidebar/lock-icon.svg" alt="">
      </span>
    </a>

    <router-link to="/games" class="category">
      <span class="category-icon">
        <img src="@/assets/images/all-icon.svg" alt="">
      </span>
      <span class="category-name">{{ $t('sidebar.all') }}</span>
    </router-link>

    <router-link :to="`/games?category=${category.id}`" v-for="category in categories" :key="category.id" href="" class="category">
      <span class="category-icon">
        <img :src="category.icon" alt="">
      </span>
      <span class="category-name">{{ category.name }}</span>
    </router-link>
  </div>
</template>

<style scoped lang="scss">
  .categories {
    margin-top: 4px;
    .category {
      padding: 9px 12px;
      display: flex;
      border-radius: 10px;
      align-items: center;
      position: relative;
      padding: 7px 12px;
      .lock {
        position: absolute;
        right: 8px;
      }
      .category-icon {
        width: 24px;
        height: 24px;
        margin-right: 7px;
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width: 768px) {
          width: 20px;
          height: 20px;
          img {
            max-width: 100%;
            max-height: 100%;
          }
        }
      }
      .category-name {
        color: #918FBB;
        font-family: "Geometria-Bold";
        font-size: 14px;
        transition: 0.2s;
        letter-spacing: -0.28px;
        @media (max-width: 768px) {
          font-size: 12px;
          padding-top: 2px;
        }
      }
      &.disabled {
        background: #02002A;
        cursor: default;
        user-select: none;
        @media (max-width: 992px) {
          background: #181732;
        }
        .category-name {
          color: #555379
        }
      }

      &:not(.disabled):hover {
        .category-name {
          color: #fff;
        }
      }
    }
  }
</style>