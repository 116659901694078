<script>
import LoyalBanner from "@/components/Modals/Loyal/LoyalBanner.vue";
import RankItem from "@/components/Modals/Loyal/RankItem.vue";
import UiClose from "@/components/Ui/UiClose.vue";
import {mapMutations} from "vuex";

export default {
  data () {
    return {
      levels: [
        {
          level: 1,
          img: require('@/assets/images/profile/level-1.png'),
          name: "LEVEL 1ST",
          from: 0,
          to: 4999,
          items: [
              '10% bonus to originals',
              'Access to quests',
              'Fast support'
          ]
        },
        {
          img: require('@/assets/images/profile/level-2.png'),
          level: 2,
          name: "LEVEL 2ND",
          from: 5000,
          to: 149999,
          items: [
            '20% bonus to originals',
            'Access to quests',
            'Fast support'
          ]
        },
        {
          img: require('@/assets/images/profile/level-3.png'),
          level: 3,
          name: "LEVEL 3RD",
          from: 150000,
          to: 1999999,
          items: [
            '30% bonus to originals',
            'Access to quests',
            'Fast support'
          ]
        },
        {
          img: require('@/assets/images/profile/level-4.png'),
          level: 4,
          name: "LEVEL 4TH",
          from: 2000000,
          to: 8999999,
          items: [
            '40% bonus to originals',
            'Premium tournaments',
            'VIP support'
          ]
        },
        {
          img: require('@/assets/images/profile/level-5.png'),
          level: 5,
          name: "LEVEL 5TH",
          from: 9000000,
          to: 19999999,
          items: [
            '50% bonus to originals',
            'Premium tournaments',
            'VIP support'
          ]
        },
        {
          img: require('@/assets/images/loyal/level-6.png'),
          level: 6,
          name: "LEVEL 6TH",
          from: 20000000,
          to: '~',
          items: [
            '60% bonus to originals',
            'Premium tournaments',
            'VIP support'
          ]
        }
      ]
    }
  },
  methods: {
    ...mapMutations({
      setIsOpenLoyalty: 'config/setIsOpenLoyalty'
    }),
    close() {
      this.setIsOpenLoyalty(false)
    }
  },
  name: "LoyalProgramModal",
  components: {UiClose, RankItem, LoyalBanner}
}
</script>

<template>
  <div class="loyal-program-modal">
    <LoyalBanner />
    <div class="ranking">
      <UiClose @click="setIsOpenLoyalty(false)" />
      <div class="ranking-heading">
        {{ $t('ll_ranking') }}
      </div>
      <div class="ranking-desc">
        {{ $t('ll_ranking_text') }}
      </div>
      <div class="ranking-items">
        <RankItem v-for="(level, i) in levels" :key="i" :level="level" />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .loyal-program-modal {
    display: flex;
    @media (max-height: 900px) {
      margin-top: 100px;
    }
    .ranking {
      position: relative;
      width: 810px;
      min-width: 810px;
      border-radius: 20px;
      background: var(--200, #131139);
      box-shadow: 0px 105px 153.7px 0px rgba(1, 0, 25, 0.55);
      padding: 20px;
      @media (max-width: 1170px) {
        max-width: 100%;
        min-width: 100%;
        width: 100%;
      }

      @media (max-width: 768px) {
        padding: 14px;
        padding-top: 20px;
      }
      .ranking-heading {
        font-family: Unbounded;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px; /* 116.667% */
        letter-spacing: -0.48px;
        margin-bottom: 9px;
        @media (max-width: 768px) {
          font-size: 18px;
          margin-bottom: 10px;
          line-height: 20px;
        }
      }
      .ranking-desc {
        color: var(--800, #918FBB);
        font-size: 14px;
        font-family: "Geometria-Medium";
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.28px;
        @media (max-width: 768px) {
          font-size: 13px;
        }
      }
      .ranking-items {
        margin-top: 9px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 8px;
        @media (max-width: 768px) {
          grid-template-columns: repeat(1, 1fr);
        }
      }
    }
    @media (max-width: 1170px) {
      display: block;
    }
  }
</style>