<script>
import BuySnegCoin from "@/components/Ui/BuySnegCoin.vue";
import AuthorizationState from "@/components/Auth/AuthorizationState.vue";
import LanguageSwitcher from "@/components/Parts/LanguageSwitcher.vue";
import {mapGetters, mapMutations} from "vuex";

export default {
  data () {
    return {

    }
  },
  computed: {
    ...mapGetters({
      isOpenSidebar: 'config/isOpenSidebar',
      isOpenChat: 'config/isOpenChat'
    })
  },
  methods: {
    ...mapMutations({
      setIsShowSettings: 'config/setIsShowSettings',
      setIsShowSearch: 'config/setIsShowSearch',
      setIsOpenSidebar: 'config/setIsOpenSidebar',
      setIsOpenChat: 'config/setIsOpenChat',
    })
  },
  components: {
    LanguageSwitcher,
    AuthorizationState,
    BuySnegCoin,
  }
}
</script>

<template>
  <header class="header">
    <div class="left-side">
      <div class="hide-sidebar default-hover" :class="{'open': isOpenSidebar}" @click="setIsOpenSidebar(!isOpenSidebar)">
        <img src="@/assets/images/hide-left-arrow.svg" alt="">
      </div>
      <router-link to="/" class="logo mobile-logo">
        <img src="@/assets/images/mobile-logo.svg" alt="">
      </router-link>

      <router-link to="/" class="logo">
        <img src="@/assets/images/logo.svg" alt="">
      </router-link>
      <BuySnegCoin />

      <div class="left-side__nav">
        <router-link to="/blog" class="default-hover">
          <div class="img">
            <img src="@/assets/images/blog-icon-nav.svg" alt="">
          </div>
          <div class="name">
            {{ $t('header.blog') }}
          </div>
        </router-link>
      </div>

    </div>
    <div class="right-side">
      <LanguageSwitcher/>
      <AuthorizationState />
      <div class="settings">
        <div @click="setIsOpenSidebar(!isOpenSidebar)" class="setting default-hover sidebar-setting">
          <img src="@/assets/images/sidebar-icon.svg" alt="">
        </div>
        <div class="setting default-hover" @click="setIsShowSearch(true)">
          <img src="@/assets/images/search-icon.svg" alt="">
        </div>
        <div class="setting default-hover chat-setting" @click="setIsOpenChat(!isOpenChat)">
          <img src="@/assets/images/chat-icon.svg" alt="">
        </div>
      </div>
    </div>
  </header>
</template>

<style scoped lang="scss">
header.header {
  background: $color-pre-black;
  width: 100%;
  align-items: center;
  padding: 5px 13px;
  justify-content: space-between;
  display: flex;
  position: sticky;
  top: 0;
  z-index: 9999;
  max-width: 100%;
  .left-side {
    display: flex;
    align-items: center;
    .left-side__nav {
      display: flex;
      align-items: center;
      margin-left: 32px;
      @media (max-width: 992px) {
        display: none;
      }
      a {
        display: flex;
        align-items: center;
        .img {
          width: 20px;
          height: 20px;
          display: flex;
          align-items: center;
          margin-right: 8px;
        }
        .name {
          color: #918FBB;
          font-size: 14px;
          font-weight: 700;
          font-family: "Geometria-Bold";
          letter-spacing: -0.28px;
        }
      }
      a + a {
        margin-left: 25px;
      }
    }
    .hide-sidebar {
      width: 40px;
      height: 40px;
      border-radius: 11px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 12px;
      img {
        width: 26px;
        height: 26px;
      }
      transition: 0.2s;
      display: none;
      @media (max-width: 1200px) {
        display: flex;
        transform: rotate(180deg);
        &.open {
          transform: rotate(0);
        }
      }

      @media (max-width: 992px) {
        display: none !important;
      }
    }
    .logo {
      display: flex;
      align-items: center;
      margin-right: 30px;
      @media(max-width: 992px) {
        display: none;
      }
      margin-right: 15px;
    }

    .mobile-logo {
      display: none;
    }
    @media (max-width: 992px) {
      .mobile-logo {
        width: 28px;
        height: 28px;
        img {
          width: 100%;
          height: 100%;
        }
        display: flex;
      }
    }

    @media (max-width: 1200px) {
      .buy-sneg-coin {
        display: none;
      }
    }
  }
  .right-side {
    display: flex;
    align-items: center;
    .language-switcher {
      margin-right: 8px;
      @media (max-width: 992px) {
        display: none;
      }
    }
    @media (max-width: 992px) {
      ::v-deep {
        .authorization-state {
          .balance-state {
            display: none;
          }
        }
      }
    }
    .settings {
      display: flex;
      @media (max-width: 992px) {
        margin-left: 8px;
      }
      .setting {
        border-radius: 11px;
        background: $color-gray;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        &.config-setting {
          display: none;
        }
        @media (max-width: 768px) {
          width: 35px;
          height: 35px;
          img {
            width: 17px;
          }
        }
        &.chat-setting {
          display: none;
          @media (max-width: 1600px) {
            display: flex;
          }
        }
        &.sidebar-setting {
          display: none;
          @media (max-width: 992px) {
            display: flex;
          }
        }
        @media (max-width: 992px) {
          &.config-setting {
            display: flex;
          }
        }
      }
      .setting + .setting {
        margin-left: 8px;
        @media (max-width: 768px) {
          margin-left: 4px;
        }
      }
    }
  }

  @media (max-width: 1200px) {
    height: 65px;
    background: #02002A;
  }
  @media (max-width: 992px) {
    padding-left: 20px;
  }
  @media (max-width: 400px) {
    padding-left: 10px;
  }
}
</style>