<script>
import UiInput from "@/components/Ui/UiInput.vue";
import AccentBtn from "@/components/Ui/Buttons/AccentBtn.vue";
import { changeEmail } from "@/services/user";
import extractErrors from "@/helpers/extractErrors";
import {mapGetters} from "vuex";

export default {
  data () {
    return {
      isLoading: false,
      email: null,
    }
  },
  computed: {
    ...mapGetters({
      user: 'user/user'
    })
  },
  methods: {
    goChangeEmail() {
      if (!this.email || this.isLoading) return;

      this.isLoading = true;

      changeEmail({
        email: this.email
      }).then(() => {
        this.$root.$emit('success', {
          text: this.$t('email_settings_sent'),
          title: "Success operation"
        })
      }).catch(errors => {
        extractErrors(errors).forEach(item => {
          this.$root.$emit('error', {
            title: "Error",
            text: item,
          })
        })
      }).finally(() => {
        this.email = null;
        this.isLoading = false;
      })
    }
  },
  name: "EmailSetting",
  components: {AccentBtn, UiInput}
}
</script>

<template>
  <div class="email">
    <div class="email-heading">
      <div class="icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M12.0395 0.333252H4.0013C2.16035 0.333252 0.667969 1.82564 0.667969 3.66659V11.9999C0.667969 13.8409 2.16035 15.3333 4.0013 15.3333H14.0013C15.8423 15.3333 17.3346 13.8409 17.3346 11.9999V5.62839C16.6712 6.22219 15.7951 6.58325 14.8346 6.58325C12.7636 6.58325 11.0846 4.90432 11.0846 2.83325C11.0846 1.87281 11.4457 0.996698 12.0395 0.333252ZM3.48127 4.1532C3.67274 3.86599 4.06079 3.78838 4.34799 3.97985L7.49899 6.08052C8.40872 6.687 9.59389 6.687 10.5036 6.08052L10.9123 5.8081C11.1995 5.61662 11.5875 5.69423 11.779 5.98144C11.9704 6.26864 11.8928 6.65669 11.6056 6.84816L11.197 7.12058C9.86739 8.00698 8.13522 8.00698 6.80562 7.12058L3.65462 5.01991C3.36741 4.82844 3.2898 4.4404 3.48127 4.1532ZM14.835 5.33325C16.2157 5.33325 17.335 4.21396 17.335 2.83325C17.335 1.45254 16.2157 0.333252 14.835 0.333252C13.4542 0.333252 12.335 1.45254 12.335 2.83325C12.335 4.21396 13.4542 5.33325 14.835 5.33325Z" fill="white"/>
        </svg>
      </div>
      <span>E-mail</span>
    </div>
    <form @submit.prevent.stop="goChangeEmail" class="email-form">
      <p>
        {{ $t('account.email.set_email') }}
      </p>

      <UiInput :readonly="user?.email_change_request?.done_at" v-model="email" type="email" :placeholder="$t('register_form.enter_email')" />

      <AccentBtn :is-loading="isLoading" :disabled="!email" type="submit" :title="$t('account.email.change_email')" />

      <p class="warn">
        <img src="@/assets/images/profile/warn-icon.svg" alt="">
        <template v-if="!user?.email_change_request?.done_at">
          {{ $t('account.email.once') }}
        </template>
        <template v-else>
          {{ $t('changed_available_after', {date:user?.email_change_request?.done_at }) }}
        </template>
      </p>
    </form>
  </div>
</template>

<style scoped lang="scss">
  .email {
    width: 230px;
    min-width: 230px;
    border-radius: 13px;
    background: rgba(200, 197, 255, 0.05);
    padding: 14px;
    padding-bottom: 10px;
    @media (max-width: 768px) {
      width: 100%;
      min-width: 100%;
    }
    .email-heading {
      display: flex;
      margin-bottom: 9px;
      align-items: center;
      .icon {
        margin-right: 8px;
      }
      span {
        color: #B9B8DD;
        display: flex;
        align-items: center;
        font-size: 14px;
        font-family: "Geometria-Bold";
        font-weight: 700;
        letter-spacing: -0.28px;
      }
    }
    .email-form {
      p {
        margin: 0;
        color: #918FBB;
        display: flex;
        align-items: center;
        font-family: "Geometria-Medium";
        font-size: 12px;
        font-weight: 500;
        margin-bottom: 8px;
        letter-spacing: -0.24px;
      }
      .ui-input {
        background: #0C0A33;
      }
      .accent-btn {
        margin-top: 8px;
        @media (max-width: 768px) {
          padding: 12px 0;
        }
      }
      .warn {
        justify-content: center;
        img {
          margin-right: 8px;
          margin-bottom: 2px;
        }

        margin-top: 15px;
      }
    }
  }
</style>