import { render, staticRenderFns } from "./OriginalGames.vue?vue&type=template&id=26e84ecd&scoped=true"
import script from "./OriginalGames.vue?vue&type=script&lang=js"
export * from "./OriginalGames.vue?vue&type=script&lang=js"
import style0 from "./OriginalGames.vue?vue&type=style&index=0&id=26e84ecd&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "26e84ecd",
  null
  
)

export default component.exports