<script>
import MessageItem from "@/components/Parts/Chat/MessageItem.vue";
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  data () {
    return {
      subscriber: null,
    }
  },
  computed: {
      ...mapGetters({
        user: 'user/user',
        current_chat_room: 'settings/current_chat_room',
        messages: 'chat/messages',
        replyMessage: 'chat/replyMessage',
        isShowChat: 'config/isOpenChat'
      })
  },
  watch: {
    isShowChat() {
      this.$nextTick(() => {
        this.$refs.body.scrollTo(0, 999999999);
      })
    }
  },
  methods: {
    ...mapMutations({
      pushMessage: 'chat/pushMessage'
    }),
    ...mapActions({
      getMessages: 'chat/getMessages'
    }),
    toDown () {
      console.log('toDown')
      const body = this.$refs.body;

      const isScrolledToBottom = body.scrollTop + body.clientHeight + 70 >= body.scrollHeight;
      if (isScrolledToBottom) {
        this.$refs.body.scrollTo(0, 999999999);
      }
    },
    subscribe() {
      this.subscriber = this.$echo.channel(`chat.${this.current_chat_room.code}.message-created`).listen(".MessageCreatedEvent", async (payload) => {
        await this.pushMessage(payload)

        if (payload.user.id === this.user.id) {
          this.$refs.body.scrollTo(0, 999999999);
        } else {
          this.toDown();
        }
      })

      this.$root.$on('chat.deleteUserMessages', () => {
        this.getMessages()
      })
    }
  },
  async created () {
    await this.getMessages()
    setTimeout(() => {
      this.$refs.body.scrollTo(0, 999999999);
    }, 0)
  },
  mounted () {
    this.$root.$on('delete-chat-message', (id) => {
      const index = this.messages.findIndex(item => item.id === id);
      if (index > -1) {
        this.messages.splice(index, 1);
      }
    })
    this.subscribe()
  },
  name: "ChatMessages",
  components: {MessageItem}
}
</script>

<template>
  <div class="chat-messages" :class="{'replying': !!replyMessage}">
    <div class="list" ref="body" >
      <MessageItem :isLast="j > messages.length - 4" :message="message" v-for="(message, j) in messages" :key="j" />
    </div>
  </div>
</template>

<style scoped lang="scss">
  .chat-messages {
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 12px;
    overflow: hidden;
    flex-grow: 1;
    display: flex;
    transition: 0.3s;
    flex-direction: column;
    @media (max-width: 768px) {
      padding-top: 5px;
    }
    .list {
      overflow-y: auto;
      flex-grow: 1;
      &::-webkit-scrollbar {
        display: none;
      }
      .message-item + .message-item {
        margin-top: 8px;
      }

    }
    &.replying {
      padding-bottom: 70px;
    }

    &.blured {
      position: relative;
      filter: blur(10px);
      &::after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
</style>