<script>
import {mapGetters, mapMutations} from "vuex";

export default {
  props: {
    price: {
      type: Number,
      required: true,
    },
    prizes: {
      type: Array,
      default: () => [],
    }
  },
  data () {
    return {
      balance: null,
      count: 0,
    }
  },
  computed: {
    ...mapGetters({
      completedSpins: 'lootbox/completedSpinsCurrently',
      balanceLimit: 'lootbox/balanceLimit',
      autoSelectItem: 'lootbox/autoSelectItem',
      user: 'user/user',
      spinsCount: 'lootbox/spinsCount'
    }),
    enoughBalance() {
      return (this.user && this.user.sg_balance < (this.price * (this.spinsCount + 1)));
    },
  },
  watch: {
    balance() {
      this.setBalanceLimit(this.balance)
    },
    spinsCount() {
      if (this.spinsCount !== this.count) {
        this.count = this.spinsCount
      }
    },
    count() {
      this.setSpinsCount(this.count)
    }
  },
  methods: {
    decrement() {
      if (this.completedSpins > 0) return;
      if(Number(this.spinsCount) === 1) return;

      this.count--;
    },
    increment() {
      if (this.completedSpins > 0) return;
      if (this.enoughBalance) return;
      
      this.count++;
    },
    ...mapMutations({
      setBalanceLimit: 'lootbox/setBalanceLimit',
      setSpinsCount: 'lootbox/setSpinsCount',
      setAutoSelectItem: 'lootbox/setAutoSelectItem'
    })
  },
  name: "AutoMode"
}
</script>

<template>
<div class="auto-mode">
  <div class="auto-mode__select">
    <div class="auto-mode__select-title">
      {{ $t('ll_stop') }}
    </div>
    <div class="auto-mode__select-items">
      <div class="auto-mode__select-item" :class="{'active': autoSelectItem && autoSelectItem.id === prize.id}" @click="!completedSpins ? setAutoSelectItem(prize) : ''" v-for="(prize, i) in prizes" :key="i">
        <div class="auto-mode__select-item__content">
          <img :src="prize.nft.image_url" v-if="prize.type === 'nft'" alt="" class="auto-mode__select-item__content-nft">
          <div class="auto-mode__select-item__content-coin" v-if="prize.type === 'coins'">
            <span>{{ prize.sg_coins }}</span>
            <img src="@/assets/images/short-coin.svg" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="auto-mode__spins">
    <div class="auto-mode__spins-controls">
      <button class="default-hover" @click="decrement" :class="{'disabled': count === 1}">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path d="M4.0293 6.01465L7.99988 9.98524L11.9705 6.01465" stroke="#7F7DA6" stroke-width="1.76471" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </button>
      <button class="default-hover" :class="{'disabled': enoughBalance}" @click="increment">
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
          <path d="M12.8527 9.98364L8.88207 6.01305L4.91148 9.98364" stroke="#7F7DA6" stroke-width="1.76471" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </button>
    </div>
    <div class="auto-mode__spins-input">
      <span>
        {{ $t('ll_spins_a') }}
      </span>
      <input :readonly="completedSpins > 0" v-model="count" type="number" :placeholder="$t('ll_spins_a')">
    </div>
    <div class="auto-mode__spins-infinite default-hover" :class="{'active': !count}" @click="completedSpins === 0 ? count = 0 : ''">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path d="M12.3053 11.6648C10.4847 11.6648 9.194 10.3988 8 9.01412C6.796 10.4074 5.50933 11.6648 3.69467 11.6648C1.65733 11.6648 0 10.0208 0 8.00012C0 5.97945 1.65733 4.33545 3.69467 4.33545C5.516 4.33545 6.846 5.62745 8 6.95878C9.15333 5.62878 10.4847 4.33545 12.3053 4.33545C14.3427 4.33545 16 5.97945 16 8.00012C16 10.0208 14.342 11.6648 12.3053 11.6648ZM8.874 7.98678C9.95467 9.25078 10.9993 10.3321 12.3053 10.3321C13.6073 10.3321 14.6667 9.28678 14.6667 8.00078C14.6667 6.71545 13.6073 5.66945 12.3053 5.66945C10.98 5.66878 9.936 6.74412 8.874 7.98678ZM3.69467 5.66878C2.39267 5.66878 1.33333 6.71478 1.33333 8.00012C1.33333 9.28612 2.39267 10.3314 3.69467 10.3314C5.00733 10.3314 6.05533 9.24078 7.126 7.98545C6.06267 6.74412 5.01933 5.66878 3.69467 5.66878Z" fill="#7F7DA6"/>
      </svg>
    </div>
  </div>
  <div class="auto-mode__balance">
    <div class="auto-mode__balance-labels">
      <div class="auto-mode__balance-label">
        {{ $t('ll_stop_balance') }}
      </div>
      <div class="auto-mode__balance-label">
        {{ $t('ll_balance') }}
        <strong>${{ parseFloat(user.sg_balance).toFixed(2) }}</strong>
      </div>
    </div>
    <div class="auto-mode__balance-input">
      <input :readonly="completedSpins > 0" v-model="balance" type="number" :placeholder="$t('ll_enter')">
    </div>
  </div>
</div>
</template>

<style scoped lang="scss">
  .auto-mode {
    margin: 8px 0;
    margin-bottom: 21px;
    .auto-mode__select {
      height: 168px;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      padding: 16px;
      padding-bottom: 0;
      border-radius: 12px;
      background: var(--10, rgba(200, 197, 255, 0.10));
      @media (max-width: 768px) {
        height: 250px;
      }
      .auto-mode__select-title {
        font-size: 14px;
        font-family: "Geometria-Bold";
        opacity: 0.8;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.28px;
      }
      .auto-mode__select-items {
        margin-top: 8px;
        overflow-y: auto;
        padding-bottom: 40px;
        &::-webkit-scrollbar {
          display: none;
        }
        flex-grow: 1;
        align-items: flex-start;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 6px;
        width: 100%;
        .auto-mode__select-item {
          border-radius: 13.64px;
          position: relative;
          padding-bottom: 100%;
          opacity: 0.6;
          transition: 0.2s;
          cursor: pointer;
          overflow: hidden;
          background: var(--20-purple, rgba(171, 107, 255, 0.20));
          .auto-mode__select-item__content {
            position: absolute;
            border: 2px solid transparent;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            overflow: hidden;
            border-radius: 13.64px;
            .auto-mode__select-item__content-nft {
              width: 100%;
              height: 100%;
            }
            .auto-mode__select-item__content-coin {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              height: 100%;
              background: url(@/assets/images/lootbox/coins-shadow.png);
              background-size:  contain;
              background-position: center center;
              background-repeat: no-repeat;
              span {
                width: 100%;
                text-align: center;
                display: block;
                font-family: "Geometria-Bold";
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                letter-spacing: -0.28px;
              }
              img {
                width: 31px;
                height: 30px;
              }
            }
          }
          &.active {
            opacity: 0.9;
            cursor: default !important;
           .auto-mode__select-item__content {
             border: 2px solid var(--Primary, #9D42F8);
           }
          }
          &:hover {
            opacity: 0.8;
          }
        }
      }

      position: relative;
      &::after {
        content: "";
        display: block;
        bottom: 0;
        position: absolute;
        left: 0;
        width: 100%;
        height: 35px;
        background: linear-gradient(0deg, #1C1E52 0%, rgba(45, 48, 132, 0.51) 48.94%, rgba(63, 67, 184, 0.00) 100%);
      }
    }
    .auto-mode__spins {
      border-radius: 12px;
      background: var(--10, rgba(200, 197, 255, 0.10));
      margin: 11px 0;
      padding: 8px 6px;
      display: flex;
      overflow: hidden;
      .auto-mode__spins-controls {
        display: flex;
        margin-right: 10px;
        grid-gap: 2px;
        border-radius: 10px;
        background: var(--30-dark, rgba(0, 3, 42, 0.30));
        padding: 2px;
        button {
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          border-radius: 7.941px;
          background: var(--10, rgba(200, 197, 255, 0.10));
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;
          &.disabled {
            opacity: 0.8;
            cursor: default;
            &:hover {
              opacity: 0.8;
            }
          }
        }
      }
      .auto-mode__spins-input {
        flex-grow: 1;
        span,input {
          display: block;
          width: 100%;
        }
        span {
          color: var(--700, #7F7DA6);
          font-family: "Geometria-Bold";
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          letter-spacing: -0.24px;
        }
        input {
          font-family: Unbounded;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          letter-spacing: -0.28px;
          background: transparent;
          outline: none;
          border: none;
          &::placeholder {
            color: var(--600, #555379);
          }

        }
      }
      .auto-mode__spins-infinite {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 34px;
        width: 34px;
        min-width: 34px;
        border-radius: 10px;
        background: var(--30-dark, rgba(0, 3, 42, 0.30));
        transition: 0.2s;
        &.active {
          background: #5d00bb;
        }
      }
    }
    .auto-mode__balance {
      .auto-mode__balance-labels {
        margin-bottom: 8px;
        display: flex;
        justify-content: space-between;
        .auto-mode__balance-label {
          color: var(--700, #7F7DA6);
          font-size: 12px;
          font-family: "Geometria-Bold";
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          letter-spacing: -0.24px;
          strong {
            color: var(--700, #7F7DA6);
            margin-left: 3px;
            font-family: "Geometria-Bold";
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            letter-spacing: -0.24px;
          }
          &:last-child {
            color: #555379;
          }
        }
      }
      .auto-mode__balance-input {
        input {
          height: 50px;
          border-radius: 12px;
          background: var(--10, rgba(200, 197, 255, 0.10));
          padding: 0 20px;
          outline: none;
          border: none;
          color: #fff;
          font-family: Unbounded;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          letter-spacing: -0.28px;
          display: block;
          width: 100%;
          &::placeholder {
            color: var(--600, #555379);
          }
        }
      }
    }
  }
</style>