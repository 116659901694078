<script>
export default {
  data () {
    return {
      observer: null,
    }
  },
  mounted() {
    let options = {
      root: document,
      rootMargin: "0px",
      threshold: 1.0,
    };

    this.observer = new IntersectionObserver(() => this.$emit('intersect'), options);
    this.observer.observe(this.$refs.intersection)
  },
  name: "IntersectionTrigger"
}
</script>

<template>
  <div ref="intersection" class="intersection"></div>
</template>

<style scoped lang="scss">
  .intersection {
    opacity: 0;
    height: 1px;
    width: 100%;
  }
</style>