<script>
import {cancelNftSell} from "@/services/user";
import extractErrors from "@/helpers/extractErrors";
import {vue} from "@/main";
import {mapMutations} from "vuex";

export default {
  props: {
    sale: {
      type: Object,
      required: true
    }
  },
  methods: {
    ...mapMutations({
      setOpenSellNft: 'config/setOpenSellNft'
    }),
    async unsell() {
      await cancelNftSell(this.sale.nft.id).then(() => {
        this.$root.$emit('success', {
          title: this.$t('account.nfts.notifications.success'),
          text: this.$t('account.nfts.notifications.cancel_from_selling')
        });
        this.$root.$emit('unsell-nft', this.sale.nft.unique_id)
      }).catch(err => {
        const errors = extractErrors(err);
        errors.forEach(item => {
          vue.$root.$emit('error', {
            title: 'Error',
            text: item
          })
        })
      })
    },
  },
  mounted() {
    this.$root.$on('selling.change-price.' + this.sale.nft.id, (price) => {
      // eslint-disable-next-line vue/no-mutating-props
      this.sale.selling_price = price;
    })
  },
  name: "SaleItem"
}
</script>

<template>
  <div class="sale-item">
    <div class="nft">
      <div class="ntf-image">
        <img :src="sale.nft.image_url" alt="">
      </div>
      <div class="nft-name">
        {{ sale.nft.name }}
      </div>
    </div>
    <div class="price">
      <div class="price-coin">
        <img src="@/assets/images/sneg-coin.svg" alt="">
      </div>
      <div class="price-value">
        {{ parseFloat(sale.selling_price).toFixed(2) }}
      </div>
    </div>
    <div class="status">
      <div class="status-badge sold" v-if="sale.is_sold">{{ $t('account.nfts.sold') }}</div>
      <div class="status-badge selling" v-else>{{ $t('account.nfts.selling') }}</div>
    </div>
    <div class="date">
      <div class="date-icon">
        <img src="@/assets/images/clock-icon.svg" alt="">
      </div>
      <div class="date-value">
        {{ sale.date }}
        <template v-if="sale.is_sold">{{ sale.sold_date }}</template>
        <template v-else>{{ sale.selling_date }}</template>
      </div>
    </div>
    <div class="actions">
      <template v-if="!sale.is_sold">
        <div @click="setOpenSellNft({change: true, ...sale.nft})" class="action default-hover">
          <img src="@/assets/images/nft/tag-icon.svg" alt="">
        </div>
        <div @click="unsell" class="action default-hover">
          <img src="@/assets/images/nft/minus-icon.svg" alt="">
        </div>
      </template>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .sale-item {
    display: flex;
    align-items: center;
    width: 100%;
    @media (max-width: 768px) {
      flex-wrap: wrap;
    }
    .nft {
      width: 25%;
      display: flex;
      align-items: center;
      .ntf-image {
        width: 44px;
        height: 44px;
        border-radius: 16px;
        overflow: hidden;
        min-width: 44px;
        img {
          width: 100%;
          height: 100%;
        }

        margin-right: 16px;
        @media (max-width: 768px) {
          width: 30px;
          height: 30px;
          min-width: 30px;
          margin-right: 8px;
          border-radius: 8px;
        }
      }
      .nft-name {
        font-family: "Geometria-Bold";
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-right: 5px;
        letter-spacing: -0.28px;
        @media (max-width: 768px) {
          font-size: 12px;
          padding-top: 4px;
        }
      }
      @media (max-width: 768px) {
        width: 40%;
      }
    }
    .price {
      width: 20%;
      display: flex;
      align-items: center;
      @media (max-width: 768px) {
        width: 26.66%;
        justify-content: center;
      }
      .price-coin {
        width: 18px;
        height: 18px;
        display: flex;
        align-items: center;
        margin-right: 4px;
        img {
          filter: drop-shadow(0px 0px 5px #C58AFF);
          max-width: 100%;
          max-height: 100%;
        }
        @media (max-width: 768px) {
          width: 15px;
          height: 15px;
        }
      }
      .price-value {
        font-size: 14px;
        font-family: "Geometria-Medium";
        display: flex;
        align-items: center;
        line-height: 14px;
        letter-spacing: -0.28px;
        padding-top: 2px;
        @media (max-width: 768px) {
          font-size: 12px;
        }
      }
    }
    .status {
      width: 20%;
      display: flex;
      align-items: center;
      @media (max-width: 768px) {
        width: 33.33%;
        justify-content: flex-end;
      }
      .status-badge {
        padding: 4px 8px;
        font-family: "Geometria-Bold";
        font-size: 12px;
        line-height: 14px;
        font-weight: 700;
        border-radius: 6px;
        overflow: hidden;
        text-transform: uppercase;
        letter-spacing: 0.6px;
        &.selling {
          color: #BBFF92;
          background: linear-gradient(180deg, rgba(154, 255, 52, 0.20) 0%, rgba(118, 236, 0, 0.20) 100%);
        }
        &.sold {
          color: #FF6F6F;
          background: rgba(255, 107, 107, 0.20);
        }

      }
    }
    .date {
      width: 20%;
      display: flex;
      align-items: center;
      @media (max-width: 768px) {
        width: 50%;
      }
      .date-icon {
        display: flex;
        align-items: center;
        width: 15px;
        height: 15px;
        img {
          max-width: 100%;
          max-height: 100%;
        }

        margin-right: 4px;
      }
      .date-value {
        color: #918FBB;
        font-size: 14px;
        font-family: "Geometria-Medium";
        font-weight: 500;
        letter-spacing: -0.28px;
        @media (max-width: 768px) {
          font-size: 12px;
          padding-top: 5px;
        }
      }
    }
    .actions {
      width: 15%;
      display: flex;
      justify-content: flex-end;
      @media (max-width: 768px) {
        width: 50%;
        margin-top: 10px;
      }
      .action {
        border-radius: 11px;
        background: #2B2A4E;
        display: flex;
        width: 34px;
        height: 34px;
        align-items: center;
        justify-content: center;
        @media (max-width: 768px) {
          width: 30px;
          height: 30px;
        }
      }
      .action + .action {
        margin-left: 6px;
      }
    }
  }
</style>