<script>
export default {
  props: {
    size: {
      type: String,
      default: 'default'
    },
    absolute: {
      type: Boolean,
      default: false,
    },
    subtitle: {
      type: String,
    },
    heading: {
      type: String,
      required: true,
    }
  },
  name: "EmptyState"
}
</script>

<template>
<div class="empty-state" :class="{'absolute': absolute, 'small': size === 'small'}">
  <div class="empty-state-img">
    <img src="@/assets/images/empty-state.svg" alt="">
  </div>
  <div class="empty-state-heading">
    {{ heading }}
  </div>
  <div class="empty-state-subtitle" v-if="subtitle">
    {{ subtitle }}
  </div>
</div>
</template>

<style scoped lang="scss">
  .empty-state {
    width: 100%;
    padding: 20px 0;
    .empty-state-img {
      width: 100%;
      display: flex;
      justify-content: center;
    }

    &.absolute {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .empty-state-heading {
      margin-top: 23px;
      color: var(--1000, #FFF);
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.32px;
      font-family: "Geometria-Bold";

      text-align: center;
      width: 100%;
      @media (max-width: 768px) {
        font-size: 14px;
      }
    }
    .empty-state-subtitle {
      margin-top: 8px;
      width: 100%;
      text-align: center;
      color: var(--800, #918FBB);
      font-size: 14px;
      font-family: "Geometria-Medium";
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.28px;
      opacity: 0.8;
      @media (max-width: 768px) {
        font-size: 12px;
      }
    }

    &.small {
      .empty-state-img {
        img {
          width: 50px;
        }
      }
      .empty-state-heading {
        margin-top: 10px;
        font-size: 14px;
      }
      .empty-state-subtitle {
        font-size: 12px;
      }
    }
  @media (max-width: 768px) {
    .empty-state-heading {
      padding-left: 10px;
      padding-right: 10px;
    }
    .empty-state-img {
      img {
        width: 100px;
      }
    }
  }
  }
</style>