<script>
import UiClose from "@/components/Ui/UiClose.vue";
import AccentBtn from "@/components/Ui/Buttons/AccentBtn.vue";
import {mapActions, mapMutations} from "vuex";
import CountryCodeSelect from "@/components/Modals/TwoFa/CountryCodeSelect.vue";
import {send2faSmsCode} from "@/services/user";
import extractErrors from "@/helpers/extractErrors";
import {send2faConfirmation} from "@/services/user";
import TwoFaTimer from "@/components/Modals/TwoFa/TwoFaTimer.vue";
export default {
  data () {
    return {
      codeInvalid: false,
      isLoading: false,
      inputs: ['', '', '', '', '', ''],
      isShowTimer: false,
      number: null,
    }
  },
  name: "TwoFaModal",
  computed: {
    isEnteredCode () {
      for (const item in this.inputs) {
        if (!this.inputs[item]) {
          return false;
        }
      }

      return true;
    }
  },
  watch: {
    inputs: {
      deep: true,
      handler () {
        this.codeInvalid = false;
      }
    }
  },
  methods: {
    close() {
      this.setOpenTwoFa(false)
    },
    ...mapActions({
      me: 'user/me'
    }),
    ...mapMutations({
      setOpenTwoFa: 'config/setOpenTwoFa'
    }),
    setTimer () {
      this.isShowTimer = true;
    },
    handleInput(index, event) {
      const value = event.target.value;
      if (value.length === 1) {
        if (index < this.inputs.length - 1) {
          this.$refs['input' + (index + 1)][0].focus();
        }
      }
    },
    handleKeyDown(index, event) {
      if (event.key === 'Backspace' || event.key === 'Delete') {
        if (index > 0 && this.inputs[index] === '') {
          this.$refs['input' + (index - 1)][0].focus();
        }
      }
    },
    async sendSms ()
    {
      const phone_code = this.$refs.country_code.getCurrentCountrySelected();
      const phone_number = this.number;

      if (!phone_code || !phone_number) return;

      await send2faSmsCode({phone_code: phone_code.code, phone_number: this.number}).then(() => {
        this.setTimer();
        this.$root.$emit('success', {
          title: "Success",
          text: this.$t('fa_sent')
        })
      }).catch(err => {
        extractErrors(err).forEach(item => {
          this.$root.$emit('error', {
            title: "Error",
            text: item,
          })
        })
      })
    },
    async sendConfirmation () {
      if (!this.isEnteredCode) return;

      this.isLoading = true;
      const phone_code = this.$refs.country_code.getCurrentCountrySelected();
      const phone_number = this.number;
      const code = this.inputs.join('');
      if (!phone_code || !phone_number) return;

      await send2faConfirmation({
        phone_code: phone_code.code,
        phone_number,
        code
      }).then(() => {
        this.$root.$emit('success', {
          title: "Success",
          text: this.$t('fa_success')
        })
        this.me();
        this.setOpenTwoFa(false);
      }).catch(err => {
        this.codeInvalid = true;

        extractErrors(err).forEach(item => {
          this.$root.$emit('error', {
            title: "Error",
            text: item,
          })
        })
      }).finally(() => {
        this.isLoading = false
      })
    }
  },
  components: {TwoFaTimer, CountryCodeSelect, AccentBtn, UiClose}
}
</script>

<template>
  <div class="two-fa">
    <div class="right">
      <UiClose @click="setOpenTwoFa(false)" />

      <div class="right__head">
        <div class="circle">
          <img src="@/assets/images/2fa.svg" alt="">
        </div>
        <div class="text">
          <h2 v-html="$t('fa_title')"></h2>
        </div>
      </div>
      <div class="right__p">
        {{ $t('fa_desc') }}
      </div>

      <div class="phone-input">
        <CountryCodeSelect ref="country_code" />
        <input @keydown.enter="sendSms" :readonly="isShowTimer || inputs.some(item => !!item)" v-model="number" maxlength="12" minlength="8" placeholder="000 000 00 00" type="number">
        <button :disabled="!number || isShowTimer" @click="sendSms" class="default-hover">
          <template v-if="!isShowTimer">Send</template>
          <TwoFaTimer @end="isShowTimer = false" seconds="30" v-else />
        </button>
      </div>

      <div class="verification">
        <span>{{ $t('fa_code') }}</span>

        <div class="inputs">
          <input type="number" v-for="(input, index) in inputs" :key="index"
                 v-model="inputs[index]" :class="{'green': inputs[index], 'fail': codeInvalid}" :ref="'input' + index" @input="handleInput(index, $event)"
                 @keydown="handleKeyDown(index, $event)" @keydown.enter.stop.prevent="sendConfirmation" maxlength="1"/>
        </div>
      </div>

      <AccentBtn :is-loading="isLoading" @click="sendConfirmation" :disabled="!isEnteredCode || !number || codeInvalid" :title="$t('fa_set')" />

      <div class="right__p last">
        {{ $t('fa_help') }}
      </div>

    </div>
  </div>
</template>

<style scoped lang="scss">
  .two-fa {
    display: flex;
    .right {
      display: flex;
      flex-direction: column;
      border-radius: 20px;
      overflow: hidden;
      width: 390px;
      position: relative;
      min-width: 390px;
      padding: 20px;
      background: #131139;
      .right__head {
        display: flex;
        align-items: center;
        margin-bottom: 14px;
        .circle {
          width: 48px;
          height: 48px;
          border-radius: 100%;
          margin-right: 14px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: rgba(182, 127, 255, 0.10);
        }
        .text {
          h2 {
            font-family: "Unbounded";
            font-size: 18px;
            font-weight: 700;
            letter-spacing: -0.36px;
          }
        }
      }
      .right__p {
        color: #7F7DA6;
        font-size: 14px;
        font-family: "Geometria-Medium";
        font-weight: 500;
        line-height: 22px; /* 157.143% */
        letter-spacing: -0.28px;
      }
      .verification {
        margin: 12px 0;
        border-radius: 13px;
        background: rgba(200, 197, 255, 0.05);
        padding: 12px;
        span {
          color: #7F7DA6;
          font-family: "Geometria-Medium";
          font-size: 12px;
          font-weight: 500;
          letter-spacing: -0.24px;
        }
        .inputs {
          margin-top: 14px;
          display: grid;
          grid-template-columns: repeat(6, 1fr);
          grid-gap: 4px;
          input {
            height: 73px;
            overflow: hidden;
            outline: none;
            text-align: center;
            color: var(--800, #918FBB);
            font-family: "Unbounded";
            font-size: 18px;
            font-weight: 700;
            letter-spacing: -0.36px;
            transition: 0.2s;
            border-radius: 8px;
            display: block;
            border: 2px solid #2B2A4E;
            background: #0C0A33;
            &.green {
              border-color: forestgreen;
            }
            &.fail {
              border-color: indianred !important;
            }
          }
        }
      }
      .last {
        text-align: center;
        width: 100%;
        margin-top: 12px;
      }
      .phone-input {
        display: flex;
        align-items: center;
        border-radius: 10px;
        padding: 8px;
        background: rgba(200, 197, 255, 0.05);
        margin: 12px 0;
        .country-select {
          max-width: 100px;
        }
        input {
          padding: 12px;
          background: transparent;
          border: none;
          outline: none;
          font-family: "Geometria-Medium";
          flex-grow: 1;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          padding-top: 15px;
          overflow: hidden;
          letter-spacing: -0.28px;
          &::placeholder {
            color: #7F7DA6;
          }
        }
        & > button {
          width: fit-content;
          color: #0C0A33;
          height: 35px;
          font-size: 14px;
          font-family: "Geometria-Bold";
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          letter-spacing: -0.28px;
          padding-right: 32px;
          background: linear-gradient(180deg, #F7FC00 -4.55%, #FFEA31 98.49%);
          padding-left: 32px;
          border-radius: 11px;
          padding-top: 2px;
          &[disabled] {
            cursor: default;
            opacity: 0.7 !important;
          }
        }
      }
    }
  }
</style>