import { render, staticRenderFns } from "./TelegramSocialAuth.vue?vue&type=template&id=c78056fe&scoped=true"
import script from "./TelegramSocialAuth.vue?vue&type=script&lang=js"
export * from "./TelegramSocialAuth.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c78056fe",
  null
  
)

export default component.exports