<script>
import StatisticBlock from "@/components/Profile/StatisticBlock.vue";
import StatisticItem from "@/components/Profile/StatisticItem.vue";
import {mapGetters} from "vuex";

export default {
  computed: {
    ...mapGetters({
      user: 'user/user'
    })
  },
  name: "ActivityStatistic",
  components: {StatisticItem, StatisticBlock}
}
</script>

<template>
  <div class="activity-statistic">
    <StatisticBlock :icon="require('@/assets/images/profile/activity-icon.svg')" :name="$t('account.statistics.activity')">
      <div class="statistic-wrapper">
        <StatisticItem  :info-content="$t('deposits_text')" :with-info="true" :value="user.statistic.total_deposits" :desc="$t('account.statistics.deposits')" :icon="require('@/assets/images/deposit/wallet-2.svg')"/>
        <StatisticItem :with-info="false" value="10 943" :desc="$t('account.statistics.rains')" :icon="require('@/assets/images/rain-icon.svg')"/>
        <StatisticItem :with-info="false" :value="user.statistic.messages_in_chat" :desc="$t('account.statistics.messages')" :icon="require('@/assets/images/message-icon.svg')"/>
      </div>
    </StatisticBlock>
  </div>
</template>

<style scoped lang="scss">
.activity-statistic {
  display: flex;
  width: 100%;
  .statistic-wrapper {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 9px;
    .statistic-item:first-child {
      grid-column: 1 / -1;
    }
  }
}
</style>