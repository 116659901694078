<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import ReplyMessage from "@/components/Parts/Chat/ReplyMessage.vue";
import ChatCommands from "@/components/Parts/Chat/ChatCommands.vue";
import UnauthorizedInput from "@/components/Parts/Chat/UnauthorizedInput.vue";
import extractErrors from "@/helpers/extractErrors";
import {vue} from "@/main";
import { VEmojiPicker } from 'v-emoji-picker';
import TwoFaTimer from "@/components/Modals/TwoFa/TwoFaTimer.vue";
export default {
  components: {TwoFaTimer, UnauthorizedInput, ChatCommands, ReplyMessage, VEmojiPicker},
  data () {
    return {
      isOpenEmoji: false,
      message: '',
    }
  },
  methods: {
    ...mapMutations({
      setChatPausedTo: 'user/setChatPausedTo',
      setIsOpenCommandsList: 'chat/setIsOpenCommandsList',
      setReplyMessage: 'chat/setReplyMessage'
    }),
    ...mapActions({
      openLogin: 'config/openLogin',
      sendMessage: 'chat/sendMessage',
    }),
    selectEmoji(emoji) {
      if (emoji.data) {
        this.message += emoji.data.replace('null', '');
      }
    },
    async sendMessageProcessing ()
    {
      if (!this.message || this.user.chat_paused_to > 0) return;

      try {
        await this.sendMessage({message: this.message}).then(() => {
          this.closeEmoji()
          this.message = '';
        }).catch(err => {
          const errors = extractErrors(err);
          errors.forEach(item => {
            vue.$root.$emit('error', {
              title: 'Error',
              text: item
            })
          })
        })
      } catch (e) {
        console.error(e)
      }

      this.focusToInput()
    },

    focusToInput ()
    {
      this.$refs.input.focus()
    },
    closeEmoji() {
      this.isOpenEmoji = false;
    }
  },
  computed: {
    ...mapGetters({
      settings: 'settings/settings',
      isOpenCommandsList: 'chat/isOpenCommandsList',
      replyMessage: 'chat/replyMessage',
      isAuth: 'user/isAuth',
      user: 'user/user'
    }),
  },
  mounted() {
    document.addEventListener('click', this.closeEmoji)
  },
  beforeDestroy() {
    document.removeEventListener('click', this.closeEmoji)
  },
  watch: {
    replyMessage () {
      if (this.replyMessage) {
        this.focusToInput()
      }
    }
  },
  name: "ChatInput"
}
</script>

<template>
  <div class="chat-input">
    <div class="chat-textarea" :class="{'replying': !!replyMessage}">
      <div class="emoji-wrapper" @click.prevent.stop v-show="isOpenEmoji">
        <VEmojiPicker :emojisByRow="10" :dark="true" :emojiSize="26" @select="selectEmoji" />
      </div>
      <template v-if="isAuth">
        <div class="chat-banned" v-if="user.chat_banned">
          <img src="@/assets/images/sidebar/lock-icon.svg" alt="">
          {{ $t('chat_banned') }}
        </div>
        <div class="chat-banned" v-else-if="user.chat_muted_to > 0">
          <img src="@/assets/images/sidebar/lock-icon.svg" alt="">
          {{ $t('chat_muted') }}
          <div class="timer">
            <TwoFaTimer :seconds="user.chat_muted_to" />
          </div>
        </div>
        <template v-else>
          <transition name="fade">
            <ChatCommands v-show="isOpenCommandsList" />
          </transition>

          <input ref="input" @keydown.enter.prevent.stop="sendMessageProcessing" v-model="message" name="" id="" cols="30" rows="10" placeholder="Message" />
          <div class="reply-message" v-if="replyMessage">
            <div class="reply-message-icon">
              <img src="@/assets/images/arrow-reply.png" alt="">
            </div>
            <div class="reply-message-body">
              <ReplyMessage :message="replyMessage" />
            </div>
            <div class="reply-message-cancel default-hover" @click="setReplyMessage(null)">
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                <path d="M11 1L1 11M1 1L11 11" stroke="#918FBB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
          </div>
          <div class="chat-textarea-actions">
            <button class="rain default-hover" v-if="!user.chat_paused_to" :class="{'open': isOpenCommandsList}" @click="setIsOpenCommandsList(!isOpenCommandsList)">
              <img src="@/assets/images/chat/commands-icon.svg" v-if="!isOpenCommandsList" alt="">
              <img src="@/assets/images/chat/commands-close.svg" v-else alt="">
            </button>

            <button class="emoji default-hover" @click.prevent.stop="isOpenEmoji = !isOpenEmoji">
              <img src="@/assets/images/smile-icon.svg" alt="">
            </button>
            <button :disabled="!message || user.chat_paused_to > 0" @click.prevent.stop="sendMessageProcessing" class="send default-hover">
              <TwoFaTimer @end="setChatPausedTo(0)" :show-minutes="false" :seconds="user.chat_paused_to" v-if="user.chat_paused_to > 0" />
              <img src="@/assets/images/send-icon.svg" alt="" v-else>
            </button>
          </div>
        </template>
      </template>
      <template v-else>
        <UnauthorizedInput />
      </template>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .chat-input {
    padding: 8px;
    .chat-commands {
      position: absolute;
      bottom: calc(100% + 8px);
    }
    .chat-banned {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      color: var(--800, #918FBB);
      font-size: 14px;
      font-family: "Geometria-Bold";
      font-style: normal;
      font-weight: 700;
      position: relative;
      line-height: normal;
      letter-spacing: -0.28px;
      img {
        margin-bottom: 2px;
        margin-right: 5px;
      }
      &.big {
        padding: 5px 0;
      }
      & > .timer {
        margin-left: 10px;
        ::v-deep {
          .timer {
            width: fit-content;
            display: flex;
            justify-content: flex-end;
            color: #fff;
          }
        }
      }
    }
    .chat-textarea {
      border-radius: 10px;
      background: #1D1B41;
      position: relative;
      height: 50px;
      width: 100%;
      .emoji-wrapper {
        position: absolute;
        right: 5px;
        height: 250px;
        bottom: 60px;
        width: 280px;
        overflow: hidden;
        @media (max-width: 600px) {
          width: 100%;
          height: 300px;
          right: 0;
        }
        ::v-deep {

          #EmojiPicker {
            background: #0c0a33;
            display: flex;
            flex-direction: column;
            height: 100%;
            #Emojis {
              height: 250px;
              overflow: hidden;
              .container-emoji {
                height: 100%;
                overflow: hidden;
                .grid-emojis {
                  overflow-x: hidden;
                  overflow-y: scroll;
                  height: 100%;
                }
              }
            }
          }
          .grid-emojis {
            padding: 0 10px !important;
            padding-bottom: 10px !important;
          }

          #InputSearch {
            input {
              outline: none;
              border: none;
              background: #2B2A4E !important;
            }
          }
          .container-search {
            padding: 0 10px !important;
          }
          #Categories {
            background: #2B2A4E;
            overflow-x: unset;
            &::-webkit-scrollbar {
              display: none;
            }
            .category {
              padding: 10px 5px;
              padding-bottom: 7px;
              border-bottom: 3px solid transparent;
            }
            .category.active {
              border-color: #8862af !important;
            }
          }
          .emoji-picker {
            width: 100%;
          }
        }
      }
      &.replying {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
      input {
        height: 100%;
        background: transparent;
        outline: none;
        border: none;
        padding: 16px;
        padding-right: 50%;
        width: 100%;
        resize: none;

        &::placeholder {
          color: #918FBB;
        }
        font-family: 'Geometria-Bold';
        letter-spacing: -0.28px;
      }
      .chat-textarea-actions {
        position: absolute;
        right: 8px;
        top: 0;
        padding-bottom: 2px;
        bottom: 0;
        height: 100%;
        display: flex;
        align-items: center;

        button {
          width: 38px;
          height: 38px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 12px;
          &.send {
            border-radius: 12px;
            box-shadow: 0px -0.8px 1px 1px #BC79FF;
            background: linear-gradient(180deg, #9D42F8 -4.55%, #7F00FF 100%);
            margin-top: 3px;
            padding-bottom: 3px;
            .timer {
              color: #fff;
              font-weight: bold;
              padding-top: 2px;
            }
            &[disabled] {
              opacity: 0.5;
              cursor: default;
            }
          }
          &.rain {
            width: 39px;
            height: 39px;
            border-radius: 12px;
            background: rgba(114, 238, 255, 0.20);
          }
          &.emoji {

            width: 39px;
            height: 39px;
            border-radius: 12px;
            background: #2B2A4E;
          }
          &.open {
            background: #131139;
          }
        }
        button + button {
          margin-left: 4px;
        }
      }
      .reply-message {
        position: absolute;
        bottom: 100%;
        width: 100%;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        background: #1D1B41;
        padding: 8px;
        display: flex;
        align-items: center;
        .reply-message-icon {
          width: 16px;
          min-width: 16px;
          margin-right: 9px;
        }
        .reply-message-body {
          border-radius: 5px;
          flex-grow: 1;
          background: #131139;
          .message-body__reply {
            background: #131139;
          }
        }
        .reply-message-cancel {
          width: 20px;
          min-width: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 8px;
        }
      }
    }
  }
</style>