<script>
export default {
  name: "UiClose"
}
</script>

<template>
  <div class="ui-close default-hover" @click="$emit('click')">
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M17 7L7 17M7 7L17 17" stroke="#918FBB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </div>
</template>

<style scoped lang="scss">
  .ui-close {
    border-radius: 100%;
    width: 40px;
    height: 40px;
    position: absolute;
    right: 10px;
    top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #02002A;
  }
</style>