<script>
export default {
  name: "TextWrapper"
}
</script>

<template>
  <div class="text-wrapper">
    <slot></slot>
  </div>
</template>

<style scoped lang="scss">
* {
  color: var(--800, #918FBB);
  font-size: 15px;
  font-style: normal;
  font-family: "Geometria-Medium";
  font-weight: 500;
  line-height: 25px;
  display: block;

  letter-spacing: -0.3px;
}
.text-wrapper {
  max-width: 100%;
  overflow-x: hidden;
}

  h1 {
    display: block;
    font-family: Unbounded;
    font-size: 46px;
    font-style: normal;
    color: #fff;
    font-weight: 700;
    line-height: 50px; /* 108.696% */
    letter-spacing: -0.92px;
    margin-bottom: 18px;
    @media (max-width: 768px) {
      font-size: 24px;
      line-height: 28px;
      margin-bottom: 10px;
    }
  }
  h2,h3,h4,h5,h6 {
    color: #fff;
    font-family: Unbounded;
    display: block;

    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.28px;
    margin-bottom: 12px;
    @media (max-width: 768px) {
      font-size: 13px;
    }
  }

  h2 {
    font-size: 34px;

    @media (max-width: 768px) {
      font-size: 26px;
    }
  }
  h3 {
    font-size: 28px;

    @media (max-width: 768px) {
      font-size: 24px;
    }
  }
  h4 {
    font-size: 22px;

    @media (max-width: 768px) {
      font-size: 20px;
    }
  }
  h5 {
    font-size: 18px;
    @media (max-width: 768px) {
      font-size: 16px;
    }
  }
  h6 {
    font-size: 16px;
    @media (max-width: 768px) {
      font-size: 14px;
    }
  }

  ul {
    padding-left: 15px;
    margin-bottom: 20px;
    max-width: 100%;
    overflow-x: hidden;
    display: block;
    li {
      color: var(--900, #B9B8DD);
      font-family: "Geometria-Medium";
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: 25px; /* 166.667% */
      letter-spacing: -0.3px;
      display: block;
      padding-left: 35px;
      margin-bottom: 12px;
      background-position: center center;
      position: relative;
      @media (max-width: 768px) {
        font-size: 13px;
        line-height: 23px;
        margin-bottom: 12px;
      }
      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0px;
        width: 24px; /* Ширина изображения */
        height: 24px; /* Высота изображения */
        background-image: url(@/assets/images/li.svg); /* Путь к вашему изображению */
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
  }

  p {
    display: block;
    margin-bottom: 24px;
    color: var(--800, #918FBB);
    font-size: 15px;
    font-family: "Geometria-Medium";
    font-style: normal;
    font-weight: 500;
    line-height: 25px;
    letter-spacing: -0.3px;
    white-space: break-spaces;
    max-width: 100%;
    overflow: hidden;
    @media (max-width: 768px) {
      font-size: 13px;
      line-height: 23px;
      margin-bottom: 16px;
    }
  }

</style>