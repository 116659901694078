<script>
import {mapGetters} from "vuex";

export default {
  props: {
    level: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      user: 'user/user'
    })
  },
  name: "LevelItem"
}
</script>

<template>
  <div class="level" :class="{'disabled': level.status  === 'passed'}">
    <div class="level-image">
      <img :src="level.icon" alt="">
    </div>
    <div v-if="!user?.level?.level || user.level.level < level.level" class="level-status">
      <img src="@/assets/images/profile/upcoming.svg" alt="">
      <span>{{ $t('current_level.upcoming') }}</span>
    </div>
    <div v-else class="level-status">
      <img src="@/assets/images/profile/check-icon.svg" alt="">
      <span>{{ $t('current_level.passed') }}</span>
    </div>
    <div class="level-name">{{level.name}}</div>
  </div>
</template>

<style scoped lang="scss">
  .level {
    width: 123px;
    height: 155px;
    border-radius: 14px;
    background: rgba(200, 197, 255, 0.05);
    padding-top: 26px;
    @media (max-width: 1700px) {
      height: unset;
      position: relative;
    }
    .level-image {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: 15px;
      user-select: none;
      img {
        user-select: none;
        max-height: 50px;
      }
    }

    .level-status {
      margin: 0 auto;
      width: fit-content;
      border-radius: 8px;
      background: rgba(171, 107, 255, 0.20);
      margin-bottom: 2px;
      padding: 6px 8px;
      display: flex;
      align-items: center;
      img {
        margin-right: 4px;
      }
      span {
        display: flex;
        align-items: center;
        font-size: 11px;
        font-family: "Geometria-Medium";
        letter-spacing: -0.22px;
      }
    }
    .level-name {
      font-family: "Unbounded";
      width: 100%;
      text-align: center;
      margin-top: 5px;
      font-size: 14px;
      font-weight: 700;
      letter-spacing: -0.28px;
      opacity: 0.7;
      @media (max-width: 1700px) {
        position: absolute;
        bottom: 10px;
      }
    }

    &.disabled {
      .level-status {
        background: linear-gradient(180deg, rgba(154, 255, 52, 0.20) 0%, rgba(118, 236, 0, 0.20) 100%);
      }
    }
  }
</style>