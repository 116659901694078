<script>
import {mapActions} from "vuex";

export default {
  props: {
    text: {
      type: String,
      default: null
    }
  },
  methods: {
    ...mapActions({
      openRegister: 'config/openRegister'
    })
  }
}
</script>

<template>
  <button class="default-hover signup" @click="openRegister">{{ text ?? $t('sign_up') }}</button>
</template>

<style scoped lang="scss">
.signup {
  border-radius: 11px;
  box-shadow: 0px -1px 1px 1px #BC79FF;
  background: var(--Primary, linear-gradient(180deg, #9D42F8 -4.55%, #7F00FF 100%));
}
button {
  padding: 12px 24px;
  font-size: 14px;
  font-family: 'Geometria-Bold';
  letter-spacing: -0.28px;
}
</style>