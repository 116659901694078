<script>
import MyNftList from "@/components/Profile/Nft/MyNftList.vue";
import MyNftSales from "@/components/Profile/Nft/MyNftSales.vue";

export default {
  name: "ProfileNft",
  components: {MyNftSales, MyNftList}
}
</script>

<template>
  <div class="profile-nft">
    <div class="my-nft-list">
      <div class="my-nft-list__head">
        <div class="my-nft-list__head-icon">
          <img src="@/assets/images/nft/nft-icon.svg" alt="">
        </div>
        <div class="my-nft-list__head-name">
          {{ $t('account.nfts.title') }}
        </div>
      </div>
      <div class="my-nft-list__items">
        <MyNftList/>
      </div>
    </div>

    <div class="my-nft-sales">
      <MyNftSales/>
    </div>

  </div>
</template>

<style scoped lang="scss">
  .profile-nft {
    display: flex;
    .my-nft-list {
      margin-right: 12px;
      display: flex;
      height: 680px;
      flex-direction: column;
      overflow: hidden;
      width: 450px;
      min-width: 450px;
      border-radius: 16px;
      background: #131139;
      padding: 18px;
      padding-bottom: 0;
      @media (max-width: 1900px) {
        width: 350px;
        min-width: 350px;
      }
      .my-nft-list__head {
        display: flex;
        align-items: center;
        .my-nft-list__head-icon {
          width: 24px;
          height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 8px;
          @media (max-width: 768px) {
            width: 20px;
            height: 20px;
            img {
              max-width: 100%;
            }
          }
        }
        .my-nft-list__head-name {
          font-family: "Unbounded";
          font-size: 16px;
          font-weight: 700;
          letter-spacing: -0.32px;
          @media (max-width: 768px) {
            font-size: 14px;
          }
        }
      }
      .my-nft-list__items {
        overflow: hidden;
        margin-top: 20px;
        @media (max-width: 992px) {
          min-height: 200px;
        }
      }

      position: relative;
      &::after {
        content: "";
        display: block;
        position: absolute;
        height: 100px;
        background: linear-gradient(0deg, #1F1D51 0%, rgba(40, 37, 91, 0.00) 100%);
        bottom: 0;
        left: 0;
        z-index: 6;
        width: 100%;
      }
    }
    .my-nft-sales {
      height: 680px;
      border-radius: 16px;
      flex-grow: 1;
      background: #131139;
      padding: 18px;
      overflow: hidden;
    }

    @media (max-width: 992px) {
      display: block;
      .my-nft-list {
        width: 100%;
        min-width: 100%;
        height: auto;
        margin-bottom: 10px;
      }
    }
  }
</style>