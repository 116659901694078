<script>
import UiInput from "@/components/Ui/UiInput.vue";
import { getProviders } from "@/services/games";
import AccentBtn from "@/components/Ui/Buttons/AccentBtn.vue";
import {mapActions} from "vuex";
import EmptyState from "@/components/EmptyState.vue";

export default {
  data () {
    return {
      isLoading: false,
      providers: [],
      search: null,
      isOpenDropdown: false,
      selected: []
    }
  },
  watch: {
    search() {
      this.fetchProviders()
    }
  },
  methods: {
    ...mapActions({
      apply: 'games/updateProviders'
    }),
    async goApply(providers) {
      this.isLoading = true;
      await this.apply(providers)
      this.isLoading = false;
      this.isOpenDropdown = false;
    },
    fetchProviders() {
      const data = {
        per_page: 99999,
        page: 1
      };

      if (this.search) {
        data.search = this.search;
      }

      getProviders(data).then((response) => {
        this.providers = response.data;
      })
    },
    removeSelect(index) {
      this.selected.splice(index, 1)
    },
    close(event) {
      const body = this.$refs.body;
      if(!body.contains(event.target)) {
        this.isOpenDropdown = false;
      }
    }
  },
  mounted() {
    document.addEventListener('click', this.close);

    this.fetchProviders();
  },
  beforeDestroy() {
    document.removeEventListener('click', this.close);
  },
  name: "ProvidersFilter",
  components: {EmptyState, AccentBtn, UiInput}
}
</script>

<template>
  <div class="providers-filter">
    <div class="providers-filter__main" @click.prevent.stop="isOpenDropdown = !isOpenDropdown">
      <div class="providers-filter__main-label">
        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="14" viewBox="0 0 13 14" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M1.70847 12.8623C2.52892 13.7853 3.97109 13.7853 4.79154 12.8623L5.92042 11.5923C6.12682 11.3602 6.1059 11.0046 5.87371 10.7982C5.64152 10.5918 5.28598 10.6127 5.07959 10.8449L3.9507 12.1149C3.90805 12.1629 3.8617 12.2054 3.8125 12.2424V1.75736C3.8617 1.79437 3.90805 1.83686 3.9507 1.88485L5.07959 3.15485C5.28598 3.38704 5.64152 3.40795 5.87371 3.20156C6.1059 2.99517 6.12682 2.63963 5.92042 2.40744L4.79154 1.13744C3.97109 0.21444 2.52892 0.214441 1.70847 1.13744L0.579588 2.40744C0.373197 2.63963 0.394111 2.99517 0.626301 3.20156C0.858492 3.40795 1.21403 3.38704 1.42042 3.15485L2.54931 1.88485C2.59196 1.83687 2.6383 1.79438 2.6875 1.75737V12.2424C2.6383 12.2054 2.59196 12.1629 2.54931 12.1149L1.42042 10.8449C1.21403 10.6127 0.858492 10.5918 0.626301 10.7982C0.394111 11.0046 0.373197 11.3602 0.579588 11.5923L1.70847 12.8623ZM8.5 2.49982C8.5 1.67139 9.17157 0.999817 10 0.999817H11.5C12.3284 0.999817 13 1.67139 13 2.49982V3.99982C13 4.82824 12.3284 5.49982 11.5 5.49982H10C9.17157 5.49982 8.5 4.82824 8.5 3.99982V2.49982ZM8.5 9.99982C8.5 9.17139 9.17157 8.49982 10 8.49982H11.5C12.3284 8.49982 13 9.17139 13 9.99982V11.4998C13 12.3282 12.3284 12.9998 11.5 12.9998H10C9.17157 12.9998 8.5 12.3282 8.5 11.4998V9.99982Z" fill="#7F7DA6"/>
        </svg>
        <span>{{ $t('p_title') }}</span>
      </div>
      <div class="providers-filter__main-value">
        {{ $t('p_selected') }}
        <div class="providers-filter__main-value-counter" v-if="selected.length > 0">
          {{ selected.length }}
        </div>
        <div class="providers-filter__main-arrow" :class="{'transform': isOpenDropdown}">
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
            <path d="M4.5 6.75L9 11.25L13.5 6.75" stroke="#7F7DA6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
      </div>
    </div>
    <transition name="fade">
      <div class="providers-filter__dropdown" ref="body" v-show="isOpenDropdown">
        <UiInput :icon="require('@/assets/images/search-icon.svg')" type="text" placeholder="Search for provider" v-model="search" />

        <div class="providers-filter__dropdown-selected" v-if="selected.length">
          <div class="providers-filter__dropdown-selected-title">
            {{ $t('p_selected') }}
          </div>
          <div class="providers-filter__dropdown-selected-items">
            <div class="providers-filter__dropdown-selected-item clear" @click="selected = []">
              {{ $t('p_clear_all') }}
            </div>
            <div class="providers-filter__dropdown-selected-item" v-for="(select,i) in selected" :key="i">
              {{ select }}
              <div class="providers-filter__dropdown-selected-item__close default-hover" @click.prevent.stop="removeSelect(i)">
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                  <path d="M12.6663 5.33333L5.33301 12.6667M5.33301 5.33333L12.6663 12.6667" stroke="#918FBB" stroke-width="1.46667" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div class="providers-filter__dropdown-providers">
          <div class="providers-filter__dropdown-providers-title">
            {{ $t('p_providers') }}
          </div>
          <div class="providers-filter__dropdown-providers-items">
            <template v-if="providers.length">
              <div class="providers-filter__dropdown-providers-item" v-for="(provider, i) in providers" :key="i">
                <input v-model="selected" :value="provider.provider_name" :id="provider.provider_name" type="checkbox">
                <label :for="provider.provider_name">
                  <span class="square"></span>
                  <span class="label">{{ provider.provider_name }}</span>
                </label>
              </div>
            </template>
            <EmptyState v-if="!isLoading && !providers.length" :heading="$t('p_not_found')"></EmptyState>
          </div>
        </div>
        <div class="providers-filter__dropdown-actions">
          <AccentBtn :is-loading="isLoading" title="Confirm" @click="goApply(selected)" />
          <div class="clear default-hover" @click="selected = [], goApply([])">
            {{ $t('p_clear_all') }}
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<style scoped lang="scss">
  .providers-filter {
    position: relative;
    .providers-filter__main {
      border-radius: 12px;
      background: var(--300, #1D1B41);
      padding: 14px 16px;
      display: flex;
      align-items: center;
      padding-right: 46px;
      position: relative;
      cursor: pointer;
      .providers-filter__main-label {
        display: flex;
        align-items: center;
        svg {
          margin-right: 12px;
        }
        span {
          color: var(--800, #918FBB);
          font-size: 14px;
          font-family: "Geometria-Medium";
          font-style: normal;
          padding-top: 1px;
          font-weight: 500;
          display: flex;
          align-items: center;
          line-height: normal;
          letter-spacing: -0.28px;
        }
      }
      .providers-filter__main-value {
        font-size: 14px;
        font-style: normal;
        margin-left: 12px;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.28px;
        font-family: "Geometria-Medium";
        .providers-filter__main-value-counter {
          width: 18px;
          height: 18px;
          border-radius: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #000;
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          margin-left: 6px;
          letter-spacing: -0.24px;
          margin-bottom: 1px;
          font-family: "Geometria-Bold";
          padding-top: 2px;
          background: #FFE70C;
        }
        display: flex;
        align-items: center;
      }
      .providers-filter__main-arrow {
        position: absolute;
        right: 16px;
        display: flex;
        align-items: center;
        height: 18px;
        top: calc(50% - 9px);
        transition: 0.2s;
        &.transform {
          transform: rotate(180deg);
        }
      }
      &:hover {
        opacity: 0.8;
      }
      transition: 0.2s;
    }
    .providers-filter__dropdown {
      border-radius: 12px;
      background: var(--300, #1D1B41);
      padding: 7px 6px;
      z-index: 9;
      position: absolute;
      top: calc(100% + 7px);
      right: 0;
      width: 322px;
      @media (max-width: 768px) {
        width: 100%;
      }
      .ui-input {
        background: #302E52;
      }
      .providers-filter__dropdown-selected {
        margin: 9px 0;
        margin-bottom: 0;
        padding: 0px 8px;
        .providers-filter__dropdown-selected-title {
          color: var(--800, #918FBB);
          font-family: "Geometria-Bold";
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          letter-spacing: 0.84px;
        }
        .providers-filter__dropdown-selected-items {
          margin-top: 4px;
          display: flex;
          grid-gap: 4px;
          flex-wrap: wrap;
          .providers-filter__dropdown-selected-item {
            border-radius: 6px;
            background: #02002A;
            padding: 4px;
            display: flex;
            align-items: center;
            color: var(--800, #918FBB);
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            font-family: "Geometria-Medium";
            padding-left: 8px;
            letter-spacing: -0.28px;
            .providers-filter__dropdown-selected-item__close {
              margin-left: 8px;
              width: 22px;
              height: 22px;
              border-radius: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              background: #302E52;
            }
            &.clear {
              background: rgba(255, 67, 67, 0.23);
              color: #FF7474;
              cursor: pointer;
              padding-right: 8px;
              padding-top: 6px;
              padding-bottom: 6px;
              &:hover {
                opacity: 0.8;
              }
              transition: 0.2s;
            }
          }
        }
      }
      .providers-filter__dropdown-providers {
        padding: 0px 8px;
        margin-top: 9px;
        .providers-filter__dropdown-providers-title {
          color: var(--800, #918FBB);
          font-family: "Geometria-Bold";
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          letter-spacing: 0.84px;
        }
        .providers-filter__dropdown-providers-items {
          margin-top: 9px;
          max-height: 180px;
          overflow-y: auto;
          padding-bottom: 50px;
          &::-webkit-scrollbar {
            display: none;
          }
          .providers-filter__dropdown-providers-item {
            input {
              display: none;
            }
            label {
              cursor: pointer;
              display: flex;
              align-items: center;
              .square {
                width: 22px;
                height: 22px;
                border-radius: 6px;
                border: 1px solid #44426A;
                margin-right: 8px;
                position: relative;
                &::after {
                  position: absolute;
                  width: 14px;
                  height: 14px;
                  border-radius: 4px;
                  background: #C085FF;
                  content: "";
                  display: block;
                  left: 3px;
                  top: 3px;
                  opacity: 0;
                  transition: 0.2s;
                }
              }
              .label {
                color: var(--800, #918FBB);
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                padding-top: 1px;
                font-family: "Geometria-Medium";
                line-height: normal;
                transition: 0.2s;
                letter-spacing: -0.32px;
              }
            }

            input:checked + label {
              .label {
                color: #D8D6FF;
              }
              .square {
                &::after {
                  opacity: 1;
                }
              }
            }
          }
          .providers-filter__dropdown-providers-item + .providers-filter__dropdown-providers-item {
            margin-top: 9px;
          }
        }
      }
      .providers-filter__dropdown-actions {
        padding: 0px 8px;
        display: flex;
        padding-top: 5px;
        padding-bottom: 8px;
        justify-content: space-between;
        box-shadow: 0px -20px 28px 4px #1D1B41;
        position: relative;
        .accent-btn {
          width: 102px;
        }
        .clear {
          border-radius: 11px;
          border: 1px solid var(--500, #3E3C5F);
          background: var(--400, #2B2A4E);
          padding: 12px 24px;
          display: flex;
          align-items: center;
          font-size: 14px;
          font-style: normal;
          font-family: "Geometria-Bold";
          font-weight: 700;
          line-height: normal;
          letter-spacing: -0.28px;

        }
      }
    }
  }
</style>