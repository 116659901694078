<script>
export default {
  props: {
    options: {
      type: Array,
    },
    default: {
      type: Object
    }
  },
  data () {
    return {
      isOpenList: false,
      currentOption: null
    }
  },
  watch: {
    currentOption() {
      this.$emit('update', this.currentOption)
    }
  },
  computed: {
    current () {
      if (!this.currentOption) return this.default

      return this.currentOption
    }
  },
  name: "SortFilter"
}
</script>

<template>
  <div class="blog__filters-sort">
    <div @click="isOpenList = !isOpenList" class="blog__filters-select">
      <div class="blog__filters-current">
        <div class="blog__filters-current-icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="13" height="14" viewBox="0 0 13 14" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.70847 12.8625C2.52892 13.7855 3.97109 13.7855 4.79154 12.8625L5.92042 11.5925C6.12682 11.3603 6.1059 11.0047 5.87371 10.7983C5.64152 10.592 5.28598 10.6129 5.07959 10.8451L3.9507 12.1151C3.90805 12.163 3.8617 12.2055 3.8125 12.2425V1.75748C3.8617 1.79449 3.90805 1.83699 3.9507 1.88497L5.07959 3.15497C5.28598 3.38716 5.64152 3.40807 5.87371 3.20168C6.1059 2.99529 6.12682 2.63975 5.92042 2.40756L4.79154 1.13756C3.97109 0.214562 2.52892 0.214563 1.70847 1.13756L0.579588 2.40756C0.373197 2.63975 0.394111 2.99529 0.626301 3.20168C0.858492 3.40807 1.21403 3.38716 1.42042 3.15497L2.54931 1.88497C2.59196 1.83699 2.6383 1.7945 2.6875 1.75749V12.2425C2.6383 12.2055 2.59196 12.163 2.54931 12.1151L1.42042 10.8451C1.21403 10.6129 0.858492 10.592 0.626301 10.7983C0.394111 11.0047 0.373197 11.3603 0.579588 11.5925L1.70847 12.8625ZM8.5 2.49994C8.5 1.67151 9.17157 0.999939 10 0.999939H11.5C12.3284 0.999939 13 1.67151 13 2.49994V3.99994C13 4.82837 12.3284 5.49994 11.5 5.49994H10C9.17157 5.49994 8.5 4.82837 8.5 3.99994V2.49994ZM8.5 9.99994C8.5 9.17151 9.17157 8.49994 10 8.49994H11.5C12.3284 8.49994 13 9.17151 13 9.99994V11.4999C13 12.3284 12.3284 12.9999 11.5 12.9999H10C9.17157 12.9999 8.5 12.3284 8.5 11.4999V9.99994Z" fill="#7F7DA6"/>
          </svg>
        </div>
        <div class="blog__filters-current-sort-name">
          {{ $t('sort_by_text') }}:
        </div>
        <div class="blog__filters-current-sort-value">
          {{ current.name }}
        </div>
        <div class="blog__filters-current-sort-arrow" :class="{'rotate': isOpenList}">
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.5 6.75L9 11.25L13.5 6.75" stroke="#7F7DA6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
      </div>
      <div class="blog__filters-options" v-show="isOpenList">
        <div @click="currentOption = option" class="blog__filters-option default-hover" v-for="(option, i) in options" :key="i">{{ option.name }}</div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.blog__filters-sort {
  .blog__filters-select {
    .blog__filters-current {
      border-radius: 12px;
      background: #1D1B41;
      display: flex;
      transition: 0.2s;
      cursor: pointer;
      align-items: center;
      padding: 14px 16px;
      @media (max-width: 768px) {
        padding: 11px 16px;
      }
      &:hover {
        opacity: 0.8;
      }
      .blog__filters-current-icon {
        width: 18px;
        height: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 12px;
      }
      .blog__filters-current-sort-name {
        color: #918FBB;
        margin-right: 12px;
        font-family: "Geometria-Medium";
        font-size: 14px;
        font-weight: 500;
        display: flex;
        align-items: center;
        letter-spacing: -0.28px;
      }
      .blog__filters-current-sort-value {
        width: 100px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        font-size: 14px;
        display: flex;
        align-items: center;
        font-weight: 500;
        letter-spacing: -0.28px;
        font-family: "Geometria-Medium";
      }
      .blog__filters-current-sort-arrow {
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.2s;
        &.rotate {
          transform: rotate(180deg);
        }
      }
    }

    position: relative;
    .blog__filters-options {
      position: absolute;
      top: calc(100% + 5px);
      z-index: 6;
      width: 100%;
      left: 0;
      padding: 6px;
      background: #1D1B41;
      border-radius: 12px;
      .blog__filters-option {
        padding: 12px;
        border-radius: 12px;
        background: #131139;

        color: #918FBB;
        margin-right: 12px;
        font-family: "Geometria-Medium";
        font-size: 14px;
        font-weight: 500;
        display: flex;
        align-items: center;
        letter-spacing: -0.28px;
      }
      .blog__filters-option + .blog__filters-option {
        margin-top: 4px;
      }
    }
  }
}

</style>