<script>
import CryptoDeposit from "@/components/Modals/Deposit/Contents/Deposits/CryptoDeposit.vue";
import FiatDeposit from "@/components/Modals/Deposit/Contents/Deposits/FiatDeposit.vue";

export default {
  components: {FiatDeposit, CryptoDeposit},
  data () {
    return {
      tab: 'crypto'
    }
  },
  name: "DepositForm"
}
</script>

<template>
  <div class="deposit-form">
    <div class="deposit-form__tabs">
      <div class="deposit-form__tab" :class="{'active': tab === 'crypto'}" @click="tab = 'crypto'">
        {{ $t('d_crypt') }}
      </div>
      <div class="deposit-form__tab" :class="{'active': tab === 'fiat'}" @click="tab = 'fiat'">
        {{ $t('d_fiat') }}
      </div>
    </div>

    <div class="deposit-form__content">
      <CryptoDeposit v-if="tab === 'crypto'" />
      <FiatDeposit v-if="tab === 'fiat'" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.deposit-form {
  .deposit-form__tabs {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    .deposit-form__tab {
      padding: 8px 0;
      font-size: 14px;
      font-family: "Geometria-Bold";
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.28px;
      border-bottom: 2px solid var(--10, rgba(200, 197, 255, 0.10));
      text-align: center;
      transition: 0.2s;
      cursor: pointer;
      &:hover {
        opacity: 0.8;
      }
      &.active {
        border-color: #AB6BFF;
      }
    }
  }
  .deposit-form__content {
    @media (max-width: 768px) {
      min-height: 250px;
    }
  }
}
</style>