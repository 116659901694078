<script>
import DepositSelect from "@/components/Modals/Deposit/Ui/DepositSelect.vue";
import { getCryptoCurrencies } from "@/services/deposit";
import UiInput from "@/components/Ui/UiInput.vue";
import AccentBtn from "@/components/Ui/Buttons/AccentBtn.vue";
import PayForm from "@/components/Modals/Deposit/Contents/Deposits/Crypto/PayForm.vue";
import UiLoader from "@/components/UiLoader.vue";
import { createCryptoDeposit, getCurrentDeposit } from "@/services/user";
import extractErrors from "@/helpers/extractErrors";

export default {
  data () {
    return {
      isCreateLoading: false,
      isLoading: true,
      amount: null,
      selectCurrency: null,
      selectNetwork: null,
      currencies: [],
      depositInstance: null,
    }
  },
  watch: {
    selectCurrency() {
      this.selectNetwork = null;
    }
  },
  computed: {
    selectCurrencyProxy() {
      if (!this.selectCurrency) {
        if (!this.currencies.length) {
          return null;
        }

        return this.currencies[0];
      }

      return this.selectCurrency;
    },
    selectCurrencyNetworkProxy ()
    {
      if (this.selectNetwork) {
        return this.selectNetwork
      }

      if (!this.selectCurrencyProxy) {
        return null;
      }

      return this.selectCurrencyProxy.networks[0];
    },
    getAmount () {
      return this.amount * this.selectCurrencyProxy.rate_to_usd;
    }
  },
  methods: {
    deposit () {
      this.isCreateLoading = true;
      createCryptoDeposit({
        currency_id: this.selectCurrencyProxy.id,
        network_id: this.selectCurrencyNetworkProxy.id,
        amount: this.amount,
      }).then(() => {
        this.init();
      }).catch(errors => {
        extractErrors(errors).forEach(item => {
          this.$root.$emit('error', {
            title: "Error",
            text: item,
          })
        })
      }).finally(() => {
        this.isCreateLoading = false;
      })
    },
    fetchCurrencies()
    {
      getCryptoCurrencies().then(response => {
        this.currencies = response.data.data;
      })
    },
    fetchActiveDeposit() {
      getCurrentDeposit().then(response => {
        this.depositInstance = response.data;
      }).catch(() => {
        this.depositInstance = null;
      })
    },
    async reset() {
      this.selectCurrency = null;
      this.selectNetwork = null;
      this.amount = null;
      this.init();
    },
    async init () {
      this.isLoading = true;
      try {
        await this.fetchCurrencies();
        await this.fetchActiveDeposit();
      } catch(e) {
        console.log(e.message);
      }
      this.isLoading = false;
    },
  },
  async mounted () {
    await this.init();

    this.$root.$on('refresh-deposit', () => {
      this.selectCurrency = null;
      this.selectNetwork = null;
      this.amount = null;
      this.init();
    })
  },
  name: "CryptoDeposit",
  components: {UiLoader, PayForm, AccentBtn, UiInput, DepositSelect}
}
</script>

<template>
  <div class="crypto-deposit" v-if="selectCurrencyProxy">
    <UiLoader size="small" v-if="isLoading" />
    <template v-else>
      <template v-if="!depositInstance">
        <div class="crypto-selects">
          <DepositSelect :disabled="isCreateLoading" :label="$t('d_currency')" @update="selectCurrency = $event" :value="selectCurrencyProxy" :options="currencies" />
          <DepositSelect :disabled="isCreateLoading" :label="$t('d_network')" @update="selectNetwork = $event" :value="selectCurrencyNetworkProxy" name-by="network_id" :options="selectCurrencyProxy.networks" />
        </div>

        <div class="crypto-inputs">
          <UiInput :readonly="isCreateLoading" v-model="amount" :icon="selectCurrencyProxy.icon" type="number" :placeholder="$t('d_amount')" />
          <UiInput :icon="require('@/assets/images/dollar.svg')" :value="parseFloat(getAmount).toFixed(2)" type="number" :placeholder="$t('d_you_get')" readonly />
        </div>

        <div class="crypto-bottom">
          <AccentBtn :is-loading="isCreateLoading" @click="deposit" :disabled="!amount" :title="$t('d_pay')" />
        </div>
      </template>
      <PayForm @update="reset" v-else :depositInstance="depositInstance" />
    </template>
  </div>
</template>

<style scoped lang="scss">
  .crypto-deposit {
    margin-top: 13px;
    display: grid;
    .crypto-selects {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      z-index: 2;
      grid-gap: 9px;
    }
    .crypto-inputs {
      margin-top: 12px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 9px;
    }
    .crypto-bottom {
      position: absolute;
      bottom: 20px;
      left: 20px;
      width: calc(100% - 40px);
      margin-top: auto;
    }
  }
</style>