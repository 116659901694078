import { render, staticRenderFns } from "./GamingStatistic.vue?vue&type=template&id=707d0967&scoped=true"
import script from "./GamingStatistic.vue?vue&type=script&lang=js"
export * from "./GamingStatistic.vue?vue&type=script&lang=js"
import style0 from "./GamingStatistic.vue?vue&type=style&index=0&id=707d0967&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "707d0967",
  null
  
)

export default component.exports