export default {
    setLootbox(state, lootbox) {
        state.lootbox = lootbox;
    },
    setIsPlaying(state, value) {
        state.isPlaying = value;
    },
    setWinItem(state, item) {
        state.winItem = item;
    },
    reset(state) {
        console.log('reset')
        state.isPlaying = false;
        state.lootbox = null;
        state.winItem = null;
    },
    setMode(state, mode) {
        state.mode = mode;
    },
    setAutoSelectItem(state, item) {
        state.autoSelectItem = item;
    },
    setSpinsCount(state, value) {
        state.spinsCount = value;
    },
    setCompletedSpinsCurrently(state, value) {
        state.completedSpinsCurrently = value;
    },
    setBalanceLimit(state, value) {
        state.balanceLimit = value;
    }
}