<script>
import {mapActions} from "vuex";

export default {
  name: "GoogleSocialAuth",
  methods: {
    ...mapActions({
      socialCallback: 'auth/socialCallback'
    }),
    socialCallbackProcessing (data) {
      this.socialCallback({data, provider: 'google'})
    }
  },
  mounted() {
    window.onload = async () => {
      await window.google.accounts.id.initialize({
        client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
        callback: this.socialCallbackProcessing
      });
      const parent = this.$refs.google;
      window.google.accounts.id.renderButton(parent, {theme: "outline", size: 'medium', shape: 'circle', type: 'icon'});
      window.google.accounts.id.prompt();
    }
  }
}
</script>

<template>
  <div class="google-social-auth" ref="google"></div>
</template>

<style scoped lang="scss">

</style>