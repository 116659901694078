<script>
export default {
  name: "ComingSoon"
}
</script>

<template>
  <div class="coming-soon">
    <h2>{{ $t('in_house_games') }}</h2>
    <div class="games">
      <div class="game">
        <img src="@/assets/images/sidebar/game-1.png" alt="">
      </div>
      <div class="game">
        <img src="@/assets/images/sidebar/game-2.png" alt="">
        <div class="hot">
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
            <path d="M3.58991 5.43856L3.5885 5.43961L3.5862 5.44136L3.58015 5.446C3.57549 5.4496 3.56958 5.45424 3.56253 5.45992C3.54842 5.47128 3.5297 5.4868 3.50711 5.50655C3.46197 5.54602 3.40119 5.60253 3.33091 5.67652C3.19053 5.8243 3.01084 6.04328 2.8425 6.33677C2.5029 6.92885 2.21751 7.81124 2.37793 8.99171C2.53618 10.1563 3.02522 11.1301 3.85285 11.8091C4.67752 12.4856 5.79431 12.8334 7.14455 12.8334C8.53727 12.8334 9.64846 12.3116 10.3845 11.4159C11.1138 10.5284 11.4391 9.31831 11.3613 7.99522C11.2866 6.72606 10.5953 5.76309 9.98464 4.91246C9.92568 4.83033 9.86746 4.74924 9.81063 4.66903C9.14451 3.72888 8.61852 2.86225 8.74631 1.65012C8.75931 1.52679 8.71936 1.40373 8.63639 1.31156C8.55342 1.21938 8.43523 1.16675 8.31122 1.16675C8.08839 1.16675 7.83341 1.23571 7.58655 1.33941C7.33208 1.4463 7.05608 1.60279 6.78583 1.80663C6.24644 2.21347 5.70713 2.8267 5.41924 3.64684C5.13206 4.46496 5.27756 5.24477 5.48791 5.81195C5.62605 6.18441 5.47623 6.55266 5.25072 6.65973C5.05129 6.75443 4.81267 6.67497 4.70981 6.47962L4.23887 5.58522C4.17929 5.47205 4.07317 5.39063 3.94844 5.36236C3.82371 5.3341 3.69245 5.36213 3.58991 5.43856Z" fill="white"/>
          </svg>
        </div>
      </div>
      <div class="game">
        <img src="@/assets/images/sidebar/game-3.png" alt="">
      </div>
      <div class="game">
        <img src="@/assets/images/sidebar/game-4.png" alt="">
        <div class="new">
          {{ $t('new') }}
        </div>
      </div>
      <div class="game">
        <img src="@/assets/images/sidebar/game-5.png" alt="">
      </div>
    </div>

    <div class="holder">
      <img src="@/assets/images/sidebar/lock.svg" alt="">
      <strong v-html="$t('coming_soon_with_br')"></strong>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .coming-soon {
    width: calc(100% - 8px);
    margin-left: 4px;
    position: relative;
    @media (max-width: 992px) {
      border-radius: 12px;
      overflow: hidden;
      background: rgba(23, 21, 71, 0.72);
      backdrop-filter: blur(3.700000047683716px);
      height: 88px;
      min-height: 88px;
    }
    @media (max-height: 680px) {
      height: 70px;
      min-height: 70px;
    }
    h2 {
      color: #7F7DA6;
      font-size: 12px;
      font-weight: 700;
      font-family: "Geometria-Bold";
      letter-spacing: 0.6px;
      padding-top: 7px;
      display: block;
      @media (max-width: 992px) {
        display: none;
      }
    }
    .games {
      padding: 2px;
      margin-top: 10px;
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-gap: 6px;
      @media (max-width: 992px) {
        display: none;
      }
      .game {
        border-radius: 11px;
        background: #2B2A4E;
        height: 58px;
        position: relative;
        img {
          max-width: 100%;
          border-radius: 11px;
          max-height: 100%;
        }
        .hot {
          position: absolute;
          top: -10px;
          width: 20px;
          height: 20px;
          right: -5px;
          border-radius: 29px;
          background: linear-gradient(180deg, #FF763B 0%, #FF3B3B 100%), #FFF;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .new {
          position: absolute;
          top: -8px;
          border-radius: 29px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: linear-gradient(180deg, #0582E9 0%, #06F 100%);
          padding: 3px 0;
          width: 30px;
          left: calc(50% - 15px);
          color: var(--1000, #FFF);
          font-family: "Geometria-Bold";
          font-size: 10px;
          font-weight: 700;
          letter-spacing: -0.2px;
        }
      }
    }
    .holder {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      border-radius: 12px;
      background: rgba(23, 21, 71, 0.72);
      backdrop-filter: blur(3.700000047683716px);
      overflow: hidden;
      z-index: 3;
      padding: 12px;
      display: flex;
      align-items: center;
      img {
        margin-right: 12px;
        @media (max-width: 768px) {
          width: 60px;
        }
      }
      strong {
        font-family: "Unbounded", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.28px;
      }
    }
  }
</style>