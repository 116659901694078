import {sendMessage} from "@/services/chat";
import {getMessages} from "@/services/chat";

export default {
    // eslint-disable-next-line no-unused-vars
    async sendMessage({commit, getters, rootGetters}, {message, type = 'text', last_win_transaction_id = null}) {
        await sendMessage({
            message,
            reply_message_id: getters?.replyMessage?.id,
            chat_room: rootGetters["settings/current_chat_room"].id,
            type,
            last_win_transaction_id
        }).then(() => {
            commit('setReplyMessage', null);
            commit('user/setChatPausedTo', rootGetters['settings/settings'].chat_paused_interval ?? 10, {root: true})
        })
    },

    async getMessages({commit, rootGetters}) {
        await getMessages(rootGetters["settings/current_chat_room"].id).then(response => {
            commit('setMessages', response.data.data.reverse());
        }).catch(() => {})
    }
}