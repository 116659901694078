<script>
import {mapMutations} from "vuex";

export default {
  name: "ForgotPassword",
  methods: {
    ...mapMutations({
      setIsShowRecoverPasswordModal: 'config/setIsShowRecoverPasswordModal'
    })
  }
}
</script>

<template>
  <div class="forgot-password">
    <span>{{ $t('forgot_password.heading') }}</span>
    <button class="default-hover" @click="setIsShowRecoverPasswordModal(true)">
      {{ $t('forgot_password.recover') }}
      <img src="@/assets/images/auth/recover-icon.svg" alt="">
    </button>
  </div>
</template>

<style scoped lang="scss">
  .forgot-password {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 18px 0;
    padding-bottom: 0;
    justify-content: center;
    span {
      color: #918FBB;
      font-size: 14px;
      font-family: "Geometria-Bold";
      letter-spacing: -0.28px;
    }
    button {
      background: transparent;
      color: #23A3FF;
      font-size: 14px;
      display: flex;
      align-items: center;
      margin-left: 11px;
      img {
        margin-left: 11px;
      }
      font-family: "Geometria-Bold";
      letter-spacing: -0.28px;
    }
  }
</style>