<script>
import TransactionsFilter from "@/components/Profile/Transactions/TransactionsFilter.vue";
import TransactionItem from "@/components/Profile/Transactions/TransactionItem.vue";
import TransactionItemHead from "@/components/Profile/Transactions/TransactionItemHead.vue";
import MainPagination from "@/components/MainPagination.vue";
import { getTransactions } from "@/services/user";
import UiLoader from "@/components/UiLoader.vue";
import EmptyState from "@/components/EmptyState.vue";

export default {
  data () {
    return {
      isLoading: false,
      transactions: [],
      pages: 1,
      current_page: 1,
      type: null,
      range: null,
    }
  },
  watch: {
    type() {
      this.current_page = 1;
      this.fetchTransactions()
    },
    range () {
      this.current_page = 1;
      this.fetchTransactions()
    }
  },
  methods: {
    changePage (page) {
      if (page < 1 || page > this.pages) {
        return;
      }

      this.current_page = page;
      this.fetchTransactions()
    },
    fetchTransactions () {
      this.isLoading = true;

      const data = {
        page: this.current_page,
        type: this.type,
      };

      if (this.range) {
        data.fromDate = Math.ceil(new Date(this.range.start).getTime() / 1000)
        data.toDate = Math.ceil(new Date(this.range.end).getTime() / 1000)
      }

      getTransactions(data).then(response => {
        this.transactions = response.data.data;
        this.pages = response.data.meta.last_page;
        this.current_page = response.data.meta.current_page;
      }).finally(() => {
        setTimeout(() => {
          this.isLoading = false;
        }, 500)
      })
    }
  },
  mounted() {
    this.fetchTransactions();
  },
  name: "ProfileTransactions",
  components: {EmptyState, UiLoader, MainPagination, TransactionItemHead, TransactionItem, TransactionsFilter}
}
</script>

<template>
  <div class="profile-transactions">
    <TransactionsFilter @range="range = $event" @update="type = $event" :tab="type" />
    <div class="table">
      <div v-if="!isLoading" class="items">
        <template v-if="transactions.length">
          <TransactionItemHead />
          <TransactionItem v-for="(transaction, i) in transactions" :key="i" :transaction="transaction" />
        </template>
        <EmptyState :heading="$t('no_transactions')" v-else />
      </div>
      <div class="items" v-else>
        <UiLoader />
      </div>

      <MainPagination v-if="pages > 1" @setPage="changePage" :totalPages="pages" :current-page="current_page" />
    </div>
  </div>
</template>

<style scoped lang="scss">
  .profile-transactions {
    .table {
      margin-bottom: 48px;
      .items {
        margin-bottom: 15px;
        margin-top: 8px;
        .transaction-item + .transaction-item {
          margin-top: 4px;
        }
      }
    }
  }
</style>