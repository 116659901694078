<script>
export default {
  name: "BetItemHead"
}
</script>

<template>
  <div class="bet-head">
    <div class="game">{{ $t('b_game') }}</div>
    <div class="user">{{ $t('b_user') }}</div>
    <div class="time">{{ $t('b_time') }}</div>
    <div class="wager">{{ $t('b_amount') }}</div>
    <div class="multiplier">{{ $t('b_multiplier') }}</div>
    <div class="payout">{{ $t('b_result') }}</div>
  </div>
</template>

<style scoped lang="scss">
  .bet-head {
    display: flex;
    padding: 8px 0;
    align-items: center;
    & > div {
      color: #3E3C5F;
      font-size: 12px;
      font-family: "Geometria-Bold";
      font-weight: 700;
      letter-spacing: 0.6px;
    }
    .game {
      width: 20%;
      @media (max-width: 768px) {
        width: 40%;
      }
    }
    .user {
      width: 20%;
      @media (max-width: 768px) {
        width: 33.33%;
      }
    }
    .time {
      width: 20%;
      @media (max-width: 768px) {
        display: none;
      }
    }
    .wager {
      width: 10%;
      @media (max-width: 768px) {
        display: none;
      }
    }
    .multiplier {
      width: 10%;
      @media (max-width: 768px) {
        display: none;
      }
    }
    .payout {
      width: 20%;
      display: flex;
      justify-content: flex-end;
      @media (max-width: 768px) {
        width: 27%;
      }
    }
  }
</style>