<script>
import UiClose from "@/components/Ui/UiClose.vue";
import AccentBtn from "@/components/Ui/Buttons/AccentBtn.vue";
import {getFreeLootbox} from "@/services/lootboxes";
import extractErrors from "@/helpers/extractErrors";
import {mapActions, mapGetters, mapMutations} from "vuex";
import UiCheckbox from "@/components/Ui/UiCheckbox.vue";
import { seenWelcome } from "@/services/user";
import { sendVerificationEmail } from "@/services/user";

export default {
  name: "FreeLootboxModal",
  data () {
    return {
      disabledInFuture: false,
      isLoading: false,
    }
  },
  computed: {
    ...mapGetters({
      user: 'user/user',
      freeLootboxShowFromLogin: 'config/freeLootboxShowFromLogin'
    })
  },
  components: {UiCheckbox, AccentBtn, UiClose},
  methods: {
    close() {
      if (this.disabledInFuture) {
        seenWelcome()
      }
      this.setFreeLootboxShowFromLogin(false);
      this.setIsOpenGetFreeLootbox(false)
    },
    ...mapMutations({
      setFreeLootboxShowFromLogin: 'config/setFreeLootboxShowFromLogin',
      setIsOpenGetFreeLootbox: 'config/setIsOpenGetFreeLootbox'
    }),
    ...mapActions({
      getMe: 'user/me'
    }),
    sendVerification () {
      this.isLoading = true;

      sendVerificationEmail().then(async () => {
        await this.getMe();
        this.$root.$emit('success', {
          title: "Email verification sent",
          text: 'Check your inbox!'
        })
        // this.close()
      }).catch(errors => {
        if (!errors) {
          this.$root.$emit('error', {
            title: "Error",
            text: this.$t('l_unavailable'),
          })
        }
        extractErrors(errors).forEach(item => {
          this.$root.$emit('error', {
            title: "Error",
            text: item,
          })
        })
      }).finally(() => {
        setTimeout(() => {
          this.isLoading = false
        }, 300);
      })
    },
    get() {
      this.isLoading = true;
      getFreeLootbox().then(async () => {
        await this.getMe();
        this.$root.$emit('success', {
          title: "Success",
          text: this.$t('l_received')
        })
        await this.$root.$emit('reload-free-lootboxes');
        this.close()
      }).catch(errors => {
        if (!errors) {
          this.$root.$emit('error', {
            title: "Error",
            text: this.$t('l_unavailable'),
          })
        }
        extractErrors(errors).forEach(item => {
          this.$root.$emit('error', {
            title: "Error",
            text: item,
          })
        })
      }).finally(() => {
        setTimeout(() => {
          this.isLoading = false
        }, 300);
      })
    }
  }
}
</script>

<template>
  <div class="free-lootbox">
    <UiClose @click="close" />
    <img src="@/assets/images/free-lootbox-image.svg" class="image" alt="">
    <h2 v-html="$t('l_got_free')"></h2>

    <UiCheckbox v-if="freeLootboxShowFromLogin" @change="disabledInFuture = $event.target.checked">
      <div class="not-show">
        No longer show on login
      </div>
    </UiCheckbox>

    <div class="email-verification" v-if="!user.email_verified">
      <template v-if="user.email">
        <div class="lootboxes-head__danger">
          <img src="@/assets/images/danger.svg" alt="">
          To get a free lootbox, you need to confirm your email address
        </div>

        <AccentBtn :is-loading="isLoading" @click="sendVerification" title="Send verification email" />
      </template>
      <template v-else>
        <div class="lootboxes-head__danger">
          <img src="@/assets/images/danger.svg" alt="">
          To get a lootbox you need to add and confirm your e-mail, you can do this in your personal profile
        </div>

        <AccentBtn :is-loading="isLoading" @click="$router.push(`/profile`)" title="Go to profile" />
      </template>
    </div>

    <AccentBtn class="send" v-if="user.email_verified" :is-loading="isLoading" @click="get" :title="$t('l_claim')" />
  </div>
</template>

<style scoped lang="scss">
  .free-lootbox {
    width: 343px;
    border-radius: 20px;
    position: relative;
    overflow: hidden;
    padding: 24px;
    max-width: 100%;
    box-shadow: 0px 105px 153.7px 0px rgba(1, 0, 25, 0.55);
    background: url(@/assets/images/free-lootbox-bg.svg), linear-gradient(180deg, rgba(135, 89, 19, 0.59) 0%, rgba(19, 17, 57, 0.00) 100%), #131139;
    background-size: 100% auto;
    background-position: center top;
    background-repeat: no-repeat;
    .email-verification {
      .lootboxes-head__danger {
        border-radius: 10px;
        margin-bottom: 10px;
        background: rgba(255, 103, 103, 0.19);
        padding: 12px 16px;
        display: flex;
        align-items: center;
        margin-left: auto;
        margin-right: 8px;
        img {
          margin-right: 12px;
          width: 20px;
          height: 20px;
        }
        font-family: "Geometria-Medium";
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.24px;
        @media (max-width: 768px) {
          display: none;
        }
      }
    }

    .image {
      margin: 0 auto;
      margin-top: 83px;
      display: block;
      width: 206px;
    }
    .send {
      margin-top: 20px;
    }
    h2 {
      margin-top: 33px;
      display: block;
      text-align: center;
      width: 100%;
      font-family: Unbounded;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px; /* 116.667% */
      letter-spacing: -0.48px;
      margin-bottom: 24px;
    }
    .ui-checkbox {
      margin-bottom: 20px;
    }
    .not-show {
      font-weight: bold;
    }
  }
</style>