<script>
export default {
  props: {
    required: {
      type: Boolean,
      default: false,
    },
    value: {},
    type: {
      type: String,
    },
    icon: {
      type: String
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String
    }
  },
  data () {
    return {
      isShowPassword: false,
    }
  },
  methods: {
    updateValue(newValue) {
      this.$emit('input', newValue); // Генерируем событие 'input', чтобы обновить внешнее значение
    }
  },
  computed: {
      getType () {
        if (this.isShowPassword) {
          return 'text';
        }

        return this.type
      }
  },
  name: "UiInput"
}
</script>

<template>
  <div class="ui-input">
    <div class="ui-input__icon" v-if="icon">
      <img :src="icon" alt="">
    </div>
    <div class="ui-input__area">
      <input :readonly="readonly" :required="required" @input="updateValue($event.target.value)" :value="value" :type="getType" :placeholder="placeholder">
    </div>

    <template v-if="type === 'password'">
      <div @click="isShowPassword = true" v-show="!isShowPassword" class="ui-input__show default-hover">
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="12" viewBox="0 0 18 12" fill="none">
          <path opacity="0.4" d="M16.606 4.211C17.5723 5.22772 17.5723 6.77244 16.606 7.78917C14.9762 9.50397 12.177 11.8334 8.9974 11.8334C5.8178 11.8334 3.01864 9.50397 1.38882 7.78917C0.422478 6.77244 0.422477 5.22772 1.38882 4.211C3.01864 2.49619 5.8178 0.166748 8.9974 0.166748C12.177 0.166748 14.9762 2.49619 16.606 4.211Z" fill="white"/>
          <circle cx="9" cy="6" r="2.5" fill="white"/>
        </svg>
      </div>

      <div @click="isShowPassword = false" v-show="isShowPassword" class="ui-input__show default-hover">
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16" fill="none">
          <path opacity="0.4" d="M16.6079 6.211C17.5743 7.22772 17.5743 8.77244 16.6079 9.78916C14.9781 11.504 12.1789 13.8334 8.99935 13.8334C5.81975 13.8334 3.02059 11.504 1.39077 9.78917C0.424431 8.77244 0.42443 7.22772 1.39077 6.211C3.02059 4.49619 5.81975 2.16675 8.99935 2.16675C12.1789 2.16675 14.9781 4.49619 16.6079 6.211Z" fill="#918FBB"/>
          <path d="M10.7678 9.76777C10.9999 9.53562 11.1841 9.26002 11.3097 8.95671C11.4353 8.65339 11.5 8.3283 11.5 8C11.5 7.6717 11.4353 7.34661 11.3097 7.04329C11.1841 6.73998 10.9999 6.46438 10.7678 6.23223C10.5356 6.00009 10.26 5.81594 9.95671 5.6903C9.65339 5.56466 9.3283 5.5 9 5.5C8.6717 5.5 8.34661 5.56466 8.04329 5.6903C7.73998 5.81594 7.46438 6.00009 7.23223 6.23223L9 8L10.7678 9.76777Z" fill="#918FBB"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M8.99935 13.8335C5.81975 13.8335 3.02059 11.5041 1.39077 9.78925C0.424431 8.77252 0.42443 7.22781 1.39077 6.21108C2.0823 5.48349 2.98434 4.64526 4.03663 3.92125L1.89081 1.77544C1.64673 1.53136 1.64673 1.13563 1.89081 0.891554C2.13489 0.647477 2.53062 0.647477 2.7747 0.891554L16.108 14.2249C16.3521 14.469 16.3521 14.8647 16.108 15.1088C15.864 15.3528 15.4682 15.3528 15.2241 15.1088L12.8685 12.7531C11.6941 13.3925 10.3827 13.8335 8.99935 13.8335ZM9 10.5005C7.61929 10.5005 6.5 9.38119 6.5 8.00048C6.5 7.53684 6.62621 7.10267 6.84616 6.73047L10.27 10.1543C9.89781 10.3743 9.46364 10.5005 9 10.5005Z" fill="#918FBB"/>
        </svg>
      </div>
    </template>

  </div>
</template>

<style scoped lang="scss">
  .ui-input {
    border-radius: 10px;
    background: #1D1B41;
    display: flex;
    transition: 0.2s;
    align-items: center;
    padding: 0 12px;
    .ui-input__icon {
      width: 20px;
      height: 20px;
      min-width: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        max-width: 100%;
      }
    }
    .ui-input__area {
      padding-left: 12px;
      flex-grow: 1;
      overflow: hidden;
      input {
        width: 100%;
        padding: 16px 0;
        font-size: 14px;
        font-family: "Geometria-Medium";
        letter-spacing: -0.28px;
        &::placeholder {
          color: #7F7DA6
        }
        border: none;
        background: transparent;
        outline: none;

      }
    }
    .ui-input__show {
      width: 34px;
      height: 34px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      background: #2B2A4E;
      min-width: 34px;
    }
  }
</style>