<script>
import EmailSetting from "@/components/Profile/EmailSetting.vue";
import ShortUser from "@/components/Profile/ShortUser.vue";
import PersonalSettings from "@/components/Profile/PersonalSettings.vue";

export default {
  name: "ProfileSettings",
  components: {PersonalSettings, ShortUser, EmailSetting}
}
</script>

<template>
  <div class="settings">
    <div class="settings-user">
      <ShortUser/>
      <PersonalSettings/>
    </div>
    <EmailSetting/>
  </div>
</template>

<style scoped lang="scss">
  .settings {
    border-radius: 16px;
    background: #131139;
    padding: 12px;
    overflow: hidden;
    display: flex;
    @media (max-width: 768px) {
      display: block;
      background: transparent;
      padding: 0;
    }
    .settings-user {
      flex-grow: 1;
      @media (max-width: 768px) {
        background: #131139;
        padding: 12px;
        border-radius: 20px;
      }
    }
    .personal-settings {
      margin-top: 34px;
      padding-left: 12px;
      @media (max-width: 768px) {
        padding-left: 0;
        margin-top: 14px;
      }
    }

    .email {
      @media (max-width: 768px) {
        margin-top: 10px;
        background: #131139;
      }
    }
  }
</style>