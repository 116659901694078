export default {
    setMessages (state, messages) {
        state.messages = messages
    },

    pushMessage (state, message) {
        state.messages.push(message);
        state.messages.shift();
    },

    setReplyMessage (state, message) {
        state.replyMessage = message;
    },

    setIsOpenCommandsList (state, value) {
        state.isOpenCommandsList = value
    }
}