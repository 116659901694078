import mutations from './mutations'
import state from './state.js';
import actions from './actions'
import getters from './getters'
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}