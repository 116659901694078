<script>
import SoonFeatures from "@/components/Sidebar/SoonFeatures.vue";
import OtherNav from "@/components/Sidebar/OtherNav.vue";
import SettingsButton from "@/components/Parts/SettingsButton.vue";
import {mapGetters, mapMutations} from "vuex";
import ProfileButton from "@/components/Parts/ProfileButton.vue";
import BlogButton from "@/components/Ui/Buttons/BlogButton.vue";

export default {
  data () {
    return {
      isShowMore: false,
    }
  },
  name: "MobileNav",
  computed: {
    ...mapGetters({
      isAuth: 'user/isAuth'
    })
  },
  components: {BlogButton, ProfileButton, SettingsButton, OtherNav, SoonFeatures},
  methods: {
    ...mapMutations({
      setIsOpenChat: 'config/setIsOpenChat'
    }),
    closeMore () {
      this.isShowMore = false
    },
  },
  watch: {
    isShowMore() {
      if (this.isShowMore) {
        this.$root.$emit('open-additional-mobile-nav');
      }
    }
  },
  mounted() {
    this.$root.$on('open-user-dropdown', () => {
      this.closeMore()
    })

    document.addEventListener('click', this.closeMore)
  },
  beforeDestroy() {
    document.removeEventListener('click', this.closeMore)
  }
}
</script>

<template>
<div class="mobile-nav" @click="setIsOpenChat(false)">
  <router-link  to="/" :class="{'active': $route.name === 'home'}">
    <div class="mobile-nav__link-icon">
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.09673 5.51903C0.664062 6.30948 0.664062 7.26299 0.664062 9.17001V10.4375C0.664062 13.6883 0.664062 15.3137 1.64037 16.3235C2.61668 17.3334 4.18803 17.3334 7.33073 17.3334H10.6641C13.8068 17.3334 15.3781 17.3334 16.3544 16.3235C17.3307 15.3137 17.3307 13.6883 17.3307 10.4375V9.17001C17.3307 7.26299 17.3307 6.30948 16.8981 5.51903C16.4654 4.72859 15.6749 4.23801 14.094 3.25685L12.4274 2.22247C10.7562 1.18532 9.92068 0.666748 8.9974 0.666748C8.07412 0.666748 7.23855 1.18532 5.56742 2.22247L3.90076 3.25685C2.31985 4.23801 1.5294 4.72859 1.09673 5.51903ZM6.4974 13.3751C6.15222 13.3751 5.8724 13.6549 5.8724 14.0001C5.8724 14.3453 6.15222 14.6251 6.4974 14.6251H11.4974C11.8426 14.6251 12.1224 14.3453 12.1224 14.0001C12.1224 13.6549 11.8426 13.3751 11.4974 13.3751H6.4974Z" fill="#555379"/>
      </svg>
    </div>
    <div class="mobile-nav__link-title">
      Home
    </div>
  </router-link>
  <router-link to="/lootboxes" :class="{'active': $route.name === 'lootboxes'}">
    <div class="mobile-nav__link-icon">
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path d="M5.625 3.75H5C2.93125 3.75 1.25 5.43125 1.25 7.5V8.75H5.625V3.75Z" fill="#555379"/>
        <path d="M9.5125 7.1125C9.75 6.8125 10.25 6.8125 10.4875 7.1125L11.8 8.75H13.125V3.75H6.875V8.75H8.2L9.5125 7.1125Z" fill="#555379"/>
        <path d="M15 3.75H14.375V8.75H18.75V7.5C18.75 5.43125 17.0687 3.75 15 3.75Z" fill="#555379"/>
        <path d="M11.8 10L10.4875 11.6375C10.3688 11.7875 10.1875 11.875 10 11.875C9.8125 11.875 9.63125 11.7875 9.5125 11.6375L8.2 10H1.25V15C1.25 15.6875 1.8125 16.25 2.5 16.25H17.5C18.1875 16.25 18.75 15.6875 18.75 15V10H11.8Z" fill="#555379"/>
      </svg>
    </div>
    <div class="mobile-nav__link-title">
      Lootboxes
    </div>
  </router-link>
  <router-link to="/games" :class="{'active': $route.name === 'games'}">
    <div class="mobile-nav__link-icon">
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path d="M15.4227 11.2645C15.0317 11.2734 14.6487 11.3769 14.3066 11.5663C14.0522 10.677 12.6918 6.15827 10.5046 3.16241L10.0221 2.5L9.51095 3.14054C7.17438 6.05203 5.92517 10.6252 5.68708 11.5651C5.34032 11.3761 4.9533 11.273 4.55848 11.2645C3.54674 11.2645 2.5 12.2162 2.5 13.8129C2.50924 14.7987 2.90924 15.7406 3.6122 16.4319C4.31516 17.1231 5.26365 17.5072 6.24951 17.4999C7.23536 17.5072 8.18386 17.1231 8.88681 16.4319C9.58977 15.7406 9.98977 14.7987 9.99901 13.8129C9.99901 12.2162 8.94415 11.2645 7.92366 11.2645C7.60677 11.2691 7.29434 11.3398 7.00628 11.4719C7.65281 9.04938 8.65436 6.73586 9.97839 4.6066C11.2502 6.76333 12.2554 9.06656 12.9717 11.4657C12.6839 11.337 12.3728 11.2685 12.0575 11.2645C11.5732 11.2791 11.1134 11.4807 10.7745 11.8269C11.0938 12.4397 11.2567 13.1219 11.2488 13.8129C11.248 14.5688 11.0701 15.314 10.7295 15.9888C11.0806 16.458 11.5363 16.8388 12.0604 17.1008C12.5845 17.3628 13.1626 17.4988 13.7485 17.498C14.7344 17.5053 15.6829 17.1212 16.3858 16.43C17.0888 15.7388 17.4888 14.7969 17.498 13.811C17.498 12.2162 16.4432 11.2645 15.4227 11.2645Z" fill="#555379"/>
      </svg>
    </div>
    <div class="mobile-nav__link-title">
      Slots
    </div>
  </router-link>
  <router-link to="/nft-marketplace" :class="{'active': $route.name === 'nft-marketplace'}">
    <div class="mobile-nav__link-icon">
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.97535 0.897008C8.62052 0.574424 9.37991 0.574425 10.0251 0.897008L16.1223 3.94564C17.1972 4.48307 17.1972 6.01696 16.1223 6.55439L10.0251 9.60302C9.37991 9.9256 8.62052 9.9256 7.97535 9.60302L1.87809 6.55439C0.803222 6.01695 0.803228 4.48307 1.87809 3.94564L7.97535 0.897008ZM0.929024 13.3295C1.06921 13.014 1.43856 12.872 1.75399 13.0122L8.57709 16.0446C8.84643 16.1643 9.15388 16.1643 9.42322 16.0446L16.2463 13.0122C16.5617 12.872 16.9311 13.014 17.0713 13.3295C17.2115 13.6449 17.0694 14.0142 16.754 14.1544L9.93089 17.1869C9.33834 17.4503 8.66197 17.4503 8.06942 17.1869L1.24632 14.1544C0.930892 14.0142 0.788834 13.6449 0.929024 13.3295ZM1.75399 9.26215C1.43856 9.12196 1.06921 9.26402 0.929024 9.57945C0.788834 9.89488 0.930892 10.2642 1.24632 10.4044L8.06942 13.4369C8.66197 13.7003 9.33834 13.7003 9.93089 13.4369L16.754 10.4044C17.0694 10.2642 17.2115 9.89488 17.0713 9.57945C16.9311 9.26402 16.5617 9.12196 16.2463 9.26215L9.42322 12.2946C9.15388 12.4143 8.84643 12.4143 8.57709 12.2946L1.75399 9.26215Z" fill="#555379"/>
      </svg>
    </div>
    <div class="mobile-nav__link-title">
      NFT
    </div>
  </router-link>
  <div class="nav-item" :class="{'active': isShowMore}" @click.stop="isShowMore = !isShowMore">
    <transition name="fade">
      <div class="nav-item__content" v-if="isShowMore">
        <SoonFeatures/>
        <BlogButton />
        <SettingsButton />
        <ProfileButton v-if="isAuth" />
        <OtherNav />

      </div>
    </transition>
    <div class="mobile-nav__link-icon">
      <svg xmlns="http://www.w3.org/2000/svg" width="11" height="3" viewBox="0 0 11 3" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.417969 1.37492C0.417969 1.95022 0.884339 2.41659 1.45964 2.41659C2.03493 2.41659 2.5013 1.95022 2.5013 1.37492C2.5013 0.799622 2.03493 0.333252 1.45964 0.333252C0.884339 0.333252 0.417969 0.799622 0.417969 1.37492ZM5.6263 2.41659C5.05101 2.41659 4.58464 1.95022 4.58464 1.37492C4.58464 0.799622 5.05101 0.333252 5.6263 0.333252C6.2016 0.333252 6.66797 0.799622 6.66797 1.37492C6.66797 1.95022 6.2016 2.41659 5.6263 2.41659ZM9.79297 2.41659C9.21767 2.41659 8.7513 1.95022 8.7513 1.37492C8.7513 0.799622 9.21767 0.333252 9.79297 0.333252C10.3683 0.333252 10.8346 0.799622 10.8346 1.37492C10.8346 1.95022 10.3683 2.41659 9.79297 2.41659Z" fill="#555379"/>
      </svg>
    </div>
    <div class="mobile-nav__link-title">
      {{ $t('m_more') }}
    </div>
  </div>
</div>
</template>

<style scoped lang="scss">
  .mobile-nav {
    position: fixed;
    //bottom: 0;
    left: 0;
    width: 100%;
    background: #0C0A33;
    padding: 10px 24px;
    box-shadow: 0px -6px 24.4px 0px rgba(0, 0, 0, 0.25);
    z-index: 99;
    display: flex;
    grid-gap: 4px;
    justify-content: space-evenly;
    padding-bottom: 10px;

    bottom:0;

    //padding-bottom: calc(10px + env(safe-area-inset-bottom));
    @media (max-width: 600px) {
      justify-content: space-between;
      padding-right: 10px;
      padding-left: 10px;
    }
    a, .nav-item {
      display: block;
      padding: 7px 1px;
      position: relative;
      flex: 1;
      .mobile-nav__link-icon {
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20px;
        margin: 0 auto;
        margin-bottom: 4px;
        svg {
          max-height: 100%;
          max-width: 100%;
        }
      }
      .mobile-nav__link-title {
        color: #918FBB;
        text-align: center;
        font-size: 12px;
        font-weight: 500;
        font-family: "Geometria-Medium";
        letter-spacing: -0.22px;
      }
      &.active {
        border-radius: 12px;
        background: linear-gradient(180deg, rgba(96, 246, 240, 0.18) 0%, rgba(96, 246, 240, 0.00) 100%);
        position: relative;
        &::after {
          content: "";
          display: block;
          width: 16px;
          height: 2px;
          position: absolute;
          top: 0;
          left: calc(50% - 8px);
          border-radius: 36px;
          background: #72EEFF;
          box-shadow: 0px 1px 4px 0px #72EEFF;
        }
        svg {
          path {
            fill: #72EEFF;
          }
        }
      }
      .disabled {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 9px;
        background: rgba(85, 83, 121, 0.25);
        backdrop-filter: blur(1.25px);
      }
      &.disabled {
        padding-right: 5px;
        padding-left: 5px;
      }

      .nav-item__content {
        position: absolute;
        border-radius: 13px;
        background: #02002A;
        padding: 7px;
        width: 252px;
        bottom: calc(100% + 18px);
        right: -4px;
        .settings {
          margin: 7px 0;
        }
        @media (max-width: 768px) {
          padding: 4px;
        }
      }
    }

    display: none;
    @media (max-width: 992px) {
      display: flex;
    }
  }
</style>