import axios from 'axios';
const baseURL = process.env.VUE_APP_API_URL; // Замените на ваш базовый URL

class ApiClient {
    constructor() {
        this.setClient()
    }

    setClient () {
        this.client = axios.create({

            baseURL: baseURL,
            timeout: 10000, // Время ожидания ответа (в миллисекундах)
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json',
            },
        });

        // Перехватчик ответов для обработки ошибок, если необходимо
        this.client.interceptors.response.use(
            response => response,
            error => {
                let errors = error?.response?.data?.errors;
                if (!errors) {
                    const responseCode = error?.response?.status;

                    if (responseCode) {

                        if (responseCode === 400) {
                            errors = [
                                'Возникла ошибка при выполнении запроса на сервер.'
                            ]
                        }

                        if (responseCode === 429) {
                            errors = [
                                'Слишком много попыток, попробуйте через несколько минут.'
                            ];
                        } else if (responseCode === 401) {
                            localStorage.removeItem('token')
                        }
                    } else {
                        errors = [
                            'Возникла непредвиденая ошибка.'
                        ];
                    }
                }

                return Promise.reject(errors);
            }
        );
    }

    async recreate () {
        this.setClient()
    }

    // Метод для выполнения GET-запроса
    async get(url, params = {}) {
        return await this.client.get(url, { params });
    }

    // Метод для выполнения POST-запроса
    async post(url, data = {}) {
        return await this.client.post(url, data);
    }

    // Метод для выполнения PUT-запроса
    async put(url, data = {}) {
        return await this.client.put(url, data);
    }

    async patch(url, data = {}) {
        return await this.client.patch(url, data);
    }

    // Метод для выполнения DELETE-запроса
    async delete(url) {
        return await this.client.delete(url);
    }
}

// Создаем один экземпляр ApiClient для всего приложения
const apiClient = new ApiClient();

export default apiClient;