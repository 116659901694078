<script>
import AccentBtn from "@/components/Ui/Buttons/AccentBtn.vue";

export default {
  name: "NotFoundView",
  components: {AccentBtn}
}
</script>

<template>
  <div class="not-found page">
    <div class="body">
      <div class="image">
        <img src="@/assets/images/404-image.png" alt="">
      </div>
      <div class="text">
        <h2>{{ $t('not_found_title') }}</h2>
        <span>{{ $t('not_found__text') }}</span>
        <router-link to="/">
          <AccentBtn :title="$t('not_found_go_to_main')" />
        </router-link>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .not-found {
    height: 80vh;
    background: url(@/assets/images/404-bg.png);
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    background-position: center center;
    @media (max-width: 992px) {
      margin-right: -10px;
      margin-left: -10px;
      height: 60vh;
    }
    .body {
      width: 100%;
    }
    .text {
      margin-top: 67px;
      h2 {
        width: 100%;
        text-align: center;
        display: block;
        font-family: "Unbounded";
        font-size: 24px;
        margin-bottom: 12px;
        font-weight: 700;
        line-height: 28px; /* 116.667% */
        letter-spacing: -0.48px;
        @media (max-width: 768px) {
          font-size: 18px;
          line-height: 22px;
          margin-bottom: 8px;
        }
      }
      span {
        display: block;
        text-align: center;
        width: 100%;
        color: #B9B8DD;
        font-family: "Geometria-Medium";
        font-size: 15px;
        font-weight: 500;
        line-height: 25px; /* 166.667% */
        letter-spacing: -0.3px;
        @media (max-width: 768px) {
          font-size: 12px;
          line-height: 17px;
        }
      }
      a {
        display: block;
        text-align: center;
        width: 174px;
        max-width: 100%;
        margin: 0 auto;
        margin-top: 24px;
      }
    }
    .image {
      display: flex;
      justify-content: center;
      width: 100%;
      img {
        max-width: 80%;
      }
    }
  }
</style>