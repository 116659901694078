export default {
    namespaced: true,
    state: {
        freeLootboxShowFromLogin: false,
        depositModalTab: null,
        isShowSettings: false,
        isShowChatRules: false,
        isShowSearch: false,
        isOpenLoyalty: false,
        isShowDepositModal: false,
        isOpenCancelDepositModal: null,
        buyNftObject: null,
        isOpenSidebar: false,
        fullscreenGame: false,
        isOpenChat: false,
        isShowWeb3Register: false,
        web3RegisterMethod: 'register',
        confirmationModal: null,
        isOpenAuth: false,
        authTab: 'login',
        profileTab: 'profile',
        isOpenChangePassword: false,
        isShowRecoverPasswordModal: false,
        isOpenTwoFa: false,
        isOpenSetAvatar: false,
        sellNft: {
            isOpenSellNft: false,
            nft: null
        },
        isOpenGetFreeLootbox: false,
        isOpenWelcomeFreeLootbox: false,
    },
    getters: {
        disabledScroll: state => {
          return state.fullscreenGame
              || state.isShowSettings
              || state.isShowChatRules
              || state.isShowSearch
              || state.isShowDepositModal
              || state.isOpenCancelDepositModal
              || !!state.buyNftObject
              || state.isShowWeb3Register
              || state.isOpenAuth
              || state.isOpenLoyalty
              || state.isOpenSetAvatar
              || state.isOpenChangePassword
              || state.isShowRecoverPasswordModal
              || state.isOpenTwoFa
              || state.sellNft?.nft
              || state.isOpenChat
              || state.isOpenSidebar
              || state.isOpenGetFreeLootbox
              || state.isOpenWelcomeFreeLootbox
        },
        freeLootboxShowFromLogin: state => state.freeLootboxShowFromLogin,
        fullscreenGame: state => state.fullscreenGame,
        depositModalTab: state => state.depositModalTab,
        isShowSettings: state => state.isShowSettings,
        isShowChatRules: state => state.isShowChatRules,
        isShowSearch: state => state.isShowSearch,
        isShowDepositModal: state => state.isShowDepositModal,
        isOpenCancelDepositModal: state => state.isOpenCancelDepositModal,
        buyNftObject: state => state.buyNftObject,
        isShowWeb3Register: state => state.isShowWeb3Register,
        web3RegisterMethod: state => state.web3RegisterMethod,
        confirmationModal: state => state.confirmationModal,
        authTab: state => state.authTab,
        isOpenAuth: state => state.isOpenAuth,
        isOpenLoyalty: state => state.isOpenLoyalty,
        isOpenSetAvatar: state => state.isOpenSetAvatar,
        profileTab: state => state.profileTab,
        isOpenChangePassword: state => state.isOpenChangePassword,
        isShowRecoverPasswordModal: state => state.isShowRecoverPasswordModal,
        isOpenTwoFa: state => state.isOpenTwoFa,
        sellNft: state => state.sellNft,
        isOpenChat: state => state.isOpenChat,
        isOpenSidebar: state => state.isOpenSidebar,
        isOpenGetFreeLootbox: state => state.isOpenGetFreeLootbox,
        isOpenWelcomeFreeLootbox: state => state.isOpenWelcomeFreeLootbox,
    },
    mutations: {
        setFreeLootboxShowFromLogin(state, value) {
            state.freeLootboxShowFromLogin = value;
        },
        setIsOpenWelcomeFreeLootbox(state, value) {
            state.isOpenWelcomeFreeLootbox = value;
        },
        setIsOpenGetFreeLootbox(state, value) {
            if(!localStorage.getItem('token')) {
                state.isOpenAuth = true;
                return;
            }
            state.isOpenGetFreeLootbox = value;
        },
        setIsShowRecoverPasswordModal(state, value) {
            state.isShowRecoverPasswordModal = value;
        },
        setDepositModalTab(state, value) {
            state.depositModalTab = value;
        },
        setIsShowSettings(state, value) {
            state.isShowSettings = value;
        },
        setIsShowChatRules(state, value) {
            state.isShowChatRules = value;
        },
        setIsShowSearch(state, value) {
            state.isShowSearch = value;
        },
        setIsOpenLoyalty(state, value) {
            state.isOpenLoyalty = value;
        },
        setIsShowDepositModal(state, value) {
            if(!localStorage.getItem('token')) {
                state.isOpenAuth = true;
                return;
            }
            state.isShowDepositModal = value;
            if (!value) {
                state.depositModalTab = null;
            }
        },
        setIsOpenCancelDepositModal(state, value) {
            state.isOpenCancelDepositModal = value;
        },
        setBuyNftObject(state, nft) {
            state.buyNftObject = nft;
        },
        setOpenAuth(state, value) {
            state.isOpenAuth = value;
        },
        setIsOpenChat(state, value) {
            state.isOpenChat = value
            if (value && window.innerWidth < 992) {
                state.isOpenSidebar = false;
            }
        },
        setAuthTab(state, value) {
            state.authTab = value;
        },
        setProfileTab(state, value) {
            state.profileTab = value
        },
        setOpenChangePassword(state, value) {
            state.isOpenChangePassword = value
        },
        setOpenTwoFa(state, value) {
            state.isOpenTwoFa = value
        },
        setOpenSetAvatar(state, value) {
            state.isOpenSetAvatar = value
        },
        setOpenSellNft (state, value) {
            state.sellNft = {
                isOpenSellNft: true,
                nft: value
            }
        },
        setCloseSellNft (state) {
            state.sellNft = {
                isOpenSellNft: false,
                nft: null
            }
        },
        setConfirmationModal (state, data) {
            state.confirmationModal = data;
        },
        setIsShowWeb3Register (state, {value, method}) {
            state.isShowWeb3Register = value;
            state.web3RegisterMethod = method;
        },
        setIsOpenSidebar(state, value) {
            state.isOpenSidebar = value;
            if (value && window.innerWidth < 992) {
                state.isOpenChat = false;
            }
        },
        setIsFulScreenGame(state, value) {
            state.fullscreenGame = value;
        },
        closeAllModals(state) {
            state.isOpenChat = false;
            state.confirmationModal = false;
            state.web3RegisterMethod = false;
            state.isShowRecoverPasswordModal = false;
            state.isShowSearch = false;
            state.isShowDepositModal = false;
            state.isOpenCancelDepositModal = false;
            state.isOpenSidebar = false;
            state.isOpenAuth = false;
            state.isOpenTwoFa = false;
            state.isOpenChangePassword = false;
            state.isOpenLoyalty = false;
            state.isShowChatRules = false;
            state.isOpenGetFreeLootbox = false;
            state.isOpenWelcomeFreeLootbox = false;
        }
    },
    actions: {
        async openLogin ({commit}) {
            await commit('setOpenAuth', true);
            await commit('setAuthTab', 'login');
        },
        async openRegister ({commit}) {
            await commit('setOpenAuth', true);
            await commit('setAuthTab', 'register');
        }
    }
}