<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    }
  },
  data () {
    return {
      id: Math.random().toString(36).substring(2,7)
    }
  },
  name: "CheckboxToggle"
}
</script>

<template>
  <div class="checkbox">
<!--  eslint-disable-next-line  -->
    <input v-model="value" @input="$emit('input', $event)" type="checkbox" :id="id">
    <label :for="id">
      <span class="circle"></span>
    </label>
  </div>
</template>

<style scoped lang="scss">
  .checkbox {
    input {
      display: none;
    }
    label {
      cursor: pointer;
      width: 37px;
      height: 22px;
      transition: 0.2s;
      background: #3E3C5F;
      display: block;
      border-radius: 16px;
      position: relative;
      .circle {
        position: absolute;
        height: 16px;
        width: 16px;
        border-radius: 100%;
        background: #7F7DA6;
        top: 3px;
        left: 3px;
        transition: 0.2s;
      }
    }
    input:checked + label {
      background: #FFE70C;
      .circle {
        transform: translateX(14px);
        background: #8B7D00;
      }
    }
  }
</style>