<script>
import {mapGetters, mapMutations} from "vuex";
import formatNumber from "@/helpers/formatNumber";
export default {
  data () {
    return {
      formatNumber
    }
  },
  computed: {
    ...mapGetters({
      user: 'user/user'
    }),
    formattedBalance () {
      return formatNumber(parseFloat(this.user.balance.balance))
    },
    formattedSgBalance () {
      return formatNumber(parseFloat(this.user.sg_balance))
    }
  },
  methods: {
    ...mapMutations({
      setBalance: 'user/setBalance'
    }),
    ...mapMutations({
      setIsShowDepositModal: 'config/setIsShowDepositModal'
    })
  },
  mounted () {
    this.$echo.private(`balance-changed.${this.user.id}`).listen(".BalanceChangedEvent", async (payload) => {
      this.setBalance({balance: payload.balance, sg_balance: payload.sg_balance})
    })
  },
  name: "BalanceState"
}
</script>

<template>
  <div class="balance-state">
    <div class="wallet">
      <img src="@/assets/images/coin.svg" alt="">
    </div>
    <div class="value" v-if="formattedBalance">
        {{ formattedBalance.integer }}
        <em v-if="formattedBalance.decimal">.{{ formattedBalance.decimal }}</em>
    </div>
    <div v-else class="value">
      0
      <em>.00</em>
    </div>
    <div class="sneg-balance">
      <div class="wallet">
        <img src="@/assets/images/sneg-coin.svg" alt="">
      </div>
      <div class="value" v-if="formattedBalance">
        {{ formattedSgBalance.integer }}
        <em v-if="formattedSgBalance.decimal">.{{ formattedSgBalance.decimal }}</em>
      </div>
      <div v-else class="value">
        0
        <em>.00</em>
      </div>
    </div>
    <button class="default-hover" @click="setIsShowDepositModal(true)">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path d="M7.99992 3.33325V12.6666M3.33325 7.99992H12.6666" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </button>
  </div>
</template>

<style scoped lang="scss">
  .balance-state {
    border-radius: 11px;
    background: #1D1B41;
    padding: 4px;
    padding-left: 8px;
    display: flex;
    align-items: center;
    .sneg-balance {
      display: flex;
      align-items: center;

    }
    .wallet {
      display: flex;
      align-items: center;
      img {
        width: 15px;
      }
    }
    .wallet {
      color: rgb(247, 252, 0);
      margin-right: 10px;
    }
    .value {
      color: #fff;
      display: flex;
      align-items: center;
      margin-right: 10px;
      font-family: "Geometria-Bold";
      font-size: 14px;
      font-weight: 700;
      letter-spacing: -0.28px;
      em {
        color: #918FBB;
        font-style: normal;
        display: flex;
        align-items: center;
        margin-right: 10px;
        font-family: "Geometria-Bold";
        font-size: 14px;
        font-weight: 700;
        letter-spacing: -0.28px;
      }
    }
    button {
      border-radius: 11px;
      box-shadow: 0px -1px 1px 1px #BC79FF;
      background: linear-gradient(180deg, #9D42F8 -4.55%, #7F00FF 100%);
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
</style>