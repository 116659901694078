<script>
import AccentBtn from "@/components/Ui/Buttons/AccentBtn.vue";
import LootboxRoulette from "@/components/Lootboxes/LootboxRoulette.vue";
import NftWins from "@/components/Nft/NftWins.vue";
import LootboxContains from "@/components/Lootboxes/LootboxContains.vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
import SignUp from "@/components/Auth/Buttons/SignUp.vue";
import AutoMode from "@/components/Lootboxes/AutoMode.vue";

export default {
  data () {
    return {
      rouletteKey: 0,
      isLoading: false,
    }
  },
  computed: {
    ...mapGetters({
      isAuth: 'user/isAuth',
      lootbox: 'lootbox/lootbox',
      isPlaying: 'lootbox/isPlaying',
      mode: 'lootbox/mode',
      user: 'user/user',
      autoSelectItem: 'lootbox/autoSelectItem',
      balanceLimit: 'lootbox/balanceLimit',
    })
  },
  methods: {
    ...mapMutations({
      setMode: 'lootbox/setMode',
      reset: 'lootbox/reset'
    }),
    ...mapActions({
      openLootbox: 'lootbox/open',
      getLootbox: 'lootbox/getLootbox'
    }),
   async open() {
      if (this.mode === 'auto') {
        if (this.balanceLimit > this.user.sg_balance) {
          this.$root.$emit('error', {
            title: "Error",
            text: this.$t('l_balance_limit')
          })
          return;
        }
      }
      this.isLoading = true;
      try {
        await this.openLootbox({id: this.$route.params.id, context: this});
      } catch (e) {
        console.log(e)
      } finally {
        this.isLoading = false;
      }
    }
  },
  async mounted() {
    await this.reset();
    await this.getLootbox(this.$route.params.id);

    this.$root.$on('lootbox.finish', () => {
      if (this.lootbox.is_free) {
        this.getLootbox(this.$route.params.id)
      }

      this.rouletteKey++;
    })

    this.$root.$on('lootbox.re-run', () => {
      this.rouletteKey++;
      this.$nextTick(() => {
        this.open();
      })
    })
  },
  name: "LootboxPage",
  components: {AutoMode, SignUp, LootboxContains, NftWins, LootboxRoulette, AccentBtn}
}
</script>

<template>
  <div class="lootbox page">
    <div class="open" v-if="lootbox">
      <div class="lootbox-detail">
        <router-link to="/lootboxes" class="default-hover lootbox-back">
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.25 4.5L6.75 9L11.25 13.5" stroke="#7F7DA6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <span>{{ $t('back') }}</span>
        </router-link>
        <div class="lootbox-image">
          <img src="@/assets/images/lootbox/free.svg" v-if="lootbox.is_free" alt="">
          <img :src="lootbox.image" v-else-if="lootbox.image" alt="">
        </div>
        <div class="lootbox-name">
          {{ $t('ll_open') }}
        </div>
        <div class="lootbox-tabs">
          <div class="lootbox-tab default-hover" :class="{'active': mode === 'manual' && !lootbox.is_free}" @click="setMode('manual')">{{ $t('ll_manual') }}</div>
          <div class="lootbox-tab default-hover" v-if="!lootbox.is_free" :class="{'active': mode === 'auto'}" @click="setMode('auto')">{{ $t('ll_auto') }}</div>
        </div>

        <AutoMode v-if="mode === 'auto'" :price="lootbox.price" :prizes="lootbox.prizes" />

        <div class="lootbox-price" v-if="!lootbox.is_free">
          <strong >
            <img src="@/assets/images/sneg-coin.svg" alt="">
            {{ parseFloat(lootbox.price).toFixed(2) }}
          </strong>
          <div v-if="!lootbox.is_free" class="coin">SNEG</div>
        </div>
        <SignUp v-if="!isAuth" />
        <AccentBtn :is-loading="isLoading" v-else @click="open" :disabled="isPlaying || (!autoSelectItem && mode === 'auto') || !lootbox.available" :title="''">
          <template v-if="!autoSelectItem && mode === 'auto'">
            {{ $t('ll_select_prize') }}
          </template>
          <template v-else>
            <template v-if="isPlaying">
              {{ $t('ll_opening') }}
            </template>
            <template v-else>
              <template v-if="lootbox.is_free">
                {{ $t('l_claim') }}
              </template>
              <template v-else>
                {{ $t('ll_open') }}
              </template>
            </template>
          </template>
        </AccentBtn>

      </div>
      <div class="lootbox-roulette" v-if="lootbox">
        <LootboxRoulette :price="lootbox.price" ref="roulette" :key="rouletteKey" :items="lootbox.prizes" />
        <LootboxContains :items="lootbox.prizes" />
      </div>
    </div>

    <div class="table">
      <NftWins/>
    </div>

  </div>
</template>

<style scoped lang="scss">
  .open {
    display: flex;
    align-items: flex-start;
    .lootbox-detail {
      width: 344px;
      min-width: 344px;
      margin-right: 16px;
      padding: 20px;
      border-radius: 20px;
      background: linear-gradient(180deg, #131857 0%, rgba(19, 24, 87, 0.00) 100%);
      @media (max-width: 992px) {
        width: 100%;
        min-width: 100%;
        margin-right: 0;
        margin-bottom: 16px;
      }
      @media (max-width: 768px) {
        padding: 10px 12px;
        margin-bottom: 5px;
      }
      .lootbox-back {
        display: flex;
        align-items: center;
        width: fit-content;
        padding: 14px 16px;
        border-radius: 14px;
        background: rgba(0, 3, 42, 0.30);
        span {
          display: flex;
          align-items: center;
          font-family: "Unbounded";
          font-size: 12px;
          font-weight: 700;
          letter-spacing: -0.24px;
          margin-left: 8px;
        }
      }
      .lootbox-image {
        margin: 0 auto;
        margin-top: 11px;
        border-radius: 8px;
        overflow: hidden;
        width: 110px;
        height: 110px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .lootbox-name {
        font-family: "Unbounded";
        font-size: 24px;
        font-weight: 700;
        line-height: 28px;
        width: 100%;
        display: flex;
        justify-content: center;
        margin: 16px 0;
        letter-spacing: -0.48px;
        @media (max-width: 768px) {
          font-size: 18px;
          line-height: 20px;
        }
      }
      .lootbox-tabs {
        display: flex;
        margin-bottom: 22px;
        border-radius: 12px;
        grid-gap: 5px;
        background: rgba(200, 197, 255, 0.10);
        padding: 4px;
        @media (max-width: 768px) {
          margin-bottom: 10px;
        }
        .lootbox-tab {
          flex: 1;
          padding: 12px 24px;
          border-radius: 12px;
          text-align: center;
          color: #7F7DA6;
          font-family: "Geometria-Bold";
          font-size: 14px;
          font-weight: 700;
          letter-spacing: -0.28px;
          &.active {
            border-radius: 12px;
            background: #FFE70C;
            color: #02002A
          }

        }
      }
      .lootbox-price {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        justify-content: center;
        strong {
          display: flex;
          align-items: center;
          img {
            margin-right: 6px;
            width: 20px;
            height: 20px;
          }
          font-family: "Unbounded";
          line-height: 14px;
          font-size: 16px;
          font-weight: 700;
          letter-spacing: -0.32px;
          @media (max-width: 768px) {
            font-size: 14px;
          }
        }
        .coin {
          margin-left: 5px;
          padding: 4px 6px;
          border-radius: 5px;
          background: rgba(255, 255, 255, 0.15);
          color: #B9B8DD;
          font-family: "Unbounded";
          font-size: 16px;
          font-weight: 700;
          line-height: 14px;
          letter-spacing: -0.32px;
          @media (max-width: 768px) {
            font-size: 14px;
          }
        }
      }
      & > button {
        width: 100%;
      }
    }
    .lootbox-roulette {
      flex-grow: 1;
      overflow: hidden;
    }
    @media (max-width: 992px) {
      display: block;
    }
  }
  .lootbox-contains {
    margin-top: 10px;
  }
  .table {
    margin-top: 36px;
  }

</style>