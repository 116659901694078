<script>
import {mapMutations} from "vuex";

export default {
  data () {
    return {
      isOpenList: false,
      languages: [
        {
          code: 'ru',
          name: 'RU'
        },
        {
          code: 'en',
          name: "EN"
        },
        {
          code: 'de',
          name: "DE"
        }
      ],
      currentLanguage: localStorage.getItem('lang') ?? 'en'
    }
  },
  computed: {
    current() {
      return this.languages.find(item => item.code === this.currentLanguage)
    },
  },
  watch: {
    '$i18n.locale': function () {
      this.currentLanguage = localStorage.getItem('lang') ?? 'en';
    }
  },
  methods: {
    ...mapMutations({
      setCurrentLang: 'settings/setCurrentLang'
    }),
      close () {
        this.isOpenList = false
      },
    switchLanguage (lang) {
      localStorage.setItem('lang', lang)
      this.$i18n.locale = lang;
      this.setCurrentLang(lang);
      this.currentLanguage = lang;
    },
  },
  mounted () {
      document.addEventListener('click', this.close)
  },
  beforeDestroy() {
    document.removeEventListener('click', this.close)
  },
  name: "LanguageSwitcher"
}
</script>

<template>
  <div class="language-switcher">
    <div class="current-language default-hover" :class="{'is-open': isOpenList}" @click.stop.prevent="isOpenList = !isOpenList">
      <img :src="require(`@/assets/images/lang/${current?.code ?? 'en'}.svg`)" alt="">
      <span>{{ current?.name ?? 'EN' }}</span>
      <div class="toggle">
        <img src="@/assets/images/lang/arrow-down.svg" alt="">
      </div>
    </div>
    <div v-show="isOpenList" class="list">
      <div v-for="(language, i) in languages" :key="i" class="language-item" @click="switchLanguage(language.code)">
        <img :src="require(`@/assets/images/lang/${language.code}.svg`)" alt="">
        <span>{{ language.name }}</span>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .language-switcher {
    z-index: 8;
    position: relative;
    .current-language {
      display: flex;
      padding: 9px;
      align-items: center;
      border-radius: 11px;
      background: #1D1B41;
      padding-right: 38px;
      position: relative;
      img {
        width: 20px;
      }
      span {
        margin-left: 8px;
        display: flex;
        margin-top: 2.5px;
        align-items: center;
        font-family: "Geometria-Bold";
        width: 35px;
        font-size: 14px;
        letter-spacing: -0.28px;
        @media (max-width: 575px) {
          font-size: 12px;
          padding-top: 2px;
        }
      }
      @media (max-width: 575px) {
        padding: 9px;
        padding-right: 20px;
        & > img {
          width: 20px;
          height: 20px;
          min-width: 20px;
        }
      }
      .toggle {
        transition: 0.2s;
        position: absolute;
        right: 8px;
        display: flex;
        align-items: center;
      }

      &.is-open {
        .toggle {
          transform: rotate(180deg);
        }
      }
    }
    .list {
      position: absolute;
      top: calc(100% + 5px);
      right: 0;
      width: 100%;
      border-radius: 12px;
      background: #131139;
      box-shadow: 0px 51px 186.1px 0px #020030;
      padding: 8px;
      .language-item {
        display: flex;
        cursor: pointer;
        align-items: center;
        border-radius: 9px;
        overflow: hidden;
        padding: 8px;
        img {
          width: 24px;
          height: 24px;
          margin-right: 8px;
        }
        span {
          font-family: "Geometria-Bold";
          font-size: 14px;
          letter-spacing: -0.28px;
        }
        transition: 0.2s;
        &:hover, &.active {
          background: #1D1B41;
        }
        &.active {
          cursor: default;
        }
      }
      .language-item + .language-item {
        margin-top: 4px;
      }
    }
  }
</style>