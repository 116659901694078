<script>
import { formatDate } from "@/helpers/formatDate";

export default {
  props: {
    type: {
      type: String
    }
  },
  data () {
    return {
      formatDate,
      range: null,
    }
  },
  watch: {
    range() {
      this.$emit('range', this.range)
    }
  },
  methods: {
    updateType (type) {
      this.$emit('updateType', type)
    }
  },
  name: "HistoryFilter"
}
</script>

<template>
  <div class="transactions-filter">
    <div class="transactions-filter__title">
      <div class="transactions-filter__title-icon">
        <img src="@/assets/images/reload-icon.svg" alt="">
      </div>
      <strong>{{ $t('gm.title') }}</strong>
    </div>
    <div class="transactions-filter__filters">
      <div class="transactions-filter__filters-tabs">
        <div @click="updateType(null)" :class="{'active': type === null}" class="transactions-filter__filters-tab default-hover">
          <div class="transactions-filter__filters-tab__name">
            {{ $t('gm.all') }}
          </div>
        </div>
        <div @click="updateType('win')" :class="{'active': type === 'win'}" class="transactions-filter__filters-tab default-hover">
          <div class="transactions-filter__filters-tab__icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="13" viewBox="0 0 15 13" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M11.7513 0.125H3.2513C1.6865 0.125 0.417969 1.39353 0.417969 2.95833C0.417969 4.14741 1.15045 5.1654 2.1888 5.58571V2.95833C2.1888 2.67654 2.30074 2.40629 2.5 2.20703C2.69926 2.00778 2.96951 1.89583 3.2513 1.89583H11.7513C12.0331 1.89583 12.3033 2.00778 12.5026 2.20703C12.7019 2.40629 12.8138 2.67654 12.8138 2.95833V5.58571C13.8522 5.1654 14.5846 4.14741 14.5846 2.95833C14.5846 1.39353 13.3161 0.125 11.7513 0.125ZM11.751 11.4585L11.751 2.9585H3.25098V11.4585C3.25098 12.2409 3.88524 12.8752 4.66764 12.8752H10.3343C11.1167 12.8752 11.751 12.2409 11.751 11.4585ZM6.7513 7.30925L6.61497 7.44559C6.32207 7.73848 5.8472 7.73848 5.55431 7.44559C5.26141 7.15269 5.26141 6.67782 5.55431 6.38493L6.4701 5.46913C7.03962 4.89961 7.96298 4.89961 8.5325 5.46913L9.4483 6.38493C9.74119 6.67782 9.74119 7.15269 9.4483 7.44559C9.15541 7.73848 8.68053 7.73848 8.38764 7.44559L8.2513 7.30925V9.74859C8.2513 10.1628 7.91552 10.4986 7.5013 10.4986C7.08709 10.4986 6.7513 10.1628 6.7513 9.74859V7.30925Z" fill="#7F7DA6"/>
            </svg>
          </div>
          <div class="transactions-filter__filters-tab__name">
            {{ $t('gm.win') }}
          </div>
        </div>
        <div @click="updateType('bet')" :class="{'active': type === 'bet'}" class="transactions-filter__filters-tab default-hover">
          <div class="transactions-filter__filters-tab__icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="13" viewBox="0 0 15 13" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M11.7513 0.125H3.2513C1.6865 0.125 0.417969 1.39353 0.417969 2.95833C0.417969 4.14741 1.15045 5.1654 2.1888 5.58571V2.95833C2.1888 2.67654 2.30074 2.40629 2.5 2.20703C2.69926 2.00778 2.96951 1.89583 3.2513 1.89583H11.7513C12.0331 1.89583 12.3033 2.00778 12.5026 2.20703C12.7019 2.40629 12.8138 2.67654 12.8138 2.95833V5.58571C13.8522 5.1654 14.5846 4.14741 14.5846 2.95833C14.5846 1.39353 13.3161 0.125 11.7513 0.125ZM11.751 11.4585L11.751 2.9585H3.25098V11.4585C3.25098 12.2409 3.88524 12.8752 4.66764 12.8752H10.3343C11.1167 12.8752 11.751 12.2409 11.751 11.4585ZM6.7513 8.23128L6.61497 8.09494C6.32207 7.80205 5.8472 7.80205 5.55431 8.09494C5.26141 8.38783 5.26141 8.86271 5.55431 9.1556L6.4701 10.0714C7.03962 10.6409 7.96298 10.6409 8.5325 10.0714L9.4483 9.1556C9.74119 8.86271 9.74119 8.38783 9.4483 8.09494C9.15541 7.80205 8.68053 7.80205 8.38764 8.09494L8.2513 8.23128V5.79194C8.2513 5.37772 7.91552 5.04194 7.5013 5.04194C7.08709 5.04194 6.7513 5.37772 6.7513 5.79194V8.23128Z" fill="#555379"/>
            </svg>
          </div>
          <div class="transactions-filter__filters-tab__name">
            {{ $t('gm.bet') }}
          </div>
        </div>
      </div>

      <v-date-picker
          v-model="range"
          color="purple"
          is-dark
          is-range
      >
        <template v-slot="{  inputEvents }">
          <div class="transactions-filter__filters-calendar" :class="{'full': range}" v-on="inputEvents.end">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
              <path d="M12.5627 2.67V1.5C12.5627 1.1925 12.3077 0.9375 12.0002 0.9375C11.6927 0.9375 11.4377 1.1925 11.4377 1.5V2.625H6.56267V1.5C6.56267 1.1925 6.30767 0.9375 6.00017 0.9375C5.69267 0.9375 5.43767 1.1925 5.43767 1.5V2.67C3.41267 2.8575 2.43017 4.065 2.28017 5.8575C2.26517 6.075 2.44517 6.255 2.65517 6.255H15.3452C15.5627 6.255 15.7427 6.0675 15.7202 5.8575C15.5702 4.065 14.5877 2.8575 12.5627 2.67Z" fill="#918FBB"/>
              <path d="M15 7.38H3C2.5875 7.38 2.25 7.7175 2.25 8.13V12.75C2.25 15 3.375 16.5 6 16.5H12C14.625 16.5 15.75 15 15.75 12.75V8.13C15.75 7.7175 15.4125 7.38 15 7.38ZM6.9075 13.6575C6.8325 13.725 6.75 13.7775 6.66 13.815C6.57 13.8525 6.4725 13.875 6.375 13.875C6.2775 13.875 6.18 13.8525 6.09 13.815C6 13.7775 5.9175 13.725 5.8425 13.6575C5.7075 13.515 5.625 13.32 5.625 13.125C5.625 12.93 5.7075 12.735 5.8425 12.5925C5.9175 12.525 6 12.4725 6.09 12.435C6.27 12.36 6.48 12.36 6.66 12.435C6.75 12.4725 6.8325 12.525 6.9075 12.5925C7.0425 12.735 7.125 12.93 7.125 13.125C7.125 13.32 7.0425 13.515 6.9075 13.6575ZM7.065 10.785C7.0275 10.875 6.975 10.9575 6.9075 11.0325C6.8325 11.1 6.75 11.1525 6.66 11.19C6.57 11.2275 6.4725 11.25 6.375 11.25C6.2775 11.25 6.18 11.2275 6.09 11.19C6 11.1525 5.9175 11.1 5.8425 11.0325C5.775 10.9575 5.7225 10.875 5.685 10.785C5.6475 10.695 5.625 10.5975 5.625 10.5C5.625 10.4025 5.6475 10.305 5.685 10.215C5.7225 10.125 5.775 10.0425 5.8425 9.9675C5.9175 9.9 6 9.8475 6.09 9.81C6.27 9.735 6.48 9.735 6.66 9.81C6.75 9.8475 6.8325 9.9 6.9075 9.9675C6.975 10.0425 7.0275 10.125 7.065 10.215C7.1025 10.305 7.125 10.4025 7.125 10.5C7.125 10.5975 7.1025 10.695 7.065 10.785ZM9.5325 11.0325C9.4575 11.1 9.375 11.1525 9.285 11.19C9.195 11.2275 9.0975 11.25 9 11.25C8.9025 11.25 8.805 11.2275 8.715 11.19C8.625 11.1525 8.5425 11.1 8.4675 11.0325C8.3325 10.89 8.25 10.695 8.25 10.5C8.25 10.305 8.3325 10.11 8.4675 9.9675C8.5425 9.9 8.625 9.8475 8.715 9.81C8.895 9.7275 9.105 9.7275 9.285 9.81C9.375 9.8475 9.4575 9.9 9.5325 9.9675C9.6675 10.11 9.75 10.305 9.75 10.5C9.75 10.695 9.6675 10.89 9.5325 11.0325Z" fill="#918FBB"/>
            </svg>
            <div class="dates" v-if="range">
              {{ formatDate(range.start) }} - {{ formatDate(range.end) }}
            </div>
          </div>
        </template>
      </v-date-picker>
    </div>
  </div>
</template>

<style scoped lang="scss">
.transactions-filter__filters-tabs {
  display: flex;
  padding: 4px;

}
</style>