<script>
export default {
  name: "OriginalsComing"
}
</script>

<template>
  <div class="originals-coming">
    <div class="originals-coming__image">
      <img src="@/assets/images/main/coming-lock.png" alt="">
    </div>
    <div class="originals-coming__text">
      <strong>{{ $t('originals_is_coming') }}... </strong>
      <span>{{ $t('feature_wil_be_soon') }}</span>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .originals-coming {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    overflow: hidden;
    border-radius: 12px;
    background: rgba(23, 21, 71, 0.72);
    backdrop-filter: blur(10.800000190734863px);
    display: flex;
    align-items: center;
    justify-content: center;
    .originals-coming__image {
      width: 204px;
      margin-right: 35px;
      img {
        width: 204px;
      }
      @media (max-width: 768px) {
        width: 108px;
        margin-left: 20px;
        img {
          width: 108px;
        }
      }
    }
    .originals-coming__text {
      strong,span {
        display: block;
        width: 100%;
      }
      strong {
        font-family: "Unbounded";
        font-size: 18px;
        font-weight: 700;
        letter-spacing: -0.36px;
        margin-bottom: 8px;
        @media (max-width: 768px) {
          font-size: 16px;
        }
      }
      span {
        color: #918FBB;
        opacity: 0.8;
        font-family: Geometria;
        font-family: "Geometria-Medium";
        font-size: 14px;
        letter-spacing: -0.28px;
        @media (max-width: 768px) {
          font-size: 12px;
        }
      }
    }
  }
</style>