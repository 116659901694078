<script>
import SliderHead from "@/components/Ui/SliderHead.vue";
import OriginalsComing from "@/components/Main/OriginalsComing.vue";
import {Hooper, Slide} from "hooper";

export default {
  name: "OriginalGames",
  components: {OriginalsComing, SliderHead,
    Hooper,
    Slide
  },
  data () {
    return {
      baseSettings: {
        autoPlay: false,
        playSpeed: 2000,
        transition: 300,
        rtl: false,
        hoverPause: false,
        centerMode: false,
        touchDrag: false,
        mouseDrag: false,
        wheelControl: false,
        keysControl: false,
        itemsToSlide: 1,
        trimWhiteSpace: true,
        infinite: false,
        infiniteScroll: false,
      }
    }
  },
  computed: {
    settings () {
      const settings = this.baseSettings;
      settings.itemsToShow = this.itemsToShow;

      return settings;
    },
    itemsToShow()
    {
      const width = window.innerWidth;

      if (width > 2100) {
        return 8;
      } else if (width > 1900) {
        return 6;
      } else if (width > 1800) {
        return 5;
      } else if (width > 1700) {
        return 5;
      } else if (width > 1400) {
        return 5;
      } else if (width > 992) {
        return 4;
      } else if (width > 768) {
        return 4;
      } else if (width > 650) {
        return 4;
      } else if (width > 500) {
        return 3.5;
      }
      else if (width > 200) {
        return 2.5;
      }

      return 9;
    }
  },
}
</script>

<template>
  <div class="original-games">
    <SliderHead>
      <template v-slot:image>
        <img src="@/assets/images/main/sneg-originals-icon.svg" alt="">
      </template>
      <template v-slot:name>
        SnegBet originals
      </template>
    </SliderHead>
    <div class="games">
      <hooper ref="slider" :settings="settings">
        <slide>
          <div class="game orange">
            <div class="game-logo">
              <img src="@/assets/images/white-logo.svg" alt="">
            </div>
            <div class="game-name">
              CRUSH
            </div>
            <div class="game-image">
              <img src="@/assets/images/main/origin-1.png" alt="">
            </div>
          </div>
        </slide>
        <slide>
          <div class="game purple">
            <div class="game-logo">
              <img src="@/assets/images/white-logo.svg" alt="">
            </div>
            <div class="game-name">
              MINES
            </div>
            <div class="game-image">
              <img src="@/assets/images/main/original-2.png" alt="">
            </div>
          </div>
        </slide>
        <slide>
          <div class="game red">
            <div class="game-logo">
              <img src="@/assets/images/white-logo.svg" alt="">
            </div>
            <div class="game-name">
              DICE
            </div>
            <div class="game-image">
              <img src="@/assets/images/main/original-3.png" alt="">
            </div>
          </div>
        </slide>
        <slide>
          <div class="game blue">
            <div class="game-logo">
              <img src="@/assets/images/white-logo.svg" alt="">
            </div>
            <div class="game-name">
              LIMBO
            </div>
            <div class="game-image">
              <img src="@/assets/images/main/original-4.png" alt="">
            </div>
          </div>
        </slide>
        <slide>
          <div class="game green">
            <div class="game-logo">
              <img src="@/assets/images/white-logo.svg" alt="">
            </div>
            <div class="game-name">
              HILO
            </div>
            <div class="game-image">
              <img src="@/assets/images/main/original-5.png" alt="">
            </div>
          </div>
        </slide>
        <slide>
          <div class="game orange">
            <div class="game-logo">
              <img src="@/assets/images/white-logo.svg" alt="">
            </div>
            <div class="game-name">
              CRUSH
            </div>
            <div class="game-image">
              <img src="@/assets/images/main/origin-1.png" alt="">
            </div>
          </div>
        </slide>
        <slide>
          <div class="game purple">
            <div class="game-logo">
              <img src="@/assets/images/white-logo.svg" alt="">
            </div>
            <div class="game-name">
              MINES
            </div>
            <div class="game-image">
              <img src="@/assets/images/main/original-2.png" alt="">
            </div>
          </div>
        </slide>
      </hooper>
    </div>
    <OriginalsComing/>
  </div>
</template>

<style scoped lang="scss">
  .original-games {
    margin-bottom: 14px;
    position: relative;
    .games {
      margin-top: 12px;
      border-radius: 12px;
      overflow: hidden;
      .game {
        border-radius: 15px;
        overflow: hidden;
        height: 289px;
        .game-logo {
          width: 100%;
          display: flex;
          justify-content: center;
          margin-top: 12px;
          @media (max-width: 768px) {
            margin-top: 6px;
          }
          img {
            width: 109px;
            max-width: 90%;
            @media (max-width: 768px) {
              width: auto;
              height: 20px;

            }
          }
        }
        @media (max-width: 768px) {
          height: 166px;
        }
        .game-name {
          font-family: "Unbounded";
          font-size: 40px;
          font-weight: 700;
          line-height: 50px; /* 125% */
          letter-spacing: -0.8px;
          width: 100%;
          @media (max-width: 768px) {
            font-size: 22px;
            line-height: 28px;
          }
          text-align: center;
        }
        .game-name, .game-logo {
          position: relative;
          z-index: 2;
        }
        &.orange {
          background: linear-gradient(180deg, #F8A442 -4.55%, #FF4D00 100%);
        }
        &.purple {
          background: linear-gradient(180deg, #9D05D8 0%, rgba(60, 0, 83, 0.65) 100%);
        }
        &.red {
          background: linear-gradient(180deg, #FF1D61 0%, #9F0031 100%);
        }
        &.blue {
          background: linear-gradient(180deg, #2A73FF 0%, #0048D5 100%);
        }
        &.green {
          background: linear-gradient(180deg, #00FF57 0%, #009255 100%);
        }

        position: relative;

        .game-image {
          position: absolute;
          bottom: -5px;
          width: 100%;
          display: flex;
          justify-content: center;
          img {
            @media (max-width: 768px) {
              max-width: 120px;
            }
            width: 100%;
          }
        }
      }

      ::v-deep {
        .hooper {
          height: unset;
        }
        .hooper-list {
          padding-top: 2px;
          height: unset;
          @media (max-width: 768px) {
            //.hooper-track {
            //  height: 174px;
            //}
          }
          .hooper-slide {
            padding-right: 8px;
            padding-left: 8px;
            @media (max-width: 768px) {
              padding-right: 4px;
              padding-left: 4px;
            }
          }
        }
      }
    }
  }
</style>