<script>
export default {
  name: "NftHeadItem"
}
</script>

<template>
  <div class="nft-head-item">
    <div class="lootbox">{{ $t('n_lootbox') }}</div>
    <div class="user">{{ $t('n_user') }}</div>
    <div class="time">{{ $t('n_time') }}</div>
    <div class="price">{{ $t('n_price') }}</div>
    <div class="chance">{{ $t('n_chance') }}</div>
    <div class="rank">{{ $t('n_rank') }}</div>
  </div>
</template>

<style scoped lang="scss">
  .nft-head-item {
    display: flex;
    padding: 8px 0;
    & > div {
      color: #3E3C5F;
      font-family: "Geometria-Bold";
      font-size: 12px;
      font-weight: 700;
      letter-spacing: 0.6px;
    }
    .lootbox {
      @media (max-width: 768px) {
        display: none;
      }
    }
    .lootbox, .user {
      width: 20%;
    }
    .user {
      @media (max-width: 768px) {
        width: 40%;
      }
    }
    .time {
      width: 15%;
      @media (max-width: 768px) {
        display: none;
      }
    }
    .price {
      width: 25%;
      @media (max-width: 768px) {
        width: 45%;
      }
    }
    .chance {
      width: 10%;
    }
    .rank {
      padding-right: 15px;
      width: 10%;
      display: flex;
      justify-content: flex-end;
      @media (max-width: 768px) {
        display: none;
      }
    }
  }
</style>