<script>
export default {
  props: {
    size: {
      type: String,
      default: 'normal'
    },
    fill: {
      type: Boolean,
      default: false,
    }
  },
  name: "UiLoader"
}
</script>

<template>
  <div class="loader" :class="{'fill': fill, 'small': size === 'small'}">
    <img src="@/assets/images/loader.gif" alt="">
  </div>
</template>

<style scoped lang="scss">
  .loader {
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    img {
      width: 100px;
    }
    &.small {
      img {
        width: 50px;
      }
    }
    &.fill {
      padding: 20px 0;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }
</style>