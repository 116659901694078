<script>
import SliderHead from "@/components/Ui/SliderHead.vue";
import ViewAll from "@/components/Parts/ViewAll.vue";
import GameProviderItem from "@/components/Main/GameProviderItem.vue";
import {Hooper, Slide} from "hooper";
import {getProviders} from "@/services/games";

export default {
  data () {
    return {
        providers: [],
        baseSettings: {
        autoPlay: false,
        playSpeed: 2000,
        transition: 300,
        rtl: false,
        hoverPause: false,
        centerMode: false,
          trimWhiteSpace: true,
          touchDrag: true,
        mouseDrag: true,
        wheelControl: false,
        keysControl: false,
        itemsToSlide: 1,
        infinite: false,
        infiniteScroll: false,
      }
    }
  },
  mounted() {
    this.fetchProviders()
  },
  methods: {
    fetchProviders() {
      getProviders({
        per_page: 20
      }).then(response => {
        this.providers = response.data;
      })
    },
    prev () {
      const slider = this.$refs.slider;

      slider.slidePrev();
    },
    next () {
      const slider = this.$refs.slider;
      slider.slideNext();
    },
  },
  computed: {
    settings () {
      const settings = this.baseSettings;
      settings.itemsToShow = this.itemsToShow;

      return settings;
    },
    itemsToShow()
    {
      const width = window.innerWidth;

      if (width > 2100) {
        return 8;
      } else if (width > 1950) {
        return 7;
      } else if (width > 1800) {
        return 7;
      } else if (width > 1600) {
        return 6;
      } else if (width > 1400) {
        return 7;
      } else if (width > 992) {
        return 5;
      } else if (width > 768) {
        return 5;
      } else if (width > 650) {
        return 4;
      } else if (width > 500) {
        return 3.5;
      }
      else if (width > 200) {
        return 2.5;
      }

      return 9;
    }
  },
  name: "GameProviders",
  components: {GameProviderItem, ViewAll, SliderHead,
    Hooper,
    Slide
  }
}
</script>

<template>
  <div class="game-providers">
    <SliderHead @prev="prev" @next="next">
      <template v-slot:image>
        <img src="@/assets/images/main/providers-icon.svg" alt="">
      </template>
      <template v-slot:name>{{ $t('providers') }}</template>
      <template v-slot:all>
        <ViewAll link="/games/providers"/>
      </template>
    </SliderHead>

    <div class="providers">
      <hooper ref="slider" :settings="settings">
        <slide v-for="(provider,i) in providers" :key="i">
          <GameProviderItem  :provider="provider" />
        </slide>
      </hooper>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .game-providers {
    margin-bottom: 14px;
    .providers {
      margin-left: -8px;
      margin-right: -8px;
      margin-top: 12px;
      position: relative;
      @media (max-width: 768px) {
        &::after {
          content: "";
          width: 93px;
          height: 100%;
          position: absolute;
          right: -10px;
          display: block;
          background: linear-gradient(270deg, #02002A 0%, rgba(2, 0, 42, 0.00) 100%);
          top: 0;
        }
        margin-right: -4px;
        margin-left: -4px;
      }
      ::v-deep {
        .hooper {
          height: unset;
        }
        .hooper-list {
          padding-top: 2px;
          height: unset;
          @media (max-width: 768px) {
            .hooper-track {
              height: 72px;
            }
          }
          .hooper-slide {
            padding-right: 8px;
            padding-left: 8px;
            @media (max-width: 768px) {
              padding-right: 4px;
              padding-left: 4px;
            }
          }
        }
      }
    }
  }
</style>