<script>
import BetItem from "@/components/Parts/BetItem.vue";
import BetItemHead from "@/components/Parts/BetItemHead.vue";
import { getBets } from "@/services/bets";

export default {
  name: "BetsList",
  components: {BetItemHead, BetItem},
  data () {
    return {
      bets: [],
    }
  },
  methods: {
    subscribeToNewItems ()
    {
      this.$echo.channel(`live-bets`).listen(".BetCreatedEvent", async (payload) => {
        this.bets.unshift(payload)
        this.bets.pop();
      })
    },
    fetchBets() {
      getBets().then(response => {
        this.bets = response.data.data;
      })
    }
  },
  mounted() {
    this.fetchBets();
    this.subscribeToNewItems()
  }
}
</script>

<template>
  <div class="bets">

    <div class="bets-head">
      <div class="head-title">
        <div class="img">
          <img src="@/assets/images/bet/bet-icon.svg" alt="">
        </div>
        <span>{{ $t('b_bets') }}</span>
      </div>
      <div class="bets-head__tabs">
        <div class="bets-head__tab active">{{ $t('b_all_bets') }}</div>
<!--        <div class="bets-head__tab">High rollers</div>-->
<!--        <div class="bets-head__tab">Lucky bets</div>-->
<!--        <div class="bets-head__tab">Trades</div>-->
      </div>
    </div>

    <BetItemHead />
    <div class="items">
      <BetItem v-for="(bet,i) in bets" :key="i" :bet="bet" />
    </div>
  </div>
</template>

<style scoped lang="scss">
  .bets {
    .bets-head {
      display: flex;
      padding: 7px 0;
      justify-content: space-between;
      align-items: center;
      @media (max-width: 768px) {
        display: block;
      }
      .head-title {
        display: flex;
        align-items: center;
        .img {
          width: 18px;
          height: 18px;
          img {
            width: 100%;
            height: 100%;
          }

          margin-right: 6px;
        }
        span {
          color: #7F7DA6;
          font-family: "Geometria-Bold";
          font-size: 14px;
          font-weight: 700;
          letter-spacing: -0.28px;
        }
      }
      .bets-head__tabs {
        border-radius: 26px;
        background: #1D1B41;
        padding: 4px;
        display: flex;
        @media (max-width: 768px) {
          width: fit-content;
          margin-top: 14px;
        }
        .bets-head__tab {
          padding: 6px 12px;
          border-radius: 31px;
          color: #7F7DA6;
          text-align: justify;
          font-family: "Geometria-Medium";
          font-size: 14px;
          transition: 0.2s;
          font-weight: 500;
          letter-spacing: -0.28px;
          cursor: pointer;
          &:hover {
            opacity: 0.8;
          }
          &.active {
            background: #FFE70C;
            color: #0C0A33;
          }
        }
      }
    }
    .items {
      padding-bottom: 50px;
      max-height: 700px;
      &::-webkit-scrollbar {
        display: none;
      }
      overflow-y: auto;
    }
    .bet + .bet {
      margin-top: 4px;
    }

    position: relative;
    &::after {
      content: "";
      display: block;
      width: 100%;
      position: absolute;
      bottom: 0;
      height: 150px;
      background: linear-gradient(360deg,#02002A 0%, rgba(2, 0, 42, 0.00) 100%);
    }
  }
</style>