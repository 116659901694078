<script>
export default {
  props: {
    feed: {
      type: Object,
      required: true
    }
  },
  name: "FeedItem"
}
</script>

<template>
  <div class="feed-item">
    <div class="feed-item_icon" v-if="feed.game">
      <img :src="feed.game.preview_image" alt="">
    </div>
    <div class="feed-item_info">
      <div class="feed-item_icon_info-user">
        <div class="avatar">
          <img :class="{'without-avatar': !feed.user.avatar}" :src="feed.user.avatar ? feed.user.avatar : require('@/assets/images/default-avatar.png')" alt="">
        </div>
        <span>{{ feed.user.name }}</span>
      </div>
      <div class="feed-item_icon_info-wallet">
        <div class="feed-item_icon_info-wallet_icon">
          <img src="@/assets/images/dollar-icon.svg" alt="">
        </div>
        <div class="feed-item_icon_info-wallet_amount">{{ feed.amount }}</div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .feed-item {
    border-radius: 10px;
    background: #131139;
    width: 200px;
    min-width: 200px;
    padding: 4px;
    display: flex;
    .feed-item_icon {
      width: 40px;
      height: 54px;
      border-radius: 8px;
      margin-right: 10px;
      overflow: hidden;
      @media (max-width: 768px) {
        height: 45px;
        width: 34px;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
    .feed-item_info {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .feed-item_icon_info-user {
        display: flex;
        align-items: center;
        .avatar {
          width: 20px;
          height: 20px;
          border-radius: 100%;
          background: $color-gray-light;
          margin-right: 4px;
          overflow: hidden;
          @media (max-width: 768px) {
            width: 18px;
            height: 18px;
          }
          img {
            width: 100%;
            height: 100%;
            &.without-avatar {
              filter: grayscale(1);
            }
          }
        }
        span {
          color: #918FBB;
          font-family: "Geometria-Medium";
          font-size: 12px;
          font-weight: 500;
          letter-spacing: -0.24px;
          max-width: 100%;
          display: block;
          text-overflow: ellipsis;
          overflow: hidden;
          padding-top: 2px;
        }
      }
      .feed-item_icon_info-wallet {
        display: flex;
        margin-top: 5px;
        align-items: center;
        @media (max-width: 768px) {
          margin-top: 3.5px;
        }
        .feed-item_icon_info-wallet_icon {
          width: 18px;
          height: 18px;
          overflow: hidden;
          margin-right: 6px;
          border-radius: 100%;
          color: rgb(247, 252, 0);
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .feed-item_icon_info-wallet_amount {
          font-family: "Geometria-Medium";
          font-size: 14px;
          font-weight: 500;
          padding-top: 1px;
          max-width: 62px;
          letter-spacing: -0.28px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          @media (max-width: 768px) {
            font-size: 12px;
            padding-top: 2px;
          }
        }
        .feed-item_icon_info-wallet_coin {
          color: #B9B8DD;
          display: flex;
          align-items: center;
          border-radius: 5px;
          margin-left: 5px;
          padding: 2px 4px;
          background: rgba(255, 255, 255, 0.15);
          font-size: 12px;
          font-weight: 700;
          line-height: 14px;
          letter-spacing: -0.24px;
          font-family: "Geometria-Bold";
        }
      }
    }
  }
</style>