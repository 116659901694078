<script>
import SignUp from "@/components/Auth/Buttons/SignUp.vue";
import {mapGetters} from "vuex";

export default {
  name: "PaymentMethodsBlock",
  components: {SignUp},
  computed: {
    ...mapGetters({
      isAuth: 'user/isAuth'
    })
  }
}
</script>

<template>
  <div class="payment-methods">
    <div class="shadow left">
      <img src="@/assets/images/left-shadow-circle.png" alt="">
    </div>
    <div class="shadow right">
      <img src="@/assets/images/right-shadow-circle.png" alt="">
    </div>
    <div class="shadow right">
      <img src="@/assets/images/right-shadow-circle-2.png" alt="">
    </div>
    <div class="payment-methods-second_text">
      <h2>{{ $t('d_crypto') }}</h2>
      <span>{{ $t('d_process_1') }}</span>
    </div>
    <div class="payment-methods-second_payments">
      <img src="@/assets/images/apple-white-icon.png" alt="">
      <img src="@/assets/images/google-icon.png" alt="">
      <img src="@/assets/images/visa-icon.png" alt="">
      <img src="@/assets/images/mastercard-icon.png" alt="">
    </div>
    <div class="payment-methods-second_auth" v-if="!isAuth">
      <SignUp  />
    </div>
  </div>
</template>

<style scoped lang="scss">
.payment-methods {
  margin-top: 24px;
  border-radius: 19px;
  background: #131139;
  padding: 24px;
  position: relative;
  overflow: hidden;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 992px) {
    display: block;
    padding: 20px;
    padding-bottom: 10px;
  }
  .shadow {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 0;
    @media (max-width: 992px) {
      height: 100%;
      img {
        height: 100%;
      }
    }
    &.left {
      left: 0;
    }
    &.right {
      right: 0;
    }
  }
  .payment-methods-second_payments {
    display: flex;
    align-items: center;
    padding-right: 50px;
    position: relative;
    z-index: 2;
    img + img {
      margin-left: 22px;
    }
    @media (max-width: 992px) {
      padding-right: 0;
    }
  }
  .payment-methods-second_text {
    position: relative;
    z-index: 2;
    h2 {
      font-family: "Unbounded", sans-serif;
      margin-bottom: 8px;
      display: block;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: -0.32px;
      @media (max-width: 575px) {
        font-size: 12px;
        margin-bottom: 6px;
      }
    }

    position: relative;
    span {
      font-weight: 500;
      opacity: 0.6;
      letter-spacing: -0.28px;
      display: block;
      font-family: "Geometria-Medium";
      @media (max-width: 575px) {
        font-size: 12px;
      }
    }
  }
  .payment-methods-second_auth {
    position: relative;
    z-index: 3;

    @media (max-width: 992px) {
      position: absolute;
      top: 10px;
      right: 10px;
    }
    @media (max-width: 575px) {
      position: relative;
      margin-bottom: 10px;
      top: 0;
      margin-top: 10px;
      right: 0;
      justify-content: center;
      display: flex;
      z-index: 8;
    }
  }
}
</style>