<script>
export default {
  props: {
    text: {
      type: String,
      default: 'success'
    }
  },
  name: "SuccessBadge"
}
</script>

<template>
  <div class="success-badge">
      <div class="text">
        {{ text }}
      </div>
    <div class="close" @click="$emit('close')">
      <svg data-v-29a248d9="" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none"><path data-v-29a248d9="" d="M17 7L7 17M7 7L17 17" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .success-badge {
    padding: 15px;
    background: #7F00FF;
    border-radius: 8px;
    display: flex;
    align-items: center;
    .close {
      width: 20px;
      cursor: pointer;
      transition: 0.2s;
      &:hover {
        opacity: 0.8;
      }
    }
    .text {
      color:#fff;
      font-weight: bold;
      font-family: "Geometria-Bold";
    }
  }
</style>