<script>
import CheckboxToggle from "@/components/Ui/CheckboxToggle.vue";
import {mapGetters} from "vuex";

export default {
  components: {CheckboxToggle},
  props: {
    icon: {
      type: String,
    },
    id: {
      type: String,
    },
    name: {
      type: String
    },
  },
  computed: {
    ...mapGetters({
      user: 'user/user'
    }),
    value () {
      if (this.id && this.user) {
        return this.user[this.id]
      }

      return false;
    }
  },
  name: "SettingItem"
}
</script>

<template>
  <div class="setting">
    <div class="setting-icon">
      <img :src="icon" alt="">
    </div>

    <div class="setting-name">
      {{ name }}
    </div>

    <CheckboxToggle :value="value" @input="$emit('update', $event)" />
  </div>
</template>

<style scoped lang="scss">
  .setting {
    display: flex;
    align-items: center;
    .setting-icon {
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 8px;
    }
    .setting-name {
      color: #B9B8DD;
      width: 200px;
      font-family: "Geometria-Medium";
      font-size: 14px;
      font-weight: 500;
      letter-spacing: -0.28px;
    }
    @media (max-width: 768px) {
      .checkbox {
        margin-left: auto;
      }
    }
  }
</style>