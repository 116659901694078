<script>
import {mapGetters} from "vuex";

export default {
  data () {
    return {
      timeRemaining: 0
    }
  },
  computed: {
    ...mapGetters({
      user: "user/user"
    }),
    hours() {
      return Math.floor(this.timeRemaining / 3600);
    },
    minutes() {
      return Math.floor((this.timeRemaining % 3600) / 60);
    },
    seconds() {
      return this.timeRemaining % 60;
    }
  },
  methods: {
    formatNumber(number) {
      return number < 10 ? `0${number}` : number;
    },
    updateTimeRemaining() {
      this.timeRemaining = this.user.next_give_free_lootbox--;
      if (this.timeRemaining < 0) {
        this.timeRemaining = 0;
      }
    }
  },
  mounted() {
    this.updateTimeRemaining();
    this.timer = setInterval(this.updateTimeRemaining, 1000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  name: "FreeTimer"
}
</script>

<template>
  <div class="free-timer">
    <div class="timer-item">
      <div class="timer-label">
        {{ formatNumber(hours) }}
      </div>
      <div class="timer-value">
        HOURS
      </div>
    </div>
    <div class="timer-separator">
      :
    </div>
    <div class="timer-item">
      <div class="timer-label">
        {{ formatNumber(minutes) }}
      </div>
      <div class="timer-value">
        MINUTES
      </div>
    </div>
    <div class="timer-separator">
      :
    </div>
    <div class="timer-item">
      <div class="timer-label">
        {{ formatNumber(seconds) }}
      </div>
      <div class="timer-value">
        SECONDS
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .free-timer {
    display: flex;
    margin-top: 14px;
    .timer-item {
      width: fit-content;
      .timer-label {
        font-family: Unbounded;
        font-size: 38px;
        font-style: normal;
        font-weight: 700;
        text-align: center;
        line-height: 40px; /* 131.579% */
        letter-spacing: -0.76px;
      }
      .timer-value {
        padding: 6px 8px;
        border-radius: 8px;
        white-space: nowrap;
        border: 1px solid #5A5885;
        background: rgba(255, 255, 255, 0.12);
        backdrop-filter: blur(16.899999618530273px);
        font-size: 12px;
        font-family: "Geometria-Bold";
        font-style: normal;
        padding-top: 8px;
        font-weight: 800;
        line-height: 13px;
        letter-spacing: 0.48px;
      }
    }
    .timer-separator {
      font-family: Unbounded;
      font-size: 38px;
      font-style: normal;
      font-weight: 700;
      display: flex;
      align-items: flex-start;
      line-height: 36px; /* 131.579% */
      letter-spacing: -0.76px;
    }
  }
</style>