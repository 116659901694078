<script>
export default {
  props: {
    callback: {
      type: Function
    }
  },
  name: "BackButton"
}
</script>

<template>
  <button @click="callback" class="back default-hover">
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <path d="M11.25 4.5L6.75 9L11.25 13.5" stroke="#7F7DA6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

    <span>{{ $t('back') }}</span>
  </button>
</template>

<style scoped lang="scss">
.back {
  display: flex;
  align-items: center;
  padding: 10px 12px;
  border-radius: 11px;
  background: #1D1B41;
  span {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    font-family: "Geometria-Bold";
    line-height: normal;
    letter-spacing: -0.28px;
    margin-left: 8px;
  }
}
</style>