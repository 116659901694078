export default function formatNumber (number) {
    let parts = number.toFixed(2).toString().split('.');
    let integerPart = parts[0];
    let decimalPart = parts[1];

    // Добавляем разделитель тысяч для целой части
    integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    // Возвращаем объект с целой и десятичной частями числа
    return {
        integer: integerPart,
        decimal: decimalPart
    };
}