import { render, staticRenderFns } from "./WelcomeBanner.vue?vue&type=template&id=fd34f5e8&scoped=true"
import script from "./WelcomeBanner.vue?vue&type=script&lang=js"
export * from "./WelcomeBanner.vue?vue&type=script&lang=js"
import style0 from "./WelcomeBanner.vue?vue&type=style&index=0&id=fd34f5e8&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fd34f5e8",
  null
  
)

export default component.exports