<script>
import {VueAgile} from "vue-agile";
import SliderItem from "@/components/Lootboxes/SliderItem.vue";
export default {
  props: {
    items: {
      type: Array,
    }
  },
  data () {
    return {
      settings: {
        rtl: false,
        pauseOnHover: false,
        touchDrag: true,
        mouseDrag: true,
        pauseOnDotsHover: false,
        navButtons: false,
        centerMode: false,
        slidesToShow: 5,
        infinite: true,
        timing: 'ease-out',
        dots: false,
        responsive: [
          {
            breakpoint: 2000,
            settings: {
              slidesToShow: 5.2,
            }
          },
          {
            breakpoint: 1800,
            settings: {
              slidesToShow: 3.2,
            }
          },
          {
            breakpoint: 1600,
            settings: {
              slidesToShow: 3.3,
            }
          },
          {
            breakpoint: 1300,
            settings: {
              slidesToShow: 3.3,
            }
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 3.25,
            }
          },
          {
            breakpoint: 700,
            settings: {
              slidesToShow: 3.25,
            }
          },
          {
            breakpoint: 500,
            settings: {
              slidesToShow: 2.5,
            }
          },

          {
            breakpoint: 400,
            settings: {
              slidesToShow: 2.5,
            }
          },

          {
            breakpoint: 300,
            settings: {
              slidesToShow: 2.5,
            }
          },
        ]

      }
    }
  },
  methods: {
    prev () {
      this.$refs.slider.goToPrev()
    },
    next () {
      this.$refs.slider.goToNext()
    }
  },
  name: "LootboxContains",
  components: {
    SliderItem,
    'agile': VueAgile
  }
}
</script>

<template>
  <div class="lootbox-contains">
    <div class="head">
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.9997 6.93332C12.5212 6.62448 13.6663 5.27931 13.6663 3.66667C13.6663 1.82572 12.174 0.333336 10.333 0.333336C8.72037 0.333336 7.3752 1.47851 7.06635 3C7.02263 3.21541 6.99967 3.43836 6.99967 3.66667C6.99967 5.50762 8.49206 7 10.333 7C10.5613 7 10.7843 6.97705 10.9997 6.93332ZM2.99967 3H6.05064C6.01708 3.21732 5.99967 3.43996 5.99967 3.66667C5.99967 6.0599 7.93977 8 10.333 8C10.5597 8 10.7824 7.98259 10.9997 7.94904V11C10.9997 12.4728 9.80577 13.6667 8.33301 13.6667H2.99967C1.52692 13.6667 0.333008 12.4728 0.333008 11V5.66667C0.333008 4.19391 1.52692 3 2.99967 3Z" fill="#23A3FF"/>
      </svg>
      <span>{{ $t('ll_contains') }}</span>
    </div>
    <div class="items">
      <div class="nav default-hover left" @click="prev">
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M11.25 4.5L6.75 9L11.25 13.5" stroke="#7F7DA6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
      <div class="nav default-hover right" @click="next">
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6.75 13.5L11.25 9L6.75 4.5" stroke="#7F7DA6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
      <agile ref="slider" :options="settings">
        <div class="item"  v-for="(item,i) in items" :key="i">
          <SliderItem :item="item" />
        </div>
      </agile>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .lootbox-contains {
    border-radius: 20px;
    padding: 16px 20px;
    background: #0C0A33;
    @media (max-width: 768px) {
      padding: 14px 10px;
      padding-bottom: 0;
    }
    .head {
      display: flex;
      margin-bottom: 12px;
      align-items: center;
      span {
        display: flex;
        align-items: center;
        margin-left: 9px;
        color: #7F7DA6;
        font-family: "Geometria-Bold";
        font-size: 14px;
        font-weight: 700;
        letter-spacing: -0.28px;
      }
    }
    .items {
      position: relative;
      .nav {
        position: absolute;
        z-index: 3;
        display: flex;
        justify-content: center;
        align-items: center;
        top: calc(50% - 17px);
        width: 34px;
        height: 34px;
        left: 4px;
        border-radius: 100%;
        background: rgba(200, 197, 255, 0.10);
        backdrop-filter: blur(5.900000095367432px);
        &.right {
          left: unset;
          right: 5px;
        }
      }
      &::after {
        position: absolute;
        left: 0;
      }
      &::after,&::before {
        content: "";
        display: block;
        width: 140px;
        height: 100%;
        z-index: 2;
        position: absolute;
        top: 0;
        @media (max-width: 700px) {
          width: 60px;
        }
      }
      &::after {
        left: -2px;
        background: linear-gradient(90deg, #0C0A33 0%, rgba(12, 10, 51, 0.00) 100%);
      }
      &::before {
        right: 0;
        background: linear-gradient(90deg, #0C0A33 0%, rgba(12, 10, 51, 0.00) 100%);
        transform: scale(-1, 1);
        right: -2px;
      }

      ::v-deep {
        @media (max-width: 768px) {
          .nft-item-content__image {
            margin-bottom: 7px;
          }
          .nft-item-content__name {
            white-space: pre-wrap !important;
            height: 28px;
            display: flex;
            align-items: center;
            text-align: center;
            justify-content: center;
            font-size: 11px;
          }
          .nft-item-content {
            border-radius: 20px;
            background: var(--10, rgba(200, 197, 255, 0.10));
            box-shadow: 0px 4px 21.9px 0px rgba(0, 0, 0, 0.25), 0px 80px 70.2px 0px rgba(0, 0, 0, 0.25);
            backdrop-filter: blur(15.550000190734863px);
          }
          .agile__list {
            padding-bottom: 18px;
          }
        }
      }
    }
    .item {
      padding: 0 4px;
    }
  }
</style>