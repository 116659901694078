export default {
    currentLanguage: state => state.currentLang,
    settings: state => state.settings,
    coin_exchange_rate: state => {
        return state?.settings?.coin_exchange_rate ?? 1;
    },
    current_chat_room: (state) => {
        if (!state.settings || !state.settings.chat_rooms) return null;

        if (state.currentLang) {
            return state.settings.chat_rooms.find(item => item.code.includes(state.currentLang))
        }

        return state.settings.chat_rooms.find(item => item.is_default)
    }
}