<script>
import BlockCategory from "@/components/Ui/BlockCategory.vue";

export default {
  data () {
    return {
      blocks: [
        {
          image: require('@/assets/images/main/block-1.png'),
          available: true,
          link: '/games',
          categoryIcon: require('@/assets/images/sidebar/dice-icon.svg'),
          name: 'casino',
        },
        {
          image: require('@/assets/images/main/block-4.png'),
          available: true,
          link: '/lootboxes',
          categoryIcon: require('@/assets/images/sidebar/lootboxes-icon.svg'),
          name: 'lootboxes',
        },
        {
          image: require('@/assets/images/main/block-6.png'),
          available: true,
          categoryIcon: require('@/assets/images/sidebar/loyal-icon.svg'),
          name: 'loyal',
          isLoyal: true,
        },
        {
          isSmall: true,
          image: require('@/assets/images/main/block-5.png'),
          available: true,
          link: '/nft-marketplace',
          categoryIcon: require('@/assets/images/sidebar/nft-icon.svg'),
          name: 'nfts',
        },
        {
          image: require('@/assets/images/main/block-2.png'),
          available: false,
          categoryIcon: require('@/assets/images/main/sport-icon.svg'),
          name: 'sports',
          isSmall: true,
        },
        {
          image: require('@/assets/images/main/block-3.png'),
          available: false,
          categoryIcon: require('@/assets/images/main/future-icon.svg'),
          name: 'futures',
        },


      ]
    }
  },
  name: "BlockCategories",
  components: {BlockCategory}
}
</script>

<template>
  <div class="block-categories">
    <BlockCategory v-for="(block,i) in blocks" :key="i" :block="block" />
  </div>
</template>

<style scoped lang="scss">
  .block-categories {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 16px;
    margin-top: 24px;
    margin-bottom: 16px;
    @media (max-width: 900px) {
      grid-gap: 9px;
      grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 768px) {
      margin-top: 8px;
    }
  }
</style>