<script>
import UiInput from "@/components/Ui/UiInput.vue";
import AccentBtn from "@/components/Ui/Buttons/AccentBtn.vue";
import UiCheckbox from "@/components/Ui/UiCheckbox.vue";
import HavePromo from "@/components/Modals/Auth/HavePromo.vue";
import {markInputErrors, unmarkInputErrors} from "@/helpers/inputErrors";
import {mapActions} from "vuex";
export default {
  data () {
    return {
      isLoading: false,
      payload: {
        username: null,
        email: null,
        password: null,
        terms: false,
      },
      errors: [],
    }
  },
  watch: {
    errors() {
      for(const item in this.errors) {
        this.$root.$emit('error', {
          title: "Validation Error",
          text: this.errors[item][0]
        })
      }
    }
  },
  name: "RegisterForm",
  methods: {
    ...mapActions({
      register: 'auth/register'
    }),
    async registerMethod () {
      await this.clearErrors()

      this.isLoading = true;

      try {
        await this.register({
          email: this.payload.email,
          username: this.payload.username,
          password: this.payload.password,
          password_confirmation: this.payload.password,
          showErrors: this.showErrors,
          terms: this.payload.terms,
        })
      } catch (e) {
        console.error(e.message);
      }

      this.isLoading = false
    },
    showErrors (errors) {
      this.errors = errors
      markInputErrors(Object.keys(this.payload), errors, this.$refs);
    },
    clearErrors () {
      this.errors = []
      unmarkInputErrors(Object.keys(this.payload), this.$refs)
    }
  },

  components: {HavePromo, AccentBtn, UiInput, UiCheckbox}
}
</script>

<template>
  <div class="register-form">
    <UiInput ref="username" v-model="payload.username" type="text" :placeholder="$t('register_form.create_username')" :icon="require('@/assets/images/auth/user-icon.svg')" />
    <UiInput ref="email" v-model="payload.email" type="email" :placeholder="$t('register_form.enter_email')" :icon="require('@/assets/images/auth/email-icon.svg')" />
    <UiInput ref="password" @keydown.enter="registerMethod" v-model="payload.password" type="password" :placeholder="$t('register_form.create_password')" :icon="require('@/assets/images/auth/password-icon.svg')" />

    <UiCheckbox @change="payload.terms = $event.target.checked">
      <div class="agree-terms" v-html="$t('register_form.agree_text')"></div>
    </UiCheckbox>

<!--    <transition name="fade">-->
<!--      <UiErrors :errors="errors" />-->
<!--    </transition>-->

    <AccentBtn :is-loading="isLoading" @click="registerMethod" :title="$t('sign_up')"/>

    <HavePromo/>

  </div>
</template>

<style scoped lang="scss">
  .register-form {
    .ui-input + .ui-input {
      margin-top: 4px;
    }

    .accent-btn {
      margin-top: 16px;
    }
    .ui-checkbox {
      margin-top: 16px;
    }
    .agree-terms {
      color: #7F7DA6;
      font-family: "Geometria-Medium";
      font-size: 12px;
      letter-spacing: -0.24px;
      padding-top: 2px;
      ::v-deep {
        a {
          color: #23A3FF;
          font-family: "Geometria-Medium";
          font-size: 12px;
          letter-spacing: -0.24px;
        }
      }
    }
    .have-promo {
      border-bottom: 1px solid #1D1B41
    }
  }
</style>