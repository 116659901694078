<script>
import UiInput from "@/components/Ui/UiInput.vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
import SlideGameItem from "@/components/Main/SlideGameItem.vue";
import UiClose from "@/components/Ui/UiClose.vue";
import EmptyState from "@/components/EmptyState.vue";
import UiLoader from "@/components/UiLoader.vue";
import {getList} from "@/services/games";

export default {
  data () {
    return {
      loading: false,
      page: 1,
      last_page: 1,
      search: '',
      per_page: 30,
      section: null,
      games: [],
    }
  },
  watch: {
    section() {
      this.page = 1;
      this.getGames()
    },
    search() {
      this.page = 1;
      this.getGames()
    }
  },
  methods: {
    upPage() {
      this.page++;
      this.getGames(true);
    },
    async getGames(append = false) {
      if (!append) {
        this.loading = true;
      }
      await getList({
        page: this.page,
        search: this.search,
        per_page: this.per_page,
        category_id: this.section,
      }).then(response => {
        if (append) {
          this.games = [...this.games, ...response.data.data];
        } else {
          this.games = response.data.data;
        }

        this.last_page = response.data.meta.last_page
      }).catch(() => {}).finally(() => {
        setTimeout(() => {
          this.loading = false;
        }, 500);
      })
    },
    ...mapActions({
      getCategories: 'games/getCategories',
    }),
    ...mapMutations({
      resetPagination: 'games/resetPagination',
      setIsShowSearch: 'config/setIsShowSearch'
    }),
    close() {
      this.setIsShowSearch(false)
    }
  },
  computed: {
    ...mapGetters({
      categories: 'games/categories',
    })
  },
  async mounted() {
    this.getCategories();
    await this.getGames();
  },
  name: "SearchModal",
  components: {UiLoader, EmptyState, UiClose, SlideGameItem, UiInput}
}
</script>

<template>
  <div class="search-modal" ref="body">
    <UiClose @click="setIsShowSearch(false)" />
    <div class="search-modal-title">
      <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
        <path opacity="0.4" fill-rule="evenodd" clip-rule="evenodd" d="M14.9697 14.9697C15.2626 14.6768 15.7374 14.6768 16.0303 14.9697L20.5303 19.4697C20.8232 19.7626 20.8232 20.2374 20.5303 20.5303C20.2374 20.8232 19.7626 20.8232 19.4697 20.5303L14.9697 16.0303C14.6768 15.7374 14.6768 15.2626 14.9697 14.9697Z" fill="#FFE70C"/>
        <circle cx="9.5" cy="9.5" r="9.5" transform="matrix(1 0 0 -1 0 19)" fill="#FFE70C"/>
      </svg>
      {{ $t('s_title') }}
    </div>
    <div class="search-modal-subtitle">
      {{ $t('s_name') }}
    </div>
    <UiInput type="search" @input="search = $event" :placeholder="$t('s_search_for')" :icon="require('@/assets/images/search-blog-icon.svg')" />
    <div class="search-modal__content">
      <div class="search-modal__content-categories">
        <div class="search-modal__content-category" @click="section = null" :class="{'active': section === null}">
          <div class="search-modal__content-category_img">
            <img src="@/assets/images/all-icon.svg" alt="">
          </div>
          <div class="search-modal__content-category_name">
            {{ $t('sidebar.all') }}
          </div>
        </div>

        <div class="search-modal__content-category" @click="section = category.id" :class="{'active': section === category.id}" v-for="(category,i) in categories" :key="i">
          <div class="search-modal__content-category_img">
            <img :src="category.icon" alt="">
          </div>
          <div class="search-modal__content-category_name">
            {{ category.name }}
          </div>
        </div>
      </div>
      <div class="search-modal__content-games">
        <UiLoader :fill="true" v-if="loading" />
        <template v-else>
          <EmptyState :heading="$t('s_not_found')" :absolute="true" v-if="!games.length" />
          <template v-else>
            <SlideGameItem v-for="(game,i) in games" :key="i" :game="game" />


            <div class="load__more" v-if="last_page > 1 && last_page > page" @click.prevent.stop>
              <button @click.prevent.stop="upPage">
                Load more
              </button>
            </div>

          </template>
        </template>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .search-modal {
    border-radius: 20px;
    background: var(--200, #131139);
    box-shadow: 0px 105px 153.7px 0px rgba(1, 0, 25, 0.55);
    padding: 16px;
    position: relative;
    overflow: hidden;
    width: 998px;
    max-width: 100%;
    @media (max-width: 1000px) {
      width: 700px;
    }
    .search-modal-title {
      display: flex;
      align-items: center;
      svg {
        margin-right: 13px;
      }
      font-family: Unbounded;
      font-size: 16px;
      margin-bottom: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.32px;
    }
    .search-modal-subtitle {
      color: var(--700, #7F7DA6);
      font-size: 14px;
      font-family: "Geometria-Medium";
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.28px;
      margin-bottom: 17px;
    }
    .search-modal__content {
      display: flex;
      margin-top: 13px;

      @media (max-width: 768px) {
        display: block;
      }
      .search-modal__content-categories {
        width: 150px;
        min-width: 150px;
        margin-right: 14px;
        @media (max-width: 768px) {
          width: 100%;
          display: flex;
          min-width: 100%;
          margin-right: 0;
          overflow-x: auto;
          margin-bottom: 8px;
          padding-bottom: 4px;
          &::-webkit-scrollbar {
            display: none;
          }
        }
        .search-modal__content-category {
          border-radius: 12px;
          background: var(--300, #1D1B41);
          padding: 12px;
          display: flex;
          align-items: center;

          .search-modal__content-category_img {
            width: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 5px;
          }
          .search-modal__content-category_name {
            color: var(--700, #7F7DA6);
            font-family: "Geometria-Bold";
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            line-height: normal;
            letter-spacing: -0.28px;
          }
          &.active {
            background: var(--Primary, linear-gradient(180deg, #9D42F8 -4.55%, #7F00FF 100%));
            box-shadow: 0px 4px 0px 0px #51049F;
            .search-modal__content-category_img {
              img {
                filter: invert(1)
              }
            }
            .search-modal__content-category_name {
              color: #fff;
            }
          }
          &:hover {
            opacity: 0.8;
          }

          cursor: pointer;
          transition: opacity 0.2s;
        }
        .search-modal__content-category + .search-modal__content-category {
          margin-top: 4px;
          @media (max-width: 768px) {
            margin-left: 6px;
            margin-top: 0;
          }
        }
      }
      .search-modal__content-games {
        position: relative;
        flex-grow: 1;
        grid-template-columns: repeat(5, 1fr);
        grid-gap: 16px;
        display: grid;
        align-items: flex-start;
        height: 550px;
        overflow-y: auto;
        border-radius: 16px;
        max-height: 45vh;
        .load__more {
          grid-column: span 5;
          display: flex;
          justify-content: center;
          margin-bottom: 40px;
          margin-top: 10px;
          button {
            width: fit-content;
            padding: 13px 60px;
            border-radius: 11px;
            background: linear-gradient(180deg, #9D42F8 -4.55%, #7F00FF 100%);
            box-shadow: 0 -1px 1px 1px #BC79FF;
            font-size: 14px;
            cursor: pointer;
            font-family: "Geometria-Bold";
            font-weight: 700;
            letter-spacing: -0.28px;
            position: relative;
            transition: 0.2s;
            &:hover {
              opacity: 0.8;
            }
          }
        }
        @media (max-width: 1000px) {
          height: auto;
          max-height: 550px;
          min-height: 200px;
          grid-template-columns: repeat(3, 1fr);
          .load__more {
            grid-column: span 3;
          }
        }
        @media (max-width: 540px) {
          grid-gap: 10px;
          height: 60vh;
          max-height: 60vh;
          grid-template-columns: repeat(2, 1fr);
          .load__more {
            grid-column: span 2;
          }
        }

        ::v-deep {
          .game {
            height: 203px;
            @media (max-width: 768px) {
              position: relative;
              padding-bottom: 130%;
              img {
                position: absolute;
              }

            }
            .hover-holder__text {
              strong {
                font-size: 14px;
              }
            }
          }
        }
        &::-webkit-scrollbar {
          display: none;
        }
      }

      position: relative;
      &::after {
        content: "";
        display: block;
        height: 1px;
        width: 100%;
        position: absolute;
        bottom: -2px;
        left: 0;
        box-shadow: 0px -4px 43px 32px #131139;
        background: #131139;
      }
    }
  }
</style>