import Echo from "laravel-echo";
window.Pusher = require('pusher-js');

export let echoConfiguration = new Echo({
    broadcaster: 'pusher',
    key: process.env.VUE_APP_PUSHER_APP_KEY,
    wsHost: 'socket.snegbet.com',
    authEndpoint: process.env.VUE_APP_API_URL + '/broadcasting/auth',
    encrypted: false,
    forceTLS: false,
    wsPort: 80,
    wssPort: 443,
    disableStats: true,
    enabledTransports: ['ws', 'wss'],
    cluster: 'mt1',
    auth: {
        headers: {
            authorization: 'Bearer ' + localStorage.getItem('token'),
        }
    }
})
