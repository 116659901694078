import ApiClient from "@/services/ApiClient";

export function sendMessage ({ message, reply_message_id, type, last_win_transaction_id, chat_room }) {
    return ApiClient.post('/chat/message', {
        message,
        chat_room,
        reply_message_id,
        type,
        last_win_transaction_id
    });
}

export function getMessages (chat_room) {
    return ApiClient.get('/chat/message', {
        chat_room
    });
}

export function getLastWins ()
{
    return ApiClient.get('/chat/last-wins');
}

export function deleteMessage(id) {
    return ApiClient.delete('/chat/' + id);
}

export function muteUser(id) {
    return ApiClient.post('/chat/mute/' + id);
}

export function unmuteUser(id) {
    return ApiClient.post('/chat/unmute/' + id);
}

export function banUser(id) {
    return ApiClient.post('/chat/ban/' + id);
}

export function unbanUser(id) {
    return ApiClient.post('/chat/unban/' + id);
}