<script>
import UiClose from "@/components/Ui/UiClose.vue";
import AccentBtn from "@/components/Ui/Buttons/AccentBtn.vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
import { buyNft, buyFromShop } from "@/services/marketplace";
import extractErrors from "@/helpers/extractErrors";

export default {
  name: "BuyNftModal",
  data () {
    return {
      isLoading: false,
    }
  },
  computed: {
    ...mapGetters({
      buyNftObject: 'config/buyNftObject'
    })
  },
  methods: {
    ...mapActions({
      getMe: 'user/me'
    }),
    close() {
      this.setBuyNftObject(null)
    },
    ...mapMutations({
      setBuyNftObject: 'config/setBuyNftObject'
    }),
    async buy () {
      if (this.isLoading) return;

      this.isLoading = true;
      let call = null;

      if (this.buyNftObject.fromShop) {
        call = buyFromShop(this.buyNftObject.id)
      } else {
        call = buyNft(this.buyNftObject.id)
      }

      await call.then(() => {
        this.$root.$emit('success', {
          title: "Success buy!",
          text: this.$t('n_success')
        })
        this.$root.$emit('drop-nft', this.buyNftObject.id);
        this.setBuyNftObject(null);
        this.$router.push(`/profile?tab=nft`);
      }).catch(errors => {
        extractErrors(errors).forEach(item => {
          this.$root.$emit('error', {
            title: "Error buy",
            text: item,
          })
        })
      }).finally(() => {
        this.isLoading = false;
        this.getMe();
      })
    }
  },
  components: {AccentBtn, UiClose}
}
</script>

<template>
  <div class="buy-nft">
    <UiClose @click="setBuyNftObject(null)" />
    
    <div class="nft-image">
      <img :src="buyNftObject.image_url" alt="">
    </div>

    <h1>{{ buyNftObject.name }}</h1>
    <span>{{ $t('n_buy_sure') }} {{ parseFloat(buyNftObject.price).toFixed(2) }} <img src="@/assets/images/sneg-coin.svg" class="coin" alt=""> ?</span>

    <div class="buttons">
      <button @click="setBuyNftObject(null)" class="cancel default-hover">
        {{ $t('n_cancel') }}
      </button>
      <AccentBtn :is-loading="isLoading" @click="buy" :title="$t('buy_nft')" />
    </div>

  </div>
</template>

<style scoped lang="scss">
.buy-nft {
  border-radius: 20px;
  background: var(--200, #131139);
  box-shadow: 0px 105px 153.7px 0px rgba(1, 0, 25, 0.55);
  width: 343px;
  max-width: 100%;
  padding: 24px;
  position: relative;
  .nft-image {
    background: url(@/assets/images/nft/nft-bg.png);
    background-size: 105% 105%;
    background-position: center top;
    background-repeat: no-repeat;
    padding-bottom: 50px;
    padding-top: 40px;
    width: 100%;
    display: flex;
    justify-content: center;
    border-radius: 8px;
    img {
      filter: drop-shadow(5px 5px rgba(9, 6, 40, 0.25));
      box-shadow: 0px 23px 29px 0px rgba(9, 6, 40, 0.25);

      width: 165px;
      height: 165px;
      border-radius: 8px;
    }
  }
  .coin {
    width: 12px;
    vertical-align: text-top;
  }
  h1 {
    display: block;
    width: 100%;
    font-size: 24px;
    font-family: "Geometria-Bold";
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* 116.667% */
    letter-spacing: -0.48px;
    margin-bottom: 8px;
  }
  span {
    color: var(--800, #918FBB);
    font-family: "Geometria-Bold";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.28px;
  }
  .buttons {
    margin-top: 20px;
    display: flex;
    .accent-btn {
      margin-left: 5%;
      width: 55%;
    }
    .cancel {
      width: 40%;
      color: var(--1000, #FFF);
      font-family: "Geometria-Bold";
      border-radius: 11px;
      border: 1px solid var(--500, #3E3C5F);
      background: var(--400, #2B2A4E);
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.28px;
    }
  }
}
</style>