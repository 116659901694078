<script>
import {mapGetters, mapMutations} from "vuex";
import AccentBtn from "@/components/Ui/Buttons/AccentBtn.vue";

export default {
  props: {
    hideButton: {
      type: Boolean,
      default: false,
    }
  },
  components: {AccentBtn},
  computed: {
    ...mapGetters({
      settings: 'settings/settings',
      user: 'user/user'
    }),
    currentLevel () {
      if (!this.user?.level?.level) {
        return this.settings.levels[0];
      }

      if (this.settings.levels) {
        return this.settings.levels.find(item => item.level === this.user.level.level) || {name:"Level 1", level: 1};
      }

      return {name:"Level 1", level: 1};
    },
    nextLevelXpLeft () {
      return this.nextLevel.from - this.user.xp_balance;
    },
    nextLevel () {
      if (!this.settings.levels) {
        return null;
      }

      if(!this.user?.level?.level) {
        return this.settings.levels[1];
      }

      for (const item in this.settings.levels) {
        if(this.settings.levels[item]?.level > this.user.level.level) {
          return this.settings.levels[item];
        }
      }

      return this.settings.levels[this.settings.levels.length - 1];
    },
    userLastLevel() {
      return this.user?.level?.level === 6;
    },
    progressPercent ()
    {
      if (this.userLastLevel) {
        return 100;
      }

      return (this.user.xp_balance / this.nextLevel.from) * 100;
    }
  },
  methods: {
    ...mapMutations({
      setIsShowLoyality: 'config/setIsOpenLoyalty'
    })
  },
  name: "CurrentLevel"
}
</script>

<template>
  <div class="level" v-if="nextLevel">
    <div class="level-image">
      <img :src="require(`@/assets/images/profile/level-${currentLevel.level}.png`)" alt="">
    </div>
    <div class="level-info">
      <span>{{ $t('current_level.title') }}</span>
      <strong>{{ currentLevel.name }}</strong>

      <div class="progress">
        <div class="progress-bar">
          <div class="progress-bar__fill" :style="{'width': `${progressPercent + 0.3}%`}"></div>
        </div>
        <div class="progress-labels">
          <div class="progress-label">
            <div class="progress-labels-icon">
              <img src="@/assets/images/profile/xp.svg" alt="">
            </div>
            <div class="progress-labels-wrap">
              {{ user.xp_balance }} <em>EXP</em>
            </div>
          </div>

          <div class="progress-label" v-if="!userLastLevel">
            <div class="progress-labels-icon">
              <img src="@/assets/images/profile/xp.svg" alt="">
            </div>
            <div class="progress-labels-wrap">
              {{ nextLevel.from }} <em>EXP</em>

              <div class="left" v-if="nextLevelXpLeft > 0">{{ nextLevelXpLeft }} {{ $t('current_level.left') }}</div>
            </div>
          </div>
        </div>
        <div class="progress-desc" v-if="!hideButton">
          <AccentBtn @click="setIsShowLoyality(true)" :title="$t('how_works')" />
        </div>
      </div>

    </div>
  </div>
</template>

<style scoped lang="scss">
  .level {
    height: 100%;
    display: flex;
    align-items: center;
    @media (max-width: 1700px) {
      display: block;
    }
    .level-image {
      margin-right: 15px;
      width: 130px;
      min-width: 120px;
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: center;
      user-select: none;
      padding-bottom: 40px;
      img {
        user-select: none;
        //filter: drop-shadow(0px 0px 24px #44ABFF);
        position: relative;
        z-index: 2;
      }

      position: relative;
      &::after {
        width: 2px;
        height: 2px;
        border-radius: 100%;
        content: "";
        display: block;
        position: absolute;
        top: 30px;
        left: calc(50% - 1px);
        box-shadow: 0px 0px 34px 25px #44ABFF;
      }

      background: url(@/assets/images/profile/stand.png);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center bottom;

      @media (max-width: 1700px) {
        height: auto;
        margin: 0 auto;
        background: url(@/assets/images/profile/stand-2.svg);
        background-size: 100% auto;
        background-repeat: no-repeat;
        padding-bottom: 50px;
        background-position: center top 20px;
      }

      @media (max-width: 992px) {
        img {
          max-width: 84px;
          height: 60px;
        }
      }
    }
    .level-info {
      flex-grow: 1;
      @media (max-width: 1700px) {
        margin-top: -40px;
        padding-bottom: 10px;
      }
      @media (max-width: 992px) {
        margin-top: -30px;
      }
      span {
        display: block;
        width: 100%;
        font-size: 12px;
        font-family: "Geometria-Medium";
        line-height: normal;
        letter-spacing: -0.24px;
        opacity: 0.8;
        font-weight: 500;
        @media (max-width: 1700px) {
          text-align: center;
        }
      }
      strong {
        font-family: "Unbounded";
        font-size: 16px;
        font-weight: 700;
        letter-spacing: -0.32px;
        display: block;
        width: 100%;
        @media (max-width: 1700px) {
          text-align: center;
        }
      }

      .progress-bar {
        margin-top: 4px;
        width: 100%;
        height: 6px;
        border-radius: 36px;
        background: rgba(182, 127, 255, 0.10);
        overflow: hidden;
        .progress-bar__fill {
          background: #AB6BFF;
          height: 100%;
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
        }
      }
      .progress-labels {
        margin-top: 7px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .progress-label {
          display: flex;
          align-items: center;
          .progress-labels-icon {
            margin-right: 4px;
            display: flex;
            align-items: center;
          }
          .progress-labels-wrap {
            display: flex;
            padding-bottom: 5px;
            align-items: center;
            font-size: 12px;
            font-weight: 500;
            letter-spacing: -0.24px;
            line-height: 12px;
            font-family: "Geometria-Medium";
            em {
              font-size: 12px;
              font-family: "Geometria-Medium";
              font-weight: 500;
              margin-left: 4px;
              letter-spacing: -0.24px;
              color: #918FBB;
              font-style: normal;
              line-height: 12px;
            }
            .left {
              padding: 3px 5px;
              border-radius: 7px;
              background: rgba(171, 107, 255, 0.20);
              font-size: 12px;
              line-height: 14px;
              padding-top: 4px;
              font-weight: 500;
              font-family: "Geometria-Medium";
              letter-spacing: -0.24px;
              white-space: nowrap;
              margin-left: 4px;
              @media (max-width: 575px) {
                display: none;
              }
            }
          }
        }
      }
      .progress-desc {
        margin-top: 8px;
        width: 100%;
        font-size: 12px;
        font-family: "Geometria-Medium";
        font-weight: 500;
        letter-spacing: -0.24px;
        opacity: 0.8;
        .accent-btn {
          width: fit-content;
          padding: 12px 24px;
        }
        @media (max-width: 1700px) {
          margin-top: 3px;
        }
        @media (max-width: 992px) {
          text-align: center;
        }
      }
    }
  }
</style>