<script>
import SocialAuthList from "@/components/Auth/Buttons/SocialAuthList.vue";
import LoginForm from "@/components/Modals/Auth/LoginForm.vue";
import ForgotPassword from "@/components/Modals/Auth/ForgotPassword.vue";
import LoginBanner from "@/components/Modals/Auth/LoginBanner.vue";
import RegisterForm from "@/components/Modals/Auth/RegisterForm.vue";
import RegisterBanner from "@/components/Modals/Auth/RegisterBanner.vue";
import UiClose from "@/components/Ui/UiClose.vue";
import {mapGetters, mapMutations} from "vuex";

export default {
  computed: {
    ...mapGetters({
      tab: 'config/authTab',
      currentLanguage: 'settings/currentLanguage',
      isOpenAuth: 'config/isOpenAuth'
    })
  },
  methods: {
      ...mapMutations({
        setOpenAuth: 'config/setOpenAuth',
        setAuthTab: 'config/setAuthTab'
      }),
    close () {
        this.setOpenAuth(false)
    },
  },
  name: "AuthModal",
  components: {UiClose, RegisterBanner, RegisterForm, LoginBanner, ForgotPassword, LoginForm, SocialAuthList}
}
</script>

<template>
  <div class="auth-modal" ref="body">
    <div class="auth-modal__banner">
      <LoginBanner v-if="tab === 'login'"/>
      <RegisterBanner v-if="tab === 'register'"/>
    </div>
    <div class="auth-modal__form">
      <UiClose @click="setOpenAuth(false)"/>

      <div class="auth-modal__form-title" :class="{'small': currentLanguage !== 'en'}" v-html="$t('auth_modal.signup_heading')"></div>

      <div class="auth-modal__form-tabs">
        <div @click="setAuthTab('register')" :class="{'active': tab === 'register'}" class="auth-modal__form-tab default-hover">
          {{ $t('sign_up') }}
        </div>
        <div @click="setAuthTab('login')" :class="{'active': tab === 'login'}" class="auth-modal__form-tab default-hover">
          {{ $t('sign_in') }}
        </div>
      </div>

      <div class="auth-modal__from-content">
        <LoginForm v-show="tab === 'login'"/>
        <RegisterForm v-show="tab === 'register'"/>
      </div>


      <div class="auth-modal__from-socials">
        <h3>{{ $t('auth_modal.quick_auth') }}</h3>
        <SocialAuthList/>
      </div>

      <ForgotPassword v-if="tab === 'login'"/>

    </div>
  </div>
</template>

<style scoped lang="scss">
  .auth-modal {
    display: flex;
    @media (max-width: 768px) {
      display: block;
      width: 100%;
    }
    .auth-modal__banner {
      width: 330px;
      margin-right: 8px;
      height: 572px;
      @media (max-width: 768px) {
        width: 100%;
        min-width: 100%;
        height: 120px;

      }
    }
    .auth-modal__form {
      width: 343px;
      min-width: 343px;
      padding: 20px;
      border-radius: 20px;
      background: #131139;
      box-shadow: 0px 105px 153.7px 0px rgba(1, 0, 25, 0.55);
      padding-bottom: 20px;
      display: flex;
      flex-direction: column;
      position: relative;
      @media (max-width: 768px) {
        width: 100%;
        padding: 16px;
        min-width: 100%;
        margin-top: 6px;
      }
      .auth-modal__form-title {
        font-family: "Unbounded";
        font-size: 24px;
        font-weight: 700;
        line-height: 28px; /* 116.667% */
        letter-spacing: -0.48px;
        &.small {
          font-size: 20px;
        }
        @media (max-width: 768px) {
          font-size: 18px;
          white-space: nowrap;
          br {
            display: none;
          }
          line-height: 24px;
        }
      }
      .auth-modal__form-tabs {
        margin-top: 14px;
        padding: 4px;
        width: 200px;
        border-radius: 12px;
        background: #1D1B41;
        overflow: hidden;
        display: flex;
        grid-gap: 5px;
        @media (max-width: 768px) {
          margin-top: 12px;
        }
        .auth-modal__form-tab {
          flex: 1;
          display: flex;
          align-items: center;
          padding: 12px 10px;
          justify-content: center;
          color: #7F7DA6;
          font-size: 14px;
          font-family: "Geometria-Bold";
          border-radius: 12px;
          letter-spacing: -0.28px;
          border: 1px solid transparent;
          @media (max-width: 768px) {
            font-size: 12px;
            padding-top: 13px;
            padding-bottom: 10px;
          }
          &.active {
            border: 1px solid #FFEF5E;
            background: #FFE70C;
            color: #000;
          }
        }
      }
      .auth-modal__from-content {
        margin-top: 10px;
        flex-grow: 1;
      }
      .auth-modal__from-socials {
        width: 100%;
        margin-top: 20px;
        h3 {
          text-align: center;
          display: block;
          width: 100%;
          color: #7F7DA6;
          font-family: "Geometria-Bold";
          font-size: 12px;
          font-weight: 700;
          letter-spacing: 0.6px;
          margin-bottom: 3px;
        }
        .social-auth-list {
          width: 100%;
          justify-content: center;
        }
      }
      .forgot-password {
        border-top: 1px solid #1D1B41;
        margin-top: 18px;
      }
    }
  }
</style>