import {getPlaySession} from "@/services/play";

export default {
    async getPlaySession ({commit}, {gameId, mode}) {
        await getPlaySession(gameId, {
            mode
        }).then(response => {
            commit('setPlaySession', response.data);
        }).catch(() => {})
    }
}