<script>
export default {
  props: {
    size: {
      type: String,
      default: 'normal'
    },
    fill: {
      type: Boolean,
      default: false,
    }
  },
  name: "MainLoader"
}
</script>

<template>
  <div class="loader" :class="{'fill': fill, 'small': size === 'small'}">
    <div class="box">
      <div class="loader-38"></div>
    </div>
    <div class="loader-text">
      <img src="@/assets/images/logo.svg" alt="">
      <span>We are loading slots for you...</span>
    </div>
  </div>
</template>

<style scoped lang="scss">
.loader {
  width: 100%;
  img {
    width: 100px;
  }
  &.small {
    img {
      width: 50px;
    }
  }
  &.fill {
    padding: 20px 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  .loader-text {
    width: 100%;
    margin-top: 70px;
    img {
      margin: 0 auto;
      width: 140px;
      display: block;
    }
    span {
      display: block;
      width: 100%;
      text-align: center;
      margin-top: 10px;
      color: var(--800, #918FBB);
      font-family: "Geometria-Medium";
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.24px;
    }
  }
}

.box {
  font-size: 30px;
  padding: 1em;
  position: relative;
  vertical-align: top;
  transition: .3s color, .3s border, .3s transform, .3s opacity;
  display: flex;
  justify-content: center;
}

.loader-38 {
  height: 0.1em;
  width: 0.1em;
  transform: scale(10) rotate(45deg);
  box-shadow: -0.2em -0.2em 0 0.1em #9D42F8, -0.2em -0.2em 0 0.1em #9D42F8, -0.2em -0.2em 0 0.1em #9D42F8, -0.2em -0.2em 0 0.1em #9D42F8;
  -webkit-animation: loader-38 6s infinite;
  animation: loader-38 6s infinite;
}

@-webkit-keyframes loader-38 {
  0% {
    box-shadow: -0.2em -0.2em 0 0.1em #9D42F8, -0.2em -0.2em 0 0.1em #9D42F8, -0.2em -0.2em 0 0.1em #9D42F8, -0.2em -0.2em 0 0.1em #9D42F8;
  }
  8.33% {
    box-shadow: -0.2em -0.2em 0 0.1em #9D42F8, 0.2em -0.2em 0 0.1em #9D42F8, 0.2em -0.2em 0 0.1em #9D42F8, 0.2em -0.2em 0 0.1em #9D42F8;
  }
  16.66% {
    box-shadow: -0.2em -0.2em 0 0.1em #9D42F8, 0.2em -0.2em 0 0.1em #9D42F8, 0.2em 0.2em 0 0.1em #9D42F8, 0.2em 0.2em 0 0.1em #9D42F8;
  }
  24.99% {
    box-shadow: -0.2em -0.2em 0 0.1em #9D42F8, 0.2em -0.2em 0 0.1em #9D42F8, 0.2em 0.2em 0 0.1em #9D42F8, -0.2em 0.2em 0 0.1em #9D42F8;
  }
  33.32% {
    box-shadow: -0.2em -0.2em 0 0.1em #9D42F8, 0.2em -0.2em 0 0.1em #9D42F8, 0.2em 0.2em 0 0.1em #9D42F8, -0.2em -0.2em 0 0.1em #9D42F8;
  }
  41.65% {
    box-shadow: 0.2em -0.2em 0 0.1em #9D42F8, 0.2em -0.2em 0 0.1em #9D42F8, 0.2em 0.2em 0 0.1em #9D42F8, 0.2em -0.2em 0 0.1em #9D42F8;
  }
  49.98% {
    box-shadow: 0.2em 0.2em 0 0.1em #9D42F8, 0.2em 0.2em 0 0.1em #9D42F8, 0.2em 0.2em 0 0.1em #9D42F8, 0.2em 0.2em 0 0.1em #9D42F8;
  }
  58.31% {
    box-shadow: -0.2em 0.2em 0 0.1em #9D42F8, -0.2em 0.2em 0 0.1em #9D42F8, 0.2em 0.2em 0 0.1em #9D42F8, -0.2em 0.2em 0 0.1em #9D42F8;
  }
  66.64% {
    box-shadow: -0.2em -0.2em 0 0.1em #9D42F8, -0.2em -0.2em 0 0.1em #9D42F8, 0.2em 0.2em 0 0.1em #9D42F8, -0.2em 0.2em 0 0.1em #9D42F8;
  }
  74.97% {
    box-shadow: -0.2em -0.2em 0 0.1em #9D42F8, 0.2em -0.2em 0 0.1em #9D42F8, 0.2em 0.2em 0 0.1em #9D42F8, -0.2em 0.2em 0 0.1em #9D42F8;
  }
  83.3% {
    box-shadow: -0.2em -0.2em 0 0.1em #9D42F8, 0.2em 0.2em 0 0.1em #9D42F8, 0.2em 0.2em 0 0.1em #9D42F8, -0.2em 0.2em 0 0.1em #9D42F8;
  }
  91.63% {
    box-shadow: -0.2em -0.2em 0 0.1em #9D42F8, -0.2em 0.2em 0 0.1em #9D42F8, -0.2em 0.2em 0 0.1em #9D42F8, -0.2em 0.2em 0 0.1em #9D42F8;
  }
  100% {
    box-shadow: -0.2em -0.2em 0 0.1em #9D42F8, -0.2em -0.2em 0 0.1em #9D42F8, -0.2em -0.2em 0 0.1em #9D42F8, -0.2em -0.2em 0 0.1em #9D42F8;
  }
}

@keyframes loader-38 {
  0% {
    box-shadow: -0.2em -0.2em 0 0.1em #9D42F8, -0.2em -0.2em 0 0.1em #9D42F8, -0.2em -0.2em 0 0.1em #9D42F8, -0.2em -0.2em 0 0.1em #9D42F8;
  }
  8.33% {
    box-shadow: -0.2em -0.2em 0 0.1em #9D42F8, 0.2em -0.2em 0 0.1em #9D42F8, 0.2em -0.2em 0 0.1em #9D42F8, 0.2em -0.2em 0 0.1em #9D42F8;
  }
  16.66% {
    box-shadow: -0.2em -0.2em 0 0.1em #9D42F8, 0.2em -0.2em 0 0.1em #9D42F8, 0.2em 0.2em 0 0.1em #9D42F8, 0.2em 0.2em 0 0.1em #9D42F8;
  }
  24.99% {
    box-shadow: -0.2em -0.2em 0 0.1em #9D42F8, 0.2em -0.2em 0 0.1em #9D42F8, 0.2em 0.2em 0 0.1em #9D42F8, -0.2em 0.2em 0 0.1em #9D42F8;
  }
  33.32% {
    box-shadow: -0.2em -0.2em 0 0.1em #9D42F8, 0.2em -0.2em 0 0.1em #9D42F8, 0.2em 0.2em 0 0.1em #9D42F8, -0.2em -0.2em 0 0.1em #9D42F8;
  }
  41.65% {
    box-shadow: 0.2em -0.2em 0 0.1em #9D42F8, 0.2em -0.2em 0 0.1em #9D42F8, 0.2em 0.2em 0 0.1em #9D42F8, 0.2em -0.2em 0 0.1em #9D42F8;
  }
  49.98% {
    box-shadow: 0.2em 0.2em 0 0.1em #9D42F8, 0.2em 0.2em 0 0.1em #9D42F8, 0.2em 0.2em 0 0.1em #9D42F8, 0.2em 0.2em 0 0.1em #9D42F8;
  }
  58.31% {
    box-shadow: -0.2em 0.2em 0 0.1em #9D42F8, -0.2em 0.2em 0 0.1em #9D42F8, 0.2em 0.2em 0 0.1em #9D42F8, -0.2em 0.2em 0 0.1em #9D42F8;
  }
  66.64% {
    box-shadow: -0.2em -0.2em 0 0.1em #9D42F8, -0.2em -0.2em 0 0.1em #9D42F8, 0.2em 0.2em 0 0.1em #9D42F8, -0.2em 0.2em 0 0.1em #9D42F8;
  }
  74.97% {
    box-shadow: -0.2em -0.2em 0 0.1em #9D42F8, 0.2em -0.2em 0 0.1em #9D42F8, 0.2em 0.2em 0 0.1em #9D42F8, -0.2em 0.2em 0 0.1em #9D42F8;
  }
  83.3% {
    box-shadow: -0.2em -0.2em 0 0.1em #9D42F8, 0.2em 0.2em 0 0.1em #9D42F8, 0.2em 0.2em 0 0.1em #9D42F8, -0.2em 0.2em 0 0.1em #9D42F8;
  }
  91.63% {
    box-shadow: -0.2em -0.2em 0 0.1em #9D42F8, -0.2em 0.2em 0 0.1em #9D42F8, -0.2em 0.2em 0 0.1em #9D42F8, -0.2em 0.2em 0 0.1em #9D42F8;
  }
  100% {
    box-shadow: -0.2em -0.2em 0 0.1em #9D42F8, -0.2em -0.2em 0 0.1em #9D42F8, -0.2em -0.2em 0 0.1em #9D42F8, -0.2em -0.2em 0 0.1em #9D42F8;
  }
}
</style>