<script>
import {mapMutations} from "vuex";

export default {
  name: "SettingsButton",
  methods: {
    ...mapMutations({
      setIsShowSettings: 'config/setIsShowSettings',
    })
  }
}
</script>

<template>
  <button class="settings" @click="setIsShowSettings(true)">
    <span class="settings-icon">
      <img src="@/assets/images/settings-icon.svg" alt="">
    </span>
    <span class="settings-name">
      {{ $t('settings_btn_text') }}
    </span>
  </button>
</template>

<style scoped lang="scss">
  .settings {
    display: flex;
    align-items: center;
    border-radius: 10px;
    background: #1D1B41;
    padding: 10.5px 12px;
    width: 100%;
    .settings-icon {
      margin-right: 7px;
      display: flex;
      align-items: center;
    }
    .settings-name {
      color: #B9B8DD;
      font-family: "Geometria-Bold";
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.28px;
    }
  }
</style>