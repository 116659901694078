<script>
export default {
  name: "SideBottom"
}
</script>

<template>
  <div class="side-bottom">
    <router-link to="/terms-and-conditions">
      <span>{{ $t('sidebar.terms_and_conditions') }}</span>
      <span class="go default-hover"></span>
    </router-link>
    <router-link to="/privacy-policy">
      <span>{{ $t('sidebar.privacy_policy') }}</span>
      <span class="go default-hover"></span>
    </router-link>
  </div>
</template>

<style scoped lang="scss">
  .side-bottom {
    @media (max-width: 1200px) {
      padding-left: 8px;
      padding-right: 8px;
      padding-bottom: 8px;
    }
    a {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 4px 0;
      span {
        color: #7F7DA6;
        transition: 0.2s;
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 0.6px;
        font-family: "Geometria-Bold";
      }
      .go {
        width: 24px;
        height: 24px;
        background-color: #2B2A4E;
        border-radius: 6px;
        background-image: url(@/assets/images/sidebar/arrow-right.svg);
        background-size: 12px 12px;
        background-repeat: no-repeat;
        background-position: center center;
      }
      &:hover {
        .go {
          background-color: rgba(255,255,255, 0.2);
        }
        span {
          color: #fff;
        }
      }
    }
  }
</style>