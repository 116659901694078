export default function diffDateToNow (registrationTimestamp) {
    let currentDate = new Date();
    let registrationDate = new Date(registrationTimestamp * 1000); // Преобразование timestamp в миллисекунды

    let diffInMilliseconds = currentDate - registrationDate;
    let diffInSeconds = Math.floor(diffInMilliseconds / 1000);
    let diffInDays = Math.floor(diffInSeconds / (60 * 60 * 24));
    let diffInMonths = Math.floor(diffInDays / 30);
    let diffInYears = Math.floor(diffInDays / 365);

    if (diffInDays < 1) {
        diffInDays = 1
    }

    // Проверяем, сколько времени прошло с момента регистрации
    if (diffInDays < 30) {
        return diffInDays === 1 ? '1 day' : diffInDays + ' days';
    } else if (diffInDays < 365) {
        let remainingDays = diffInDays % 30;
        let result = '';

        if (diffInMonths > 0) {
            result += diffInMonths === 1 ? '1 month' : diffInMonths + ' months';
        }

        if (remainingDays > 0) {
            result += (diffInMonths > 0 ? ', ' : '') + (remainingDays === 1 ? '1 day' : remainingDays + ' days');
        }

        return result;
    } else {
        let remainingMonths = diffInMonths % 12;
        let remainingDays = diffInDays % 365;
        let result = '';

        if (diffInYears > 0) {
            result += diffInYears === 1 ? '1 year' : diffInYears + ' years';
        }

        if (remainingMonths > 0) {
            result += (diffInYears > 0 ? ', ' : '') + (remainingMonths === 1 ? '1 month' : remainingMonths + ' months');
        }

        if (remainingDays > 0) {
            result += (remainingMonths > 0 ? ', ' : '') + (remainingDays === 1 ? '1 day' : remainingDays + ' days');
        }

        return result;
    }
}