<script>
export default {
  name: "OtherNav"
}
</script>

<template>
  <div class="other-nav">
        <div class="soon">
          <div class="lists">
            <div class="list">
              <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                <path d="M11.7869 12.5H12.375C16.5269 12.5 18 10.3125 18 8.4375C17.9986 7.92196 17.8152 7.42348 17.4821 7.03C17.149 6.63653 16.6876 6.37334 16.1794 6.28688C16.4149 5.79491 16.5939 5.27787 16.7131 4.74563C16.7686 4.47544 16.763 4.19626 16.6967 3.92851C16.6305 3.66076 16.5051 3.41121 16.33 3.19813C16.1526 2.97963 15.9286 2.80355 15.6743 2.68276C15.4201 2.56197 15.1421 2.49953 14.8606 2.5H6.13937C5.85791 2.49953 5.5799 2.56197 5.32567 2.68276C5.07145 2.80355 4.84743 2.97963 4.67 3.19813C4.49485 3.41121 4.36955 3.66076 4.30326 3.92851C4.23697 4.19626 4.23137 4.47544 4.28687 4.74563C4.40625 5.27785 4.58487 5.79504 4.81937 6.2875C4.31147 6.37421 3.85048 6.63742 3.51764 7.03074C3.1848 7.42406 3.00148 7.92225 3 8.4375C3 10.3125 4.47312 12.5 8.625 12.5H9.20812C9.23152 12.6315 9.24551 12.7646 9.25 12.8981V15C9.26074 15.1669 9.23576 15.3342 9.17675 15.4908C9.11773 15.6473 9.02601 15.7894 8.90773 15.9077C8.78944 16.026 8.6473 16.1177 8.49077 16.1767C8.33425 16.2358 8.16694 16.2607 8 16.25H6.125V17.5H14.875V16.25H13C12.8327 16.2613 12.6649 16.2367 12.5079 16.178C12.3509 16.1192 12.2082 16.0276 12.0894 15.9093C11.9706 15.7909 11.8784 15.6486 11.819 15.4918C11.7596 15.335 11.7344 15.1673 11.745 15V12.8981C11.7498 12.7646 11.7638 12.6316 11.7869 12.5ZM12.6731 10.8431C13.803 9.89628 14.7656 8.76614 15.5206 7.5H15.8125C16.0611 7.5 16.2996 7.59877 16.4754 7.77459C16.6512 7.9504 16.75 8.18886 16.75 8.4375C16.75 9.7975 15.505 11.25 12.2794 11.25C12.393 11.0983 12.5253 10.9616 12.6731 10.8431ZM4.25 8.4375C4.25 8.18886 4.34877 7.9504 4.52459 7.77459C4.7004 7.59877 4.93886 7.5 5.1875 7.5H5.4775C6.23103 8.76629 7.19262 9.8965 8.32187 10.8431C8.46943 10.962 8.60169 11.0986 8.71562 11.25C5.49 11.25 4.25 9.7975 4.25 8.4375Z" fill="#72EEFF"/>
              </svg>
            </div>
            <div class="list">
              <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                <path d="M8.86051 2.72617C9.53109 1.31349 11.4686 1.31349 12.1392 2.72616L13.2981 5.16764C13.5644 5.72861 14.0791 6.11744 14.6745 6.20739L17.266 6.5989C18.7654 6.82544 19.3641 8.74128 18.2791 9.84089L16.4039 11.7413C15.9731 12.178 15.7765 12.8071 15.8782 13.4237L16.3208 16.1071C16.577 17.6598 15.0095 18.8439 13.6684 18.1108L11.3505 16.8438C10.818 16.5527 10.1817 16.5527 9.64915 16.8438L7.33131 18.1108C5.99016 18.8439 4.42269 17.6598 4.67883 16.1071L5.12149 13.4237C5.22321 12.8071 5.0266 12.178 4.59574 11.7413L2.72057 9.84089C1.63556 8.74128 2.23428 6.82544 3.73372 6.5989L6.32515 6.20739C6.92058 6.11744 7.43531 5.72861 7.70159 5.16764L8.86051 2.72617Z" fill="#72EEFF"/>
              </svg>
            </div>
          </div>
          <strong>{{ $t('coming_soon') }}</strong>
        </div>
    <strong>{{ $t('sidebar.other_heading') }}</strong>
    <div class="list" >
      <a class="item disabled" v-tooltip.right="{content: 'Coming soon'}">
        <div class="item-icon">
          <img src="@/assets/images/sidebar/tournaments-icon.svg" alt="">
        </div>
        <div class="item-name">{{ $t('sidebar.tournaments') }}</div>
        <span class="lock">
          <img src="@/assets/images/sidebar/lock-icon.svg" alt="">
        </span>
      </a>

      <a class="item disabled" v-tooltip.right="'Coming soon'">
        <div class="item-icon">
          <img src="@/assets/images/sidebar/leaderboard-icon.svg" alt="">
        </div>
        <div class="item-name">{{ $t('sidebar.leaderboard') }}</div>

        <span class="lock">
          <img src="@/assets/images/sidebar/lock-icon.svg" alt="">
        </span>
      </a>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .other-nav {
    .soon {
      top: 0;
      z-index: 2;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      border-radius: 12px;
      background: rgba(23, 21, 71, 0.72);
      backdrop-filter: blur(3.700000047683716px);
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: center;
      display: none;
      @media (max-width: 992px) {
        display: flex;
      }
      .lists {
        display: flex;
        justify-content: center;
        .list {
          width: 37px;
          height: 37px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 100%;
          background: rgba(215, 212, 253, 0.15);
          backdrop-filter: blur(3.340277910232544px);
          img {
            height: 20px;
          }
        }
        .list + .list {
          margin-left: -5px;
        }
      }
      strong {
        display: block;
        margin-top: 10px;
        text-align: center;
        width: 100%;
        font-size: 14px;
        font-weight: 700;
        letter-spacing: -0.28px;
        font-family: "Unbounded";
        color: #fff;
      }
    }

    padding: 12px 8px;
    border-radius: 10px;
    background: #1D1B41;
    margin-top: 7px;
    position: relative;

    strong {
      color: #7F7DA6;
      font-family: "Geometria-Bold";
      font-size: 12px;
      letter-spacing: 0.6px;
    }
    .list {
      margin-top: 4px;
      @media (max-width: 768px) {
        margin-top: 0;
      }
      .item {
        padding: 12px 4px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        position: relative;
        @media (max-width: 768px) {
          padding: 9px 4px;
        }
        .lock {
          position: absolute;
          right: 8px;
        }
        .item-icon {
          width: 24px;
          height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 7px;
        }
        .item-name {
          display: flex;
          align-items: center;
          color: #B9B8DD;
          font-family: "Geometria-Bold";
          font-size: 14px;
          letter-spacing: -0.28px;
        }
        &.disabled {
          background: #02002A;
          user-select: none;
          cursor: default;
        }
      }
      .item + .item {
        margin-top: 3px;
      }
    }
  }
</style>