<script >
import {mapMutations} from "vuex";

export default {
  methods: {
    ...mapMutations({
      setDepositModalTab: 'config/setDepositModalTab',
      setIsShowDepositModal: 'config/setIsShowDepositModal'
    }),
    buy() {
      this.setDepositModalTab('exchange')
      this.setIsShowDepositModal(true);
    }
  }
}
</script>

<template>
  <div class="buy-sneg-coin">
    <div class="buy-sneg-coin__info">
     <div class="buy-sneg-coin__info_icon">
       <img src="../../assets/images/sneg-coin.svg" alt="">
     </div>
      <div class="buy-sneg-coin__info_text">
        <strong>SNEG COIN</strong>
        <div class="indicators">
          <span>$7,05</span>
          <div class="dir">
            <img src="@/assets/images/green-arrow-up.png" alt="">
          </div>
          <span>12.5%</span>
        </div>
      </div>
    </div>
    <button @click="buy" class="default-hover buy-sneg-coin__btn">
      {{ $t('buy') }}
    </button>
  </div>
</template>

<style scoped lang="scss">
.buy-sneg-coin {
  width: 253px;
  padding: 6.5px;
  padding-left: 14px;
  border-radius: 12px;
  background: linear-gradient(90deg, rgba(118, 88, 149, 0.29) 0%, rgba(114, 39, 188, 0.00) 101.23%);
  display: flex;
  align-items: center;
  justify-content: space-between;

  .buy-sneg-coin__info {
    display: flex;
    align-items: center;
    .buy-sneg-coin__info_icon {
      display: flex;
      align-items: center;
      img {
        width: 26px;
        height: 26px;
      }

      margin-right: 11px;
    }
    .buy-sneg-coin__info_text {
      display: block;
      strong {
        display: block;
        margin-top: 4px;
        margin-bottom: 2px;
        font-family: "Unbounded", sans-serif;
        font-size: 12px;
        font-weight: 700;
        opacity: 0.8;
        letter-spacing: -0.24px;
      }
      .indicators {
        display: flex;
        align-items: center;
        span {
          font-size: 12px;
          font-style: normal;
          font-family: 'Geometria-Bold';
          line-height: normal;
          letter-spacing: -0.24px;
          opacity: 0.8;
        }
        .dir {
          margin-left: 2px;
        }
      }
    }
  }
  .buy-sneg-coin__btn {
    background: $color-yellow;
    border-radius: 11px;
    padding: 12.5px 24px;
    color: $color-pre-black;
    font-family: 'Geometria-Bold';
  }
}
</style>