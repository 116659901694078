import {getMe} from "@/services/user";
import {changePassword} from "@/services/user";
import {removeTwoFactor} from "@/services/user";
import {vue} from '@/main'
import extractErrors from "@/helpers/extractErrors";
export default {
    async me ({commit}) {
        await getMe().then(async response => {
            await commit('setUser', response.data)
            console.log(response.data.is_seen_welcome)
            // if (!response.data.is_seen_welcome) {
            //     commit('config/setIsOpenWelcomeFreeLootbox', true, {root: true});
            // }
        }).catch(err => {
            extractErrors(err)
            const errors = extractErrors(err);
            errors.forEach(item => {
                vue.$root.$emit('error', {
                    title: 'Ошибка',
                    text: item
                })
            })

            throw new Error(err)
        })
    },

    // eslint-disable-next-line no-unused-vars
    async changePasswordProcessing ({commit}, {data}) {
        await changePassword(data).then(response => {
            console.log(response)
        }).catch((err) => {
            extractErrors(err)
            const errors = extractErrors(err);
            errors.forEach(item => {
                vue.$root.$emit('error', {
                    title: 'Ошибка',
                    text: item
                })
            })

            throw new Error(err)
        })
    },

    async removeTwoFactor({dispatch})
    {
        await removeTwoFactor().then(() => {
            vue.$root.$emit('success', {
                title: "Success",
                text: "Two-factor authorization has been removed."
            })
            dispatch('me');

        }).catch(err => {
            extractErrors(err)
            const errors = extractErrors(err);
            errors.forEach(item => {
                vue.$root.$emit('error', {
                    title: 'Error',
                    text: item
                })
            })
        })
    }
}