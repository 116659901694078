<script>
import BackButton from "@/components/BackButton.vue";
import UiInput from "@/components/Ui/UiInput.vue";
import SlideGameItem from "@/components/Main/SlideGameItem.vue";
import { getList } from "@/services/games";
import EmptyState from "@/components/EmptyState.vue";
import AccentBtn from "@/components/Ui/Buttons/AccentBtn.vue";
import UiLoader from "@/components/UiLoader.vue";

export default {
  data () {
    return {
      pages: 1,
      page: 1,
      games: [],
      isLoading: true,
      search: null,
    }
  },
  methods: {
    loadMore() {
      this.page++;
      this.getGames(true)
    },
    getGames(append = false) {
      getList({
        search: this.search,
        page: this.page,
        provider: this.$route.params.id,
      }).then(response => {
        if (append) {
          this.games = [...this.games, ...response.data.data];
        } else {
          this.games = response.data.data;
        }
        this.pages = response.data.meta.last_page;
        this.page = response.data.meta.current_page;
      }).finally(() => {
        setTimeout(() => {
          this.isLoading = false;
        }, 500)
      })
    }
  },
  watch: {
    search() {
      this.page = 1;
      this.getGames(false)
    }
  },
  mounted() {
    this.getGames()
  },
  name: "ProviderGamesView",
  components: {UiLoader, AccentBtn, EmptyState, SlideGameItem, UiInput, BackButton}
}
</script>

<template>
  <div class="provider-games">
    <BackButton :callback="() => $router.push('/games')" />

    <div class="provider-games-title">
      {{ $route.params.id }} {{ $t('games') }}
    </div>

    <div class="provider-games-filters">
      <div class="provider-games-filters__search">
        <UiInput v-model="search" type="text" :icon="require('@/assets/images/search-blog-icon.svg')" :placeholder="$t('s_search_for')" />
      </div>
    </div>

    <div class="provider-games-items">
      <template v-if="!isLoading">
        <EmptyState :absolute="true" :heading="$t('no_records_l')" v-if="!games.length" />
        <template v-else>
          <SlideGameItem v-for="(game, i) in games" :key="i" :game="game" />
        </template>
      </template>
      <UiLoader v-else :fill="true" />
    </div>

    <div class="pagination">
      <AccentBtn :title="$t('load_more')" v-if="pages > 1 && page < pages" @click="loadMore" />
    </div>
  </div>
</template>

<style scoped lang="scss">
  .provider-games {
    .back {
      @media (max-width: 768px) {
        display: none;
      }
    }
    .provider-games-title {
      font-family: Unbounded;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 34px; /* 106.25% */
      letter-spacing: -0.64px;
      margin-top: 47px;
      @media (max-width: 768px) {
        font-size: 20px;
        margin-top: 0px;
      }
    }
    .pagination {
      margin-top: 20px;

      display: flex;
      justify-content: center;
      margin-bottom: 30px;
      .accent-btn {
        width: fit-content;
        padding: 16px 34px;
      }

    }
    .provider-games-filters {
      margin-top: 19px;
      @media (max-width: 768px) {
        margin-top: 10px;
      }
      .provider-games-filters__search {
        width: 488px;
        max-width: 100%;
      }
    }
    .provider-games-items {
      position: relative;
      min-height: 50vh;
      align-items: flex-start;
      margin-top: 28px;
      display: grid;
      grid-gap: 16px;
      grid-template-columns: repeat(9, 1fr);
      @media (max-width: 768px) {
        margin-top: 10px;
      }
      @media (max-width: 2100px) {
        grid-template-columns: repeat(7, 1fr);
      }
      @media (max-width: 1850px) {
        grid-template-columns: repeat(6, 1fr);
      }
      @media (max-width: 1600px) {
        grid-template-columns: repeat(6, 1fr);
      }
      @media (max-width: 1400px) and (min-width: 1200px) {
        grid-template-columns: repeat(5, 1fr);
      }
      @media (max-width: 992px) {
        grid-template-columns: repeat(5, 1fr);
      }
      @media (max-width: 768px) {
        grid-gap: 8px;
        grid-template-columns: repeat(4, 1fr);
      }
      @media (max-width: 500px) {
        grid-template-columns: repeat(3, 1fr);
      }
      @media (max-width: 420px) {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
</style>