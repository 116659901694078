<script>
import DepositSelect from "@/components/Modals/Deposit/Ui/DepositSelect.vue";
import {getCryptoCurrencies} from "@/services/deposit";
import UiInput from "@/components/Ui/UiInput.vue";
import AccentBtn from "@/components/Ui/Buttons/AccentBtn.vue";
import {mapGetters} from "vuex";
import { withdraw } from "@/services/user";
import extractErrors from "@/helpers/extractErrors";

export default {
  data () {
    return {
      isLoading: false,
      selectCurrency: null,
      selectNetwork: null,
      currencies: [],
      address: null,
      amount: 10,
    }
  },
  components: {
    AccentBtn,
    UiInput,
    DepositSelect
  },
  watch: {
    selectCurrency() {
      this.selectNetwork = null;
    },
    amount() {
      if (Number(this.amount) > Number(this.user.balance.balance)) {
        this.amount = this.user.balance.balance;
      }
    }
  },
  computed: {
    ...mapGetters({
      user: 'user/user'
    }),
    selectCurrencyProxy() {
      if (!this.selectCurrency) {
        if (!this.currencies.length) {
          return null;
        }

        return this.currencies[0];
      }

      return this.selectCurrency;
    },
    selectCurrencyNetworkProxy ()
    {
      if (this.selectNetwork) {
        return this.selectNetwork
      }

      if (!this.selectCurrencyProxy) {
        return null;
      }

      return this.selectCurrencyProxy.networks[0];
    },
    getAmount () {
      return this.amount * this.selectCurrencyProxy.rate_to_usd;
    },
    hasBalance() {
      return Number(this.amount) <= this.user?.balance?.balance;
    }
  },
  methods: {
    setMax() {
      this.amount = Number(this.user.balance.balance);
    },
    fetchCurrencies()
    {
      getCryptoCurrencies().then(response => {
        this.currencies = response.data.data;
      })
    },
    async init() {
      await this.fetchCurrencies();
    },
    async handle() {
      if (!this.amount || !this.address) {
        this.$root.$emit('success', {
          title: "Error",
          text: this.$t('w_enter_all'),
        })
        return;
      }

      this.isLoading = true;
      await withdraw({
        amount: this.amount,
        method: 'crypto',
        crypto_currency_id: this.selectCurrencyProxy.id,
        crypto_network_id: this.selectCurrencyNetworkProxy.id,
        address: this.address
      }).then(() => {
        this.amount = 10;
        this.address = null;
        this.$root.$emit('success', {
          title: "Success",
          text: this.$t('w_created_success'),
        })
      }).catch(errors => {
        extractErrors(errors).forEach(item => {
          this.$root.$emit('error', {
            title: "Error",
            text: item,
          })
        })
      }).finally(() => {
        this.isLoading = false;
      })
    }
  },
  mounted() {
    this.init();
  },
  name: "WithdrawForm"
}
</script>

<template>
<div class="withdraw-form">
  <div class="withdraw-form-inputs">
    <DepositSelect :label="$t('d_currency')"  @update="selectCurrency = $event" :value="selectCurrencyProxy" :options="currencies"></DepositSelect>
    <DepositSelect :label="$t('d_network')"  @update="selectNetwork = $event" :value="selectCurrencyNetworkProxy" name-by="network_id" :options="selectCurrencyProxy.networks"></DepositSelect>
  </div>

  <div class="withdraw-form-balance">
    <input v-model="amount" maxlength="1000" type="number">
    <div class="balance__value">
      <span>{{ $t('w_balance') }}</span>
      <strong>$ {{ parseFloat(user.balance.balance).toFixed(2) }}</strong>
    </div>

    <div class="right-label">
      <span class="max default-hover" @click="setMax">{{ $t('d_max') }}</span>
      {{ $t('d_you_sent') }}
    </div>
  </div>
  <div class="withdraw-form-balance">
    <input readonly :value="parseFloat(getAmount).toFixed(2)" maxlength="1000" type="number">
    <div class="balance__value">
      <strong>$ 1 = {{ selectCurrencyProxy.rate_to_usd }} {{ selectCurrencyProxy.name }}</strong>
    </div>

    <div class="right-label">{{ $t('d_you_get') }}</div>
  </div>

  <UiInput class="address" v-model="address" type="text" :placeholder="$t('w_address')" :icon="require('@/assets/images/wallet.svg')" />

  <AccentBtn @click="handle" :is-loading="isLoading" :disabled="!amount || !hasBalance || !address" title="Withdraw" />
  <div class="note">

    <div class="note-label">
      <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
        <path d="M15.0067 10.6133L10.74 2.93325C10.1667 1.89992 9.37332 1.33325 8.49998 1.33325C7.62665 1.33325 6.83332 1.89992 6.25998 2.93325L1.99332 10.6133C1.45332 11.5933 1.39332 12.5333 1.82665 13.2733C2.25999 14.0133 3.11332 14.4199 4.23332 14.4199H12.7667C13.8867 14.4199 14.74 14.0133 15.1733 13.2733C15.6067 12.5333 15.5467 11.5866 15.0067 10.6133ZM7.99998 5.99992C7.99998 5.72659 8.22665 5.49992 8.49998 5.49992C8.77332 5.49992 8.99998 5.72659 8.99998 5.99992V9.33325C8.99998 9.60659 8.77332 9.83325 8.49998 9.83325C8.22665 9.83325 7.99998 9.60659 7.99998 9.33325V5.99992ZM8.97332 11.8066C8.93998 11.8333 8.90665 11.8599 8.87332 11.8866C8.83332 11.9133 8.79332 11.9333 8.75332 11.9466C8.71332 11.9666 8.67332 11.9799 8.62665 11.9866C8.58665 11.9933 8.53998 11.9999 8.49998 11.9999C8.45998 11.9999 8.41332 11.9933 8.36665 11.9866C8.32665 11.9799 8.28665 11.9666 8.24665 11.9466C8.20665 11.9333 8.16665 11.9133 8.12665 11.8866C8.09332 11.8599 8.05998 11.8333 8.02665 11.8066C7.90665 11.6799 7.83332 11.5066 7.83332 11.3333C7.83332 11.1599 7.90665 10.9866 8.02665 10.8599C8.05998 10.8333 8.09332 10.8066 8.12665 10.7799C8.16665 10.7533 8.20665 10.7333 8.24665 10.7199C8.28665 10.6999 8.32665 10.6866 8.36665 10.6799C8.45332 10.6599 8.54665 10.6599 8.62665 10.6799C8.67332 10.6866 8.71332 10.6999 8.75332 10.7199C8.79332 10.7333 8.83332 10.7533 8.87332 10.7799C8.90665 10.8066 8.93998 10.8333 8.97332 10.8599C9.09332 10.9866 9.16665 11.1599 9.16665 11.3333C9.16665 11.5066 9.09332 11.6799 8.97332 11.8066Z" fill="#FFE70C"/>
      </svg>

      <span>{{ $t('w_min_text') }}</span>
      <strong>20.00 $</strong>
    </div>

  </div>
  <div class="shadow"></div>

</div>
</template>

<style scoped lang="scss">
.withdraw-form {
  .shadow {
    position: absolute;
    bottom: 0;
    left: calc(50% - 50px);
    width: 1px;
    height: 1px;
    border-radius: 100%;
    background: rgba(171, 107, 255, 0.2);
    box-shadow: 0px 0px 200px 120px rgba(171, 107, 255, 0.5);
  }

  position: relative;
  .withdraw-form-inputs {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 9px;
  }
  .withdraw-form-balance {
    border-radius: 12px;
    margin-top: 4px;
    background: var(--50-Dark, rgba(0, 3, 42, 0.50));
    padding: 16px;
    position: relative;
    .right-label {
      position: absolute;
      right: 16px;
      top: calc(50% - 9px);
      color: var(--700, #7F7DA6);
      font-family: "Geometria-Medium";
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.28px;
      @media (max-width: 768px) {
        font-size: 12px;
      }
      .max {
        border-radius: 5px;
        background: linear-gradient(180deg, #9D42F8 -4.55%, #7F00FF 100%);
        box-shadow: 0 -1px 1px 1px #BC79FF;
        padding: 4px;
        font-size: 14px;
        margin-right: 4px;
        font-weight: normal;
        font-family: "Geometria-Medium";
        cursor: pointer;
      }
    }
    input {
      display: block;
      width: 100%;
      background: transparent;
      outline: none;
      border: none;
      margin-bottom: 4px;
      font-family: Unbounded;
      padding-right: 80px;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.32px;
      @media (max-width: 768px) {
        font-size: 14px;
      }
    }
    .balance__value {
      display: flex;
      align-items: center;
      span {
        margin-right: 6px;
        display: block;
        color: var(--700, #7F7DA6);
        font-family: "Geometria-Medium";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.28px;
      }
      strong {
        font-family: "Geometria-Bold";
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.28px;
      }
    }
  }
  .address {
    margin-top: 4px;
    background: var(--50-Dark, rgba(0, 3, 42, 0.50));
  }
  .accent-btn {
    margin-top: 42px;
  }
  .note {
    display: flex;
    margin-top: 42px;
    justify-content: center;
    align-items: center;
    .note-label {
      display: flex;
      align-items: center;
      svg {
        margin-right: 8px;
      }
      span {
        font-family: "Geometria-Medium";
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        opacity: 0.6;
        line-height: normal;
        letter-spacing: -0.24px;
      }
      strong {
        margin-left: 8px;
        display: block;
        font-family: "Geometria-Medium";
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.24px;
      }
    }
  }
  .warning-block {
    display: flex;
    align-items: center;
    border-radius: 10px;
    background: var(--6-danger, rgba(255, 103, 103, 0.06));
    padding: 12px;
    margin-top: 4px;
    span {
      font-family: "Geometria-Medium";
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.24px;
    }
    img {
      margin-right: 12px;
    }
  }
}
</style>