export function markInputErrors(fields, errors, refs) {

    if (typeof errors !== 'object') return;

    for (const item in fields) {
        // eslint-disable-next-line no-prototype-builtins
        if (errors.hasOwnProperty(fields[item]) && refs.hasOwnProperty(fields[item])) {
            refs[fields[item]].$el.classList.add('validation-error');
        }
    }
}
export function unmarkInputErrors (fields, refs) {
    for (const item in fields) {
        // eslint-disable-next-line no-prototype-builtins
        if (refs.hasOwnProperty(fields[item])) {
            refs[fields[item]].$el.classList.remove('validation-error');
        }
    }
}