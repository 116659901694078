<script>
import MyNftItem from "@/components/Profile/Nft/MyNftItem.vue";
import {getNfts} from "@/services/user";
import EmptyState from "@/components/EmptyState.vue";
import UiLoader from "@/components/UiLoader.vue";
export default {
  data () {
    return {
      loading: false,
      nfts: [],
    }
  },
  methods: {
    async fetchNfts() {
      this.loading = true;
      await getNfts().then(response => {
        this.nfts = response.data.data;
      }).finally(() => {
        this.loading = false;
      })
    }
  },
  mounted () {
    this.fetchNfts()
  },
  name: "MyNftList",
  components: {UiLoader, EmptyState, MyNftItem}
}
</script>

<template>
  <div class="nfts">
    <UiLoader :fill="true" v-if="loading"></UiLoader>
    <template v-else>
      <template v-if="nfts && nfts.length">
        <MyNftItem v-for="(nft, i) in nfts" :key="i" :nft="nft" />
      </template>
      <EmptyState :absolute="true" heading="You don't have a nft yet" v-else />
    </template>
  </div>
</template>

<style scoped lang="scss">
  .nfts {
    display: grid;
    grid-gap: 8px;
    grid-template-columns: repeat(2, 1fr);
    overflow-y: auto;
    position: relative;
    height: 100%;
    min-height: 250px;
    @media (max-width: 992px) {
      grid-template-columns: repeat(5, 1fr);
      padding-bottom: 50px;
    }
    @media (max-width: 768px) {
      grid-template-columns: repeat(4, 1fr);
    }
    @media (max-width: 620px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media (max-width: 575px) {
      grid-template-columns: repeat(2, 1fr);
    }
    &::-webkit-scrollbar {
      display: none;
    }
  }
</style>