<script>
import TypeDeposit from "@/components/Profile/Transactions/TypeDeposit.vue";
import TypeWithdraw from "@/components/Profile/Transactions/TypeWithdraw.vue";
import BadgeStatus from "@/components/Profile/Transactions/BadgeStatus.vue";
import {shortenWalletAddress} from "@/helpers/shortAddress";

export default {
  data () {
    return {
      shortenWalletAddress
    }
  },
  props: {
    transaction: {
      type: Object
    }
  },
  methods: {
    goToTransaction(url) {
      window.open(url, '_blank')
    },
    copy(text) {
      navigator.clipboard.writeText(text);

      this.$root.$emit('success', {
        title: "Success",
        text: "Successfully copied to clipboard"
      })
    }
  },
  name: "TransactionItem",
  components: {BadgeStatus, TypeWithdraw, TypeDeposit}
}
</script>

<template>
  <div class="transaction-item">
    <div class="type">
      <TypeDeposit v-if="transaction.direction === 'deposit'"/>
      <TypeWithdraw v-else />
    </div>

    <div class="time">
      <img src="@/assets/images/clock-icon.svg" alt="">
      <span>{{ transaction.time }}</span>
    </div>

    <div class="wager">
      <img src="@/assets/images/sneg-coin.svg" alt="">
      <span>{{ parseFloat(transaction.amount).toFixed(2) }}</span>
    </div>

    <div class="status">
      <BadgeStatus :status="transaction.status" />
    </div>

    <div class="transaction">
      <div class="transaction-body" v-if="transaction.hash || transaction.explorer_url">
        <div class="transaction-body__address" v-if="transaction.hash">
          {{ shortenWalletAddress(transaction.hash) }}
        </div>
        
        <div class="transaction-body__buttons">
          <div class="transaction-body__button default-hover" @click="copy(transaction.hash)" v-if="transaction.hash">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path d="M3.66788 3.0847L3.66602 4.5V11.5028C3.66602 12.6994 4.63607 13.6695 5.83268 13.6695L11.5769 13.6696C11.3709 14.2522 10.8153 14.6695 10.1623 14.6695H5.83268C4.08378 14.6695 2.66602 13.2517 2.66602 11.5028V4.5C2.66602 3.84619 3.08431 3.29009 3.66788 3.0847ZM11.8327 1.33334C12.6611 1.33334 13.3327 2.00491 13.3327 2.83334V11.5C13.3327 12.3284 12.6611 13 11.8327 13H5.83268C5.00426 13 4.33268 12.3284 4.33268 11.5V2.83334C4.33268 2.00491 5.00426 1.33334 5.83268 1.33334H11.8327Z" fill="#7F7DA6"/>
            </svg>
          </div>
          <div class="transaction-body__button default-hover" @click="goToTransaction(transaction.explorer_url)" v-if="transaction.explorer_url">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path d="M14 6L14 2M14 2H10M14 2L8.66667 7.33333M6.66667 3.33333H5.2C4.0799 3.33333 3.51984 3.33333 3.09202 3.55132C2.71569 3.74307 2.40973 4.04903 2.21799 4.42535C2 4.85318 2 5.41323 2 6.53333V10.8C2 11.9201 2 12.4802 2.21799 12.908C2.40973 13.2843 2.71569 13.5903 3.09202 13.782C3.51984 14 4.0799 14 5.2 14H9.46667C10.5868 14 11.1468 14 11.5746 13.782C11.951 13.5903 12.2569 13.2843 12.4487 12.908C12.6667 12.4802 12.6667 11.9201 12.6667 10.8V9.33333" stroke="#918FBB" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>

        </div>
      </div>
    </div>

  </div>
</template>

<style scoped lang="scss">
  .transaction-item {
    border-radius: 15px;
    background: #0C0A33;
    padding: 7px;
    display: flex;
    align-items: center;
    @media (max-width: 768px) {
    }
    .type {
      width: 200px;
      min-width: 200px;
      @media (max-width: 768px) {
        width: 50%;
        min-width: 50%;
      }
    }
    .time {
      width: 150px;
      min-width: 150px;
      display: flex;
      align-items: center;
      @media (max-width: 768px) {
        display: none;
      }
      img {
        margin-right: 5.5px;
      }
      span {
        color: #918FBB;
        font-family: "Geometria-Medium";
        font-size: 14px;
        font-weight: 500;
        letter-spacing: -0.28px;
      }
    }
    .wager {
      @media (max-width: 768px) {
        display: none;
      }
      display: flex;
      width: 130px;
      min-width: 130px;
      align-items: center;
      img {
        margin-right: 4px;
        width: 18px;
        height: 18px;
      }
      span {
        line-height: 14px;
        color: #fff;
        font-family: "Geometria-Medium";
        font-size: 14px;
        font-weight: 500;
        letter-spacing: -0.28px;
      }
    }
    .status {
      width: 150px;
      min-width: 150px;
      @media (max-width: 768px) {
        width: 50%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
    }
    .transaction {
      flex-grow: 1;
      justify-content: flex-end;
      display: flex;
      @media (max-width: 768px) {
        display: none;
      }
      .transaction-body {
        width: fit-content;
        display: flex;
        align-items: center;
        border-radius: 10px;
        background: rgba(200, 197, 255, 0.05);
        padding: 4px;
        padding-left: 12px;
        .transaction-body__address {
          font-family: "Geometria-Medium";
          font-size: 14px;
          font-weight: 500;
          letter-spacing: -0.28px;
          margin-right: 23px;
        }
        .transaction-body__buttons {
          display: flex;
          align-items: center;
          .transaction-body__button {
            width: 28px;
            height: 28px;
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: rgba(200, 197, 255, 0.10);
          }
          .transaction-body__button + .transaction-body__button {
            margin-left: 4px;
          }
        }
      }
    }
  }
</style>