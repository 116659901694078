<script>
import {getLastWins} from "@/services/chat";
import CoinPlaceholder from "@/components/Ui/CoinPlaceholder.vue";
import formatNumber from "@/helpers/formatNumber";
import {mapActions, mapMutations} from "vuex";
import EmptyState from "@/components/EmptyState.vue";
import extractErrors from "@/helpers/extractErrors";
import {vue} from "@/main";
export default {
  components: {EmptyState, CoinPlaceholder},
  data () {
    return {
      formatNumber,
      wins: []
    }
  },
  name: "LastWins",
  methods: {
    ...mapMutations({
      setIsOpenCommandsList: 'chat/setIsOpenCommandsList',
    }),
    ...mapActions({
      sendMessage: 'chat/sendMessage'
    }),
    send (transaction_id)
    {
      this.setIsOpenCommandsList(false);
      this.sendMessage({type: 'last_win', last_win_transaction_id: transaction_id}).catch(err => {
        const errors = extractErrors(err);
        errors.forEach(item => {
          vue.$root.$emit('error', {
            title: 'Error',
            text: item
          })
        })
      })
    },
    fetchLastWins()
    {
        getLastWins().then(response => {
          this.wins = response.data.data;
        }).catch(() => {})
    }
  },
  mounted() {
    this.fetchLastWins()
  }
}
</script>

<template>
  <div class="last-wins">
    <div class="chat-commands_head">
      <button @click="$emit('close')" class="default-hover">
        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
          <path d="M8.125 3.25L4.875 6.5L8.125 9.75" stroke="#C489FF" stroke-width="1.44444" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <span>{{ $t('back') }}</span>
      </button>
    </div>
    <div class="chat-commands_label">
      {{ $t('recent_wins') }}
    </div>
    <div class="chat-commands-scroll">
      <div class="chat-commands-wins">
        <div class="chat-commands-win default-hover" @click="send(win.transaction_id)" v-for="(win, i) in wins" :key="i">
          <div class="chat-commands-win__game">
            <img :src="win.game.preview_image" alt="">
          </div>
          <div class="chat-commands-win__name">
            {{ win.game.name }}
          </div>
          <div class="chat-commands-win__amount">
            <CoinPlaceholder :symbol="win.currency.symbol" />
            <div class="chat-commands-win__sum">
              {{ formatNumber(win.amount).integer }}
              <em>
                .{{ formatNumber(win.amount).decimal }}
              </em>
            </div>
            <div class="chat-commands-win__currency-name">
              {{ win.currency.code }}
            </div>
          </div>
        </div>
        <EmptyState size="small" :absolute="true" :heading="$t('not_have_wins')" v-if="!wins.length"  />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.last-wins {
  .chat-commands_head {
    display: flex;
    align-items: center;
    button {
      border-radius: 9px;
      background: rgba(181, 107, 255, 0.20);
      display: flex;
      align-items: center;
      padding: 8px 13px;
      span {
        margin-left: 8px;
        color: #C489FF;
        display: flex;
        align-items: center;
        padding-top: 2px;
        font-family: "Geometria-Bold";
        font-size: 12px;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.24px;
      }
    }
  }
  .chat-commands_label {
    margin-top: 9px;
    margin-bottom: 6px;
    color: #918FBB;
    font-family: "Geometria-Medium";
    font-size: 12px;
    font-weight: 500;
    letter-spacing: -0.24px;
  }
  .chat-commands-scroll {
    position: relative;
    &::after {
      bottom: 0;
      width: 100%;
      height: 50px;
      content: "";
      display: block;
      left: 0;
      background: linear-gradient(0deg, #1D1B41 0%, rgba(29, 27, 65, 0.00) 92.98%);
      z-index: 2;
      position: absolute;
    }
  }
  .chat-commands-wins {
    padding-bottom: 40px;
    position: relative;
    height: 200px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    .chat-commands-win {
      display: flex;
      align-items: center;
      border-radius: 10px;
      overflow: hidden;
      background: #131139;
      padding: 8px;
      .chat-commands-win__game {
        margin-right: 10px;
        height: 30px;
        width: 30px;
        overflow: hidden;
        border-radius: 8px;
        img {
          width: 100%;
        }
      }
      .chat-commands-win__name {
        font-size: 12px;
        font-family: "Geometria-Medium";
        font-style: normal;
        font-weight: 500;
        width: 90px;
        line-height: normal;
        padding-top: 2px;
        letter-spacing: -0.24px;
        flex-grow: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-right: 10px;
      }
      .chat-commands-win__amount {
        display: flex;
        align-items: center;
        margin-left: auto;
        overflow: hidden;
        .chat-commands-win__sum {

          margin: 0 6px;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          display: flex;
          align-items: center;
          padding-top: 1px;
          letter-spacing: -0.28px;
          em {
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            color: #B9B8DD
          }
        }
        .chat-commands-win__currency-name {
          border-radius: 5px;
          background: rgba(255, 255, 255, 0.15);
          padding: 2px 4px;
          color: #B9B8DD;
          font-family: "Geometria-Bold";
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          padding-top: 5px;
          letter-spacing: -0.24px;
        }
      }
    }
    .chat-commands-win + .chat-commands-win {
      margin-top: 4px;
    }
  }
}
</style>