<script>
export default {
  props: {
    icon: {
      type: String,
    },
    name: {
      type: String
    }
  },
  name: "StatisticBlock"
}
</script>

<template>
  <div class="statistic-block">
    <div class="statistic-block__head">
      <div class="statistic-block__head-img">
        <img :src="icon" alt="">
      </div>
      <div class="statistic-block__head-name">
        {{ name }}
      </div>
    </div>
    <div class="statistic-block__items">
      <slot></slot>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .statistic-block {
    width: 100%;
    border-radius: 16px;
    background: #131139;
    padding: 20px;
    @media (max-width: 768px) {
      padding: 16px;
    }
    .statistic-block__head {
      display: flex;
      align-items: center;
      .statistic-block__head-img {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        margin-right: 7px;
      }
      .statistic-block__head-name {
        color: #B9B8DD;
        font-family: "Geometria-Bold";
        font-size: 14px;
        font-weight: 700;
        line-height: normal;
        display: flex;
        align-items: center;
        letter-spacing: -0.28px;
      }
    }
    .statistic-block__items {
      margin-top: 14px;
    }
  }
</style>