import { getLootbox, openLootbox } from "@/services/lootboxes";
import extractErrors from "@/helpers/extractErrors";

export default {
    async getLootbox({commit}, id) {
        getLootbox(id).then(response => {
            commit('setLootbox', response.data);
        })
    },
    async open({commit}, {id, context}) {
        openLootbox(id).then(async (response) => {
            await commit('setWinItem', response.data);
            context.$refs.roulette.start()
        }).catch(errors => {
            if (!errors) {
                context.$root.$emit('error', {
                    title: "Error",
                    text: "Error when opening lootbox, try again later.",
                });
            }

            extractErrors(errors).forEach(item => {
                context.$root.$emit('error', {
                    title: "Error",
                    text: item,
                })
            })
        })
    }
}