import signIn from "@/services/auth";
import {register} from "@/services/auth";
import {socialAuth, web3Auth} from "@/services/auth";
import ApiClient from "@/services/ApiClient";
import {echoConfiguration} from "@/plugins/echo";

export default {
    async signIn ({commit, rootGetters, dispatch}, data) {
        return await signIn(data).then(async response => {
            echoConfiguration.connector.pusher.config.auth.headers.authorization = 'Bearer ' + response.data.access_token
            await commit('setToken', response.data.access_token);

            await ApiClient.recreate()

            await dispatch('user/me', null, {root: true}).then(() => {
                commit('config/setOpenAuth', false, {root: true})

                if (rootGetters['user/user']?.next_give_free_lootbox === 0 && rootGetters['user/user']?.is_seen_welcome === 0) {
                    commit('config/setFreeLootboxShowFromLogin', true, {root: true});
                    commit('config/setIsOpenGetFreeLootbox', true, {root: true});
                }
            }).catch(err => {
                console.log(err.message)
            })
        }).catch(errors => data.showErrors(errors))
    },

    async register ({commit, dispatch}, data) {
        return await register(data).then(async response => {
            echoConfiguration.connector.pusher.config.auth.headers.authorization = 'Bearer ' + response.data.access_token
            await commit('setToken', response.data.access_token);

            await ApiClient.recreate()

            await dispatch('user/me', null, {root: true}).then(() => {
                commit('config/setOpenAuth', false, {root: true})
                commit('config/setIsOpenWelcomeFreeLootbox', true, {root: true});
            }).catch(err => {
                console.log(err.message)
            })
        }).catch(errors => data.showErrors(errors))
    },

    async logout ({commit}) {
        commit('setToken', null)
        commit('config/setIsShowWeb3Register', { value: false, method: 'login' }, {root: true})
        commit('user/setUser', null, {root: true});
    },

    async web3Auth({commit, dispatch}, {payload, showErrors}) {
        return await web3Auth(payload).then(async response => {
            echoConfiguration.connector.pusher.config.auth.headers.authorization = 'Bearer ' + response.data.access_token
            await commit('setToken', response.data.access_token);

            await ApiClient.recreate()

            await dispatch('user/me', null, {root: true}).then(() => {
                commit('config/setOpenAuth', false, {root: true})
            }).catch(err => {
                console.log(err.message)
            })
        }).catch(errors => showErrors(errors))
    },

    async socialCallback ({commit, dispatch}, {data, provider}) {
        await socialAuth(provider, data).then(async response => {
            echoConfiguration.connector.pusher.config.auth.headers.authorization = 'Bearer ' + response.data.access_token
            await commit('setToken', response.data.access_token);

            await ApiClient.recreate()

            await dispatch('user/me', null, {root: true}).then(() => {
                commit('config/setOpenAuth', false, {root: true})
            }).catch(err => {
                console.log(err.message)
            })
        }).catch(() => {})
    }
}