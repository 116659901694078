<script>
import {countries} from "@/resources/countries";
export default {
  data () {
    return {
      countries,
      isOpenDropdown: false,
      select: null,
    }
  },
  methods: {
    getCurrentCountrySelected () {
      return this.currentCountry
    },
    close () {
      this.isOpenDropdown = false
    }
  },
  mounted() {
    document.addEventListener('click', this.close);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.close)
  },
  computed: {
    currentCountry() {
      if (!this.select) {
        return this.countries[0]
      }

      return this.select;
    }
  },
  name: "CountryCodeSelect"
}
</script>

<template>
  <div class="country-select">
    <div class="country-select__main default-hover" @click.prevent.stop="isOpenDropdown = !isOpenDropdown">
      <div class="country-select__main-icon">
        <img :src="`https://flagsapi.com/${currentCountry.iso}/flat/64.png`"  alt="">
      </div>
      <div class="country-select__main-code">
        +{{ currentCountry.code }}
      </div>
      <div class="country-select__main-arrow" :class="{'reverse': isOpenDropdown}">
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
          <path d="M3.5 5.25L7 8.75L10.5 5.25" stroke="#7F7DA6" stroke-width="1.55556" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
    </div>
    <div v-if="isOpenDropdown" class="country-select__dropdown">
      <div @click="select = country" v-for="(country, i) in countries.filter(item => item.iso !== currentCountry.iso)" :key="i" class="country-select__dropdown-item default-hover">
        <div class="country-select__dropdown-item-icon">
          <img :src="`https://flagsapi.com/${country.iso}/flat/64.png`" alt="">
        </div>
        <div class="country-select__dropdown-item-code">
          +{{country.code}}
        </div>
        <div class="country-select__dropdown-item-name">
          {{ country.country }}
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .country-select {
    width: 100%;
    position: relative;
    .country-select__main {
      border-radius: 9px;
      background: #0C0A33;
      padding: 8px;
      display: flex;
      align-items: center;
      overflow: hidden;
      .country-select__main-icon {
        width: 20px;
        height: 20px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .country-select__main-code {
        margin: 0 6px;
        color: #7F7DA6;
        font-family: "Geometria-Medium";
        font-size: 14px;
        padding-top: 2px;
        flex-grow: 1;
        font-style: normal;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.28px;
      }
      .country-select__main-arrow {
        display: flex;
        align-items: center;
        width: 14px;
        height: 14px;
        transition: 0.2s;
        &.reverse {
          transform: rotate(180deg);
        }
      }
    }
    .country-select__dropdown {
      position: absolute;
      top: calc(100% + 5px);
      padding: 4px;
      padding-top: 8px;
      padding-bottom: 8px;
      left: 0;
      width: 340px;
      z-index: 9;
      max-height: 200px;
      height: 200px;
      &::-webkit-scrollbar {
        width: 2px;
      }
      &::-webkit-scrollbar-thumb {
        background: #28105F;
        border-radius: 20px;
      }
      overflow-y: auto;
      border-radius: 10px;
      background: #0C0A33;
      .country-select__dropdown-item {
        display: flex;
        align-items: center;
        padding: 4px 8px;
        overflow: hidden;
        .country-select__dropdown-item-icon {
          width: 20px;
          height: 20px;
          min-width: 20px;
          img {
            width: 100%;
            height: 100%;
            border-radius: 5px;
          }

          margin-right: 6px;
        }
        .country-select__dropdown-item-code {
          color: #7F7DA6;
          font-size: 14px;
          font-family: "Geometria-Medium";
          font-style: normal;
          font-weight: 500;
          padding-right: 2px;
          min-width: 50px;
          width: 66px;
          line-height: normal;
          letter-spacing: -0.28px;
        }
        .country-select__dropdown-item-name {
          margin-left: 8px;
          color: #fff;
          font-family: "Geometria-Bold";
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 14px;
        }
      }
      .country-select__dropdown-item + .country-select__dropdown-item {
        margin-top: 4px;
      }
    }
  }
</style>