import { render, staticRenderFns } from "./LootboxesBanner.vue?vue&type=template&id=610b0251&scoped=true"
import script from "./LootboxesBanner.vue?vue&type=script&lang=js"
export * from "./LootboxesBanner.vue?vue&type=script&lang=js"
import style0 from "./LootboxesBanner.vue?vue&type=style&index=0&id=610b0251&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "610b0251",
  null
  
)

export default component.exports