import {getList} from "@/services/games";
import {getCategoriesList} from "@/services/games";
export default {
    async getGames ({commit, getters}, append = false) {
        if (!append) {
            commit('setIsLoading', true);
        }
        await getList({
            page: getters.page,
            search: getters.search,
            per_page: getters.per_page,
            isMobile: window.innerWidth < 992 ? 1 : 0,
            providers: getters.providers,
            category_id: getters.section !== 'originals' ? getters.section : null,
        }).then(response => {
            if (append) {
                commit('appendList', response.data.data);
            } else {
                commit('setList', response.data.data);
            }
            commit('setLastPage', response.data.meta.last_page)
        }).catch(() => {}).finally(() => {
            setTimeout(() => {
                commit('setIsLoading', false);
            }, 500);
        })
    },

    async updateProviders({dispatch, commit}, providers) {
        await commit('setProviders', providers);
        await dispatch('getGames')
    },

    async setSection ({dispatch, commit}, section) {
        await commit('resetPagination');

        await commit('setSection', section);
        if (section !== 'originals') {
              dispatch('getGames')
        }
    },

    async setSearch ({dispatch, commit}, search) {
        await commit('resetPagination');
        await commit('setSearch', search);
        dispatch('getGames');
    },

    async getCategories ({commit}) {
        await getCategoriesList().then(response => {
            commit('setCategories', response.data.data);
        })
    },

    async upPage({dispatch, getters, commit}) {
        const nextPage = getters.page + 1;

        if (nextPage > getters.last_page) return;

        await commit('setPage', nextPage);
        dispatch('getGames', true);
    }
}