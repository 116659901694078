<script>
import ProfileLevels from "@/components/Profile/ProfileLevels.vue";
import ProfileSettings from "@/components/Profile/ProfileSettings.vue";
import GamingStatistic from "@/components/Profile/GamingStatistic.vue";
import ActivityStatistic from "@/components/Profile/ActivityStatistic.vue";

export default {
  name: "ProfileInformation",
  components: {ActivityStatistic, GamingStatistic, ProfileSettings, ProfileLevels}
}
</script>

<template>
  <div class="information">
    <ProfileLevels/>

    <div class="panel">
      <div class="info">
        <ProfileSettings/>

        <div class="statistics">
          <GamingStatistic/>
          <ActivityStatistic/>
        </div>
      </div>
      <div class="additional">

      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .information {
    .panel {
      width: 100%;
      margin-top: 16px;
      display: flex;
      align-items: flex-start;
      @media (max-width: 768px) {
        margin-top: 10px;
      }
      .info {
        flex-grow: 1;
        margin-right: 16px;
        overflow: hidden;
        @media (max-width: 1900px) {
          margin-right: 0;
        }
        .statistics {
          margin-top: 16px;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-gap: 16px;
          @media (max-width: 900px) {
            grid-template-columns: repeat(1, 1fr);
            margin-top: 10px;
            grid-gap: 10px;
          }
        }
      }
      .additional {
        width: 490px;
        min-width: 490px;
        @media (max-width: 1900px) {
          display: none;
        }
      }
    }
  }
</style>