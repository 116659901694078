<script>
import {mapMutations} from "vuex";
import {setNftAsAvatar} from "@/services/user";
import {cancelNftSell} from "@/services/user";
import extractErrors from "@/helpers/extractErrors";
import {vue} from "@/main";
import RankIcon from "@/components/Ui/RankIcon.vue";
export default {
  components: {RankIcon},
  props: {
    nft: {
      type: Object
    }
  },
  data () {
    return {
      id: Math.floor(Math.random() * 100),
      isOpenSellModal: true,
      isOpenOptions: false,
    }
  },
  watch: {
    async isOpenOptions() {
      if (this.isOpenOptions) {
        this.$root.$emit('openDropdown', this.id);
      }
    }
  },
  methods: {
    ...mapMutations({
      updateAvatar: 'user/updateAvatar',
      setOpenSellNft: 'config/setOpenSellNft'
    }),
    async unsell() {
      await cancelNftSell(this.nft.id).then(() => {
        // eslint-disable-next-line vue/no-mutating-props
        this.nft.is_selling = false;
        this.$root.$emit('success', {
          title: this.$t('account.nfts.notifications.success'),
          text: this.$t('account.nfts.notifications.cancel_from_selling')
        });
        this.$root.$emit('unsell-nft', this.nft.unique_id)
      }).catch(err => {
        const errors = extractErrors(err);
        errors.forEach(item => {
          vue.$root.$emit('error', {
            title: 'Error',
            text: item
          })
        })
      })
    },
    checkClose () {
        this.isOpenOptions = false
    },
    async setAsAvatar () {
      await setNftAsAvatar(this.nft.id).then(async (resp) => {
        if (resp.data.avatar) {
          await this.updateAvatar(resp.data.avatar)
          this.$root.$emit('success', {
            title: this.$t('account.nfts.notifications.success'),
            text: this.$t('account.nfts.notifications.avatar_success_updated')
          });
        }
      }).catch(err => {
        const errors = extractErrors(err);
        errors.forEach(item => {
          vue.$root.$emit('error', {
            title: 'Error',
            text: item
          })
        })
      })
    }
  },
  mounted() {
    this.$root.$on('selling.' + this.nft.unique_id, () => {
      // eslint-disable-next-line vue/no-mutating-props
      this.nft.is_selling = true;
    })
    this.$root.$on('unsell-nft', (id) => {
      if (this.nft.unique_id == id) {
        // eslint-disable-next-line vue/no-mutating-props
        this.nft.is_selling = false;
      }
    })
    this.$root.$on('openDropdown', async (id) => {
      if (this.id != id) {
        this.isOpenOptions = false;
      }
    })
    document.addEventListener('click', this.checkClose)
  },
  beforeDestroy() {
    document.removeEventListener('click', this.checkClose)
  },
  name: "MyNftItem"
}
</script>

<template>
  <div class="nft-item">
    <div class="nft-image-level">
      <RankIcon :rank="nft.level" />
      <div class="nft-image-level-name">
        Level {{ nft.level }}
      </div>
    </div>
    <div class="nft-sell" v-if="nft.is_selling">
      <img src="@/assets/images/nft/tag-icon.svg" alt="">
    </div>
    <div class="nft-options">
      <div class="icon" :class="{'active': isOpenOptions}" @click.prevent.stop="isOpenOptions = !isOpenOptions"><img src="@/assets/images/options-icon.svg" alt=""></div>
      <div class="options-wrapper" v-show="isOpenOptions">
        <div @click="setAsAvatar" class="option-item default-hover">
          <div class="option-item-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path d="M14.7948 11.6666C15.8015 11.6666 16.6229 12.4604 16.667 13.4563L16.6663 13.5417L10.0026 13.5416V14.3742L16.6486 14.3752C16.598 14.8182 16.4532 15.2446 16.2247 15.626L10.0026 15.6242V16.4583L15.5347 16.4592C14.234 17.7141 12.3766 18.3342 9.99965 18.3342C7.3781 18.3342 5.38976 17.5799 4.0845 16.0514C3.60137 15.4856 3.33594 14.7661 3.33594 14.0221V13.5407C3.33594 12.5057 4.17498 11.6666 5.21 11.6666H14.7948ZM9.99965 1.66669C11.3628 1.66669 12.573 2.32125 13.3332 3.33319L10.0022 3.33335L10.0018 4.16585L13.8198 4.16707C13.9898 4.5563 14.1021 4.97654 14.1458 5.41694L10.0018 5.41585V6.24919L14.1458 6.25061C14.1019 6.69101 13.9895 7.11124 13.8194 7.50046L10.0018 7.49919L10.0022 8.33335L13.3325 8.33435C12.5723 9.34582 11.3624 10 9.99965 10C7.69847 10 5.83299 8.13454 5.83299 5.83335C5.83299 3.53217 7.69847 1.66669 9.99965 1.66669Z" fill="#7F7DA6"/>
            </svg>
          </div>
          <div class="option-item-name">
            {{$t('account.nfts.set_as_avatar')}}
          </div>
        </div>
        <div class="option-item default-hover" v-if="!nft.is_selling" @click="setOpenSellNft(nft)">
          <div class="option-item-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 16 18" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M7.99929 0.354187C6.2734 0.354187 4.87429 1.7533 4.87429 3.47919V4.32067C3.29737 4.43064 1.99972 5.6424 1.80097 7.2324L0.967638 13.8991C0.718949 15.8886 2.27024 17.6459 4.27523 17.6459H11.7233C13.7283 17.6459 15.2796 15.8886 15.0309 13.8991L14.1976 7.2324C13.9989 5.6424 12.7012 4.43064 11.1243 4.32067V3.47919C11.1243 1.7533 9.72518 0.354187 7.99929 0.354187ZM9.87429 4.31252V3.47919C9.87429 2.44365 9.03482 1.60419 7.99929 1.60419C6.96376 1.60419 6.12429 2.44365 6.12429 3.47919V4.31252H9.87429Z" fill="#7F7DA6"/>
            </svg>
          </div>
          <div class="option-item-name">
            {{ $t('account.nfts.sell') }}
          </div>
        </div>

        <div class="option-item default-hover" @click="unsell" v-else>
          <div class="option-item-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 16 18" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M7.99929 0.354187C6.2734 0.354187 4.87429 1.7533 4.87429 3.47919V4.32067C3.29737 4.43064 1.99972 5.6424 1.80097 7.2324L0.967638 13.8991C0.718949 15.8886 2.27024 17.6459 4.27523 17.6459H11.7233C13.7283 17.6459 15.2796 15.8886 15.0309 13.8991L14.1976 7.2324C13.9989 5.6424 12.7012 4.43064 11.1243 4.32067V3.47919C11.1243 1.7533 9.72518 0.354187 7.99929 0.354187ZM9.87429 4.31252V3.47919C9.87429 2.44365 9.03482 1.60419 7.99929 1.60419C6.96376 1.60419 6.12429 2.44365 6.12429 3.47919V4.31252H9.87429Z" fill="#7F7DA6"/>
            </svg>
          </div>
          <div class="option-item-name">
            {{ $t('account.nfts.cancel_sell') }}
          </div>
        </div>

      </div>
    </div>
    <div class="main-img">
      <img :src="nft.image_url" alt="">
    </div>
    <div class="nft-name">
      {{ nft.name }}
    </div>
  </div>
</template>

<style scoped lang="scss">
  .nft-item {
    background: $color-gray-light;
    border-radius: 24px;
    position: relative;
    height: 200px;
    .nft-sell {
      position: absolute;
      top: 50px;
      right: 8px;
      background: #2B2A4E;
      backdrop-filter: blur(16.899999618530273px);
      z-index: 2;
      border-radius: 100%;
      height: 36px;
      width: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .nft-image-level {
      position: absolute;
      top: 4px;
      left: 4px;
      z-index: 2;
      border-radius: 34px;
      padding: 6px;
      background: var(--50-Dark, rgba(0, 3, 42, 0.50));
      backdrop-filter: blur(16.899999618530273px);
      display: flex;
      align-items: center;
      padding-right: 10px;
      .nft-image-level-name {
        margin-left: 6px;
        font-size: 12px;
        font-family: "Geometria-Bold";
        font-style: normal;
        font-weight: 700;
        line-height: 14px;
        letter-spacing: -0.24px;
      }
    }

    @media (max-width: 1900px) {
      height: 150px;
    }
    .main-img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 99%;
      overflow: hidden;
      border-radius: 24px;
      img {
        width: 100%;
      }
    }
    .nft-options {
      position: absolute;
      top: 8px;
      right: 8px;
      z-index: 8;
      .icon {
        width: 36px;
        height: 36px;
        border-radius: 100%;
        display: flex;
        transition: 0.2s;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        background: rgba(7, 0, 49, 0.33);
        &:hover, &.active {
          background: #7F00FF;
        }
      }
      .options-wrapper {
        position: absolute;
        top: calc(100% + 5px);
        z-index: 9;
        right: 0;
        width: 170px;
        border-radius: 16px;
        background: #02002A;
        box-shadow: 0px 20px 32px 0px rgba(0, 0, 0, 0.25);
        padding: 6px;

        @media (max-width: 1900px) {
          right: -8px;
          width: 140px;
        }
        @media (max-width: 575px) {
          right: -10px;
          width: 150px;
        }
        .option-item {
          display: flex;
          align-items: center;
          padding: 14px;
          border-radius: 11px;
          background: #131139;
          .option-item-icon {
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
          }
          .option-item-name {
            font-family: "Geometria-Bold";
            font-size: 14px;
            font-weight: 700;
            letter-spacing: -0.28px;
            @media (max-width: 575px) {
              font-size: 12px;
            }
          }
        }
        .option-item + .option-item {
          margin-top: 6px;
        }
      }
    }
    .nft-name {
      z-index: 3;
      bottom: 20px;
      left: 20px;
      max-width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      position: absolute;
      font-family: "Unbounded";
      font-size: 16px;
      font-weight: 700;
      letter-spacing: -0.32px;
      @media (max-width: 768px) {
        left: 15px;
        bottom: 15px;
        font-size: 12px;
      }
    }
    &:before {
      position: absolute;
      bottom: 0;
      content: "";
      display: block;
      height: 50%;
      width: 100%;
      left: 0;
      z-index: 2;
      background: linear-gradient(0deg, #1F1D51 0%, rgba(40, 37, 91, 0.00) 100%);
      border-bottom-right-radius: 24px;
      border-bottom-left-radius: 24px;
    }
  }
</style>