<script>
import UiInput from "@/components/Ui/UiInput.vue";
import AccentBtn from "@/components/Ui/Buttons/AccentBtn.vue";
import UiClose from "@/components/Ui/UiClose.vue";
import {mapMutations} from "vuex";
import TwoFaTimer from "@/components/Modals/TwoFa/TwoFaTimer.vue";
import SuccessBadge from "@/components/SuccessBadge.vue";

export default {
  data () {
    return {
      isShowSuccess: false,
    }
  },
  components: {SuccessBadge, TwoFaTimer, UiClose, AccentBtn, UiInput},
  props: {
    depositInstance: {
      type: Object,
    }
  },
  methods: {
    ...mapMutations({
      setIsOpenCancelDepositModal: 'config/setIsOpenCancelDepositModal'
    }),
    copy () {
      navigator.clipboard.writeText(this.depositInstance.meta.address);

      this.$root.$emit('success', {
        title: "Success",
        text: this.$t('d_copied_to_clp')
      })
    }
  },
  mounted() {
    this.$echo.private(`deposit-changed.${this.depositInstance.id}`).listen(".DepositChangedStatusEvent", async (payload) => {
      if (payload.status === 'success') {
        this.$root.$emit('success', {
          title: "Success Deposit!",
          text: this.$t('d_success_dep')
        })

        this.isShowSuccess = true;
        return;
      }

      this.$emit('update');
    })
  },
  name: "PayForm"
}
</script>

<template>
  <div class="pay-form">
    <template v-if="!isShowSuccess">
      <div class="pay-form__timer" v-if="depositInstance.meta.expiry_at">
        <TwoFaTimer :seconds="depositInstance.meta.expiry_at - Math.ceil((new Date().getTime() / 1000))" />
      </div>
      <div class="pay-form__qr">
        <UiClose @click="setIsOpenCancelDepositModal(true)" />
        <img :src="depositInstance.meta.qrcode" alt="">
      </div>

      <div class="pay-form__address">
        <UiInput ref="input" readonly :value="depositInstance.meta.address" :icon="require('@/assets/images/deposit/wallet.svg')" />
        <AccentBtn @click="copy">
          <img src="@/assets/images/copy.svg" alt="">
        </AccentBtn>
      </div>

      <div class="pay-form__notice">
        <span>{{ $t('d_sent_to_network') }} <em>{{ depositInstance.meta.network }}</em></span>
        <strong>
          {{ depositInstance.meta.amount }} {{ depositInstance.meta.currency }}
        </strong>
      </div>

      <div class="pay-form__warning">
        <img src="@/assets/images/danger.svg" alt="">
        <span>
          {{ $t('d_sent_only', {currency: depositInstance.meta.currency}) }}</span>
      </div>
    </template>
    <SuccessBadge v-else @close="$emit('update')" :text="`${$t('d_success_badge')} ${parseFloat(depositInstance.fiat_amount).toFixed(2)}`" />
  </div>
</template>

<style scoped lang="scss">
  .pay-form {
    position: relative;
    padding-top: 10px;
    .pay-form__timer {
      position: absolute;
      top: 0;
      left: 0;
      padding: 5px 10px;
      background: #9D42F8;
      width: fit-content;
      border-radius: 10px;
      padding-top: 6px;
    }
    .pay-form__qr {
      margin: 0 auto;
      width: 130px;
      height: 130px;
      border-radius: 20px;
      border: 4px solid #9D42F8;
      padding: 10px;
      img {
        width: 100%;
        height: 100%;
      }
      background: #1D1B41;
    }
    .pay-form__address {
      margin-top: 20px;
      display: flex;
      align-items: center;
      position: relative;
      overflow: hidden;
      .ui-input {
        width: 100%;
      }
      .accent-btn {
        position: absolute;
        height: 40px;
        right: 0;
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        top: calc(50% - 20px);
        box-shadow: -20px 0px 29px 16px #1D1B41;
        &:hover {
          opacity: 1;
        }

        &:focus {
          opacity: 0.8;
        }
      }
    }
    .pay-form__notice {
      margin-top: 14px;
      span {
        display: block;
        width: 100%;
        color: var(--700, #7F7DA6);
        font-family: "Geometria-Medium";
        font-size: 12px;
        font-style: normal;
        text-align: center;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.24px;
        em {
          font-size: 12px;
          font-style: normal;
          text-align: center;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.24px;
          color: #7F00FF;
          text-transform: uppercase;
          font-family: "Geometria-Bold";
          font-weight: bold;
        }
      }
      strong {
        padding: 5px 10px;
        display: block;
        font-weight: bold;
        font-family: "Geometria-Bold";
        background: #7F00FF;
        width: fit-content;
        margin: 0 auto;
        margin-top: 15px;
        border-radius: 10px;
      }
    }
    .pay-form__warning {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 12px;
      border-radius: 10px;
      margin-top: 30px;
      background: var(--6-danger, rgba(255, 103, 103, 0.06));
      img {
        margin-right: 12px;
        width: 20px;
        min-width: 20px;
      }
      span {
        font-size: 12px;
        font-family: "Geometria-Medium";
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.24px;
      }
    }
  }
</style>