<script>
import CoinPlaceholder from "@/components/Ui/CoinPlaceholder.vue";
import formatNumber from "@/helpers/formatNumber";
import {timeAgo} from "@/helpers/timeAgo";
export default {
  data () {
    return {
      timeAgo,
      formatNumber
    }
  },
  props: {
    item: {
      type: Object,
      required: true,
    }
  },
  name: "HistoryItem",
  components: {CoinPlaceholder}
}
</script>

<template>
  <div class="history-item">
    <div class="game">
      <div class="game-img">
        <img :src="item.game.preview_image" alt="">
      </div>
      <div class="game-name">
        {{ item.game.name }}
      </div>
    </div>
    <div class="time">
      <img src="@/assets/images/clock-icon.svg" alt="">
      <span>{{ item.created_at_human }}</span>
    </div>
    <div class="amount">
      <CoinPlaceholder symbol="$" />
      <span>
        {{ formatNumber(item.amount).integer }}
        <em>
          .{{ formatNumber(item.amount).decimal }}
        </em>
      </span>
    </div>
    <div class="wager" :class="{'red': item.type === 'bet'}">
      <template v-if="item.type === 'bet'">
        {{ $t('gm.bet') }}
      </template>
      <template v-else-if="item.type === 'win'">{{ $t('gm.win') }}</template>
    </div>
    <div class="payout">
      <CoinPlaceholder :symbol="item.currency.symbol" />
      <div class="payout-dir plus" v-if="item.type === 'win'">+</div>
      <div class="payout-dir minus" v-if="item.type === 'bet'">-</div>
      <div class="payout-value">
        {{ formatNumber(item.amount).integer }}
        <em>
          .{{ formatNumber(item.amount).decimal }}
        </em>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .history-item {
    display: flex;
    align-items: center;
    padding: 7px;
    border-radius: 15px;
    background: #0C0A33;

    .game {
      width: 30%;
      overflow: hidden;
      display: flex;
      align-items: center;
      @media (max-width: 800px) {
        width: 50%;
      }
      .game-img {
        img {
          width: 100%;
        }
        width: 38px;
        overflow: hidden;
        min-width: 38px;
        height: 38px;
        margin-right: 12px;
        border-radius: 10px;
      }
      .game-name {
        flex-grow: 1;
        padding-right: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #B9B8DD;
        font-family: "Geometria-Bold";
        font-size: 14px;
        font-weight: 700;
        letter-spacing: -0.28px;
        padding-top: 2px;
        @media (max-width: 768px) {
          font-size: 12px;
        }
      }
    }
    .time {
      display: flex;
      align-items: center;

      span {
        margin-left: 4px;
        color: #918FBB;
        text-align: justify;
        font-family: "Geometria-Medium";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.28px;
        padding-top: 3px;
      }
      width: 15%;
      @media (max-width: 800px) {
        display: none;
      }
    }
    .amount {
      width: 15%;
      display: flex;
      align-items: center;
      @media (max-width: 800px) {
        display: none;
      }
      & > span {
        margin-left: 4px;
        font-family: "Geometria-Medium";
        font-size: 14px;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.28px;
        padding-top: 3px;
        display: flex;
        em {
          font-family: "Geometria-Medium";
          font-size: 14px;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.28px;
          color: #918FBB;
          font-style: normal;
        }
      }
    }
    .wager {
      @media (max-width: 800px) {
        display: none;
      }
      width: 15%;
      color: forestgreen;
      font-family: "Geometria-Bold";
      padding-top: 2px;
      &.red {
        color: #ff6060;
      }
    }
    .payout {
      width: 25%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 18px;
      @media (max-width: 800px) {
        width: 50%;
      }
      .payout-dir {
        margin: 4px;
        padding-top: 1px;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        font-family: "Geometria-Medium";
        line-height: normal;
        letter-spacing: -0.28px;
        &.plus {
          color: #BBFF92;
        }
        &.minus {
          color: indianred;
        }
      }
      .payout-value {
        font-size: 14px;
        display: flex;
        font-family: "Geometria-Medium";
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.28px;
        padding-top: 1px;
        em {
          font-size: 14px;
          font-family: "Geometria-Medium";
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.28px;
          font-style: normal;
          color: #918FBB
        }
      }
    }
  }
</style>