import { render, staticRenderFns } from "./ProfileGameHistories.vue?vue&type=template&id=9a7f522c&scoped=true"
import script from "./ProfileGameHistories.vue?vue&type=script&lang=js"
export * from "./ProfileGameHistories.vue?vue&type=script&lang=js"
import style0 from "./ProfileGameHistories.vue?vue&type=style&index=0&id=9a7f522c&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9a7f522c",
  null
  
)

export default component.exports