<script>
import GameProviderItem from "@/components/Main/GameProviderItem.vue";
import { getProviders } from "@/services/games";

export default {
  components: {GameProviderItem},
  data () {
    return {
      providers: []
    }
  },
  methods: {
    fetchProviders() {
      getProviders().then(response => {
        this.providers = response.data;
      })
    }
  },
  mounted() {
    this.fetchProviders()
  },
  name: "GamesProvidersView"
}
</script>

<template>
  <div class="games-providers">

    <router-link to="/games" class="back default-hover">
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.25 4.5L6.75 9L11.25 13.5" stroke="#7F7DA6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      {{ $t('back') }}
    </router-link>

    <h1>{{ $t('all_providers') }}</h1>

    <div class="providers">
      <GameProviderItem :showCount="true" v-for="(provider, i) in providers" :key="i" :provider="provider" />
    </div>
  </div>
</template>

<style scoped lang="scss">
  .games-providers {
    padding: 10px 0;
    min-height: 70vh;
    padding-bottom: 30px;
    .back {
      margin-bottom: 26px;
      display: flex;
      width: fit-content;
      align-items: center;
      svg {
        margin-right: 8px;
      }
      border-radius: 14px;
      background: #1D1B41;
      padding: 14px 16px;
      font-family: "Unbounded";
      font-size: 12px;
      font-weight: 700;
      letter-spacing: -0.24px;
    }
    h1 {
      font-family: "Unbounded";
      font-size: 46px;
      font-weight: 700;
      line-height: 50px; /* 108.696% */
      letter-spacing: -0.92px;
      display: block;
      width: 100%;
      @media (max-width: 575px) {
        font-size: 18px;
        line-height: 20px;
      }
    }
    .providers {
      margin-top: 18px;
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-gap: 16px;
      @media (max-width: 992px) {
        grid-template-columns: repeat(5, 1fr);
      }
      @media (max-width: 768px) {
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 8px;
      }
      @media (max-width: 575px) {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
</style>