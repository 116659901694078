<script>
import AccentBtn from "@/components/Ui/Buttons/AccentBtn.vue";
import YellowBtn from "@/components/Ui/Buttons/YellowBtn.vue";
import UiClose from "@/components/Ui/UiClose.vue";
import {mapGetters, mapMutations} from "vuex";
import {setNftAsAvatar} from "@/services/user";
import {getNfts} from "@/services/user";
import extractErrors from "@/helpers/extractErrors";
import {vue} from "@/main";
export default {
  data () {
    return {
      isLoading: false,
      select: null,
      nfts: []
    }
  },
  computed: {
    getSelect() {
      if (!this.select) {
        const index = this.nfts.findIndex(item => item.image_url === this.user.avatar);
        if (index > -1) {
          return this.nfts[index]
        }
      }

      return this.select;
    },
    ...mapGetters({
      user: 'user/user'
    })
  },
  methods: {
    close() {
      this.setOpenSetAvatar(false)
    },
    ...mapMutations({
      updateAvatar: "user/updateAvatar",
      setOpenSetAvatar: 'config/setOpenSetAvatar'
    }),
    async setAvatar () {
      if (!this.select) return;

      this.isLoading = true;
      await setNftAsAvatar(this.select.id).then(() => {
        this.$root.$emit('success', {
          title: "Success",
          text: this.$t('a_success')
        })
        this.updateAvatar(this.select.image_url)
        this.setOpenSetAvatar(false);
      }).catch(err => {
        const errors = extractErrors(err);
        errors.forEach(item => {
          vue.$root.$emit('error', {
            title: 'Error',
            text: item
          })
        })
      }).finally(() => {
        this.isLoading = false;
      })
    },
    fetchNfts () {
      getNfts().then(response => {
        this.nfts = response.data.data;
      })
    }
  },
  mounted() {
    this.fetchNfts()
  },
  name: "SetAvatarModal",
  components: {UiClose, YellowBtn, AccentBtn}
}
</script>

<template>
  <div class="avatars">

    <UiClose @click="setOpenSetAvatar(false)" />

    <div class="head">
      <div class="circle">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M17.7545 13.9999C18.9626 13.9999 19.9482 14.9525 20.0012 16.1475L20.0004 16.25L12.0039 16.2499V17.249L19.9791 17.2502C19.9184 17.7818 19.7446 18.2935 19.4705 18.7512L12.0039 18.749V19.7499L18.6425 19.751C17.0816 21.2569 14.8527 22.0011 12.0004 22.0011C8.8545 22.0011 6.46849 21.0959 4.90219 19.2617C4.32242 18.5827 4.00391 17.7193 4.00391 16.8265V16.2488C4.00391 15.0068 5.01076 13.9999 6.25278 13.9999H17.7545ZM12.0004 2C13.6361 2 15.0884 2.78547 16.0006 3.99981L12.0034 4L12.0029 4.999L16.5845 5.00046C16.7885 5.46754 16.9233 5.97182 16.9757 6.5003L12.0029 6.499V7.499L16.9757 7.5007C16.9231 8.02919 16.7882 8.53347 16.5841 9.00053L12.0029 8.999L12.0034 10L15.9998 10.0012C15.0876 11.215 13.6356 12 12.0004 12C9.23894 12 7.00036 9.76142 7.00036 7C7.00036 4.23858 9.23894 2 12.0004 2Z" fill="#C489FF"/>
        </svg>
      </div>
      <div class="text">
        <strong>{{ $t('a_title') }}</strong>
        <span>{{ $t('a_desc') }}</span>
      </div>
    </div>

    <div class="items">
      <div class="list">
        <div @click="select = nft" :class="{'select': getSelect === nft}" class="avatar" v-for="nft in nfts" :key="nft.id">
          <img :src="nft.image_url" alt="">
        </div>
      </div>

      <div class="actions">
        <AccentBtn :is-loading="isLoading" @click="setAvatar" class="save" :title="$t('a_save')" />
        <YellowBtn class="go-to" :title="$t('a_more')" />
      </div>
    </div>

  </div>
</template>

<style scoped lang="scss">
  .avatars {
    position: relative;
    border-radius: 20px;
    background: #131139;
    width: 496px;
    padding: 20px;
    padding-bottom: 0;
    box-shadow: 0px 105px 153.7px 0px rgba(1, 0, 25, 0.55);
    .head {
      display: flex;
      align-items: center;
      .circle {
        width: 48px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        margin-right: 14px;
        background: rgba(182, 127, 255, 0.10);
      }
      .text {
        strong {
          font-family: "Unbounded";
          font-size: 18px;
          font-weight: 700;
          letter-spacing: -0.36px;
          display: block;
          width: 100%;
          margin-bottom: 6px;
        }
        span {
          color: #918FBB;
          font-family: "Geometria-Medium";
          font-size: 14px;
          font-weight: 500;
          letter-spacing: -0.28px;
        }
      }
    }
    .items {
      margin-top: 20px;
      position: relative;
      .list {
        overflow-y: auto;
        height: 400px;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 5px;
        align-items: flex-start;
        @media (max-width: 575px) {
          grid-template-columns: repeat(3, 1fr);
          padding-bottom: 100px;
        }
        @media (max-width: 450px) {
          grid-template-columns: repeat(2, 1fr);
        }
        &::-webkit-scrollbar {
          display: none;
        }
        .avatar {
          cursor: pointer;
          height: 108px;
          border-radius: 16px;
          overflow: hidden;
          background: $color-gray-light;
          padding: 4px;
          border: 2px solid #131139;

          &.select {
            border: 4px solid #916DF6;
          }
          img {
            width: 100%;
            height: 100%;
          }
          &:after {
            content: "";
            display: block;
            padding-bottom: 100%;
          }

          @media (max-width: 575px) {
            height: auto;
            position: relative;
            overflow: unset;
            &::after {
              content: "";
              display: block;
              padding-bottom: 100%;
            }
            img {
              position: absolute;
              top: 4px;
              left: 4px;
              width: calc(100% - 8px);
              height: calc(100% - 8px);
            }
          }
        }
      }
      .actions {
        z-index: 2;
        width: 100%;
        position: absolute;
        bottom: 20px;
        display: flex;
        justify-content: space-between;
        button {
          width: fit-content;
          padding-right: 24px;
          padding-left: 24px;
        }
      }

      position: relative;
      &:after {
        content: "";
        height: 100px;
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        display: block;
        background: linear-gradient(0deg, #131139 0%, rgba(19, 17, 57, 0.00) 100%);
      }
    }
  }
</style>