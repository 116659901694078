<script>
import {mapGetters} from "vuex";

export default {
  data () {
    return {
      openLevel: null,
    }
  },
  props: {
    level: {
      type: Object,
    }
  },
  computed: {
    isTopLevel() {
      return this.user && this.user?.level.level === 6;
    },
    isCurrent() {
      if (!this.user) return false;

      return this.user?.level?.level === this.level.level;
    },
    ...mapGetters({
      user: 'user/user'
    }),
  },
  name: "RankItem"
}
</script>

<template>
  <div class="rank" @click="openLevel = !openLevel" :class="{open: openLevel, top: level?.level === 6}">
    <div class="rank-current" v-if="isCurrent">
      <img src="@/assets/images/current-level.svg" alt="">
      {{ $t('ll_current_2') }}
    </div>
    <div class="rank-image">
      <img :src="level.img" alt="">
    </div>
    <div class="rank-body">
      <div class="rank-body__progress">
        <div class="rank-body__heading">
          <div class="rank-body__heading-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M4.5 0.5C2.29086 0.5 0.5 2.29086 0.5 4.5V11.5C0.5 13.7091 2.29086 15.5 4.5 15.5H11.5C13.7091 15.5 15.5 13.7091 15.5 11.5V4.5C15.5 2.29086 13.7091 0.5 11.5 0.5H4.5ZM8.75 4.25C8.75 3.83579 8.41421 3.5 8 3.5C7.58579 3.5 7.25 3.83579 7.25 4.25V11.75C7.25 12.1642 7.58579 12.5 8 12.5C8.41421 12.5 8.75 12.1642 8.75 11.75V4.25ZM11.75 5C12.1642 5 12.5 5.33579 12.5 5.75V10.25C12.5 10.6642 12.1642 11 11.75 11C11.3358 11 11 10.6642 11 10.25V5.75C11 5.33579 11.3358 5 11.75 5ZM5 5.75C5 5.33579 4.66421 5 4.25 5C3.83579 5 3.5 5.33579 3.5 5.75V10.25C3.5 10.6642 3.83579 11 4.25 11C4.66421 11 5 10.6642 5 10.25V5.75Z" fill="white"/>
            </svg>
          </div>
          <div class="rank-body__heading-name">
            {{ level.name }}
          </div>
          <div class="rank-body__heading-current" v-if="isCurrent">
            <img src="@/assets/images/current-level.svg" alt="">
            {{ $t('ll_current_2') }}
          </div>
        </div>
        <div class="rank-body__progressing">
          <div class="rank-body__progressing-line">
            <div class="rank-body__progressing-line-fill" :style="{'width': level.level < user?.level?.level ? '100%' : isCurrent ? (user.xp_balance / level.to) * 100 + '%' : '1%'}"></div>
          </div>
          <div class="rank-body__progressing-labels">
            <div class="rank-body__progressing-label">
              <div class="rank-body__progressing-label__quick">
                <img src="@/assets/images/quick.svg" alt="">
              </div>
              <span v-if="isCurrent">{{ user.xp_balance }} <em>EXP</em></span>
              <span v-else>{{ level.from }} <em>EXP</em></span>
            </div>
            <div class="rank-body__progressing-label" v-if="level.level !== 6">
              <div class="rank-body__progressing-label__quick">
                <img src="@/assets/images/quick.svg" alt="">
              </div>
              <span>{{ level.to }} <em>EXP</em></span>
              <div class="rank-body__progressing-label__left" v-if="isCurrent">
                {{ level.to - user.xp_balance }} {{ $t('ll_left') }}
              </div>
            </div>
          </div>
        </div>
        <div class="rank-body__content">
          <div class="rank-body__content-title">
            {{ $t('ll_given') }}
          </div>
          <div class="rank-body__content-items">
            <div class="rank-body__content-item" v-for="(item,i) in level.items" :key="i">
              <img src="@/assets/images/diamond-icon.svg" alt="">
              {{ item }}
            </div>
          </div>
        </div>
      </div>
      <div class="rank-body__arrow">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path d="M12 10L8 6L4 10" stroke="#7F7DA6" stroke-width="1.77778" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
 .rank {
   border-radius: 18px;
   background: var(--50-Dark, rgba(0, 3, 42, 0.50));
   padding: 16px;
   position: relative;
   .rank-current {
     position: absolute;
     top: 4px;
     right: 4px;
     border-radius: 8px;
     background: var(--yellow-20, rgba(255, 226, 79, 0.27));
     padding: 6px 8px;
     font-family: "Geometria-Medium";
     font-size: 11px;
     font-style: normal;
     font-weight: 500;
     line-height: normal;
     letter-spacing: -0.22px;
     display: flex;
     align-items: center;
     img {
       margin-bottom: 2px;
       margin-right: 4px;
     }
   }
   .rank-image {
     height: 75px;
     display: flex;
     margin-bottom: 9px;
     justify-content: center;
     align-items: center;
     img {
       max-height: 55px;
     }
   }
   .rank-body {
     .rank-body__progress {
       .rank-body__heading {
         display: flex;
         align-items: center;
         .rank-body__heading-icon {
           width: 15px;
           height: 15px;
           margin-right: 7.5px;
           display: flex;
           align-items: center;
           justify-content: center;
         }
         .rank-body__heading-name {
           color: var(--900, #B9B8DD);
           font-family: "Geometria-Bold";
           font-size: 12px;
           font-style: normal;
           font-weight: 700;
           line-height: 12px;
           padding-top: 2px;
           letter-spacing: 0.6px;
         }
         .rank-body__heading-current {
           display: none;
           border-radius: 8px;
           background: var(--yellow-20, rgba(255, 226, 79, 0.27));
           font-family: "Geometria-Medium";
           padding: 3px 5px;
           margin-left: 8px;
           img {
             margin-right: 4px;
             width: 12px;
             margin-bottom: 2px;
           }

           padding-right: 7px;
           font-size: 12px;
           font-style: normal;
           color: #fff;
           font-weight: 500;
           line-height: normal;
           letter-spacing: -0.22px;
           @media (max-width: 768px) {
             display: flex;
           }
         }
       }
       .rank-body__progressing {
         margin-top: 8px;
         margin-bottom: 8px;
         .rank-body__progressing-line {
           height: 6px;
           margin-bottom: 5px;
           background: var(--10-purple, rgba(182, 127, 255, 0.10));
           border-radius: 36px;
           overflow: hidden;
           .rank-body__progressing-line-fill {
             height: 100%;
             border-radius: 36px;
             background: var(--Purple, #AB6BFF);
           }
         }
         .rank-body__progressing-labels {
           display: flex;
           justify-content: space-between;
           align-items: center;
           .rank-body__progressing-label {
             display: flex;
             align-items: center;
             .rank-body__progressing-label__left {
               font-size: 11px;
               font-family: "Geometria-Medium";
               font-style: normal;
               font-weight: 500;
               line-height: normal;
               letter-spacing: -0.22px;
               border-radius: 7px;
               background: var(--20-purple, rgba(171, 107, 255, 0.20));
               padding: 3px 5px;
               margin-left: 4px;
               padding-top: 4px;
               margin-top: -4px;
             }
             .rank-body__progressing-label__quick {
               display: flex;
               align-items: center;
               margin-right: 1px;
             }
             span {
               font-size: 11px;
               font-family: "Geometria-Medium";
               font-style: normal;
               font-weight: 500;
               line-height: normal;
               padding-bottom: 3px;
               letter-spacing: -0.22px;
               em {
                 font-size: 11px;
                 font-family: "Geometria-Medium";
                 font-style: normal;
                 font-weight: 500;
                 line-height: normal;
                 letter-spacing: -0.22px;
                 color: #918FBB
               }
             }
           }
         }
       }
       .rank-body__content {
         .rank-body__content-title {
           margin: 8px 0;
           color: var(--800, #918FBB);
           font-size: 12px;
           opacity: 0.8;
           font-style: normal;
           font-family: "Geometria-Medium";
           font-weight: 500;
           line-height: normal;
           letter-spacing: -0.24px;
         }
         .rank-body__content-items {
           .rank-body__content-item {
             display: flex;
             align-items: center;
             img {
               margin-right: 8px;
               width: 15px;
               height: 15px;
             }
             font-family: "Geometria-Medium";
             font-size: 12px;
             font-style: normal;
             font-weight: 500;
             line-height: 14px;
             letter-spacing: -0.24px;
             opacity: 0.8;
           }
           .rank-body__content-item + .rank-body__content-item {
             margin-top: 8px;
           }
         }
       }
     }
   }
  transition: 0.1s;
   &.top {
     background: linear-gradient(180deg, rgba(80, 0, 160, 0.50) 0%, rgba(80, 0, 160, 0.00) 100%);
     .rank-image {
       img {
         height: 100%;
         max-height: 100%;
       }
     }
   }
  .rank-body__arrow {
    display: none;
    transform: rotate(180deg);
    transition: 0.2s;
  }

   @media (max-width: 768px) {
    .rank-body__arrow {
      display: block;
      position: absolute;
      right: 10px;
      top: 10px;
    }
     padding: 0;
     display: flex;
     border-radius: 11px;
     background: var(--10, rgba(200, 197, 255, 0.10));
     padding-top: 4px;
     &.top {
       background: linear-gradient(90deg, rgba(80, 0, 160, 0.50) 10.22%, rgba(80, 0, 160, 0.00) 100%), var(--10, rgba(200, 197, 255, 0.10));
     }

     .rank-current {
       display: none;
     }
     .rank-image {
       min-width: 70px;
       margin: 0;
       margin-left: 5px;
       margin-right: 10px;
       height: 70px;
       img {
         max-height: 45px;
       }
     }
     .rank-body {
       flex-grow: 1;
       padding-right: 33px;
       display: flex;
       align-items: center;
       width: 100%;
       .rank-body__content {
         display: none;
       }
       .rank-body__progress {
         width: 100%;
         .rank-body__progressing {
           margin-top: 6px;
           margin-bottom: 0;
         }
       }
     }
     &.open {
       align-items: flex-start;

       background: var(--50-Dark, rgba(0, 3, 42, 0.50));
       .rank-body__content {
         display: block;

       }
       .rank-body {
         padding-top: 7px;
         padding-bottom: 12px;
       }

       .rank-body__arrow {
         transform: rotate(0deg);
       }
     }
   }
 }
</style>