<script>
export default {
  name: "HavePromo"
}
</script>

<template>
  <div class="have-promo">
    <span>{{ $t('auth_modal.have_promo') }}</span>
    <button class="default-hover">
      {{ $t('auth_modal.enter_promocode') }}
      <img src="@/assets/images/auth/recover-icon.svg" alt="">
    </button>
  </div>
</template>

<style scoped lang="scss">
.have-promo {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 18px 0;
  justify-content: center;
  span {
    color: #918FBB;
    font-size: 14px;
    font-family: "Geometria-Bold";
    letter-spacing: -0.28px;
  }
  button {
    background: transparent;
    color: #23A3FF;
    font-size: 14px;
    display: flex;
    align-items: center;
    margin-left: 11px;
    img {
      margin-left: 11px;
    }
    font-family: "Geometria-Bold";
    letter-spacing: -0.28px;
  }
}
</style>