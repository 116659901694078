<script>
import CoinPlaceholder from "@/components/Ui/CoinPlaceholder.vue";

export default {
  components: {CoinPlaceholder},
  props: {
    bet: {
      type: Object,
    }
  },
  name: "BetItem"
}
</script>

<template>
  <div class="bet">
    <div class="game">
      <div class="game-icon">
        <img :src="bet.game.image" alt="">
      </div>
      <div class="game-name">
        {{ bet.game.name }}
      </div>
    </div>
    <div class="user">
      <div class="user-icon">
        <img :src="bet.user.avatar" alt="">
      </div>
      <div class="user-name">
        @{{ bet.user.name }}
      </div>
    </div>
    <div class="time">
      <div class="time-icon">
        <img src="@/assets/images/clock-icon.svg" alt="">
      </div>
      <div class="time-value">
        {{ bet.date }}
      </div>
    </div>
    <div class="wager">
      <div class="wager-icon">
        <CoinPlaceholder />
      </div>
      <div class="wager-value">
        {{ parseFloat(bet.bet).toFixed(2) }}
      </div>
    </div>
    <div class="multiplier">
      <div class="multiplier-icon">
        <img src="@/assets/images/bet/multiplier-icon.svg" alt="">
      </div>
      <div class="multiplier-value">
        {{ parseFloat(bet.win / bet.bet).toFixed(2) }}
      </div>
    </div>
    <div class="payout">
      <div class="payout-icon">
        <CoinPlaceholder />
      </div>
      <div class="payout-dir">+</div>
      <div class="payout-name">
        {{ parseFloat(bet.win).toFixed(2) }}
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .bet {
    border-radius: 15px;
    background: #0C0A33;
    padding: 7px;
    display: flex;
    .game {
      width: 20%;
      display: flex;
      align-items: center;
      @media (max-width: 768px) {
        width: 40%;
      }
      .game-icon {
        width: 38px;
        height: 38px;
        border-radius: 10px;
        overflow: hidden;
        margin-right: 12px;
        @media (max-width: 768px) {
          width: 24px;
          height: 24px;
          min-width: 24px;
        }
        img {
          width: 100%;
          height: 100%;
        }
      }
      .game-name {
        color: #B9B8DD;
        font-family: Geometria;
        font-family: "Geometria-Bold";
        font-size: 14px;
        font-weight: 700;
        letter-spacing: -0.28px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        @media (max-width: 768px) {
          font-size: 12px;
          padding-top: 2px;
          padding-right: 10px;
        }
      }
    }
    .user {
      width: 20%;
      display: flex;
      align-items: center;
      @media (max-width: 768px) {
        width: 33.33%;
      }
      .user-icon {
        width: 18px;
        height: 18px;
        overflow: hidden;
        border-radius: 100%;
        margin-right: 4px;
        min-width: 18px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .user-name {
        color: #918FBB;
        font-size: 14px;
        font-family: "Geometria-Medium";
        font-weight: 500;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        letter-spacing: -0.28px;
        @media (max-width: 768px) {
          font-size: 12px;
          padding-right: 10px;
        }
      }
    }
    .time {
      width: 20%;
      display: flex;
      align-items: center;
      @media (max-width: 768px) {
        display: none;
      }
      .time-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 18px;
        height: 18px;
        margin-right: 4px;
      }
      .time-value {
        color: #918FBB;
        font-family: "Geometria-Medium";
        font-size: 14px;
        font-weight: 500;
        letter-spacing: -0.28px;
      }
    }
    .wager {
      width: 10%;
      display: flex;
      align-items: center;
      @media (max-width: 768px) {
        display: none;
      }
      .wager-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 18px;
        height: 18px;
        img {
          width: 100%;
          height: 100%;
        }

        margin-right: 4px;
      }
      .wager-value {
        display: flex;
        align-items: center;
        font-family: "Geometria-Medium";
        font-size: 14px;
        line-height: 14px;
        font-weight: 500;
        letter-spacing: -0.28px;
      }
    }
    .multiplier {
      display: flex;
      align-items: center;
      width: 10%;
      @media (max-width: 768px) {
        display: none;
      }
      .multiplier-icon {
        width: 18px;
        height: 18px;
        img {
          width: 100%;
          height: 100%;
        }

        margin-right: 4px;
      }
      .multiplier-value {
        color: #918FBB;
        line-height: 14px;
        display: flex;
        align-items: center;
        padding-top: 2px;
        font-size: 14px;
        font-family: "Geometria-Medium";
        font-weight: 500;
        letter-spacing: -0.28px;
      }
    }
    .payout {
      width: 20%;
      justify-content: flex-end;
      padding-right: 20px;
      display: flex;
      align-items: center;
      @media (max-width: 768px) {
        width: 27%;
      }
      .payout-icon {
        width: 18px;
        height: 18px;
        display: flex;
        align-items: center;
        @media (max-width: 768px) {
          width: 14px;
          height: 14px;
          margin-right: 5px;
        }
        img {
          width: 100%;
          height: 100%;
        }
      }
      .payout-dir {
        margin: 0 4px;
        display: flex;
        align-items: center;
        color: #BBFF92;
        font-size: 14px;
        font-family: "Geometria-Medium";
        font-weight: 500;
        letter-spacing: -0.28px;
        @media (max-width: 768px) {
          font-size: 12px;
          padding-top: 3px;
        }
      }
      .payout-name {
        font-family: "Geometria-Medium";
        font-size: 14px;
        font-weight: 500;
        letter-spacing: -0.28px;
        @media (max-width: 768px) {
          font-size: 12px;
          padding-top: 2px;
        }
      }
    }
  }
</style>