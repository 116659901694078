<script>
import UiClose from "@/components/Ui/UiClose.vue";
import LanguageSwitcher from "@/components/Parts/LanguageSwitcher.vue";
import AccentBtn from "@/components/Ui/Buttons/AccentBtn.vue";
import {mapMutations} from "vuex";
export default {
  name: "SettingsModal",
  methods: {
    click() {
      this.$refs.switcher.isOpenList = !this.$refs.switcher.isOpenList;
    },
    ...mapMutations({
      setIsShowSettings: 'config/setIsShowSettings'
    }),
    close() {
      this.setIsShowSettings(false);
    }
  },
  components: {AccentBtn, UiClose, LanguageSwitcher}
}
</script>

<template>
  <div class="settings-modal">
    <UiClose @click="setIsShowSettings(false)" />
    <div class="settings-modal__head">
      <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.0127 2.625C14.869 2.63487 15.722 2.73381 16.5578 2.92021C16.9227 3.00158 17.1956 3.30576 17.237 3.67729L17.4356 5.45861C17.5254 6.2765 18.216 6.89598 19.0393 6.89684C19.2605 6.89719 19.4794 6.85111 19.6839 6.76064L21.318 6.04282C21.6578 5.89352 22.0549 5.97492 22.3086 6.24589C23.4895 7.50708 24.369 9.01967 24.8808 10.6698C24.9912 11.0257 24.8642 11.4124 24.5643 11.6334L23.1159 12.701C22.7027 13.0046 22.4587 13.4867 22.4587 13.9994C22.4587 14.5121 22.7027 14.9941 23.1168 15.2984L24.5664 16.3664C24.8664 16.5874 24.9936 16.9741 24.8832 17.33C24.3715 18.9799 23.4925 20.4924 22.3123 21.7538C22.0589 22.0247 21.6621 22.1063 21.3223 21.9574L19.6815 21.2386C19.2122 21.0332 18.6731 21.0633 18.2295 21.3196C17.7859 21.576 17.4907 22.0281 17.4343 22.5374L17.2371 24.3185C17.1964 24.6858 16.9294 24.9879 16.5699 25.0734C14.88 25.4755 13.1194 25.4755 11.4296 25.0734C11.0701 24.9879 10.8031 24.6858 10.7624 24.3185L10.5655 22.54C10.5076 22.0317 10.2119 21.581 9.7687 21.3255C9.32546 21.07 8.78727 21.04 8.31944 21.2443L6.67837 21.9633C6.33846 22.1122 5.94158 22.0305 5.68811 21.7594C4.50726 20.4966 3.62826 18.9823 3.11727 17.3306C3.00721 16.9748 3.13437 16.5884 3.43419 16.3675L4.88474 15.2989C5.29791 14.9953 5.54192 14.5132 5.54192 14.0005C5.54192 13.4878 5.29791 13.0058 4.8842 12.7018L3.43456 11.635C3.1343 11.414 3.00701 11.0271 3.11746 10.671C3.62934 9.02084 4.5088 7.50825 5.68968 6.24705C5.94339 5.97609 6.34046 5.89469 6.68032 6.04399L8.31409 6.76168C8.78419 6.96798 9.32464 6.93682 9.77027 6.67647C10.214 6.4191 10.5094 5.96659 10.5664 5.45725L10.7648 3.67729C10.8063 3.30557 11.0794 3.0013 11.4445 2.9201C12.2813 2.73399 13.1351 2.6351 14.0127 2.625ZM13.9982 10.4999C12.0652 10.4999 10.4982 12.0669 10.4982 13.9999C10.4982 15.9329 12.0652 17.4999 13.9982 17.4999C15.9312 17.4999 17.4982 15.9329 17.4982 13.9999C17.4982 12.0669 15.9312 10.4999 13.9982 10.4999Z" fill="#FFE70C"/>
      </svg>
      {{ $t('ss_title') }}
    </div>
    <div class="settings-modal__subtitle">
      {{ $t('ss_desc') }}
    </div>

    <div class="settings-modal__language" @click.prevent.stop="click">
      <div class="settings-modal__language-label">
        {{ $t('ss_language') }}
      </div>
      <LanguageSwitcher ref="switcher"></LanguageSwitcher>
    </div>

    <AccentBtn @click="setIsShowSettings(false)" :title="$t('close')" />

  </div>
</template>

<style scoped lang="scss">
.settings-modal {
  width: 330px;
  position: relative;
  border-radius: 20px;
  background: var(--200, #131139);
  box-shadow: 0px 105px 153.7px 0px rgba(1, 0, 25, 0.55);
  padding: 16px;
  .settings-modal__language {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    cursor: pointer;
    margin-bottom: 13px;
    background: var(--300, #1D1B41);
    padding-left: 12px;
    .settings-modal__language-label {
      color: var(--900, #B9B8DD);
      font-family: "Geometria-Bold";
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.28px;
    }
  }
  .settings-modal__head {
    display: flex;
    align-items: center;
    svg {
      margin-right: 12px;
    }
    font-family: Unbounded;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.32px;
  }
  .settings-modal__subtitle {
    margin-top: 12px;
    color: var(--700, #7F7DA6);
    font-size: 14px;
    font-family: "Geometria-Medium";
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.28px;
    margin-bottom: 12px;
  }
}
</style>