<script>
import AccentBtn from "@/components/Ui/Buttons/AccentBtn.vue";
import FreeTimer from "@/components/Lootboxes/Free/FreeTimer.vue";
import {mapGetters, mapMutations} from "vuex";

export default {
  data() {
    return {
      isLoading: false,
    }
  },
  name: "LootboxesBanner",
  computed: {
    ...mapGetters({
      settings: 'settings/settings',
      user: 'user/user',
      isAuth: 'user/isAuth'
    })
  },
  methods: {
    isMobile() {
      return window.innerWidth < 600;
    },
    ...mapMutations({
      setIsOpenGetFreeLootbox: 'config/setIsOpenGetFreeLootbox'
    }),

  },
  components: {FreeTimer, AccentBtn}
}
</script>

<template>
  <div class="banner" :style="{'background-image': isMobile() ? `url('${settings?.lootbox_banner_mobile}')` : `url('${settings?.lootbox_banner}')`}">
    <div class="information">
      <h6>{{ $t('ll_play_go') }}</h6>
      <h1 v-html="$t('ll_claim')"></h1>
      <div class="badge blue">{{ $t('ll_win_up') }}</div>
      <FreeTimer v-if="isAuth && user.next_give_free_lootbox > 0" />
      <AccentBtn :is-loading="isLoading" @click="setIsOpenGetFreeLootbox(true)" :disabled="(isAuth && user.next_give_free_lootbox > 0)" :title="$t('l_claim')" />
    </div>
  </div>
</template>

<style scoped lang="scss">
  .banner {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center right;
    margin-bottom: 16px;
    @media (max-width: 992px) {
      background-position: center center;
    }
    @media (max-width: 600px) {
      background-position: center right 30%;
      margin-left: -10px;
      margin-right: -10px;
      padding: 10px;
      background-position: bottom center;
      background-size: 100% auto;
      background-repeat: no-repeat;
      padding-left: 15px;
    }
    .information {
      padding-top: 98px;
      padding-bottom: 44px;
      @media (max-width: 992px) {
        padding-top: 40px;
        padding-bottom: 30px;
      }
      @media (max-width: 768px) {
        padding-top: 20px;
        padding-bottom: 200px;
      }
      h6 {
        color: #7F7DA6;
        font-size: 14px;
        font-family: "Geometria-Medium";
        font-weight: 500;
        letter-spacing: -0.28px;
      }
      h1 {
        display: block;
        width: 100%;
        margin: 12px 0;
        font-family: "Unbounded";
        font-size: 46px;
        font-weight: 700;
        line-height: 50px; /* 108.696% */
        letter-spacing: -0.92px;
        @media (max-width: 768px) {
          margin: 8px 0;
          font-size: 24px;
          line-height: 30px;
        }
      }
      .badge {
        padding: 13px 19px;
        margin-top: 15px;
        font-family: "Unbounded";
        font-size: 16px;
        font-weight: 700;
        letter-spacing: -0.32px;
        width: fit-content;
        @media (max-width: 768px) {
          margin-top: 0;
          font-size: 14px;
        }
        &.blue {
          border-radius: 12px;
          box-shadow: 0px -1px 2px 1px #349AFF;
          background: linear-gradient(180deg, #0582E9 0%, #06F 100%);
        }
      }
      .accent-btn {
        margin-top: 18px;
        @media (max-width: 768px) {
          margin-top: 12px;
        }
        width: fit-content;
        padding-right: 32px;
        padding-left: 32px;
      }
    }
  }
</style>