<script>
export default {
  props: {
    icon: {
      type: String
    },
    name: {
      type: String
    }
  },
  name: "SecurityBlock"
}
</script>

<template>
  <div class="security-block">
    <div class="security-block__head">
      <div class="security-block__head-title">
        <div class="security-block__head-title_icon">
          <img :src="icon" alt="">
        </div>
        <div class="security-block__head-title_name">
          {{ name }}
        </div>
      </div>
      <div class="security-block__head-action">
        <slot name="button"></slot>
      </div>
    </div>
    <div class="security-block__content">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .security-block {
    border-radius: 16px;
    padding: 8px;
    background: #131139;
    .security-block__head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .security-block__head-title {
        display: flex;
        align-items: center;
        padding: 0px 12px;
        .security-block__head-title_icon {
          display: flex;
          align-items: center;
          margin-right: 7px;
        }
        .security-block__head-title_name {
          color: #B9B8DD;
          font-family: "Geometria-Bold";
          font-size: 14px;
          font-weight: 700;
          letter-spacing: -0.28px;
        }
      }
    }
    .security-block__content {
      padding: 26px;
    }
  }
</style>