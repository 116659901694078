<script>
import {mapGetters, mapMutations} from "vuex";
import ReplyMessage from "@/components/Parts/Chat/ReplyMessage.vue";
import LastWin from "@/components/Parts/Chat/MessageItem/LastWin.vue";
import ModeratorActions from "@/components/Parts/Chat/ModeratorActions.vue";

export default {
  components: {ModeratorActions, LastWin, ReplyMessage},
  props: {
    isLast: {
      type: Boolean,
      default: false,
    },
    message: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      user: 'user/user'
    }),
    isBlured () {
      if (this.user) {
        return this.user.streamer_mode;
      }

      return false;
    },
    username () {
      if (this.user) {
        if (this.message.user.id == this.user.id) {
          return this.user.username;
        }
      }

      return this.message.user.name;
    }
  },
  methods: {
    ...mapMutations({
      setReplyMessage: 'chat/setReplyMessage'
    })
  },
  name: "MessageItem"
}
</script>

<template>
  <div class="message-item" :class="{'blur': isBlured}">
    <div class="message-item_user" >
      <img :class="{'without-avatar': !message.user.avatar}" :src="message.user.avatar ? message.user.avatar : require('@/assets/images/default-avatar.png')" alt="">
      <div class="level" v-if="message.user.level" :class="{'orange': message.user.level.level > 60}">
        <img :src="require(`@/assets/images/profile/level-${message.user.level.level}.png`)" alt="">
      </div>
    </div>
    <div class="message-body">
      <div class="message-body_details">
        <div class="message-body_details-user">
          <span>{{ username }}</span>
          <div class="is-moder" v-if="message.user.isModerator">MODER</div>
        </div>
        <div class="message-body_details-actions">
          <span>{{ message.created_date }}</span>
          <div class="reply default-hover" @click="setReplyMessage(message)">
            <img src="@/assets/images/arrow-reply.png" alt="">
          </div>
          <ModeratorActions :isLast="isLast" v-if="user && user.isModerator" :message="message" />
        </div>
      </div>
      <ReplyMessage v-if="message.reply_message" :message="message.reply_message" />
      <div class="message-body_text">
        {{ message.message }}
        <LastWin :win="message.win" v-if="message.win" />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .message-item {
    display: flex;
    align-items: flex-start;
    .message-item_user {
      width: 36px;
      height: 36px;
      margin-right: 8px;
      border-radius: 9px;
      background: $color-gray-light;
      position: relative;
      img {
        width: 36px;
        border-radius: 9px;
        height: 36px;
        transition: 0.3s;
        overflow: hidden;
        &.without-avatar {
          filter: grayscale(1);
        }
      }
      .level {
        position: absolute;
        right: -10px;
        bottom: -5px;
        img {
          width: 24px;
          height: auto;
        }
      }
    }
    .message-body {
      flex-grow: 1;
      border-radius: 8px;
      background: #0C0A33;
      padding: 8px;
      max-width: calc(100% - 44px);
      .message-body_details {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .message-body_details-user {
          display: flex;
          align-items: center;
          overflow: hidden;
          padding: 2px 0;
          span {
            color: #918FBB;
            font-size: 12px;
            line-height: 12px;
            font-family: "Geometria-Medium", sans-serif;
            letter-spacing: -0.24px;
            transition: 0.3s;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
          .is-moder {
            border-radius: 6px;
            box-shadow: 0px -1.5px 3px 0.3px #B0FF8B;
            border-bottom: none;
            color: #BBFF92;
            margin-right: 7px;
            font-size: 12px;
            line-height: 12px;
            margin-left: 10px;
            font-family: "Geometria-Bold";
            font-weight: 700;
            padding: 2px 6px;
            letter-spacing: 0.6px;

            background: linear-gradient(180deg, rgba(139, 255, 67, 0.18) 0%, rgba(127, 255, 67, 0.10) 100%);
          }
        }
        .message-body_details-actions {
          display: flex;
          align-items: center;
          span {
            color: #555379;
            font-size: 11px;
            font-family: "Geometria-Medium", sans-serif;
            line-height: 7px;
            white-space: nowrap;
            letter-spacing: -0.22px;
          }
          .reply {
            width: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 20px;
            border-radius: 7px;
            background: #2B2A4E;
            margin-left: 8px;
          }
        }
      }
      .message-body_text {
        margin-top: 3px;
        color: #B9B8DD;
        font-family: "Geometria-Medium", sans-serif;
        letter-spacing: -0.28px;
      }
    }
    &.blur {
      .message-item_user {
        img {
          border-radius: 9px;
          filter: blur(5px);
        }
      }
      .message-body {
        .message-body_details {
          .message-body_details-user {
            span {
              user-select: none;
              cursor: default;
              filter: blur(5px);
            }
          }
        }
      }
    }
  }
</style>