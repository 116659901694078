<script>
import ChatHead from "@/components/Parts/Chat/ChatHead.vue";
import ChatInput from "@/components/Parts/Chat/ChatInput.vue";
import ChatMessages from "@/components/Parts/Chat/ChatMessages.vue";
import {mapGetters} from "vuex";

export default {
  name: "MainChat",
  data () {
    return {
      chatKey: 0
    }
  },
  watch: {
    current_chat_room () {
      this.chatKey++;
    },
    isOpenChat() {
      const body = document.querySelector('body');
      const html = document.querySelector('html');
      const header = document.querySelector('.header');
      if (window.innerWidth < 992) {
        if (this.isOpenChat) {
          // Сохраняем текущее положение прокрутки
          const scrollY = window.scrollY;
          // Добавляем фиксированный стиль к body
          body.style.top = `-${scrollY}px`;
          body.style.position = 'fixed';
          body.style.left = '0';
          body.style.right = '0';
          body.style.overflow = 'hidden';

          html.style.top = `-${scrollY}px`;
          html.style.position = 'fixed';
          html.style.left = '0';
          html.style.right = '0';
          html.style.overflow = 'hidden';

          header.style.position = 'fixed';
        } else {
          // Получаем текущее положение прокрутки, которое мы сохранили ранее
          const scrollY = Math.abs(parseInt(body.style.top || '0', 10));
          // Восстанавливаем положение прокрутки
          body.style.position = '';
          body.style.height = '';
          body.style.top = '';
          body.style.left = '';
          body.style.right = '';
          body.style.overflow = '';

          html.style.position = '';
          html.style.height = '';
          html.style.top = '';
          html.style.left = '';
          html.style.right = '';
          html.style.overflow = '';

          header.style.position = 'sticky';
          // Восстанавливаем прокрутку страницы
          window.scrollTo(0, scrollY);
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      user: 'user/user',
      current_chat_room: 'settings/current_chat_room',
      isOpenChat: 'config/isOpenChat',
    }),
  },
  components: {ChatMessages, ChatInput, ChatHead}
}
</script>

<template>
  <div class="chat" ref="chat" :class="{'open': isOpenChat}">
    <ChatHead/>
    <ChatMessages :key="chatKey" v-if="current_chat_room" />
    <ChatInput/>
  </div>
</template>

<style scoped lang="scss">
.chat {
  display: flex;
  flex-direction: column;
  min-width: 304px;
  right: 0;
  position: sticky;
  z-index: 9;
  top: 64px;
  height: calc(100vh - 64px);
  width: 304px;
  border-radius: 15px;
  background: #131139;
  @media (max-width: 1600px) {
    position: fixed;
    right: 0;
    display: none;
    border-radius: 0;
    &.open {
      display: flex;
    }
  }
  @media (max-width: 992px) {
    width: calc(100% - 16px);
    min-width: calc(100% - 16px);
    left: 8px;
    height: calc(100% - 132px);
  }
}
</style>