<script>
import {mapGetters, mapMutations} from "vuex";
import UiClose from "@/components/Ui/UiClose.vue";
import AccentBtn from "@/components/Ui/Buttons/AccentBtn.vue";
import {sellNft} from "@/services/user";
import extractErrors from "@/helpers/extractErrors";
import {sellChangePrice} from "@/services/user";
import {vue} from "@/main";
export default {
  data () {
    return {
      isLoading: false,
      price: null,
    }
  },
  components: {AccentBtn, UiClose},
  methods: {
    ...mapMutations({
      setCloseSellNft: 'config/setCloseSellNft'
    }),
    close() {
      this.setCloseSellNft()
    },
    async sell () {
      await sellNft(this.nft.id, this.price).then((response) => {
        this.$root.$emit('success', {
          title: "Success",
          text: this.$t('up_for_sale')
        });

        this.$root.$emit('selling.' + this.nft.unique_id);
        this.$root.$emit('unshift-sell', response.data);
        this.setCloseSellNft()
      }).catch(err => {
        const errors = extractErrors(err);
        errors.forEach(item => {
          vue.$root.$emit('error', {
            title: 'Error',
            text: item
          })
        })
      }).finally(() => {
      })
    },
    changeSellPrice () {
      const price = this.price;
      sellChangePrice(this.nft.id, price).then(() => {
        this.$root.$emit('success', {
          title: "Success",
          text: this.$t('success_changed_price')
        });
        this.$root.$emit('selling.change-price.' + this.nft.id, price);
        this.setCloseSellNft()
      }).catch(err => {
        const errors = extractErrors(err);
        errors.forEach(item => {
          vue.$root.$emit('error', {
            title: 'Error',
            text: item
          })
        })
      })
    },
    async sellOrChange () {
      if (!this.price) return;

      this.isLoading = true;

      if(!this.nft.change) {
        await this.sell()
      } else {
        await this.changeSellPrice();
      }

      this.price = null;
      this.isLoading = false
    }
  },
  computed: {
    ...mapGetters({
      sellNft: 'config/sellNft'
    }),
    nft () {
      return this.sellNft.nft
    }
  },
  name: "SellNftModal"
}
</script>

<template>
  <div class="sell-nft">
    <UiClose @click="setCloseSellNft" />
    <div class="sell-nft__head">
      <div class="circle">
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="22" viewBox="0 0 18 22" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M9.00071 0.625C6.92964 0.625 5.25071 2.30393 5.25071 4.375V5.38477C3.3584 5.51674 1.80123 6.97086 1.56273 8.87886L0.562728 16.8789C0.264301 19.2663 2.12585 21.375 4.53184 21.375H13.4696C15.8756 21.375 17.7371 19.2663 17.4387 16.8789L16.4387 8.87886C16.2002 6.97086 14.643 5.51674 12.7507 5.38477V4.375C12.7507 2.30393 11.0718 0.625 9.00071 0.625ZM11.2507 5.375V4.375C11.2507 3.13236 10.2434 2.125 9.00071 2.125C7.75807 2.125 6.75071 3.13236 6.75071 4.375V5.375H11.2507Z" fill="#C489FF"/>
        </svg>
      </div>
      <div class="sell-nft__head-text" v-if="!nft.change">
        <strong>{{ $t('sell_nft') }}</strong>
        <span>{{ $t('set_fair_price') }}</span>
      </div>

      <div class="sell-nft__head-text" v-else>
        <strong>{{ $t('change_sell_price') }}</strong>
        <span>{{ $t('set_new_price') }}</span>
      </div>
    </div>

    <div class="sell-nft__info">
      <img :src="nft.image_url" alt="">
      <div class="sell-nft__info-name">{{ nft.name }}</div>
    </div>

    <div class="cost">
      <div class="cost-coin">
        <div class="cost-coin-icon">
          <img src="@/assets/images/sneg-coin.svg" alt="">
        </div>
        <div class="cost-coin-name">
          SNEG
        </div>
      </div>
      <div class="cost-input">
        <input @keydown.enter="sellOrChange" v-model="price" type="number" :placeholder="$t('price')">
        <div class="holder">{{ $t('your_price') }}</div>
      </div>

    </div>

    <AccentBtn :is-loading="isLoading" @click="sellOrChange" :disabled="!price" :title="nft.change ? $t('change_price') : $t('place_on_marketplace')" />
  </div>
</template>

<style scoped lang="scss">
  .sell-nft {
    border-radius: 20px;
    position: relative;
    background: #131139;
    width: 357px;
    padding: 20px;
    box-shadow: 0px 105px 153.7px 0px rgba(1, 0, 25, 0.55);
    .sell-nft__head {
      display: flex;
      align-items: center;
      .circle {
        width: 48px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 40px;
        background: rgba(182, 127, 255, 0.10);
        margin-right: 14px;
      }
      .sell-nft__head-text {
        strong,span {
          display: block;
          width: 100%;
        }
        strong {
          margin-bottom: 6px;
          font-family: "Unbounded";
          font-size: 18px;
          font-weight: 700;
          letter-spacing: -0.36px;
        }
        span {
          color: #918FBB;
          font-size: 14px;
          font-family: "Geometria-Medium";
          font-weight: 500;
          letter-spacing: -0.28px;
        }
      }
    }
    .sell-nft__info {
      margin: 25px auto;
      width: 200px;
      height: 200px;
      position: relative;
      border-radius: 24px;
      overflow: hidden;
      &:before {
        position: absolute;
        bottom: 0;
        content: "";
        display: block;
        height: 50%;
        width: 100%;
        left: 0;
        z-index: 2;
        background: linear-gradient(0deg, #1F1D51 0%, rgba(40, 37, 91, 0.00) 100%);
      }
      .sell-nft__info-name {
        position: absolute;
        z-index: 2;
        bottom: 20px;
        left: 20px;
        max-width: calc(100% - 40px);
        font-family: "Unbounded";
        font-size: 16px;
        font-weight: 700;
        letter-spacing: -0.32px;
      }
      img {
        width: 100%;
        height: 99%;
      }
    }
    .cost {
      border-radius: 12px;
      background: rgba(0, 3, 42, 0.50);
      align-items: center;
      padding: 4px;
      width: 100%;
      display: flex;
      margin-bottom: 14px;
      .cost-coin {
        display: flex;
        align-items: center;
        padding: 10px;
        border-radius: 9px;
        background: rgba(182, 127, 255, 0.10);
        .cost-coin-icon {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 20px;
          height: 20px;
          img {
            filter: drop-shadow(0px 0px 5px #D3A7FF);
            max-height: 100%;
            max-width: 100%;
          }

          margin-right: 6px;
        }
        .cost-coin-name {
          padding-top: 2px;
          padding-right: 15px;
          display: flex;
          align-items: center;
          padding-top: 2px;
          line-height: 14px;
          font-size: 12px;
          font-weight: 700;
          letter-spacing: -0.24px;
          font-family: "Geometria-Bold";
        }
      }
      .cost-input {
        flex-grow: 1;
        overflow: hidden;
        position: relative;
        display: flex;
        align-items: center;
        .holder {
          color: #7F7DA6;
          padding-left: 15px;
          font-family: "Geometria-Medium";
          font-size: 11px;
          display: flex;
          align-items: center;
          font-weight: 500;
          white-space: nowrap;
          letter-spacing: -0.22px;
        }
        padding: 0 16px;
        padding-top: 3px;
        height: 100%;
        input {
          padding: 5px 0;
          outline: none;
          border: none;
          height: 100%;
          width: 100%;
          font-family: "Unbounded";
          font-size: 12px;
          font-weight: 700;
          letter-spacing: -0.24px;
          background: transparent;
        }
      }
    }
  }
</style>