<script>
import LogoutBtn from "@/components/Auth/Buttons/LogoutBtn.vue";
import TopProfileTab from "@/components/Profile/TopProfileTab.vue";
import {mapGetters, mapMutations} from "vuex";
import diffDateToNow from "@/helpers/diffDateToNow";
export default {
  data () {
    return {
      diffDateToNow
    }
  },
  computed: {
      ...mapGetters({
        user: 'user/user',
        activeTab: 'config/profileTab'
      })
  },
  methods: {
    ...mapMutations({
      setProfileTab: 'config/setProfileTab'
    }),
  },
  watch: {
    '$route': function() {
      if(this.$route.query.tab) {
        this.setProfileTab(this.$route.query.tab);
      }
    }
  },
  name: "TopProfile",
  components: {TopProfileTab, LogoutBtn},
  mounted() {
    if(this.$route.query.tab) {
      this.setProfileTab(this.$route.query.tab);
    }
  }
}
</script>

<template>
  <div class="top-profile">
    <div class="top-profile__info">
      <div class="top-profile__info-text">
        <h2>{{ $t('account.heading_1') }}</h2>
        <div class="with-block">
          <div class="icon">
            <img src="@/assets/images/profile/clock-icon.svg" alt="">
          </div>
          <div class="time">
            {{ diffDateToNow(user.created_at) }}
          </div>
          <div class="additional">
            {{ $t('account.with') }}
          </div>
        </div>
      </div>
      <div class="top-profile__info-actions">
        <LogoutBtn/>
      </div>
    </div>
    <div class="top-profile__tabs">
      <TopProfileTab @click="$router.push(`/profile?tab=profile`)"
                     :is-active="activeTab === 'profile'"
                     :name="$t('account.tab_profile')"
                     :icon="require('@/assets/images/profile/profile-tab.png')"
      />
      <TopProfileTab @click="$router.push(`/profile?tab=game_history`)"
                     :is-active="activeTab === 'game_history'"
                     :name="$t('account.tab_gh')"
                     :icon="require('@/assets/images/profile/profile-tab-2.png')"/>
      <TopProfileTab @click="$router.push(`/profile?tab=nft`)"
                     :is-active="activeTab === 'nft'"
                     :name="$t('account.tab_nft')"
                     :icon="require('@/assets/images/profile/profile-tab-3.png')"/>
      <TopProfileTab @click="$router.push(`/profile?tab=transactions`)"
                     :is-active="activeTab === 'transactions'"
                     :name="$t('account.tab_transaction')"
                     :icon="require('@/assets/images/profile/profile-tab-4.png')"/>
      <TopProfileTab @click="$router.push(`/profile?tab=security`)"
                     :is-active="activeTab === 'security'"
                     :name="$t('account.tab_security')"
                     :icon="require('@/assets/images/profile/profile-tab-5.png')"/>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .top-profile {
    border-radius: 20px;
    background: var(--10, rgba(200, 197, 255, 0.10));
    overflow: hidden;
    padding: 8px;
    width: 100%;
    .top-profile__info {
      padding-left: 24px;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      @media (max-width: 768px) {
        padding-left: 6px;
      }
      .top-profile__info-text {
        padding-top: 24px;
        padding-bottom: 24px;
        @media (max-width: 768px) {
          padding-top: 10px;
          padding-bottom: 13px;
        }
        h2 {
          font-family: "Unbounded";
          font-size: 24px;
          font-weight: 700;
          letter-spacing: -0.48px;
          margin-bottom: 8px;
          display: block;
          @media (max-width: 768px) {
            font-size: 18px;
          }
        }
        .with-block {
          display: flex;
          align-items: center;
          padding: 8px;
          border-radius: 10px;
          background: rgba(200, 197, 255, 0.10);
          @media (max-width: 575px) {
            margin-top: 15px;
          }
          .icon {
            width: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 4px;
            img {
              max-width: 100%;
              max-height: 100%;
            }
          }
          .time {
            font-family: "Unbounded";
            font-size: 12px;
            font-weight: 700;
            letter-spacing: -0.24px;
            display: flex;
            text-transform: uppercase;
            align-items: center;
            margin-right: 10px;
            @media (max-width: 768px) {
              padding-top: 2px;
              font-size: 11px;
            }
          }
          .additional {
            font-family: "Unbounded";
            opacity: 0.6;
            display: flex;
            align-items: center;
            font-size: 12px;
            font-weight: 700;
            letter-spacing: -0.24px;
            @media (max-width: 768px) {
              padding-top: 2px;
              font-size: 11px;
            }
          }
        }
      }
    }
    .top-profile__tabs {
      width: 100%;
      border-radius: 20px;
      background: #02002A;
      display: flex;
      grid-gap: 6px;
      padding: 6px;
      .tab {
        flex: 1;
      }
      @media (max-width: 768px) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        .tab:first-child {
          grid-column: span 2;
        }
      }
    }

    .top-profile__info-actions {
      @media (max-width: 768px) {
        position: absolute;
        right: 10px;
        top: 10px;
      }
    }

    position: relative;
  }
</style>