<script>
import CurrentLevel from "@/components/Profile/CurrentLevel.vue";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "LoyalBanner",
  computed: {
    ...mapGetters({
      isAuth: 'user/isAuth',
      user: 'user/user'
    }),
  },
  methods: {
    ...mapActions({
      openRegister: 'config/openRegister'
    })
  },
  components: {CurrentLevel}
}
</script>

<template>
  <div class="loyal-banner" :class="{'isAuth': isAuth}">
    <img src="@/assets/images/loyal/left-level.png" alt="" class="holder">
    <img src="@/assets/images/loyal/right-level.svg" alt="" class="holder right">
    
    <div class="loyal-banner__logo">
      <img src="@/assets/images/white-logo.svg" alt="">
    </div>

    <div class="loyal-banner_strong" v-html="$t('ll_title')"></div>

    <div class="loyal-banner_main-img">
      <img src="@/assets/images/loyal/level-6.png" alt="">
    </div>

    <div class="loyal-banner__divider"></div>

    <div class="current" v-if="isAuth">
      <div class="current-heading">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M4.5 0.5C2.29086 0.5 0.5 2.29086 0.5 4.5V11.5C0.5 13.7091 2.29086 15.5 4.5 15.5H11.5C13.7091 15.5 15.5 13.7091 15.5 11.5V4.5C15.5 2.29086 13.7091 0.5 11.5 0.5H4.5ZM8.75 4.25C8.75 3.83579 8.41421 3.5 8 3.5C7.58579 3.5 7.25 3.83579 7.25 4.25V11.75C7.25 12.1642 7.58579 12.5 8 12.5C8.41421 12.5 8.75 12.1642 8.75 11.75V4.25ZM11.75 5C12.1642 5 12.5 5.33579 12.5 5.75V10.25C12.5 10.6642 12.1642 11 11.75 11C11.3358 11 11 10.6642 11 10.25V5.75C11 5.33579 11.3358 5 11.75 5ZM5 5.75C5 5.33579 4.66421 5 4.25 5C3.83579 5 3.5 5.33579 3.5 5.75V10.25C3.5 10.6642 3.83579 11 4.25 11C4.66421 11 5 10.6642 5 10.25V5.75Z" fill="white"/>
        </svg>
        {{ $t('ll_current') }}
      </div>
      <CurrentLevel :hide-button="true" />
    </div>

    <button @click="$router.push('/games')" v-if="isAuth" class="play default-hover">
      {{ $t('ll_play') }}
    </button>
    <button @click="openRegister" v-else class="play default-hover">
      {{ $t('ll_register') }}
    </button>
  </div>
</template>

<style scoped lang="scss">
.loyal-banner {
  width: 330px;
  min-width: 330px;
  margin-right: 8px;
  height: 572px;
  border-radius: 20px;
  background: linear-gradient(180deg, #9C21FC 0%, #4200CD 100%);
  box-shadow: 0px 105px 153.7px 0px rgba(1, 0, 25, 0.55);
  position: relative;
  padding-top: 32px;
  display: flex;
  padding-bottom: 9px;
  padding-right: 8px;
  padding-left: 8px;
  flex-direction: column;
  @media (max-width: 1170px) {
    height: auto;
    padding-top: 4px;
    padding-right: 4px;
    padding-left: 4px;
    padding-bottom: 4px;
    width: 100%;
    min-width: 100%;
    margin-bottom: 8px;
    margin-right: 0;
  }
  .loyal-banner__divider {
    flex-grow: 1;
  }
  .loyal-banner_main-img {
    width: 198px;
    margin: 0 auto;
    height: 198px;
    position: absolute;
    top: calc(40% - 100px);
    left: calc(50% - 100px);
    img {
      filter: drop-shadow(0px 38px 34px #000);
    }
  }
  .loyal-banner_strong {
    font-family: Unbounded;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* 116.667% */
    letter-spacing: -0.48px;
    text-align: center;
    width: 100%;
  }
  .loyal-banner__logo {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 10px;
    img {
      width: 133px;
    }
  }
  .holder {
    position: absolute;
    top: 65px;
    left: 0;
    &.right {
      left: unset;
      right: 0;
    }
  }
  .play {
    color: var(--100, #0C0A33);
    font-size: 14px;
    font-style: normal;
    font-family: "Geometria-Bold";
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.28px;
    padding: 16px 0;
    border-radius: 11px;
    background: linear-gradient(180deg, #F7FC00 -4.55%, #FFEA31 98.49%);
    cursor: pointer;
  }
  .current {
    padding: 11px 16px;
    border-radius: 18px;
    background: var(--50-Dark, rgba(0, 3, 42, 0.50));
    overflow: hidden;
    margin-bottom: 6px;
    .current-heading {
      margin-bottom: 11px;
      display: flex;
      align-items: center;
      svg {
        margin-right: 6px;
      }
      color: var(--900, #B9B8DD);
      font-family: "Geometria-Bold";
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.6px;
    }
    ::v-deep {
      .level {
        height: auto;
        .level-info {
          margin-top: 0 !important;
          span,strong {
            text-align: left !important;
          }
        }
        .level-image {
          display: none;
        }
        .progress-desc {
          max-width: 80%;
          @media (max-width: 768px) {
            max-width: 60%;
            text-align: left;
          }
        }
      }
    }
  }

  @media (max-width: 1170px) {
    &.isAuth {
      border-radius: 20px;
      background: linear-gradient(180deg, #9C21FC 0%, #4200CD 100%), #131139;
      box-shadow: 0px 105px 153.7px 0px rgba(1, 0, 25, 0.55);
      .play {
        border-radius: 15px;
      }
      div:not(.current) {
        display: none;
      }
      .holder {
        display: none;
      }
      .current-heading {
        display: flex !important;
      }
      .current {
        ::v-deep {
          .level {
            display: block !important;
          }
        }
      }
    }
    &:not(.isAuth) {
      height: 170px;
      display: flex;
      padding: 20px;
      flex-direction: column;
      .holder {
        display: none;
      }
      .loyal-banner__logo {
        display: none;
      }
      .loyal-banner_strong {
        text-align: left;
        flex-grow: 1;
        display: flex;
        @media (max-width: 768px) {
          font-size: 20px;
          margin-bottom: 10px;
          line-height: 23px;
        }
      }
      .loyal-banner__divider {
        display: none;
      }
      .loyal-banner_main-img {
        height: auto;
        right: 20px;
        top: 0px;
        left: unset;
        width: auto;
        justify-content: flex-end;
        margin: 0;
        img {
          width: 100px;
        }
      }
    }
  }
}
</style>