<script>
import StatisticBlock from "@/components/Profile/StatisticBlock.vue";
import StatisticItem from "@/components/Profile/StatisticItem.vue";
import {mapGetters} from "vuex";

export default {
  computed: {
    ...mapGetters({
      user: 'user/user'
    })
  },
  name: "GamingStatistic",
  components: {StatisticItem, StatisticBlock}
}
</script>

<template>
  <div class="gaming-statistic">
    <StatisticBlock :icon="require('@/assets/images/profile/gaming-icon.svg')" :name="$t('account.statistics.title')">
      <div class="statistic-wrapper">
        <StatisticItem :info-content="$t('wager_text')" :with-info="true" :value="user.statistic.total_bets" :desc="$t('account.statistics.total_wager')" :icon="require('@/assets/images/sneg-coin.svg')"/>
        <StatisticItem :with-info="false" :value="user.statistic.wins" :desc="$t('account.statistics.wins')" :icon="require('@/assets/images/wins-icon.svg')"/>
        <StatisticItem :with-info="false" :value="user.statistic.bets" :desc="$t('account.statistics.bets')" :icon="require('@/assets/images/bets-icon.svg')"/>
      </div>
    </StatisticBlock>
  </div>
</template>

<style scoped lang="scss">
  .gaming-statistic {
    .statistic-wrapper {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 9px;
      .statistic-item:first-child {
        grid-column: 1 / -1;
      }


    }
  }
</style>