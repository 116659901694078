<script>
import SaleItem from "@/components/Profile/Nft/SaleItem.vue";
import SaleItemHead from "@/components/Profile/Nft/SaleItemHead.vue";
import {getNftsSales} from "@/services/user";
import IntersectionTrigger from "@/components/IntersectionTrigger.vue";
import EmptyState from "@/components/EmptyState.vue";
import UiLoader from "@/components/UiLoader.vue";
export default {
  data () {
    return {
      type: null,
      page: 1,
      last_page: 1,
      sales: [],
      loading: false,
    }
  },
  methods: {
    async fetchSales(push = false) {
      if (this.page > this.last_page)
      {
        return;
      }

      this.loading = true;

      await getNftsSales({
        page: this.page,
        type: this.type,
      }).then(response => {
        if (push) {
          this.sales = [...this.sales, ...response.data.data]
        } else {
          this.sales = response.data.data;
        }

        this.last_page = response.data.meta.last_page;
        this.page = response.data.meta.current_page;
      }).finally(() => {
        this.loading = false;
      })
    },
    upPage() {
      this.page++;
      this.fetchSales(true)
    },
  },
  watch: {
    type() {
      this.page = 1;
      this.last_page = 1;
      this.fetchSales()
    }
  },
  mounted() {
    this.fetchSales()
    this.$root.$on('unshift-sell', (data) => {
      this.sales.unshift(data);
    })
    this.$root.$on('unsell-nft', (nftId) => {
      const index = this.sales.findIndex(item => item.nft.id == nftId)

      if (index > -1) {
        this.sales.splice(index, 1)
      }
    })
  },
  name: "MyNftSales",
  components: {UiLoader, EmptyState, IntersectionTrigger, SaleItemHead, SaleItem}
}
</script>

<template>
<div class="sales">
  <div class="sales__filter">
    <div class="sales__filter-head">
      <div class="sales__filter-head-icon">
        <img src="@/assets/images/nft/sale-icon.svg" alt="">
      </div>
      <strong>{{ $t('account.nfts.my_sales') }}</strong>
    </div>
    <div class="sales__filter-tabs">
      <div @click="type = null" :class="{'active': !type}" class="sales__filter-tab default-hover">{{ $t('account.nfts.all_sales') }}</div>
      <div @click="type = 'selling'" :class="{'active': type === 'selling'}" class="sales__filter-tab default-hover">{{ $t('account.nfts.current_sales') }}</div>
      <div @click="type = 'sold'" :class="{'active': type === 'sold'}" class="sales__filter-tab default-hover">{{ $t('account.nfts.history_sales') }}</div>
    </div>
  </div>

  <div class="sales-table">
    <SaleItemHead/>
    <UiLoader v-if="loading"></UiLoader>
    <template v-else>
      <div class="items" v-if="sales.length">
        <SaleItem v-for="(sale, i) in sales" :key="i" :sale="sale" />
        <IntersectionTrigger @intersect="upPage" />
      </div>
      <EmptyState :absolute="true" :heading="$t('no_records')" v-else />
    </template>
  </div>
</div>
</template>

<style scoped lang="scss">
  .sales {
    overflow: hidden;
    flex-grow: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    .sales__filter {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .sales__filter-head {
        display: flex;
        align-items: center;
        .sales__filter-head-icon, strong {
          display: flex;
          align-items: center;
        }
        .sales__filter-head-icon {
          width: 20px;
          margin-right: 15px;
          @media (max-width: 768px) {
            width: 15px;
            img {
              max-width: 100%;
            }
            margin-right: 12px;
          }
        }
        strong {
          font-family: "Unbounded";
          line-height: 14px;
          font-size: 16px;
          font-weight: 700;
          letter-spacing: -0.32px;
          @media (max-width: 768px) {
            font-size: 12px;
          }
        }
      }
      .sales__filter-tabs {
        border-radius: 26px;
        background: #2B2A4E;
        display: flex;
        grid-gap: 4px;
        padding: 4px;
        .sales__filter-tab {
          padding: 6px 12px;
          border-radius: 30px;
          color: #7F7DA6;
          font-family: "Geometria-Medium";
          font-size: 14px;
          font-weight: 500;
          letter-spacing: -0.28px;
          &.active {
            background: #FFE70C;
            color: #0C0A33;
          }
          @media (max-width: 768px) {
            font-size: 12px;
            padding-top: 8px;
          }
        }
      }
    }
    .sales-table {
      flex-grow: 1;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      margin-top: 10px;
      position: relative;
      .items {
        flex-grow: 1;
        overflow-y: scroll;
        &::-webkit-scrollbar {
          display: none;
        }
      }
      .sale-item + .sale-item {
        margin-top: 12px;
      }
    }
  }
</style>